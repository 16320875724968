@import "project/djv-icon-font/css/comunes";
@import "project/_vars";
@import "project/_mixins";
@import "project/_common";
@import "project/_layout";

// HEADER global y menu
@import "project/_header-grid";
@import "project/_main-menu";
@import "project/_main-menu-sticky";
@import "project/_main-menu-mobile";
@import "project/_user-info";
@import "project/_cart-preview";
//@import "project/_djv-temporal";
@import "project/_footer";

@import "project/_carousel";
@import "project/_carouselh";
@import "project/_home";
@import "project/_breadcrumbs";

@import "project/_product-sheet";
@import "project/_product-img";
@import "project/_product-actions";
@import "project/_product-prices";
@import "project/_product-quantity";
@import "project/_product-variants";
@import "project/_product-suport";
//@import "project/_product-share";
@import "project/_tablas";

@import "project/_product-miniature";
@import "project/_product-accesories";
@import "project/_pagination";
@import "project/_products-list";
@import "project/_navigation-left";
@import "project/_navigation-left-filters";
@import "project/_home-product-list";
@import "project/_manufacturers";
@import "project/_troncales";
@import "project/_cms";
//@import "project/_ph-simpleblog.scss";

@import "project/_cart";
@import "project/_authentication";
@import "project/_checkout";
@import "project/_order-confirmation";

@import "project/_my-account";
@import "project/_my-addresses";
@import "project/_my-identity";
@import "project/_my-history";

@import "project/_forms";
@import "project/_contact-form";
@import "project/_pagenotfound";
@import "project/_searcher";


@import "project/shame";
@import "project/_notifications";
@import "project/_expandtext";
@import "project/_modal";
@import "project/_zoom-pan";
.watchmework { font-size: 1.75}
.test { font-size: 1.75 }
