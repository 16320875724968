#product{
    .product-buy-actions{
        border: 1px solid $gris1;
        padding: 16px;
        margin-bottom: 16px;
        margin-left: 10%;
        margin-right: 10%;
        @media(max-width: $screen-lg){
            margin-left: auto;
            margin-right: auto;
        }
        #add-to-cart-or-refresh{

            display: grid;
            grid-template-areas:
            "variants variants"
            "quantity quantity"
            "price addtocart"
            "sequra sequra"
            "textoadicional textoadicional";

            grid-template-columns: repeat(2,50%);

            @media(max-width: $screen-md){
                grid-template-areas:
                "variants variants"
                "quantity quantity"
                "price price"
                "addtocart addtocart"
                "textoadicional textoadicional";
            }

            .product-prices{
                grid-area:price;
                align-self: center;
                text-align: center;
                .product-price{
                    margin: 0;
                }
            }
            .product-quantity{
              grid-area:quantity;
              align-self: center;
              margin-bottom: 10px;
            }
            .product-add-to-cart-button{
              grid-area:addtocart;
            }
            .sequra-container{
              grid-area: sequra;
              padding-top: 1.5rem;
              .sequra-promotion-widget{
                transform: scale(75%);
              }
            }
            .product-variants{
                grid-area:variants;
                label{
                    @include fuente($font-first , $font-bold);
                }
                select{
                    width: 300px;
                    margin-bottom: 20px;
                }
                .product-variants-color{
                    ul{
                        li{
                            .swap-bg{
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                }
                .product-variants-select{
                    @media(max-width: $screen-md){
                        select{
                            margin-bottom: 5px;
                        }
                    }
                    @media(max-width: $screen-md){
                        a{
                            display: block;
                            margin-bottom: 10px;
                        }
                    }
                }
            }
            .texto-adicional{
                grid-area:textoadicional;
                margin: 10px auto;
                display: block;
                padding-top: 20px;
                border-top: 1px solid $gris3;
                width: 100%;
                .container{
                    width: 420px;
                    @media(max-width: $screen-md){
                        max-width: 90%;
                    }
                }
                img{
                    float: left;
                    width: 75px;
                    margin-right: 10px;
                    margin-top: -12px;
                }
                p{

                }
                .links-adicionales{
                    text-align: center;
                    padding-top: 10px;
                    margin-top: 45px;
                    border-top: 1px solid $gris3;
                }
                .out-of-stock{
                    p{
                        color:$gris6;
                        font-size: $font-xs;
                        text-align: center;
                        strong{
                            color : darken($complementario1, 25%);
                            font-weight: 800;
                        }
                        a{
                            color:$gris6;
                            font-size: $font-xs;
                        }
                    }
                }
            }
        }
        .djv-product-additional-info-delivery{
            margin: -10px auto 10px;
            font-size: $font-xs;
            color:$complementario1;
            line-height: 16px;
            width: 70%;
            text-align: center;
            border-bottom: 1px solid $gris4;
            p{
                @include fuente($font-first , $font-medium);
                a{
                    color:inherit;
                    text-decoration: underline;
                    text-align: center;
                }
                .djv-icon-truck{
                    width: 50px;
                    display: block;
                    margin: auto;
                    font-size: $font-xl*1.1;
                    margin-bottom: 0px;
                }
            }
        }
        .product-add-to-cart-button{
            //border-top: 1px solid $gris3;
            .gran-tirada{
              padding-top: 16px;
              color: $gris8;
              font-size: $font-smd;
              text-align: center;
              @media(max-width: $screen-md){
                  padding: 6px 0 0;
              }
            }
            .add-to-cart{
                display: flex;
                align-items: center;
                margin-bottom: 0;
                border-radius: 3px;
                text-align: left;
                background: #e88e13;
                background: -webkit-linear-gradient(#f6a212, #e88e13);
                background: -o-linear-gradient(#f6a212, #e88e13);
                background: -moz-linear-gradient(#f6a212, #e88e13);
                background: linear-gradient(#f6a212, #e88e13);
                border-color: #d97f00;
                -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
                -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
                box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
                height: 50px;
                @include fuente($font-first , $font-regular);
                font-size: $font-bmd;
                color: white;
                outline: none;
                vertical-align: middle;
                cursor: pointer;
                border: 1px solid transparent;
                white-space: nowrap;
                padding-top: 5px;
                padding-right: 84px;
                width: 220px;
                margin-left: auto;
                margin-right: auto;

                &:before{
                    content:'\0043';
                    display: inline-block;
                    font-family: 'comunes';
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    font-size: 32px;
                    float: left;
                    margin-right: 20px;
                    position: relative;
                    left: 10px;
                }
            }
        }
        .djv-advertencia-descatalogado{
            display: block;
            width: 100%;
            padding: 16px;
            color: red;
            text-align: center;
            font-size: $font-lg;
        }

    }
    .page-content{
        .product-add-to-cart-button{
            @media(max-width: $screen-md){
                display: block;
            }
        }
        .personalizalo, .sinpersonalizar{
            display: block;
            margin: auto;
            margin-bottom: 0;
            border-radius: 3px;
            text-align: left;
            -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
            -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
            box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
            height: 50px;
            width: 205px;
            font-size: 18px;
            font-weight: 400;
            color: white;
            outline: none;
            vertical-align: middle;
            cursor: pointer;
            border: 1px solid transparent;
            white-space: nowrap;
            @media(max-width: $screen-md){
                width: 130px;
            }
            img{
                width: 50px;
                top: -20px;
                position: relative;
                margin-right: 10px;
            }
        }
        .personalizalo{
            padding: 0 20px 0 5px;
            background: #e88e13;
            background: -webkit-linear-gradient(#f6a212, #e88e13);
            background: -o-linear-gradient(#f6a212, #e88e13);
            background: -moz-linear-gradient(#f6a212, #e88e13);
            background: linear-gradient(#f6a212, #e88e13);
            border-color: #d97f00;
            @media(max-width: $screen-md){
                padding: 0 5px;
                margin: auto;
                text-align: center;
                font-size: $font-sm;
                img{
                    display: none;
                }
            }
        }
        .sinpersonalizar{
            padding: 0 20px;
            background: #e85513;
            background: -webkit-linear-gradient(#ff5608, #e85513);
            background: -o-linear-gradient(#ff5608, #e85513);
            background: -moz-linear-gradient(#ff5608, #e85513);
            background: linear-gradient(#ff5608, #e85513);
            border-color: #ff6719;
            text-align: center;
            @media(max-width: $screen-md){
                padding: 0 5px;
                font-size: $font-sm;
            }
        }
    }
}
