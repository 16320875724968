#module-djv_navigation-nsearch {
    #djv-two-columns-layer.force-one-column{
        @media(max-width: $screen-md){
            img{
                max-width: 100%;
            }
            .product-miniature{
                img{
                    max-width: 160px;
                }
            }
        }
        display: block;
        max-width: 100vw;
        #left-column{
            display: none;
        }
        #content-wrapper{
            width: 100%;
        }
    }
    #djv-two-columns-layer:not(.force-one-column){
        @media(max-width: $screen-md){
            img{
                max-width: 100%;
            }
            .product-miniature{
                img{
                    max-width: 160px;
                }
            }
        }
        .textoSeo{
            width: 133%;
            margin-left: -33%;
            @media(max-width: $screen-sm){
                width: auto;
                margin-left: auto;
            }
            .full-width{
                background-color: #ECECEC;
                >img{
                    display: block;
                    margin: 0 auto;
                    max-width: 100vw;
                }
            }
            .regular-width{
                padding-left: 25%;
                margin-top: 50px;
                h2{
                  text-align: left;
                  font-size: $font-lg;
                  margin-top: 30px;
                }
                a{
                  font-weight: 400;
                }
                @media(max-width: $screen-md){
                    padding-left: 0;
                }
            }
            .serigrafiadas{
                .grid-3{
                    @media(max-width: $screen-md){
                        display: block;
                        > div {
                            display: block;
                        }
                    }
                    img{
                        margin: 40px auto;
                    }
                }
            }
        }
    }
}
#module-djv_navigation-nsearch{
    $margenesSectionTroncal : 120px auto;
    $margenesSectionTroncalsm : 40px auto;
    .djv-navigation-nsearch-header{
        background-color: white;
        aside{
            display: none;
        }
    }
    #sigue_leyendo{
        @include fuente($font-first, $font-bold);
        font-size: $font-smd;
        color: $primario2;
        cursor: pointer;
    }
    .punto-diferenciador{
        margin: 80px auto;
        position: relative;
        display: grid;
        grid-template-columns: 33% 33% 33%;
        @media(max-width: $screen-md){
            display: block;
        }
        .punto-diferenciador-element {
            position: relative;
            cursor: pointer;
            @media(max-width: $screen-md){
                display: block;
                min-height: 90px;
            }
            i{
                display: block;
                width: 90px;
                margin-right: 10px;
                margin-bottom: 10px;
                float: left;
                img{
                    width: 70%;
                }
            }
            > h3{
                @include fuente($font-first , $font-bold);
                font-size: $font-slg;
                margin-bottom: 0px;
                text-align: left;
            }
            p{
                @include fuente($font-first , $font-light);
                font-size: $font-smd;
                text-align: left;
            }
            .mensaje-hover{
                //display: none;
                opacity: 0;
                position: absolute;
                left: 57%;
                top: 30px;
                transform: translateX(-50%);
                width: 270px;
                padding: 15px 20px 10px;
                background-color: $gris9;
                border-radius: 4px;
                color: white;
                transition: all 500ms;
                cursor: pointer;
                @media(max-width: $screen-md){
                    margin-left: -10px;
                }
                &.activeMovil{
                    opacity: 1;
                    display: block;
                    position: relative;
                    top:0;
                    margin-bottom: 20px;
                }
                h4{
                    @include fuente($font-first , $font-bold);
                    font-size: $font-smd;
                    padding-bottom: 10px;
                    border-bottom: 1px solid white;
                    text-align: left;
                }
                p{
                    font-size: $font-xs;
                    margin-bottom: 0;
                }
                &::before{
                    content: "▲︎";
                    color: $gris9;
                    position: absolute;
                    top:-18px;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }
    .punto-diferenciador-cuatro{
        @extend .punto-diferenciador;
        grid-template-columns: 25% 25% 25% 25%;
      }
    .porque-elegir{
        h2{
            @include fuente($font-second,$font-regular);
            padding: 20px;
        }
        h3{
          text-align: center;
        }
        div{
            article{
                text-align: left;
                @media(max-width: $screen-md){
                    padding: 16px;
                }
                header{
                    h3{
                        @include fuente($font-first, $font-bold);
                        font-size: $font-lg;
                        color: black;
                        text-align: left;
                    }
                }
                p{
                    @include fuente($font-first, $font-regular);
                    font-size: $font-md;
                }
            }
        }
        .grid-2{
            @media(max-width: $screen-md){
                display: block;
            }
        }
    }
    .galeria{
        background-color: $gris1;
        @media(max-width: $screen-md){
            padding: 20px 10px;
        }
        &.full-width{
            background-color: #EDEDED;
        }
        .djv_carouselh{
            @include djv-container();
        }
        .galeria-content{
            @include djv-container();
            display: flex;
            @media(max-width: $screen-md){
                display: flex;
            }
            .item{
                width: calc(33% );
                text-align: center;
                display: inline-block;
                padding: 0 11px;
                @media(max-width: $screen-md){
                    width: 100%;
                    margin-bottom: 30px;
                }
                .icono{
                    display: block;
                    width: 55px;
                    margin-left: auto;
                    margin-right: auto;
                }
                h3{
                    @include fuente($font-first,$font-bold);
                    color: black;
                    margin-top: 30px;
                }
                p{
                    @include fuente($font-first,$font-regular);
                    color: black;
                    white-space: normal;
                }
                .media-content{
                    background-color: red;
                    iframe, img{
                        max-width: calc(100vw - 20px)!important;

                    }
                }
            }
        }
    }
    .loquepiensan{
        margin-top: 40px;
        .container{
            padding: 30px 0;
            display: flex;
            text-align: center;
            @media( max-width:$screen-md ){
              display: block
            }
            .valoracion{
                flex: 1;
                h4{
                    @include fuente($font-first,$font-semibold);
                    font-size: $font-smd;
                    color: black;
                    a{
                      color: black;
                    }
                }
                p{
                    @include fuente($font-first,$font-light);
                    font-size: $font-smd;
                    font-style: italic;
                    color: $gris8;
                }
            }
        }
    }
    .personalizar-manta, .flex-content{
        h2{
            margin: 60px auto;
        }
        > p{
            display: block;
            margin-top: 40px;
            text-align: center;
            font-size: $font-slg;
            a{
                font-size: $font-md;
            }
        }
        .content{
            display: flex;
            gap: 0 1rem;
            @media(max-width: $screen-md){
                display: block;
            }
            >*{
              flex: 1;
            }
            .step{
                flex: 1;
                display: grid;
                grid-template-columns: 18% 72%;
                grid-gap: 0;
                @media(max-width: $screen-md){
                    margin-bottom: 80px;
                }
                .indice{
                    @include fuente($font-second, $font-regular);
                    background-color: black;
                    color: white;
                    font-size: $font-xl;
                    border-radius: 50%;
                    height: 110px;
                    padding: 25px 70px 30px 22px;
                    width: 110px;
                    margin-top: 50px;
                }
                .contenido{
                    //background-image: url("#{$urlimagenes}bloque-numerados.svg");
                    background-position: left center;
                    background-repeat: no-repeat;
                    background-color: $gris1;
                    border-top-right-radius: 8px;
                    border-bottom-right-radius: 8px;
                    padding: 16px;
                    strong{
                        font-size: $font-slg;
                    }
                    p{
                        font-size: $font-sm;
                    }
                    img{
                        display: block;
                        width: 50px;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: -45px;
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }
    .grandes-pedidos{
        background-color: #f1f1f1;
        background-image: url("#{$urlimagenes}mantaspersonalizadas.jpg");
        background-repeat: no-repeat;
        background-position: top right;
        text-align: left;
        min-height: 450px;
        @media(max-width: $screen-md){
            padding: 40px 16px;
            background-position: bottom right;
            background-size: cover;
        }
        @media(max-width: 1170px){
            background-position-x: 240px;
        }
        .djv-container{
            > *{
                width: 35%;
                @media(max-width: $screen-md){
                    width: calc(100% - 30px);
                }
            }
            h2{
                text-align: left;
            }
            strong{
                @include fuente($font-first, $font-bold);
                display: block;
                margin-bottom: 22px;
                font-size: $font-md;
            }
            ul{
                @include fuente($font-first, $font-regular);
                font-size: $font-slg;
                margin-left: 5px;
                @media(max-width: $screen-md){
                    padding-left: 10px;
                }
            }
            a{
                @include btn-hover-animated(black, white, 0, 10px, 20px);
                display: block;
                width: 300px;
                margin-top: 25px;
                @media(max-width: $screen-md){
                    width: calc(100% - 20px);
                }
            }
        }
    }
    .tedamosideas{
        > h2{
            margin-bottom: 40px;
        }
        .container{
            display: grid;
            grid-template-columns: repeat(3 , calc(33% - 30px));
            grid-gap: 30px;
            @media(max-width: $screen-md){
                display: block;
            }
            article{
                overflow: hidden;
                h3{
                    @include fuente($font-first, $font-bold);
                    font-size: $font-lg;
                    margin-top: 20px;
                }
                p{
                    @include fuente($font-first, $font-regular);
                    font-size: $font-md;
                }
                img{
                  margin:0 auto;
                  display: block;
                }
            }
        }
    }
    .cuatro_columnas{
        > h2{
            margin-bottom: 40px;
        }
        .container{
            display: grid;
            grid-template-columns: repeat(4 , calc(25% - 20px));
            grid-gap: 30px;
            @media(max-width: $screen-md){
                display: block;
            }
            article{
                overflow: hidden;
                h3{
                    @include fuente($font-first, $font-bold);
                    font-size: $font-lg;
                    margin-top: 20px;
                }
                p{
                    @include fuente($font-first, $font-regular);
                    font-size: $font-md;
                }
            }
        }
    }
    .tres_columnas{
        > h2{
            margin-bottom: 40px;
        }
        .container{
            display: grid;
            grid-template-columns: repeat(3 , calc(33% - 20px));
            grid-gap: 30px;
            @media(max-width: $screen-md){
                display: block;
            }
            article{
                overflow: hidden;
                h3{
                    @include fuente($font-first, $font-bold);
                    font-size: $font-lg;
                    margin-top: 20px;
                }
                p{
                    @include fuente($font-first, $font-regular);
                    font-size: $font-md;
                }
            }
        }
    }
    .clientes{
        background-color: #f1f1f1;
        padding: 34px;
        @media(max-width: $screen-md){
            padding: 10px;
        }
        h2{
            @include fuente($font-first, $font-bold);
            text-transform: inherit;
            color: black;
            font-size: $font-lg;
        }
        .djv_carouselh-viewer{
            > div{
                position: relative;
                display: flex;
                transition: left 500ms;
            }
        }
        .djv-container{
            &.swipe{
                @media(max-width: $screen-md){
                    &::after{
                        top: -20px;
                    }
                }
            }
            > div{
                @media(max-width: $screen-md){
                    width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    -webkit-scroll-snap-type: mandatory;
                    scroll-snap-type: x mandatory;
                    -webkit-scroll-snap-points-x: repeat(100%);
                    scroll-snap-points-x: repeat(100%);
                }
            }
        }
    }
    .djv-listas-productos{
        margin-top: 50px;
        margin-bottom: 50px;
        h2{
            @include fuente($font-second, $font-regular);
            color: black;
        }
        > p{
            text-align: center;
            @include fuente($font-first, $font-regular);
            font-size: $font-slg;
            color: black;
            margin-bottom: 35px;
        }
    }
}

.textoSeo,#category-description{
    section:not(.full-width){
        @include djv-container();
    }
    section{
        header{
            text-align: center;
        }
    }
    #propuestas-valor-1,
    #product-list-1,
    #personaliza,
    #algunas-ideas,
    #product-list-2,
    .img-full-width{
        margin-top: 60px;
        header{
            margin-bottom: 25px;
            h2{
                @include fuente($font-second, $font-bold);
                text-transform: uppercase;
                font-size:$font-xl;
                text-align: center;
                @media(max-width: $screen-md){
                    font-size: $font-blg;
                }
            }
            h3{
                @include fuente($font-first , $font-regular);
                font-size: 24px;
            }
            p{

            }
        }
    }
    #propuestas-valor-1{
        text-align: left;
    }
    #personaliza{
        .djv-flex{
            &:first-of-type{
                margin: 25px 0px;
                >div{
                    p{
                        text-align: center;
                        padding: 0 10px;
                    }
                }
            }
            &:last-of-type{
                margin: 35px 0px 25px;
                >a{
                    position: relative;
                    display: block;
                    background-color: black;
                    @media(max-width: $screen-md){
                        margin-bottom: 10px;
                    }
                    h3{
                        position: absolute;
                        bottom: 0;
                        background-color: rgba(0,0,0,.5);
                        padding: 10px;
                        margin-bottom: 0;
                        text-align: center;
                        color:white;
                        text-transform: uppercase;
                        width: 100%;
                        font-size: $font-lg;
                    }
                    img{
                        max-width: 100%;
                    }
                }
            }
        }
    }
    #grandes-pedidos{
        background-color: $gris1;
        background-size: cover;
        background-position: center;
        min-height: 465px;
        padding: 60px 0 50px;
        @media(max-width: $screen-md){
            background-position: left;
            padding: 30px 50px;
            min-height: 335px;
        }
        .content-text{
            width: 480px;
            text-align: center;
            @media(max-width: $screen-md){
                width: 100%;
            }
            h2{
                @include fuente($font-first , $font-bold);
                font-size: 40px;
                @media(max-width: $screen-md){
                    font-size: 36px;
                }
            }
            p{
                @include fuente($font-first , $font-regular);
                font-size: 23px;
                @media(max-width: $screen-md){
                    font-size: 18px;
                }
            }
            ul{
                list-style: none;
                text-align: left;
                display: block;
                padding-left: 0;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 280px;
                li{
                    @include fuente($font-first , $font-regular);
                    font-size: 19px;
                    margin-bottom: 12px;
                    img{
                        width: 30px;
                        display: inline-block;
                    }
                }
            }
            .btn-hover-animated-orange{
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 30px;
                display: block;
                @include fuente($font-first , $font-medium);
                font-size: 19px;
            }
        }
    }
    #algunas-ideas{
        article{
            header{
                text-align: center;
                color:#666;
                h2{
                    color:black;
                }
            }
            .idea-block{
                display: grid;
                grid-template-areas: "text image";
                grid-template-columns: repeat(2,50%);
                margin: 30px 0;
                @media(max-width: $screen-md){
                    grid-template-areas: "image" "text";
                    grid-template-columns: 100%;
                    margin-bottom: 60px;
                }
                &:nth-of-type(2n){
                    grid-template-areas: "image text";
                    @media(max-width: $screen-md){
                        grid-template-areas: "image" "text";
                        grid-template-columns: 100%;
                    }
                }
                .idea-text, .idea-img{
                    align-self: center;
                    text-align: center;
                    color:#666;
                    margin: 0 3px;
                    h3{
                        display: block;
                        font-size: $font-lg + 2px;
                    }
                    p{
                        display: block;
                        font-size: $font-md;
                        padding: 0 12px;
                    }
                    .claim-button{
                        display: flex;
                        max-width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        gap: 10px;
                        h4{
                            font-size: $font-md;
                            @include fuente($font-first , $font-black);
                            background-image: url("#{$cdnserver2}/themes/base/assets/img/flecha.png");
                            background-position: 95% bottom;
                            background-repeat: no-repeat;
                            text-align: right;
                            padding-bottom: 35px;
                            padding-right: 15px;
                        }
                        >*{
                            flex: 1;
                        }
                        >a{
                            @include btn-hover-animated($primario4,white,0,5px,5px);
                            color:white;
                            align-self:flex-start;
                        }
                    }
                }
                .idea-text{
                    grid-area: text;
                    @media(max-width: $screen-md){
                        width: 100%;
                        margin-top: 10px;
                    }
                }
                .idea-img{
                    grid-area: image;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
    #propuestas-valor-2{
        margin-top: 70px;
        h3{
            display: block;
            color: $primario2;
            @include fuente($font-first , $font-bold);
            font-size: $font-lg;
            font-weight: 700;
            text-align: center;
            margin-bottom: 2px;
            &::before{
                content:"";
                display: block;
                background-image: url("#{$cdnserver1}/themes/base/assets/img/ornament_home.png");
                background-repeat: no-repeat;
                background-position: center;
                height: 30px;
                width: 100%;
            }
        }
        p{
            text-align: center;
        }
    }
    .ver-mas{
        @include btn-hover-animated($primario2,white,0,5px,5px);
        color: white;
        width: 170px;
        margin: 0px auto;
        font-size: $font-sm;
        display: block;
        margin-top: 30px;
    }
    .djv-flex{
        display: flex;
        > *{
            flex: 1;
            margin: 0 7px;
        }
        >a{
            background-color: black;
            img{
                transition: opacity 500ms;
                opacity: 1;
                &:hover{
                    opacity: .5;
                }
            }
        }
        @media(max-width: $screen-md){
            display: block;
        }
    }
    .element-icon{
        display: grid;
        grid-template-columns: 20% 80%;
        @media(max-width: $screen-md){
            display: block;
            text-align: center;
            margin-bottom: 30px;
        }
        img{
            float: left;
            display: inline-block;
            width: 50px;
            margin-right: 16px;
            @media(max-width: $screen-md){
                float: none;
                margin-right: 0;
            }
        }
        div{
            display: inline-block;
            @media(max-width: $screen-md){
                display: block;
            }
            h3{
                color:black;
                font-size: 21px;
                @include fuente($font-first , $font-medium);
                display: block;
                margin-bottom: 0;
            }
            p{
                display: block;
                font-size: 14px;
                @include fuente($font-first , $font-light);
            }
        }
    }
    .tag-list{
        display:flex;
        justify-content: center;
        flex-wrap: wrap;
        a{
            @include btn-hover-animated($primario4,white,0,5px,5px);
            min-width: 120px;
            margin: 10px;
        }
    }
    .scrollTo{
      cursor: pointer;
      color:$primario2;
    }
    .franja_destacada{
      background-color: $gris1;
      margin-top: -16px;
      span{
        font-size: 13px;
      }
    }
}
