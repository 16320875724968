body#cart {

    #notifications {
        display: block;
        margin-left: auto;
        margin-right: auto;
    }

    #content-wrapper {
        @include djv-container();

        #main {
            display: grid;
            grid-template-columns: 75% 25%;
            gap: 0;
            @media(max-width: $screen-md) {
                display: block;
            }

            .continue-shopping {
                //@include btn-hover-animated($complementario4,white,0,5px,5px);
                width: 230px;
                margin-left: inherit;
                margin-top: 10px;
                padding-top: 6px;
                padding-left: 20px;
                position: relative;
                @media(max-width: $screen-md) {
                    display: block;
                    margin-left: auto;
                    margin-right: auto;
                    margin-top: 20px;
                }

                i {
                    position: absolute;
                    left: 0px;
                    top: 8px;
                }
            }

            > div {
                @media(max-width: $screen-md) {
                    box-shadow: 2px 2px 8px rgba(0,0,0,0);
                }
            }

            .cart-overview {
                margin-right: 25px;
                @media(max-width: $screen-md) {
                    margin-right: 0;
                }

                h1 {
                    padding: 16px;
                    border-bottom: 1px solid lighten($gris2, 15%);
                    text-transform: uppercase;
                    font-size: 23px;
                }
                .banner-cart{
                  padding: 1rem;
                  background-color: #ffd6d6;
                  font-size: 14px;
                  color: #490404;
                  p{
                    margin-bottom: 0;
                    a{
                      font-weight: normal;
                      color: #490404;
                      text-decoration: underline;
                    }
                  }
                }
                .max-quantity-message{
                    display: flex;
                    background-color: $primario2;
                    color: white;
                    padding: 10px;
                    align-items: center;
                    img{
                        width: 50px;
                    }
                    p{
                        padding-left: 15px;
                        margin-bottom: 0;
                    }
                }
                .body {
                    padding: 15px 30px;

                    ul {
                        padding-left: 0;
                    }
                    .cart-header-items{
                        list-style: none;
                        display: grid;
                        grid-template-columns: 65% 35%;
                        border-bottom: 1px solid $gris3;
                        color: $gris7;
                        .cart-header-items-description{}
                        .cart-header-items-total{
                            text-align: right;
                            margin-right: 85px;
                        }
                    }
                    .cart-item {
                        list-style: none;
                        display: grid;
                        grid-template-columns: 55% 45%;
                        gap: 16px;
                        padding-bottom: 16px;
                        border-bottom: 1px dashed $gris2;
                        padding-top: 16px;
                        font-size: $font-bmd;
                        @media(max-width: $screen-md) {
                            display: block;
                            margin-bottom: 20px;
                        }
                        a {
                            color: inherit;
                        }
                        .secction-label{
                            @include fuente($font-first , $font-medium);
                            font-size: $font-smd;
                        }

                        .cart-detailed-product-line-detail {
                            display: grid;
                            grid-template-columns: 30% 70%;
                            gap: 16px;
                            @media(max-width: $screen-md){
                                display: block;
                            }

                            .cart-detailed-product-line-detail-image {
                                @media(max-width: $screen-md){
                                    float: left;
                                    width: 40%;
                                    margin-right: 15px;
                                    margin-bottom: 15px;
                                }
                                img {
                                    width: 100%;
                                }
                            }


                            .cart-detailed-product-line-detail-tex {
                                @media(max-width: $screen-md){

                                }
                                > span {
                                    display: block;
                                }
                                .product-price{
                                    @include fuente($font-first , $font-bold);
                                    font-size: $font-lg;
                                    small{
                                        @include fuente($font-first , $font-regular);
                                        font-size: $font-xxs;
                                        @media(max-width: $screen-md){
                                            display: block;
                                        }
                                    }
                                }

                                .product-discount {
                                    @media(max-width: $screen-md){

                                    }
                                    .regular-price {
                                        text-decoration: line-through;
                                        color: $gris3;
                                    }

                                    .discount-percentage {
                                        padding: 0 10px;
                                        background-color: $primario6;
                                        color: white;
                                        margin-left: 5px;
                                    }
                                }
                                .cart-resume-selected-attributes{
                                    @media(max-width: $screen-sm){
                                        display: block;
                                        clear: both;
                                    }
                                    .cart-resume-attributes{
                                    @media(max-width: $screen-md){

                                    }
                                    margin-left: 20px;
                                    list-style: none;
                                    margin-bottom: 10px;
                                    li{
                                        line-height: $font-xs + 1;
                                        font-size: $font-xs;
                                        label{
                                            margin-bottom: 0;
                                        }
                                    }
                                }
                                }
                            }
                            .personalize-miniatures{
                                position: relative;
                                @media(max-width: $screen-sm){
                                    display: block;
                                }
                                .miniatura{
                                    position: relative;
                                    display: inline-block;
                                }
                                .mini{
                                    width: 80px;
                                    height: 80px;
                                    cursor: pointer;
                                    &:hover{
                                        border: 1px solid $gris5;
                                    }
                                }
                                .ampliada{
                                    display: none;
                                    &::before{
                                        width: 0;
                                        height: 0;
                                        border-right: 20px solid #aaa;
                                        border-top: 15px solid transparent;
                                        border-bottom: 15px solid transparent;
                                        content: "";
                                        position: absolute;
                                        top: 50%;
                                        left: -20px;
                                        transform: translateY(-50%);
                                    }
                                }
                                .mini:hover + .ampliada{
                                    display: block;
                                    position: absolute;
                                    width: 250px;
                                    height: 250px;
                                    top: -105%;
                                    left: 90px;
                                    border: 1px solid #aaa;
                                    z-index: 2;
                                    box-shadow: 1px 1px 3px #999;
                                    img {
                                        width: 100%;
                                    }
                                }
                            }
                        }

                        .cart-detailed-product-line-modify {
                            display: grid;
                            grid-template-areas:
                            "unidades precio delete"
                            "edit precio delete";
                            grid-template-rows: 30px 20px;
                            text-align: center;
                            @media(max-width: $screen-md){
                                margin-top: 5px;
                                padding-top: 15px;
                                border-top: 1px dashed $gris3;
                                clear: both;
                            }
                            .totalUnidades{
                                grid-area: unidades;
                                font-size: 15px;
                                margin-top: 3px;
                            }
                            .product-price {
                                grid-area: precio;
                                text-align: center;
                                font-size: 20px;
                            }
                            .cart-edit-personalization{
                                grid-area: edit;
                                padding-top: 10px;
                                color: $primario2;
                                font-size: $font-xs;
                                &::before{
                                    font-size: $font-sm;
                                }
                            }
                            .djv-icon-delete {
                                grid-area: delete;
                                text-align: center;
                                font-size: $font-sm;
                                color:red;
                                padding-top: 6px;
                            }
                            .product-quantity{
                                border-bottom: 0;
                                .product-add-to-cart-quantity{
                                    width: 135px;
                                    #quantity_wanted {
                                        width: 70px;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .cart-detailed-right-side {
                #items-subtotal {
                    //padding: 16px;
                    overflow: auto;
                    border: 1px solid $gris2;
                    border-top: none;
                    @include fuente($font-first , $font-bold);
                    font-size: $font-bmd;
                    > * {
                        padding:8px 16px;
                    }
                    .cart-title{
                        background-color: $gris1;
                        color: black;
                        text-transform: uppercase;
                        font-size: $font-sm;
                    }
                    .js-subtotal{
                        float: left;
                    }
                    span:not(.js-subtotal) {
                        text-align: right;
                        display: inline-block;
                        &:last-of-type{
                            float: right;
                        }
                    }
                }
                .djv-cart-promo{
                    font-size: $font-xxs;
                    margin-top: 10px;
                    padding: 8px 16px;
                    border-bottom: 1px solid #dddddd;
                    .djv-icon-calidad::before{
                        margin-right: 8px;
                    }
                    .djv-cart-promo-item{
                        display: grid;
                        grid-template-columns: 50% 40% 10%;
                        color:$primario3;
                        .djv-icon-close{
                            color: $complementario2;
                        }
                    }
                }
                .cart-detailed-totals {
                    border: 1px solid $gris2;
                    border-top: none;
                    .cart-subtotals {
                        padding:8px 16px;
                        border-bottom: 1px solid $gris2;
                        .products {
                            display: none;
                        }

                        .shipping {
                            display: grid;
                            grid-template-columns: auto auto;
                            padding-bottom: 5px;
                            margin-bottom: 5px;

                            .value {
                                text-align: right;
                            }
                        }

                        .discount {
                            display: grid;
                            grid-template-columns: auto auto;
                            padding-bottom: 5px;
                            margin-bottom: 5px;
                            color: $complementario1;

                            .value {
                                text-align: right;
                            }
                        }

                        .tax {
                            display: grid;
                            grid-template-columns: auto auto;
                            padding-bottom: 5px;
                            margin-bottom: 5px;

                            .value {
                                text-align: right;
                            }
                        }
                    }

                    .cart-total {
                        display: grid;
                        grid-template-columns: auto auto;
                        font-size: $font-blg;
                        @include fuente($font-first , $font-semibold);
                        padding: 8px 16px;
                        font-weight: 600;
                        align-items: baseline;
                        .label{
                            text-align: left;
                            font-size: $font-slg;
                            small{
                                display: block;
                                font-size: $font-xxs;
                                color: $gris5;
                            }
                        }
                        .value {
                            text-align: right;
                        }
                    }
                }
                .cart-voucher {
                    margin-top: 16px;
                    padding: 16px;
                    border: 1px solid $gris2;
                    p{
                        font-size: $font-xs;
                        margin-top: 10px;
                    }
                    #vouchers-form{
                        height: 0;
                        opacity: 0;
                    }
                    /*#added_vouchers + .vouchers-title + #vouchers-form{
                        height: 100%;
                        opacity: 1;
                    }*/
                    .show-vouchers{
                        animation-name: showvouchers;
                        animation-duration: 1s;
                        animation-fill-mode: forwards;
                    }
                    @keyframes showvouchers{
                        from{
                            height: 0;
                            opacity: 0;
                        }
                        to{
                            height: 100%;
                            opacity: 1;
                        }
                    }
                    .added-vouchers-title,.vouchers-title{
                        @include fuente($font-first , $font-medium);
                        font-size: $font-md;
                        color: $primario2;
                        text-decoration: underline;
                    }
                    .vouchers-title{
                        margin-bottom: 10px;
                        text-align: center;
                        cursor: pointer;
                    }

                    #added_vouchers{
                        list-style: none;
                        font-size: $font-sm;
                        padding: 5px;
                        li{
                            display: table-row;
                            column-count: 2;
                            > *{
                                display: table-cell;
                            }
                        }
                    }
                    form{
                        ul{
                            padding-left: 0;
                            li{
                                list-style: none;
                                font-size: $font-xs;
                                text-align: center;
                                padding: 5px;
                                margin: 10px auto;
                                background-color:#e0e0e0;
                                transition: background-color 500ms;
                                &:hover{
                                    background-color: #fafafa;

                                    .label{
                                        .code{
                                            font-size: $font-lg;
                                        }
                                    }
                                }
                                .label{
                                    font-size: $font-smd;
                                    .code{
                                        display: block;
                                        @include fuente($font-first , $font-bold);
                                        font-size: $font-bmd;
                                        cursor: pointer;
                                        transition: font-size 500ms;
                                    }
                                }
                                p{
                                    display: inline-block;
                                    width: 49%;
                                }
                                a{
                                    display: inline-block;
                                    float: right;
                                    padding: 0 14px;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                    input[name="discount_name"] {
                        width: 160px;
                        text-align: center;
                        font-size: $font-xs;
                        border:1px solid $gris3;
                        padding: 3px;
                    }

                    button {
                        @include btn-hover-animated($primario2,white,0,5px,5px);
                        padding: 3px 27px 2px;
                        float: right;
                        font-size: 14px;
                    }
                    .djv-icon-question{
                        font-size: $font-xs - 1px;
                        color: $primario3;
                        margin-top: 16px;
                        display: block;
                        &::before{
                            margin-right: 5px;
                        }
                    }
                    .notification{
                        padding: 5px 0px;
                        display: none;
                        .js-error-text{
                            color: $complementario2;
                            font-size: $font-sm;
                            margin: 0;
                        }
                    }
                }

                .tramitar-pedido-btn {
                    @include btn-hover-animated($complementario1,white,0,5px,5px);
                    margin: 10px auto;
                    display: block;
                    width: 100%;
                    font-size: $font-lg;
                }

                #block-reassurance {
                    padding: 16px;
                    border-bottom: 1px solid $gris2;

                    ul {
                        padding-left: 30px;

                        li {
                            list-style: none;
                            margin-bottom: 16px;

                            img {
                                margin-left: -30px;
                                filter: sepia(100%) brightness(310%) hue-rotate(60deg) saturate(300%);
                            }
                        }
                    }
                }
            }
        }
    }

    &.cart-empty {
        .checkout {
            display: none;
        }

        .cart-overview {
            .body::after {
                content: "No hay articulos seleccionados";
            }
        }
    }
    #editPersonalizationModal{
        display: block;
        position:fixed;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        z-index: 1000;
        background-color: rgba(0,0,0,.8);
        @media(max-width: $screen-md){
            position: absolute;
        }
        #editPersonalizationBox{
            background-color: white;
            border-radius: 6px;
            display: block;
            position:absolute;
            left:50%;
            transform: translateX(-50%);
            min-width: 410px;
            z-index: 1000;
            padding: 5px;
            @media(max-width: $screen-md){
                min-width: 99vw;
                min-height: 99vh;
                overflow-y: scroll;
            }
            #closeEditPersonalizationFormButton{
                display: block;
                font-size: 25px;
                line-height: 15px;
                padding: 10px;
                background-color: black;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: white;
            }
        }
        #editPersonalizationForm{
            height: 80vh;
            @media(max-width: $screen-md){
                overflow-y: scroll;
            }
        }
    }
}
