//Colors
.carousel{
    margin-bottom: 5px;
    @media(max-width: $screen-md){
        margin-bottom: 0;
    }
    .carousel-item{
        overflow: hidden;
        max-width: 100%;
        img{
            position: relative;
            left: 50%;
            transform: translateX(-50%);
            top: 0;
        }
    }

    [class*='djv-icon-arrow-']::before{
        background: rgba(0,0,0,.8);
        font-size: $font-slg;
        font-weight: normal;
        padding: 14px;
        border-radius: 50%;
        @media(max-width: $screen-md){
            font-size: $font-md;
            padding: 10px;
        }
    }
}
.carousel-control-next{

}
.carousel-control-prev{

}

.carousel-indicator-container{
    position: relative;
    bottom: 0;
    border-bottom: 1px solid $gris1;
    height: 25px;
    width: 100%;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
    .carousel-indicators{
        position: relative;
        right: 0;
        bottom: 0px;
        left: 0;
        z-index: 15;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        padding-left: 0;
        margin-right: 15%;
        margin-left: 15%;
        list-style: none;
        width: 70%;
        @include djv-container();
        @media(max-width: $screen-md){
            min-width: 100px;
        }
        >li{
            border-left: 1px solid $gris1;
            border-right: 1px solid $gris1;
            width: auto;
            height: 24px;
            text-indent: 0;
            background-color: rgba(255,255,255,1);
            padding: 0px 15px;
            text-align: center;
            cursor: pointer;
            border-radius: 0;
            margin: 0;
            font-size: $font-sm;
            @include fuente($font-first , $font-light);
            white-space: nowrap;
            text-overflow: ellipsis;
            &::before{
                display: none;
            }
            &::after{
                display: none;
            }
            &.active {
                width: auto;
                height: auto;
                margin: 0;
                background-color: $gris1;
                border:none;
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .carousel-item {
        img{
            max-height: 175px;
        }
    }
    .carousel-indicator-container{
        bottom: 20px;
        border: none;
        .carousel-indicators{
            >li{
                font-size: 0px;
                border: 2px solid white;
                border-radius: 50%;
                margin: 3px;
                height: 10px;
                width: 10px;
                padding: 0px;
                overflow: hidden;
                background-color: transparent;
            &.active{
                height: 10px;
                width: 10px;
                margin: 3px;
                overflow: hidden;
                background-color: white;
            }
            }
        }
    }
    .carousel-control-prev, .carousel-control-next{
        max-height: 250px;
    }
}
