.product-miniature{
    position: relative;
    display: grid;
    align-items: stretch;
    grid-template-rows: 255px auto;

    @media(max-width: $screen-md){
        grid-template-rows: 170px auto;
    }
    a{
        display: block;
    }
    .product-miniature-img-block{
        max-height: 260px;
        @media(max-width: $screen-md){
            max-height: 160px;
        }
        .product-thumbnail{
            grid-area:imgProd;
            display: block;
            @media(max-width: $screen-lg){
                border: none;
                display: block;
                float: none;
                margin-left: auto;
                margin-right: auto;
            }
            img{
                display: block;
                position: relative;
                width: 100%;
                max-width: 250px;
                margin-left: auto;
                margin-right: auto;
            }
        }
    }
    .product-miniature-data-block{
        .name-product{
            grid-area:nameProd;
            display: block;
            text-align: center;
            font-size: $font-sm;
            @include fuente($font-first , $font-bold);
            line-height: $font-md;
            margin-bottom: 0;
            @media(max-width: $screen-md){
                @include fuente($font-first , $font-semibold);
                font-size: $font-sm;
                line-height: $font-md;
                padding: 0 0 5px 0;
            }
            a{
                color: black;
            }
        }
        .stars{
            grid-area: stars;
            display: block;
            color: black;
            text-align: center;
            line-height: 1em;
            span{
                display: inline-block;
                color: black;
                font-size: 11px;
            }
        }
        .product-description-short{
            display: none;
            grid-area:descProd;
            @include djv-border-bottom("dashed");
            font-size: $font-sm;
            overflow:hidden;
            padding-bottom: 10px;
            margin-bottom: 10px;
            @media(max-width: $screen-lg){
                min-height: auto;
            }
            ul{
                font-size: $font-xs*1.1;
            }
        }
        .product-price-and-shipping{
            grid-area:priceProd;
            margin: 6px 0;
            position: relative;
            text-align: center;
            .price-discounted{
                font-size: $font-lg*1.2;
                @include fuente($font-first , $font-medium);
                font-weight: 600;
                color:$complementario2;
            }
            .regular-price{
                position: relative;
                text-decoration: line-through;
                color: #777;
                font-size: $font-xs;
                font-weight: 200;
            }
            .product-price{
                @include fuente($font-first , $font-black);
                font-size: $font-blg;
                color: $primario2;
                small{
                    font-size: 0.5em;
                }
            }
            .texto-adicional{
                display: none;
            }
            p{
              margin-bottom: 0;
            }
        }
        .product-flags{
            list-style: none;
            position: absolute !important;
            top: 5px !important;
            text-transform: uppercase;
            color: white;
            left: 35px;
            font-size: 14px;
            margin-left: 0;
            padding-left: 0;
            li{
                padding: 8px;
                border-bottom-right-radius: 8px;
            }
            .discount{
                background-color: $primario9;
            }
            .on-sale{
                background-color: $gris9;
            }
        }
        .flag-rebajas-verano{
          position: absolute;
          top:5px;
          right: 0px;
          width: 80px;
          height: 80px;
          background-image: url("#{$urlimagenes}flag-rebajas.png");
          background-size: contain;
          background-repeat: no-repeat;
          background-position: top;
        }
        .product-list-actions{
            grid-area: actionsProd;
            .djv-ver-mas, .add-to-cart{
                @include btn-hover-animated(black,white,3px,1px,15px);
                display: block;
                min-width: 140px;
                max-width: 140px;
                margin: auto;
                @include fuente($font-first , $font-medium);
                font-size: $font-sm;
                text-transform: lowercase;
                @media(max-width: $screen-md){
                    width: 95%;
                }
            }
        }
        .variant-links{
          display: flex;
          flex-flow: row wrap;
          justify-content: center;
          grid-area: variants;
          padding: 5px 0;
          margin-bottom: 4px;
          border-bottom: 1px solid #e7e7e7;
          min-height: 1em;
          .color{
            display: inline-block;
            margin: 3px 1px;
            font-size: 0;
            width: 16px;
            height: 16px;
            border: 2px solid $gris2;
            //box-shadow: 0px 0px 2px #454545;
            border-radius: 50%;
            background-size: contain;
          }
        }
        .product-availability{
            display: none;
        }
    }
}
.product-miniature{
  .with-taxes{
      font-size: $font-xs;
      color: $gris6;
      small{
        font-size: $font-xs
      }
      .regular-price{
        font-size: $font-xs;
        color: $gris6;
      }
      span{
          @include fuente($font-first , $font-extrabold);
          font-size: $font-slg;
          color: $primario2;
          text-align: center;
          line-height: $font-xs;
          padding: 0 0px 0 4px;
      }
  }
  &.personalizable{
    .product-miniature-data-block{
        max-width: 250px;
        margin: 0 auto;
        .product-price-and-shipping{
            .unidades, .grantirada{
                display: block;
                p{
                    font-size: $font-xs;
                    color: $gris6;
                    margin-bottom: 0px;
                    @media(max-width: $screen-md){
                        font-size: $font-xxs;
                    }
                }
                .with-taxes{
                    font-size: $font-xs;
                    color: $gris6;
                    small{
                      font-size: $font-xs
                    }
                    .regular-price{
                      font-size: $font-xs;
                      color: $gris6;
                    }
                    span{
                        @include fuente($font-first , $font-extrabold);
                        font-size: $font-slg;
                        color: $primario2;
                        text-align: center;
                        line-height: $font-xs;
                        padding: 0 0px 0 4px;
                    }
                }
            }
            .unidades{
                .with-taxes{
                    span{
                      font-size: $font-smd;
                      font-weight: $font-extrabold;
                      color: $gris6;
                    }
                }
            }
        }
        .variant-links{
            display: flex;
            flex-flow: row wrap;
            justify-content: center;
            grid-area: variants;
            .color{
              display: inline-block;
              margin: 3px 1px;
              font-size: 0;
              width: 16px;
              height: 16px;
              border: 2px solid $gris2;
              //box-shadow: inset 2px 2px 2px 0 #454545;
              //box-shadow: 0px 0px 2px #454545;
              border-radius: 50%;
              background-size: contain;
            }
        }
    }
  }
}
