#history{
    .page-content{
        @include djv-container();
        font-size: $font-md;
        text-align: center;
        h2{
            font-size: $font-md;
            border-bottom: 1px dashed $gris3;
            padding-bottom:20px;
            margin-bottom:20px;
        }
        table{
            width: 100%;
        }
        tr{
            border-bottom: 1px solid $gris5;
        }
        th{

        }
        th,td{
            padding: 8px;
            @include fuente($font-first , $font-regular);
            .order-status-label {
                padding:16px;
                white-space: nowrap;
                color: white;
            }
            [data-link-action="view-order-details"]{
                @include btn-hover-animated($complementario4,white,0,5px,5px);
                display: block;
                margin-bottom: 8px;
            }
            .order-reorder-link{
                @include btn-hover-animated($complementario1,white,0,5px,5px);
                display: block;
            }
        }
        .page-footer{
            margin-top: 40px;
            padding-top: 20px;
            border-top: 1px dashed #999;
            ul{
                margin: 0;
                padding: 0;
                li{

                    list-style: none;
                    display: inline-block;
                    a{
                        @include btn-hover-animated($complementario4,white,0,5px,5px);
                    }
                }
            }
        }
    }
}
