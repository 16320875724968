.djv-border-top-dashed {
  border-top: 1px dashed #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-top-solid {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-bottom-dashed {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.djv-border-bottom-solid {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

* {
  box-sizing: border-box; }

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: black; }

h2 {
  font-family: "Fredoka One", cursive;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  color: black; }
  @media (max-width: 768px) {
    h2 {
      font-size: 24px; } }

h3 {
  font-family: "Fredoka One", cursive;
  font-weight: 400;
  font-size: 24px; }

a {
  color: #F28E00;
  font-weight: 700; }
  a:hover {
    color: #F28E00;
    text-decoration: underline; }

p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }

.full-width {
  width: 100vw;
  display: block;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%); }

.djv-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .djv-container {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .djv-container {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .djv-container {
      max-width: 500px;
      min-width: 300px; } }

.grid-2, .grid-3, .grid-4, .grid-5 {
  display: grid;
  grid-gap: 10px; }

.grid-2 {
  grid-template-columns: repeat(2, calc(50% - 10px)); }

.grid-3 {
  grid-template-columns: repeat(3, calc(33.33% - 10px)); }

.grid-4 {
  grid-template-columns: repeat(4, calc(25% - 10px)); }

.grid-5 {
  grid-template-columns: repeat(5, calc(20% - 10px)); }

@media (max-width: 768px) {
  .xs-visible {
    display: block !important; } }

.blackfridayflag {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1; }
  .blackfridayflag img {
    width: 75px; }

.ps-alert-success li {
  list-style: none;
  border-bottom: 1px dashed #3b7d15; }
  .ps-alert-success li i {
    float: left;
    margin-right: 20px;
    color: #5abe20;
    font-size: 45px;
    padding-bottom: 20px; }

.stars i {
  font-size: 11px;
  color: black; }

.stars span {
  font-size: 10px;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px; }
  .stars span a {
    position: absolute;
    top: 6px;
    font-size: 11px;
    color: black; }

@media (max-width: 768px) {
  body {
    overflow-x: hidden; }
  .xs-hidden {
    display: none; } }

#lgcookieslaw_banner #lgcookieslaw_accept {
  background-color: #dadada;
  padding: 5px;
  display: block;
  border: 0;
  border-radius: 6px; }

.microdatos {
  display: none; }

.hidden {
  display: none; }

@media (min-width: 768px) {
  .djv_carouselh {
    position: relative; }
    .djv_carouselh > * {
      overflow: hidden;
      position: relative; }
    .djv_carouselh .djv_carouselh-left-arrow,
    .djv_carouselh .djv_carouselh-right-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      font-size: 36px;
      width: 44px;
      height: 100%;
      cursor: pointer;
      background-color: transparent; }
      .djv_carouselh .djv_carouselh-left-arrow::before,
      .djv_carouselh .djv_carouselh-right-arrow::before {
        position: absolute;
        top: 50%;
        transform: translateY(-70%);
        background-color: #eeeefa;
        padding: 18px 3px; }
    .djv_carouselh .djv_carouselh-left-arrow {
      left: 0;
      border-left: 1px solid #ccc; }
      .djv_carouselh .djv_carouselh-left-arrow::before {
        left: 0px;
        font-size: 14px;
        content: '\004b';
        display: inline-block;
        font-family: "comunes";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc; }
    .djv_carouselh .djv_carouselh-right-arrow {
      right: 0;
      border-right: 1px solid #ccc; }
      .djv_carouselh .djv_carouselh-right-arrow::before {
        right: 0px;
        font-size: 14px;
        content: '\004c';
        display: inline-block;
        font-family: "comunes";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc; }
    .djv_carouselh .djv_carouselh-viewer {
      position: relative; }
      .djv_carouselh .djv_carouselh-viewer > * {
        text-align: left;
        position: absolute;
        transition: left 1s; }
        .djv_carouselh .djv_carouselh-viewer > * > * {
          margin: 0;
          padding: 5px;
          float: left !important;
          clear: none; } }

@media (max-width: 768px) {
  .djv_carouselh.swipe::after {
    display: inline-block;
    font-family: 'comunes';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\006e';
    position: relative;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    margin: 10px 0;
    font-size: 42px;
    color: #999999; }
  .djv_carouselh > * {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%); }
    .djv_carouselh > * > * {
      scroll-snap-align: start;
      width: 100%;
      position: relative;
      display: inline-block;
      margin-right: -4px;
      text-align: center; } }

/* Generated by Glyphter (http://www.glyphter.com) on  Thu May 16 2019*/
@font-face {
  font-family: 'comunes';
  src: url("project/djv-icon-font/fonts/comunes.eot");
  src: url("project/djv-icon-font/fonts/comunes.eot?#iefix") format("embedded-opentype"), url("project/djv-icon-font/fonts/comunes.woff") format("woff"), url("project/djv-icon-font/fonts/comunes.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[class*='djv-icon']:before {
  display: inline-block;
  font-family: 'comunes';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.djv-icon-coment:before {
  content: '\0041'; }

.djv-icon-coment-2:before {
  content: '\0042'; }

.djv-icon-carro:before {
  content: '\0043'; }

.djv-icon-menu:before {
  content: '\0044'; }

.djv-icon-lupa:before {
  content: '\0045'; }

.djv-icon-lupa-plus:before {
  content: '\0046'; }

.djv-icon-lupa-minus:before {
  content: '\0047'; }

.djv-icon-usuario:before {
  content: '\0048'; }

.djv-icon-telefono:before {
  content: '\0049'; }

.djv-icon-mail:before {
  content: '\004a'; }

.djv-icon-arrow-left:before {
  content: '\004b'; }

.djv-icon-arrow-right:before {
  content: '\004c'; }

.djv-icon-arrow-up:before {
  content: '\004d'; }

.djv-icon-arrow-down:before {
  content: '\004e'; }

.djv-icon-facebook:before {
  content: '\004f'; }

.djv-icon-twitter:before {
  content: '\0050'; }

.djv-icon-youtube:before {
  content: '\0051'; }

.djv-icon-instagram:before {
  content: '\0052'; }

.djv-icon-delete:before {
  content: '\0053'; }

.djv-icon-close:before {
  content: '\0054'; }

.djv-icon-ok:before {
  content: '\0055'; }

.djv-icon-ok-circle:before {
  content: '\0056'; }

.djv-icon-ok-circle-open:before {
  content: '\0057'; }

.djv-icon-plus:before, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li button:first-of-type:before, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li button:first-of-type:before {
  content: '\0058'; }

.djv-icon-minus:before, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li button:last-of-type:before, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li button:last-of-type:before {
  content: '\0059'; }

.djv-icon-refresh:before {
  content: '\005a'; }

.djv-icon-reload:before {
  content: '\0061'; }

.djv-icon-reply:before {
  content: '\0062'; }

.djv-icon-move:before {
  content: '\0063'; }

.djv-icon-filter:before {
  content: '\0064'; }

.djv-icon-exit:before {
  content: '\0065'; }

.djv-icon-gear:before {
  content: '\0066'; }

.djv-icon-question:before {
  content: '\0067'; }

.djv-icon-question-circle:before {
  content: '\0068'; }

.djv-icon-information:before {
  content: '\0069'; }

.djv-icon-warning:before {
  content: '\006a'; }

.djv-icon-star:before {
  content: '\006b'; }

.djv-icon-empty-star:before {
  content: '\006c'; }

.djv-icon-printer:before {
  content: '\006d'; }

.djv-icon-swipe:before {
  content: '\006e'; }

.djv-icon-tap:before {
  content: '\006f'; }

.djv-icon-whatsapp:before {
  content: '\0070'; }

.djv-icon-pinterest:before {
  content: '\0071'; }

.djv-icon-delivery:before {
  content: '\0072'; }

.djv-icon-heart:before {
  content: '\0073'; }

.djv-icon-bag:before {
  content: '\0074'; }

.djv-icon-linearheart:before {
  content: '\0075'; }

.djv-icon-linearuser:before {
  content: '\0076'; }

.djv-icon-lineardelivery:before {
  content: '\0077'; }

.djv-icon-clock:before {
  content: '\0078'; }

.djv-icon-trash:before {
  content: '\0079'; }

.djv-icon-openeye:before {
  content: '\007a'; }

.djv-icon-closeeye:before {
  content: '\0030'; }

.djv-icon-pencil:before {
  content: '\0031'; }

.djv-icon-address:before {
  content: '\0032'; }

.djv-icon-half-star:before {
  content: '\0033'; }

#header {
  min-height: 180px;
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #cccccc;
  max-width: 100vw; }
  @media (max-width: 1024px) {
    #header {
      min-height: 40PX;
      height: 40px;
      position: fixed; } }
  #header #header-topbanner {
    position: relative;
    width: 100%;
    display: none;
    overflow: hidden;
    background-color: white; }
    #header #header-topbanner .djv-container {
      position: relative; }
      @media (max-width: 768px) {
        #header #header-topbanner .djv-container {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          max-height: 105px; } }
      #header #header-topbanner .djv-container #close-banner-top {
        display: none;
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: white;
        border-radius: 50%;
        height: 18px; }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container #close-banner-top {
            right: 2px; } }
      #header #header-topbanner .djv-container a {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%; }
      #header #header-topbanner .djv-container img {
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container img {
            max-width: 100%;
            max-height: 105px; } }
    @media (max-width: 1024px) {
      #header #header-topbanner {
        display: none; } }
  #header i.djv-icon-lupa {
    display: none;
    position: relative;
    color: white;
    cursor: pointer;
    z-index: 9;
    top: 9px;
    font-size: 19px;
    position: absolute;
    right: 115px; }
    @media (max-width: 1024px) {
      #header i.djv-icon-lupa {
        display: block;
        right: auto;
        left: calc(-100vw + 169px);
        position: absolute; } }
  #header #menu-icon-responsive {
    display: inline-block;
    position: relative;
    top: 2px;
    font-size: 20px;
    left: 14px;
    color: white;
    cursor: pointer;
    z-index: 9; }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive::before {
        display: inline-block; } }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive span {
        display: none; } }
  #header .headerGrid {
    background-image: url("/themes/base/assets/img/topbarbg.gif");
    background-repeat: repeat-x;
    background-size: 1px 40px;
    background-color: white;
    height: 180px; }
    @media (max-width: 1024px) {
      #header .headerGrid {
        height: 40px; } }
    #header .headerGrid .djv-container {
      position: relative;
      display: grid;
      grid-template-columns: 31% 44% 25%;
      grid-template-areas: "topbarleft topbarcenter topbarright" "headersectionleft headersectioncenter headersectionright" "mainMenu mainMenu mainMenu";
      align-items: center;
      /* Bloque de grid */
      /* --- Fin Bloque de elementos adicionales*/
      /* Bloque de elementos adicionales*/
      /* -- Fin Bloque de elementos adicionales*/ }
      @media (max-width: 1024px) {
        #header .headerGrid .djv-container {
          grid-template-columns: 100px auto 100px;
          grid-template-areas: "navicons headersectioncenter topbarright"; } }
      @media (max-width: 1024px) {
        #header .headerGrid .djv-container {
          margin-left: 0px;
          margin-right: 0px;
          width: 100%; } }
      #header .headerGrid .djv-container .nav-icons {
        grid-area: navicons;
        display: none; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .nav-icons {
            display: block; } }
      #header .headerGrid .djv-container .header-topbar-before {
        grid-area: topbarleft;
        display: inline-block;
        font-size: 0.8rem;
        color: white; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-topbar-before {
            display: none; } }
        #header .headerGrid .djv-container .header-topbar-before a {
          color: inherit; }
        #header .headerGrid .djv-container .header-topbar-before .link-color {
          color: #cccccc; }
          #header .headerGrid .djv-container .header-topbar-before .link-color:hover {
            color: #333333; }
      #header .headerGrid .djv-container .header-topbar-center {
        grid-area: topbarcenter;
        display: inline-block;
        font-size: 0.8rem;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: white; }
        #header .headerGrid .djv-container .header-topbar-center span {
          color: white; }
          #header .headerGrid .djv-container .header-topbar-center span a {
            color: white; }
        #header .headerGrid .djv-container .header-topbar-center i {
          position: relative;
          margin-right: 7px;
          margin-left: 7px; }
          #header .headerGrid .djv-container .header-topbar-center i::before {
            font-size: 16px;
            font-weight: bold; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-topbar-center {
            display: none; } }
      #header .headerGrid .djv-container .header-topbar-after {
        grid-area: topbarright;
        display: inline-block;
        font-size: 0.8rem;
        position: relative; }
        #header .headerGrid .djv-container .header-topbar-after .djv-icon-usuario {
          color: white; }
      #header .headerGrid .djv-container .header-section-left {
        grid-area: headersectionleft; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-section-left {
            display: none; } }
        #header .headerGrid .djv-container .header-section-left h1, #header .headerGrid .djv-container .header-section-left h2 {
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          font-weight: 900;
          border-bottom: 1px "solid" #cccccc;
          padding-bottom: 10px;
          margin-bottom: 10px;
          padding-bottom: 5px;
          margin-bottom: 5px; }
        #header .headerGrid .djv-container .header-section-left p {
          font-size: 14px;
          color: #999999;
          margin-bottom: 0; }
      #header .headerGrid .djv-container .header-section-center {
        grid-area: headersectioncenter; }
      #header .headerGrid .djv-container .header-section-right {
        grid-area: headersectionright;
        position: relative; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-section-right {
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            padding: 0px 4px;
            background-color: #F28E00; } }
      #header .headerGrid .djv-container .header-nav-top {
        grid-area: mainMenu; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-nav-top {
            display: none; } }
      #header .headerGrid .djv-container .djv-icon-usuario {
        font-size: 21px;
        position: absolute;
        top: 8px;
        right: 71px;
        padding: 0 6px;
        cursor: pointer;
        color: #26314c; }
      #header .headerGrid .djv-container .djv-icon-coment, #header .headerGrid .djv-container .djv-icon-crono {
        font-size: 15.4px;
        margin-right: 4px;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px; }
      #header .headerGrid .djv-container .logo {
        background-image: url("/themes/base/assets/img/logo.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 250px;
        width: 300px;
        height: 100px;
        display: block;
        margin: -6px auto 0 auto;
        padding: 0px;
        position: relative; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .logo {
            width: 100px;
            height: 40px;
            background-image: url("/themes/base/assets/img/logo_sticky.svg");
            background-size: 100px;
            background-position: center 10px;
            margin-top: 0; } }
        @media (max-width: 576px) {
          #header .headerGrid .djv-container .logo {
            width: 100px;
            height: 40px; } }
        #header .headerGrid .djv-container .logo .titleLogo {
          font-family: "Open Sans", sans-serif;
          font-weight: 900;
          font-size: 54px;
          color: black;
          top: 35px;
          position: relative;
          margin-left: 75px; }
        #header .headerGrid .djv-container .logo .subTitleLogo {
          display: block;
          margin-top: 24px;
          margin-bottom: 10px;
          padding: 5px;
          position: absolute;
          bottom: -10px;
          font-size: 14px;
          color: #777777;
          line-height: 13px; }
          @media (max-width: 1024px) {
            #header .headerGrid .djv-container .logo .subTitleLogo {
              display: none; } }
      #header .headerGrid .djv-container #search_widget {
        width: 100%;
        display: block;
        margin: 5px auto;
        position: relative; }
        #header .headerGrid .djv-container #search_widget input {
          background-color: white;
          width: 100%;
          display: block;
          position: relative;
          border: 1px solid #999999;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          padding: 11px 20px 11px 5px;
          color: #26445C;
          font-family: "Open Sans", sans-serif;
          font-size: 13px; }
          @media (max-width: 1024px) {
            #header .headerGrid .djv-container #search_widget input {
              border: none; } }
          #header .headerGrid .djv-container #search_widget input::placeholder {
            font-size: 12px;
            color: #666666;
            font-family: "Open Sans", sans-serif;
            text-align: center; }
        #header .headerGrid .djv-container #search_widget button {
          position: absolute;
          top: 8px;
          right: 0px;
          border: none;
          background-color: transparent;
          width: 30px;
          height: 30px;
          cursor: pointer; }
          #header .headerGrid .djv-container #search_widget button::before {
            display: inline-block;
            font-size: 24px;
            right: 0px;
            top: 0px;
            position: absolute;
            color: #777777;
            margin-right: 10px;
            margin-top: 2px; }
      #header .headerGrid .djv-container #blockcart-wrapper {
        position: relative;
        z-index: 999;
        display: inline-block;
        float: right; }
      #header .headerGrid .djv-container .blockcart .header {
        color: white;
        font-size: 0.8rem; }
      #header .headerGrid .djv-container .header-nav-corporative {
        margin: 0;
        padding: 0;
        text-align: center; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-nav-corporative {
            display: none; } }
        #header .headerGrid .djv-container .header-nav-corporative li {
          list-style: none;
          display: inline-block;
          font-size: 13px;
          color: #F5A533;
          text-align: center; }
          #header .headerGrid .djv-container .header-nav-corporative li a {
            color: inherit; }
            #header .headerGrid .djv-container .header-nav-corporative li a.nav-link {
              padding: 5px; }
            #header .headerGrid .djv-container .header-nav-corporative li a i {
              display: block;
              width: 25px;
              height: 25px;
              margin: 0 auto;
              font-size: 20px; }
          #header .headerGrid .djv-container .header-nav-corporative li.nav-item-separator {
            color: #333333; }
  #header.recogido {
    height: 38px;
    min-height: auto;
    position: fixed;
    top: 0; }
    #header.recogido .djv-icon-lupa {
      display: inline-block;
      color: white; }
    #header.recogido .headerGrid {
      height: 39px; }
      #header.recogido .headerGrid .djv-container {
        grid-template-columns: 120px auto 140px;
        grid-template-areas: "headersectioncenter mainMenu topbarright";
        position: relative; }
        #header.recogido .headerGrid .djv-container form .djv-icon-lupa {
          display: none; }
      #header.recogido .headerGrid .nav-icons {
        grid-area: navicons;
        display: none; }
      #header.recogido .headerGrid .header-topbar-before {
        display: none; }
      #header.recogido .headerGrid .header-topbar-center {
        display: none; }
      #header.recogido .headerGrid .header-topbar-after {
        grid-area: topbarright;
        margin-top: -6px; }
      #header.recogido .headerGrid .header-section-left {
        display: none; }
      #header.recogido .headerGrid .header-section-center {
        grid-area: headersectioncenter;
        position: relative;
        top: -2px; }
      #header.recogido .headerGrid .header-section-right {
        display: none;
        position: absolute;
        right: 67px;
        width: 200px;
        top: 32px; }
        #header.recogido .headerGrid .header-section-right #search_widget {
          width: 100%;
          display: block;
          margin: 5px auto;
          position: relative; }
          #header.recogido .headerGrid .header-section-right #search_widget input {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px; }
      #header.recogido .headerGrid .header-nav-top {
        grid-area: mainMenu;
        z-index: 10; }
      #header.recogido .headerGrid .header-nav-corporative {
        display: none; }
      #header.recogido .headerGrid .logo {
        display: block;
        background-image: url("/themes/base/assets/img/logo_sticky.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 100px;
        height: 36px;
        margin: 7px auto 0; }
        @media (max-width: 1024px) {
          #header.recogido .headerGrid .logo {
            margin: 0; } }
        #header.recogido .headerGrid .logo .subTitleLogo {
          display: none; }

body {
  overscroll-behavior: none; }

#module-djvpersonalizeproduct-personalizeproduct,
#module-djvpersonalizeproduct-persv4 {
  overflow-x: auto; }
  #module-djvpersonalizeproduct-personalizeproduct label,
  #module-djvpersonalizeproduct-persv4 label {
    margin-bottom: 5px; }
  #module-djvpersonalizeproduct-personalizeproduct p,
  #module-djvpersonalizeproduct-persv4 p {
    margin-bottom: 5px; }
  #module-djvpersonalizeproduct-personalizeproduct .form-group,
  #module-djvpersonalizeproduct-persv4 .form-group {
    margin-bottom: 5px; }
  #module-djvpersonalizeproduct-personalizeproduct .progress,
  #module-djvpersonalizeproduct-persv4 .progress {
    height: 25px; }
  #module-djvpersonalizeproduct-personalizeproduct > header,
  #module-djvpersonalizeproduct-persv4 > header {
    height: 40px;
    min-height: 40px;
    position: sticky;
    z-index: 10000; }
    @media (max-width: 768px) {
      #module-djvpersonalizeproduct-personalizeproduct > header,
      #module-djvpersonalizeproduct-persv4 > header {
        position: fixed; } }
    #module-djvpersonalizeproduct-personalizeproduct > header > div,
    #module-djvpersonalizeproduct-persv4 > header > div {
      background-color: #333333; }
      #module-djvpersonalizeproduct-personalizeproduct > header > div a:hover,
      #module-djvpersonalizeproduct-persv4 > header > div a:hover {
        color: #F28E00; }
      #module-djvpersonalizeproduct-personalizeproduct > header > div .logo,
      #module-djvpersonalizeproduct-persv4 > header > div .logo {
        height: 40px;
        background-image: url("/themes/base/assets/img/logo_sticky.svg");
        background-size: 100px;
        background-repeat: no-repeat;
        background-position: right 7px;
        margin-top: 0;
        display: block;
        padding: 8px 0 0 10px;
        width: 290px;
        text-align: left;
        margin: auto; }
  @media (max-width: 768px) {
    #module-djvpersonalizeproduct-personalizeproduct,
    #module-djvpersonalizeproduct-persv4 {
      overflow-x: auto; } }
  @media (max-width: 768px) {
    #module-djvpersonalizeproduct-personalizeproduct #djv-two-columns-layer,
    #module-djvpersonalizeproduct-persv4 #djv-two-columns-layer {
      max-width: 1400px;
      min-width: 1170px; } }
  #module-djvpersonalizeproduct-personalizeproduct #blockPersonalizeScreener,
  #module-djvpersonalizeproduct-persv4 #blockPersonalizeScreener {
    position: fixed;
    top: 0;
    z-index: 998;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5); }
    #module-djvpersonalizeproduct-personalizeproduct #blockPersonalizeScreener .back-to-personalize,
    #module-djvpersonalizeproduct-persv4 #blockPersonalizeScreener .back-to-personalize {
      position: absolute;
      top: 60px;
      left: 0;
      border: none;
      background-color: #ff7926;
      color: white;
      padding: 10px 30px 10px 30px;
      border-top-right-radius: 30px;
      border-bottom-right-radius: 30px;
      cursor: pointer; }

.personalize-content {
  background: #f1f1f1;
  margin-top: -20px;
  padding-top: 20px;
  padding-bottom: 20px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }
  .personalize-content #columns .recordatorio-equipaciones-deportivas {
    background-color: #F5A533;
    padding: 10px;
    display: block;
    color: white;
    position: relative;
    top: -10px; }
    .personalize-content #columns .recordatorio-equipaciones-deportivas p {
      display: block;
      width: calc(100% - 60px);
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      margin-bottom: 0; }
      .personalize-content #columns .recordatorio-equipaciones-deportivas p .icon-bell {
        width: 30px;
        display: inline-block;
        margin-right: 10px;
        transform: rotateZ(-25deg);
        top: 0px;
        position: relative; }
  .personalize-content #columns .djv-product-personalize-container {
    max-width: 1170px;
    min-width: 920px;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-template-columns: 65% 35%;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 14px;
    min-width: 1170px; }
    .personalize-content #columns .djv-product-personalize-container .block-tittle {
      font-size: 12px; }
      .personalize-content #columns .djv-product-personalize-container .block-tittle::before {
        font-size: 10px; }
    .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left {
      overflow: auto; }
      .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .div-design-area {
        display: block;
        min-height: 640px; }
      .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas {
        display: block;
        min-height: 138px; }
        @media (max-width: 768px) {
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas {
            margin-left: auto;
            margin-right: auto;
            width: 768px; } }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas .select_view ul {
          display: flex;
          flex-flow: row wrap; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas .select_view ul li {
            width: 20%; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas .personalizationAreaThumbnail {
          cursor: pointer; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas .personalizationAreaThumbnail img {
            max-width: 80px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas .personalizationAreaThumbnail.active {
            border-color: #F28E00; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas strong {
          margin: 15px;
          text-align: center;
          display: block; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas i {
          background: #f1f1f1; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas ul {
          text-align: left;
          display: flex;
          flex-flow: row wrap; }
          @media (max-width: 768px) {
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas ul {
              position: relative; } }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas ul > * {
            display: inline-block !important;
            margin: 0;
            padding: 5px;
            float: left !important;
            clear: none;
            width: 20%;
            text-align: center;
            font-size: 12px;
            cursor: pointer; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-left .djv-product-personalize-areas ul > * a {
              display: block;
              text-align: center;
              border: 1px solid #aaaaaa;
              padding: 5px; }
    .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right {
      padding: 2px;
      z-index: 999; }
      .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools {
        background-color: white;
        width: auto;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        margin: auto;
        padding: 15px 20px;
        overflow-y: auto;
        overflow-x: hidden;
        height: 565px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools header {
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-bottom: 1px solid #777777;
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          font-size: 12px;
          height: auto; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools header h1 {
            margin-bottom: 5px;
            font-family: "Open Sans", sans-serif;
            font-weight: 700;
            font-size: 20px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools header .back-to-personalize {
            background-color: #5abe20;
            color: white;
            border: 2px solid #5abe20;
            border-radius: 0;
            transition: all 500ms;
            text-align: center;
            text-transform: uppercase;
            padding: 5px 5px;
            cursor: pointer;
            display: block;
            margin-top: 10px;
            font-size: 12px;
            cursor: pointer; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools header .back-to-personalize:hover {
              background-color: white;
              color: #5abe20; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box {
          padding-bottom: 30px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .djv-product-personalize-color-attribute-selection > div {
            display: flex;
            gap: 10px;
            flex-wrap: wrap;
            text-align: center; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .djv-product-personalize-color-attribute-selection > div > div {
              margin-right: 5px; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .djv-product-personalize-color-attribute-selection > div > div label {
                display: block;
                width: 30px;
                height: 30px;
                overflow: hidden;
                background-color: #D53128;
                color: transparent; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .djv-product-personalize-color-attribute-selection > div > div label input {
                  opacity: 0;
                  margin: 5px auto;
                  display: block; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel {
            width: auto;
            margin: auto;
            margin-top: 20px;
            padding: 0px;
            font-size: 12px;
            line-height: 14px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel > * {
              align-items: center; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel > * img {
                width: 30px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-image,
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-text,
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-layers {
              width: 90px;
              padding: 10px;
              display: inline-block;
              border: 1px solid #aaaaaa;
              border-bottom: 0;
              border-top-left-radius: 6px;
              border-top-right-radius: 6px;
              text-align: center;
              position: relative;
              top: 1px;
              cursor: pointer; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-image.selected,
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-text.selected,
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-layers.selected {
                border-bottom: 1px solid white; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-image a,
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-text a,
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-layers a {
                display: block; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-image a p,
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-text a p,
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .design-panel-toggle-layers a p {
                  margin-bottom: 0; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .form-group {
              margin-bottom: 5px; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel .form-group small {
                display: block;
                margin-bottom: 5px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel-panel-text,
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel-panel-image,
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .design-panel-panel-layers {
            min-height: 180px;
            padding: 10px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover {
            border: 1px solid #aaaaaa;
            width: 100%; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .add_item_text {
              display: block;
              margin-bottom: 10px; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .add_item_text .btn-create-text {
                background-color: #F28E00;
                color: white;
                border: 2px solid #F28E00;
                border-radius: 0;
                transition: all 500ms;
                text-align: center;
                text-transform: uppercase;
                padding: 5px 5px;
                cursor: pointer;
                display: block;
                margin-top: 13px;
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                font-size: 13px;
                color: white;
                text-transform: uppercase; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .add_item_text .btn-create-text:hover {
                  background-color: white;
                  color: #F28E00; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-text .form-group {
              margin-bottom: 5px;
              height: auto; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-text .form-group .text-update {
                font-size: 13px;
                padding: 5px 10px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts {
              display: block; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts > * .form-group {
                margin-bottom: 5px;
                height: auto; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts > * .form-group small {
                  display: block;
                  margin-bottom: 5px;
                  padding-left: 10px; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-family {
                display: inline-block;
                width: 70%; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-family .dropdown-letters {
                  width: 100%;
                  font-size: 14px; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-color {
                display: inline-block;
                top: -30px;
                position: relative; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-color .popover-colores {
                  margin-top: -24px; }
                  .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-color .popover-colores .popover-content {
                    padding: 9px 9px; }
                    .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-color .popover-colores .popover-content small {
                      position: relative;
                      top: 6px; }
                    .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #dg-popover .popover-content .dg-options-content .toolbar-action-fonts .select-font-color .popover-colores .popover-content .list-colors {
                      left: 40%;
                      position: relative;
                      top: 5px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container {
            border: 1px solid #aaaaaa;
            margin-bottom: 15px;
            width: 100%;
            padding: 10px;
            font-size: 14px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .action-upload {
              background-color: #F28E00;
              color: white;
              border: 2px solid #F28E00;
              border-radius: 0;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 5px 5px;
              cursor: pointer;
              display: block;
              width: 100%;
              margin-top: 13px;
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              font-size: 13px;
              color: white;
              text-transform: uppercase; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .action-upload:hover {
                background-color: white;
                color: #F28E00; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded {
              display: grid;
              grid-template-columns: repeat(4, 25%); }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded .view-thumb {
                width: 50px;
                height: 80px;
                overflow: hidden;
                position: relative;
                margin-bottom: 10px; }
                @media (max-width: 768px) {
                  .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded .view-thumb {
                    height: 95px; } }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded .view-thumb .img-thumbnail {
                  max-width: 50px;
                  max-height: 50px;
                  margin: 0 auto;
                  display: block; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded .view-thumb .progress.progress-bar-container {
                  width: 100%;
                  bottom: 0px;
                  margin-top: 5px; }
                  @media (max-width: 768px) {
                    .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded .view-thumb .progress.progress-bar-container {
                      height: 40px; } }
                  @media (max-width: 768px) {
                    .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .images-management-container .images-uploaded-container #images-uploaded .view-thumb .progress.progress-bar-container .progress-bar {
                      font-size: 12px; } }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .add_to_cart .hasterminado {
            display: block;
            background-color: white;
            padding: 5px 10px;
            text-align: center;
            border: 1px solid #aaa;
            border-radius: 10px;
            margin: 14px;
            font-size: 12px;
            color: #888; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .add_to_cart .btn-add_to_cart {
            background-color: #0249f3;
            width: 100%;
            display: block;
            border-radius: 0;
            margin: 10px auto;
            padding: 10px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .add_to_cart .btn-add_to_cart a {
              color: white;
              text-transform: uppercase;
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
              font-size: 15px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .link_help {
            display: block;
            padding: 6px 3px;
            background-color: #ededed;
            border: 1px solid #cacaca;
            text-align: center;
            font-size: 12px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .link_help img {
              display: block;
              margin-left: auto;
              margin-right: auto;
              margin-bottom: 5px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box .link_help a {
              display: block;
              color: #f09205;
              text-decoration: underline;
              margin-top: 5px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl {
            border: 1px solid #aaaaaa;
            margin-bottom: 16px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl .popover-title {
              font-family: "Open Sans", sans-serif;
              font-weight: 400;
              font-size: 13px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl #layers {
              float: none;
              padding-left: 0; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl #layers li.layer {
                border: 1px solid #E1E1E1;
                border-radius: 4px;
                line-height: 22px;
                margin: 4px;
                padding: 2px 4px;
                list-style-type: none;
                cursor: pointer; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl #layers li.layer a {
                  cursor: pointer; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl #layers li.layer img {
                  height: 25px;
                  margin-right: 0px; }
                .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-box #layersControl #layers li.layer.active {
                  background: url("/themes/base/assets/img/bg-layer.png") repeat-x scroll left top rgba(0, 0, 0, 0); }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection ul, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection ul {
          margin-bottom: 5px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants {
          border-bottom: 0;
          margin-bottom: 0; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .block-tittle, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .block-tittle {
            margin: 10px 0px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul {
            display: block; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li .product-variants-tallas-input-container, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li .product-variants-tallas-input-container {
              margin-bottom: 0px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li span, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li span {
              display: inline-block;
              min-width: 50px;
              text-align: center;
              margin-right: 10px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li label, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li label {
              text-transform: uppercase;
              display: inline-block; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li label input, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li label input {
                background-color: #f1f1f1;
                border: 1px solid #dddddd;
                border-top-left-radius: 4px;
                border-bottom-left-radius: 4px;
                padding: 5px;
                width: 200px; }
            .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li button, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li button {
              display: inline-block;
              background-color: #444444;
              border: none;
              color: white;
              padding: 7px 11px 6px;
              font-family: 'comunes';
              position: relative;
              top: 2px;
              cursor: pointer; }
              .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li button:last-of-type, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li button:last-of-type {
                border-top-right-radius: 4px;
                border-bottom-right-radius: 4px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-other-attribute-selection .block-tittle {
          margin: 10px 0px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-other-attribute-selection select {
          width: 80%;
          font-size: 13px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-production-time .block-tittle {
          margin: 10px 0px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-production-time select {
          width: 80%; }
      .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart-box {
        background-color: white;
        width: auto;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        margin: auto;
        padding: 15px 20px;
        margin-top: 16px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart-box > * > * label {
          text-transform: uppercase; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart-box > * > * label input {
            background-color: #f1f1f1;
            border: 1px solid #dddddd;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            padding: 5px; }
      .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart {
        background-color: #333333;
        width: auto;
        box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
        margin: auto;
        padding: 15px 20px;
        margin-top: 16px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .total-quantity,
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .price,
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .subtotal-price,
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .production-price,
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .total-price {
          color: white; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .total-quantity, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .production-price {
          font-size: 12px;
          color: #999999; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .total-price {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 20px; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .note {
          font-size: 10px;
          color: #999999; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart #subtotalPrice, .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart #subtotalPriceWT {
          font-size: 20px;
          font-family: "Open Sans", sans-serif;
          font-weight: 700; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .djv-product-update-price-button {
          background-color: #F28E00;
          color: white;
          border: 2px solid #F28E00;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer;
          display: block;
          width: 100%;
          margin-top: 5px;
          padding: 16px;
          border-radius: 0px;
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 17px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .djv-product-update-price-button:hover {
            background-color: white;
            color: #F28E00; }
        .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .btn-add_to_cart {
          background-color: #5abe20;
          color: white;
          border: 2px solid #5abe20;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer;
          display: block;
          width: 100%;
          margin-top: 5px;
          padding: 16px;
          border-radius: 0px;
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 17px; }
          .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-addtocart .add_to_cart .btn-add_to_cart:hover {
            background-color: white;
            color: #5abe20; }
  .personalize-content #columns > div.row {
    margin-bottom: 120px; }
  .personalize-content #columns .no_personalize_mobile {
    padding: 15px;
    line-height: 25px; }

.personalize-product-description {
  max-width: 1170px;
  min-width: 920px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 50px; }
  @media (max-width: 768px) {
    .personalize-product-description {
      padding: 10px 20px; } }
  .personalize-product-description h2 {
    font-size: 22px;
    margin-top: 50px;
    text-align: left; }
  .personalize-product-description img {
    max-width: 100%;
    height: auto; }
  @media (max-width: 768px) {
    .personalize-product-description .container-tabla-precios {
      width: 50%; }
      .personalize-product-description .container-tabla-precios .tabla-precios {
        margin: 0 0; } }

.totalization-summary .subtotal-price-wt {
  color: white; }

/* Heredado de styles.css */
#design-area {
  margin-top: 0;
  position: relative;
  z-index: 1; }
  #design-area .content-inner {
    height: 100%; }

#uniform-files-upload .action::before {
  content: 'Selec. fichero';
  display: block; }

#dg-popover .popover-title .popover-close {
  display: none; }

#dg-popover .popover-title {
  background-color: #007aff;
  border: 0 none;
  border-radius: 3px 0 0 3px;
  left: 4px;
  padding: 4px 6px;
  position: relative;
  top: 2px;
  border-bottom: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-top: 1px solid #ccc;
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  display: none; }

#dg-popover > div.popover-content {
  display: inline-block;
  height: auto;
  margin: 0;
  min-height: auto;
  padding: 0;
  width: 100%; }

.popover-content {
  display: block;
  padding: 9px 14px; }

.dropdown-color {
  border: 1px solid #CCC;
  box-shadow: 1px 1px 2px 0 #666;
  cursor: pointer;
  float: left;
  height: 22px;
  line-height: 22px;
  margin: 3px;
  padding-left: 2px;
  padding-top: 2px;
  text-align: center;
  width: 22px; }

.list-colors .bg-colors {
  display: inline-block;
  height: 27px;
  margin: 2px;
  width: 25px;
  border: 1px solid #CCC;
  cursor: pointer;
  margin-right: 6px; }

#dg-myclipart {
  top: 200px; }

.ui-movable-handle::before {
  color: #000;
  font-size: 13px;
  line-height: 100%;
  content: "\f047";
  font-family: FontAwesome;
  transform: rotate(90deg);
  position: relative;
  top: -2px; }

.ui-rotatable-handle::before {
  color: #000;
  font-size: 13px;
  line-height: 100%;
  content: "\f01e";
  font-family: FontAwesome;
  transform: rotate(90deg);
  position: relative;
  top: -2px; }

.itemControls-container {
  margin: 0;
  padding: 0;
  position: absolute;
  z-index: 999999;
  top: 0;
  left: 0;
  overflow: visible; }

.itemControls-area {
  position: absolute;
  overflow: visible;
  border--: 1px solid red; }

.itemControls-mask {
  border: 1px solid green;
  width: 0px;
  height: 0px;
  visibility: hidden;
  position: absolute;
  /*padding:2px;*/ }

.itemControls-mask .ui-resizable-handle {
  position: absolute;
  bottom: -22px;
  right: -20px;
  background-color: white;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0;
  border: 1px solid gray; }

.itemControls-mask .ui-removable-handle {
  position: absolute;
  bottom: -22px;
  left: -20px;
  background-color: white;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0;
  border: 1px solid gray; }

.itemControls-mask .ui-draggable-handle {
  position: absolute;
  top: -22px;
  right: -20px;
  background-color: white;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0;
  border: 1px solid gray; }

.itemControls-mask .ui-movable-handle {
  position: absolute;
  top: -22px;
  left: -20px;
  background-color: white;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0;
  border: 1px solid gray; }

.itemControls-mask .ui-rotatable-handle {
  position: absolute;
  top: -22px;
  right: -20px;
  background-color: white;
  height: 20px;
  display: block;
  width: 20px;
  padding: 0;
  border: 1px solid gray; }

#module-djvpersonalizeproduct-cart,
#module-djvpersonalizeproduct-cartv4 {
  display: block; }
  @media (max-width: 768px) {
    #module-djvpersonalizeproduct-cart,
    #module-djvpersonalizeproduct-cartv4 {
      overflow-y: scroll; } }
  #module-djvpersonalizeproduct-cart .recogido #djv-two-columns-layer,
  #module-djvpersonalizeproduct-cartv4 .recogido #djv-two-columns-layer {
    margin-top: 0 !important; }
  #module-djvpersonalizeproduct-cart .personalize-content #columns .djv-product-personalize-container,
  #module-djvpersonalizeproduct-cartv4 .personalize-content #columns .djv-product-personalize-container {
    min-width: auto;
    display: block; }
    @media (max-width: 768px) {
      #module-djvpersonalizeproduct-cart .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li label input,
      #module-djvpersonalizeproduct-cartv4 .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-size-attribute-qty-selection .product-variants .product-variants-tallas ul li label input {
        width: 90px; } }
    @media (max-width: 768px) {
      #module-djvpersonalizeproduct-cart .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li label input,
      #module-djvpersonalizeproduct-cartv4 .personalize-content #columns .djv-product-personalize-container .djv-product-personalize-right .djv-product-personalize-right-tools .djv-product-personalize-qty-selection .product-variants .product-variants-tallas ul li label input {
        width: 90px; } }
  #module-djvpersonalizeproduct-cart #blockPersonalizeScreener,
  #module-djvpersonalizeproduct-cartv4 #blockPersonalizeScreener {
    display: none; }
  #module-djvpersonalizeproduct-cart #djv-two-columns-layer,
  #module-djvpersonalizeproduct-cartv4 #djv-two-columns-layer {
    margin-top: 10px; }
  #module-djvpersonalizeproduct-cart .block-tittle a,
  #module-djvpersonalizeproduct-cartv4 .block-tittle a {
    display: none; }

.whatchmework {
  font-size: 1.2; }
