.product-variants {
    //border-bottom: 1px solid red;
    margin-bottom: 0px;
    label {
        display: block;
        font-size: $font-sm;
    }
    ul{
        padding-left: 0;
        li{
            list-style: none;
            font-weight: bold;
            &::before{
                font-size: $font-xs;
                color: $gris6;
            }
            ul{
                padding-left: 20px;
                li{
                    font-weight: normal;

                    input[type="radio"]{
                        display: inline-block;
                        margin-right: 10px;
                        &[checked]+label{
                            color:$complementario4;
                        }
                    }
                    label{
                        display: inline-block;
                    }
                }
            }
        }
    }
    .product-variants-tallas {
        >a{
            margin-left: 15px;
            font-size: $font-sm;
            text-decoration: underline;
            @include fuente($font-first , $font-medium);
        }
        ul {
            display: grid;
            grid-template-columns: auto auto;
            padding-left: 0;

            .product-variants-tallas-input-container {
                list-style: none;
                display: block;
                overflow: hidden;
                margin-bottom: 10px;
                cursor: pointer;

                .swap-bg {
                    width: 24px;
                    height: 24px;
                    background-color: white;
                    border: 1px solid #aaa;
                    display: inline-block;
                    background-size: contain;
                    cursor: pointer;

                    input {
                        opacity: 0;
                        width: 35px;
                        height: 35px;
                        cursor: pointer;
                    }
                }

                .attribute-name {
                    background-color: #aaa;
                    padding: 5px 15px;
                    font-size: $font-sm;
                }

                &.selected {
                    .swap-bg::before {
                        content: "✔";
                        position: absolute;
                        margin-left: 5px;
                    }

                    .attribute-name {
                        background-color: $primario3;
                        color: white;
                        padding: 5px 15px;
                        font-size: $font-sm;
                    }
                }

                &.disabled {
                    position: relative;

                    span {
                        opacity: 0.5;
                    }
                }
            }
        }
    }

    .product-variants-color {
        text-align: left;
        ul {
            padding-left: 0;
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
            @media(max-width: $screen-sm){
                margin-bottom: 5px;
            }
            .product-variants-color-input-container {
                list-style: none;
                display: inline-block;
                margin-bottom: 10px;
                width: 30px;
                height: 30px;
                margin-right: 5px;
                cursor: pointer;
                &.selected{
                    .swap-bg {
                        border: 2px solid white;
                        outline: 2px solid black;
                        background-size: contain;
                    }
                }
                .swap-bg {
                    width: 30px;
                    height: 30px;
                    display: inline-block;
                    border: 1px solid;
                    background-size: contain;
                    cursor: pointer;
                    input {
                        opacity: 0;
                        width: 28px;
                        height: 22px;
                        cursor: pointer;
                    }
                }
                &.disabled {
                    position: relative;
                    span {
                        opacity: 0.5;
                    }
                    &::after {
                        content: "";
                        display: block;
                        width: 46px;
                        height: 46px;
                        position: absolute;
                        z-index: 9;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        padding: 0 4px;
                        border-radius: 40px;
                        background-image: url("#{$cdnserver1}/themes/base/assets/img/aspa.svg");
                        color: $gris1;
                    }
                }
            }
        }
    }
}
