#product{
    .product-accessories{
        padding-top: 20px;
        @media(max-width: $screen-md){
            margin-left: 0;
            width: auto;
            float: none;
            padding: 0px;
        }
        .product-accessories-container{
            .products{
                @media(max-width: $screen-sm){
                    width: 100%;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    -webkit-overflow-scrolling: touch;
                    -webkit-scroll-snap-type: mandatory;
                    scroll-snap-type: x mandatory;
                    -webkit-scroll-snap-points-x: repeat(100%);
                    scroll-snap-points-x: repeat(100%);
                    > * {
                        scroll-snap-align: start;
                        width: 100%;
                        position: relative;
                        display: inline-block;
                        margin-right: -4px;
                        text-align: center;
                    }
                }
            }
        }

        .product-miniature{
            @media(max-width: $screen-md){
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: 260px auto;
                margin-bottom: 16px;
            }
            .product-miniature-img-block{
                @media(max-width: $screen-md){
                    max-height: 260px;
                }
                .product-thumbnail{
                    img{
                        @media(max-width: $screen-md){
                            width: auto;
                            max-width: 100%;
                            margin-bottom: 15px;
                        }
                    }
                }
            }
            .product-miniature-data-block{
                .name-product{

                    @media(max-width: $screen-md){
                        margin-left: auto;
                        margin-right: auto;
                        height: auto;
                        white-space: normal;
                        text-align: center;
                        font-size: $font-smd;
                        @include fuente($font-first , $font-bold);
                    }
                }
                .stars{
                    @media(max-width: $screen-md){
                        display: block;
                        max-width: 210px;
                        margin-left: auto;
                        margin-right: auto;
                        height: auto;
                    }
                }
                .product-price-and-shipping{
                    @media(max-width: $screen-md){
                        display: block;
                        max-width: 210px;
                        margin-left: auto;
                        margin-right: auto;
                        height: auto;
                    }

                    span.without-taxes{
                        @media(max-width: $screen-md){
                            font-size: $font-lg;
                        }
                    }

                }
                .product-list-actions{
                    @media(max-width: $screen-md){
                        display: block;
                        max-width: 210px;
                        margin-left: auto;
                        margin-right: auto;
                        height: auto;
                    }
                }
            }
        }
    }
}
