body:not(.recogido){
@media (max-width:$screen-lg) {
    #contact-icon-responsive {
        display: inline-block;
    }

    .header-nav-top {
        float: none;
        width: 100%;
        padding: 10px;
        position: fixed;
        top: 0;
        left: 0;
        background-color: rgba($primario4,0.98);
        height: calc(100vh);
        overflow-y: scroll;
        display: none;
        box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3);
        z-index: 999;
        #djv-close-nav-responsive{
            color: white;
            display: block;
            margin-bottom: 20px;
            cursor: pointer;
            position: relative;
            padding-left: 30px;
            &::before{
                font-size: $font-lg;
                position: absolute;
                top:0px;
                left: 0;
            }
        }
        #main-menu{
            margin-top: 10px;
             .sf-menu {
                display: block;
                position: relative;
                > li {
                    color: white;
                    border-bottom: 1px solid rgba(255,255,255,.9);
                    padding-bottom: 4px;
                    margin-bottom: 8px;
                    position: relative;
                    display: block;

                    &.djv-nav-drop-columns .menu-toggle-icon::after,
                    &.djv-nav-drop-simple .menu-toggle-icon::after,
                    &.djv-nav-drop-thums .menu-toggle-icon::after {
                        display: inline-block;
                        font-family: "comunes";
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: '\004e';
                        position: absolute;
                        right: 5px;
                        top: 8px;
                    }
                    &.djv-nav-drop-simple{
                        ul{
                            box-shadow: none;
                        }
                    }
                    &.djv-nav-drop-thums {
                        > ul {
                            display: none;
                            grid-template-columns: auto;
                            grid-gap: 10px;
                            left: 0;

                            > li {
                                font-size: $font-xs;
                                text-align: center;
                                margin-bottom: 10px;

                                img {
                                    width: 100px;
                                    height: 100px;
                                    display: block;
                                    margin-bottom: 10px;
                                }
                            }
                        }

                        &:hover {
                            > ul {
                                display: none;
                                grid-template-columns: auto auto;
                                grid-gap: 10px;
                                left: 0;
                            }
                        }

                        li {
                            text-align: center;

                            img {
                                margin-left: auto;
                                margin-right: auto;
                            }
                        }
                    }

                    .marcador {
                        display: none;
                    }

                    a {
                        color: white;
                        white-space: normal;
                    }

                    > ul {
                        background-color: rgba($primario4,0.3);
                        position: relative;
                        border-radius: 0;
                        li{
                            white-space: normal;
                        }
                        //box-shadow: none;
                    }

                    &:hover {
                        > ul,
                        > div:not(.marcador) {
                            display: none;
                        }
                    }
                }

                .djv-nav-drop-columns {
                    > div:not(.marcador) {
                        background-color: rgba($primario4,0.3);
                        position: relative;
                        border-radius: 0;
                        box-shadow: none;
                        padding: 0;

                        > div {
                            float: none;
                            margin: 0;

                            span {
                                color: $primario3;
                                border-bottom: 1px solid $primario3;
                                margin-top: 6px;
                            }
                        }
                    }
                }
            }
        }
    }
}
}
