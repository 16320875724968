@charset "UTF-8";
/* Generated by Glyphter (http://www.glyphter.com) on  Thu May 16 2019*/
@font-face {
  font-family: 'comunes';
  src: url("project/djv-icon-font/fonts/comunes.eot");
  src: url("project/djv-icon-font/fonts/comunes.eot?#iefix") format("embedded-opentype"), url("project/djv-icon-font/fonts/comunes.woff") format("woff"), url("project/djv-icon-font/fonts/comunes.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

[class*='djv-icon']:before {
  display: inline-block;
  font-family: 'comunes';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.djv-icon-coment:before {
  content: '\0041'; }

.djv-icon-coment-2:before {
  content: '\0042'; }

.djv-icon-carro:before {
  content: '\0043'; }

.djv-icon-menu:before {
  content: '\0044'; }

.djv-icon-lupa:before {
  content: '\0045'; }

.djv-icon-lupa-plus:before {
  content: '\0046'; }

.djv-icon-lupa-minus:before {
  content: '\0047'; }

.djv-icon-usuario:before {
  content: '\0048'; }

.djv-icon-telefono:before {
  content: '\0049'; }

.djv-icon-mail:before {
  content: '\004a'; }

.djv-icon-arrow-left:before {
  content: '\004b'; }

.djv-icon-arrow-right:before {
  content: '\004c'; }

.djv-icon-arrow-up:before {
  content: '\004d'; }

.djv-icon-arrow-down:before {
  content: '\004e'; }

.djv-icon-facebook:before {
  content: '\004f'; }

.djv-icon-twitter:before {
  content: '\0050'; }

.djv-icon-youtube:before {
  content: '\0051'; }

.djv-icon-instagram:before {
  content: '\0052'; }

.djv-icon-delete:before {
  content: '\0053'; }

.djv-icon-close:before {
  content: '\0054'; }

.djv-icon-ok:before {
  content: '\0055'; }

.djv-icon-ok-circle:before {
  content: '\0056'; }

.djv-icon-ok-circle-open:before {
  content: '\0057'; }

.djv-icon-plus:before {
  content: '\0058'; }

.djv-icon-minus:before {
  content: '\0059'; }

.djv-icon-refresh:before {
  content: '\005a'; }

.djv-icon-reload:before {
  content: '\0061'; }

.djv-icon-reply:before {
  content: '\0062'; }

.djv-icon-move:before {
  content: '\0063'; }

.djv-icon-filter:before {
  content: '\0064'; }

.djv-icon-exit:before {
  content: '\0065'; }

.djv-icon-gear:before {
  content: '\0066'; }

.djv-icon-question:before {
  content: '\0067'; }

.djv-icon-question-circle:before {
  content: '\0068'; }

.djv-icon-information:before {
  content: '\0069'; }

.djv-icon-warning:before {
  content: '\006a'; }

.djv-icon-star:before {
  content: '\006b'; }

.djv-icon-empty-star:before {
  content: '\006c'; }

.djv-icon-printer:before {
  content: '\006d'; }

.djv-icon-swipe:before {
  content: '\006e'; }

.djv-icon-tap:before {
  content: '\006f'; }

.djv-icon-whatsapp:before {
  content: '\0070'; }

.djv-icon-pinterest:before {
  content: '\0071'; }

.djv-icon-delivery:before {
  content: '\0072'; }

.djv-icon-heart:before {
  content: '\0073'; }

.djv-icon-bag:before {
  content: '\0074'; }

.djv-icon-linearheart:before {
  content: '\0075'; }

.djv-icon-linearuser:before {
  content: '\0076'; }

.djv-icon-lineardelivery:before {
  content: '\0077'; }

.djv-icon-clock:before {
  content: '\0078'; }

.djv-icon-trash:before {
  content: '\0079'; }

.djv-icon-openeye:before {
  content: '\007a'; }

.djv-icon-closeeye:before {
  content: '\0030'; }

.djv-icon-pencil:before {
  content: '\0031'; }

.djv-icon-address:before {
  content: '\0032'; }

.djv-icon-half-star:before {
  content: '\0033'; }

.djv-border-top-dashed {
  border-top: 1px dashed #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-top-solid {
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-top: 20px; }

.djv-border-bottom-dashed {
  border-bottom: 1px dashed #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

.djv-border-bottom-solid {
  border-bottom: 1px solid #cccccc;
  padding-bottom: 20px;
  margin-bottom: 20px; }

* {
  box-sizing: border-box; }

body {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  color: black; }

h2 {
  font-family: "Fredoka One", cursive;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 36px;
  text-align: center;
  color: black; }
  @media (max-width: 768px) {
    h2 {
      font-size: 24px; } }

h3 {
  font-family: "Fredoka One", cursive;
  font-weight: 400;
  font-size: 24px; }

a {
  color: #F28E00;
  font-weight: 700; }
  a:hover {
    color: #F28E00;
    text-decoration: underline; }

p {
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }

.full-width {
  width: 100vw;
  display: block;
  position: relative;
  margin-left: 50%;
  transform: translateX(-50%); }

.djv-container {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .djv-container {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .djv-container {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .djv-container {
      max-width: 500px;
      min-width: 300px; } }

.grid-2, .grid-3, .grid-4, .grid-5 {
  display: grid;
  grid-gap: 10px; }

.grid-2 {
  grid-template-columns: repeat(2, calc(50% - 10px)); }

.grid-3 {
  grid-template-columns: repeat(3, calc(33.33% - 10px)); }

.grid-4 {
  grid-template-columns: repeat(4, calc(25% - 10px)); }

.grid-5 {
  grid-template-columns: repeat(5, calc(20% - 10px)); }

@media (max-width: 768px) {
  .xs-visible {
    display: block !important; } }

.blackfridayflag {
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 1; }
  .blackfridayflag img {
    width: 75px; }

.ps-alert-success li {
  list-style: none;
  border-bottom: 1px dashed #3b7d15; }
  .ps-alert-success li i {
    float: left;
    margin-right: 20px;
    color: #5abe20;
    font-size: 45px;
    padding-bottom: 20px; }

.stars i {
  font-size: 11px;
  color: black; }

.stars span {
  font-size: 10px;
  display: inline-block;
  position: relative;
  width: 20px;
  height: 20px; }
  .stars span a {
    position: absolute;
    top: 6px;
    font-size: 11px;
    color: black; }

@media (max-width: 768px) {
  body {
    overflow-x: hidden; }
  .xs-hidden {
    display: none; } }

#lgcookieslaw_banner #lgcookieslaw_accept {
  background-color: #dadada;
  padding: 5px;
  display: block;
  border: 0;
  border-radius: 6px; }

.microdatos {
  display: none; }

.hidden {
  display: none; }

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; }

#wrapper {
  clear: both; }

#wrapper,
.header-top,
.header-topbar {
  padding-left: 0;
  padding-right: 0; }

.header-banner {
  text-align: center;
  background-color: black; }

#djv-two-columns-layer {
  min-height: 480px;
  margin-top: 20px; }
  @media (max-width: 1024px) {
    #djv-two-columns-layer {
      margin-top: 0px; } }

.recogido #djv-two-columns-layer {
  margin-top: 240px; }

@media (max-width: 1024px) {
  #index .carousel {
    margin-top: 90px;
    margin-bottom: 0px; } }

@media (max-width: 768px) {
  #index .carousel {
    margin-top: 40px; } }

#index #djv-two-columns-layer {
  margin-top: 40px; }
  @media (max-width: 768px) {
    #index #djv-two-columns-layer {
      margin-top: 10px; } }

#index.recogido .carousel {
  margin-top: 140px;
  margin-bottom: 0px; }

#module-djv_navigation-nsearch .carousel {
  margin-bottom: 0; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .carousel {
      margin-top: 40px; } }

#module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) {
  display: grid;
  grid-template-columns: 25% 75%;
  margin-top: 20px; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) {
      margin-top: 0; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) > div {
    padding: 0px; }
    @media (max-width: 1024px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) > div {
        padding: 8px; } }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) {
      display: block; } }

@media (max-width: 1024px) {
  #module-djv_navigation-nsearch .djv-navigation-nsearch-header {
    margin-top: 40px;
    padding: 10px; } }

#module-djv_navigation-nsearch.recogido .djv-navigation-nsearch-header {
  margin-top: 140px; }

#module-djv_navigation-nsearch.recogido .carousel {
  margin-top: 210px;
  margin-bottom: 0; }

#module-djv_navigation-nsearch.recogido .carousel ~ .djv-navigation-nsearch-header {
  margin-top: 0px; }

#module-djv_navigation-nsearch.recogido #djv-two-columns-layer {
  margin-top: 30px; }

#product #djv-two-columns-layer {
  margin-top: 0px; }

#product.recogido .djv-breadcrumb {
  margin-top: 200px; }

#authentication #djv-two-columns-layer {
  margin-top: 40px; }

#authentication.recogido #djv-two-columns-layer {
  margin-top: 160px; }

#module-faqs-display #center_column_fag {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #module-faqs-display #center_column_fag {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-faqs-display #center_column_fag {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-faqs-display #center_column_fag {
      max-width: 500px;
      min-width: 300px; } }

#header {
  min-height: 180px;
  position: relative;
  z-index: 99;
  width: 100%;
  top: 0;
  border-bottom: 1px solid #cccccc;
  max-width: 100vw; }
  @media (max-width: 1024px) {
    #header {
      min-height: 40PX;
      height: 40px;
      position: fixed; } }
  #header #header-topbanner {
    position: relative;
    width: 100%;
    display: none;
    overflow: hidden;
    background-color: white; }
    #header #header-topbanner .djv-container {
      position: relative; }
      @media (max-width: 768px) {
        #header #header-topbanner .djv-container {
          width: 100%;
          margin-left: 0;
          margin-right: 0;
          max-height: 105px; } }
      #header #header-topbanner .djv-container #close-banner-top {
        display: none;
        position: absolute;
        top: 5px;
        right: 10px;
        background-color: white;
        border-radius: 50%;
        height: 18px; }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container #close-banner-top {
            right: 2px; } }
      #header #header-topbanner .djv-container a {
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%; }
      #header #header-topbanner .djv-container img {
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
        @media (max-width: 768px) {
          #header #header-topbanner .djv-container img {
            max-width: 100%;
            max-height: 105px; } }
    @media (max-width: 1024px) {
      #header #header-topbanner {
        display: none; } }
  #header i.djv-icon-lupa {
    display: none;
    position: relative;
    color: white;
    cursor: pointer;
    z-index: 9;
    top: 9px;
    font-size: 19px;
    position: absolute;
    right: 115px; }
    @media (max-width: 1024px) {
      #header i.djv-icon-lupa {
        display: block;
        right: auto;
        left: calc(-100vw + 169px);
        position: absolute; } }
  #header #menu-icon-responsive {
    display: inline-block;
    position: relative;
    top: 2px;
    font-size: 20px;
    left: 14px;
    color: white;
    cursor: pointer;
    z-index: 9; }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive::before {
        display: inline-block; } }
    @media (max-width: 1024px) {
      #header #menu-icon-responsive span {
        display: none; } }
  #header .headerGrid {
    background-image: url("/themes/base/assets/img/topbarbg.gif");
    background-repeat: repeat-x;
    background-size: 1px 40px;
    background-color: white;
    height: 180px; }
    @media (max-width: 1024px) {
      #header .headerGrid {
        height: 40px; } }
    #header .headerGrid .djv-container {
      position: relative;
      display: grid;
      grid-template-columns: 31% 44% 25%;
      grid-template-areas: "topbarleft topbarcenter topbarright" "headersectionleft headersectioncenter headersectionright" "mainMenu mainMenu mainMenu";
      align-items: center;
      /* Bloque de grid */
      /* --- Fin Bloque de elementos adicionales*/
      /* Bloque de elementos adicionales*/
      /* -- Fin Bloque de elementos adicionales*/ }
      @media (max-width: 1024px) {
        #header .headerGrid .djv-container {
          grid-template-columns: 100px auto 100px;
          grid-template-areas: "navicons headersectioncenter topbarright"; } }
      @media (max-width: 1024px) {
        #header .headerGrid .djv-container {
          margin-left: 0px;
          margin-right: 0px;
          width: 100%; } }
      #header .headerGrid .djv-container .nav-icons {
        grid-area: navicons;
        display: none; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .nav-icons {
            display: block; } }
      #header .headerGrid .djv-container .header-topbar-before {
        grid-area: topbarleft;
        display: inline-block;
        font-size: 0.8rem;
        color: white; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-topbar-before {
            display: none; } }
        #header .headerGrid .djv-container .header-topbar-before a {
          color: inherit; }
        #header .headerGrid .djv-container .header-topbar-before .link-color {
          color: #cccccc; }
          #header .headerGrid .djv-container .header-topbar-before .link-color:hover {
            color: #333333; }
      #header .headerGrid .djv-container .header-topbar-center {
        grid-area: topbarcenter;
        display: inline-block;
        font-size: 0.8rem;
        text-align: center;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: white; }
        #header .headerGrid .djv-container .header-topbar-center span {
          color: white; }
          #header .headerGrid .djv-container .header-topbar-center span a {
            color: white; }
        #header .headerGrid .djv-container .header-topbar-center i {
          position: relative;
          margin-right: 7px;
          margin-left: 7px; }
          #header .headerGrid .djv-container .header-topbar-center i::before {
            font-size: 16px;
            font-weight: bold; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-topbar-center {
            display: none; } }
      #header .headerGrid .djv-container .header-topbar-after {
        grid-area: topbarright;
        display: inline-block;
        font-size: 0.8rem;
        position: relative; }
        #header .headerGrid .djv-container .header-topbar-after .djv-icon-usuario {
          color: white; }
      #header .headerGrid .djv-container .header-section-left {
        grid-area: headersectionleft; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-section-left {
            display: none; } }
        #header .headerGrid .djv-container .header-section-left h1, #header .headerGrid .djv-container .header-section-left h2 {
          font-size: 16px;
          font-family: "Open Sans", sans-serif;
          font-weight: 900;
          border-bottom: 1px "solid" #cccccc;
          padding-bottom: 10px;
          margin-bottom: 10px;
          padding-bottom: 5px;
          margin-bottom: 5px; }
        #header .headerGrid .djv-container .header-section-left p {
          font-size: 14px;
          color: #999999;
          margin-bottom: 0; }
      #header .headerGrid .djv-container .header-section-center {
        grid-area: headersectioncenter; }
      #header .headerGrid .djv-container .header-section-right {
        grid-area: headersectionright;
        position: relative; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-section-right {
            display: none;
            position: absolute;
            top: 40px;
            width: 100%;
            padding: 0px 4px;
            background-color: #F28E00; } }
      #header .headerGrid .djv-container .header-nav-top {
        grid-area: mainMenu; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-nav-top {
            display: none; } }
      #header .headerGrid .djv-container .djv-icon-usuario {
        font-size: 21px;
        position: absolute;
        top: 8px;
        right: 71px;
        padding: 0 6px;
        cursor: pointer;
        color: #26314c; }
      #header .headerGrid .djv-container .djv-icon-coment, #header .headerGrid .djv-container .djv-icon-crono {
        font-size: 15.4px;
        margin-right: 4px;
        position: relative;
        display: inline-block;
        width: 20px;
        height: 20px; }
      #header .headerGrid .djv-container .logo {
        background-image: url("/themes/base/assets/img/logo.svg");
        background-position: left top;
        background-repeat: no-repeat;
        background-size: 250px;
        width: 300px;
        height: 100px;
        display: block;
        margin: -6px auto 0 auto;
        padding: 0px;
        position: relative; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .logo {
            width: 100px;
            height: 40px;
            background-image: url("/themes/base/assets/img/logo_sticky.svg");
            background-size: 100px;
            background-position: center 10px;
            margin-top: 0; } }
        @media (max-width: 576px) {
          #header .headerGrid .djv-container .logo {
            width: 100px;
            height: 40px; } }
        #header .headerGrid .djv-container .logo .titleLogo {
          font-family: "Open Sans", sans-serif;
          font-weight: 900;
          font-size: 54px;
          color: black;
          top: 35px;
          position: relative;
          margin-left: 75px; }
        #header .headerGrid .djv-container .logo .subTitleLogo {
          display: block;
          margin-top: 24px;
          margin-bottom: 10px;
          padding: 5px;
          position: absolute;
          bottom: -10px;
          font-size: 14px;
          color: #777777;
          line-height: 13px; }
          @media (max-width: 1024px) {
            #header .headerGrid .djv-container .logo .subTitleLogo {
              display: none; } }
      #header .headerGrid .djv-container #search_widget {
        width: 100%;
        display: block;
        margin: 5px auto;
        position: relative; }
        #header .headerGrid .djv-container #search_widget input {
          background-color: white;
          width: 100%;
          display: block;
          position: relative;
          border: 1px solid #999999;
          border-bottom-left-radius: 0px;
          border-bottom-right-radius: 0px;
          border-top-left-radius: 0px;
          border-top-right-radius: 0px;
          padding: 11px 20px 11px 5px;
          color: #26445C;
          font-family: "Open Sans", sans-serif;
          font-size: 13px; }
          @media (max-width: 1024px) {
            #header .headerGrid .djv-container #search_widget input {
              border: none; } }
          #header .headerGrid .djv-container #search_widget input::placeholder {
            font-size: 12px;
            color: #666666;
            font-family: "Open Sans", sans-serif;
            text-align: center; }
        #header .headerGrid .djv-container #search_widget button {
          position: absolute;
          top: 8px;
          right: 0px;
          border: none;
          background-color: transparent;
          width: 30px;
          height: 30px;
          cursor: pointer; }
          #header .headerGrid .djv-container #search_widget button::before {
            display: inline-block;
            font-size: 24px;
            right: 0px;
            top: 0px;
            position: absolute;
            color: #777777;
            margin-right: 10px;
            margin-top: 2px; }
      #header .headerGrid .djv-container #blockcart-wrapper {
        position: relative;
        z-index: 999;
        display: inline-block;
        float: right; }
      #header .headerGrid .djv-container .blockcart .header {
        color: white;
        font-size: 0.8rem; }
      #header .headerGrid .djv-container .header-nav-corporative {
        margin: 0;
        padding: 0;
        text-align: center; }
        @media (max-width: 1024px) {
          #header .headerGrid .djv-container .header-nav-corporative {
            display: none; } }
        #header .headerGrid .djv-container .header-nav-corporative li {
          list-style: none;
          display: inline-block;
          font-size: 13px;
          color: #F5A533;
          text-align: center; }
          #header .headerGrid .djv-container .header-nav-corporative li a {
            color: inherit; }
            #header .headerGrid .djv-container .header-nav-corporative li a.nav-link {
              padding: 5px; }
            #header .headerGrid .djv-container .header-nav-corporative li a i {
              display: block;
              width: 25px;
              height: 25px;
              margin: 0 auto;
              font-size: 20px; }
          #header .headerGrid .djv-container .header-nav-corporative li.nav-item-separator {
            color: #333333; }
  #header.recogido {
    height: 38px;
    min-height: auto;
    position: fixed;
    top: 0; }
    #header.recogido .djv-icon-lupa {
      display: inline-block;
      color: white; }
    #header.recogido .headerGrid {
      height: 39px; }
      #header.recogido .headerGrid .djv-container {
        grid-template-columns: 120px auto 140px;
        grid-template-areas: "headersectioncenter mainMenu topbarright";
        position: relative; }
        #header.recogido .headerGrid .djv-container form .djv-icon-lupa {
          display: none; }
      #header.recogido .headerGrid .nav-icons {
        grid-area: navicons;
        display: none; }
      #header.recogido .headerGrid .header-topbar-before {
        display: none; }
      #header.recogido .headerGrid .header-topbar-center {
        display: none; }
      #header.recogido .headerGrid .header-topbar-after {
        grid-area: topbarright;
        margin-top: -6px; }
      #header.recogido .headerGrid .header-section-left {
        display: none; }
      #header.recogido .headerGrid .header-section-center {
        grid-area: headersectioncenter;
        position: relative;
        top: -2px; }
      #header.recogido .headerGrid .header-section-right {
        display: none;
        position: absolute;
        right: 67px;
        width: 200px;
        top: 32px; }
        #header.recogido .headerGrid .header-section-right #search_widget {
          width: 100%;
          display: block;
          margin: 5px auto;
          position: relative; }
          #header.recogido .headerGrid .header-section-right #search_widget input {
            border-bottom-left-radius: 3px;
            border-bottom-right-radius: 3px;
            border-top-left-radius: 0px;
            border-top-right-radius: 0px; }
      #header.recogido .headerGrid .header-nav-top {
        grid-area: mainMenu;
        z-index: 10; }
      #header.recogido .headerGrid .header-nav-corporative {
        display: none; }
      #header.recogido .headerGrid .logo {
        display: block;
        background-image: url("/themes/base/assets/img/logo_sticky.svg");
        background-repeat: no-repeat;
        background-size: contain;
        width: 100px;
        height: 36px;
        margin: 7px auto 0; }
        @media (max-width: 1024px) {
          #header.recogido .headerGrid .logo {
            margin: 0; } }
        #header.recogido .headerGrid .logo .subTitleLogo {
          display: none; }

body:not(.recogido) .sticky-visible {
  display: none; }

body:not(.recogido) .header-nav-top {
  width: 100%;
  position: relative;
  height: 35px;
  z-index: 1;
  margin: auto auto; }
  @media (max-width: 1024px) {
    body:not(.recogido) .header-nav-top {
      display: none;
      position: fixed;
      top: 0px;
      left: 0;
      z-index: 999;
      float: none;
      max-width: 100vw;
      width: 100%;
      height: calc(100vh);
      padding: 10px;
      overflow-y: scroll;
      background-color: rgba(53, 72, 87, 0.9);
      box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3); } }
  body:not(.recogido) .header-nav-top #djv-close-nav-responsive {
    display: none; }
  body:not(.recogido) .header-nav-top #main-menu {
    padding-top: 0px;
    margin-top: 10px; }
    body:not(.recogido) .header-nav-top #main-menu .sf-menu {
      display: flex;
      position: relative;
      padding-left: 0;
      color: black;
      margin-bottom: 0; }
      body:not(.recogido) .header-nav-top #main-menu .sf-menu a {
        color: inherit;
        text-decoration: none;
        font-weight: 400; }
      body:not(.recogido) .header-nav-top #main-menu .sf-menu ul {
        padding: 0; }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu ul.indentado {
          color: #666666;
          margin-top: -10px;
          padding-left: 5px; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu ul.indentado a:hover {
            text-decoration: underline !important; }
      body:not(.recogido) .header-nav-top #main-menu .sf-menu li {
        list-style: none; }
      body:not(.recogido) .header-nav-top #main-menu .sf-menu > li {
        white-space: nowrap;
        margin: 0 .2rem;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        padding: 6px 6px; }
        @media (max-width: 1024px) {
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li {
            font-size: 14px;
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            margin: 0 .5%; } }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li .marcador {
          width: 0;
          height: 0;
          border-right: 10px solid transparent;
          border-top: 0px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid white;
          position: absolute;
          z-index: 1;
          left: 50%;
          display: none;
          transform: translateX(-50%);
          box-shadow: none !important; }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul {
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5); }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador),
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul {
          display: none;
          position: absolute;
          background-color: white;
          padding: 5px;
          top: 37px;
          left: 0px; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador).alineado-derecha,
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul.alineado-derecha {
            left: auto;
            right: 0; }
          @media (max-width: 1024px) {
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador),
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul {
              margin-top: 5px;
              top: 0px;
              box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
              border-radius: 3px; } }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) span,
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul span {
            color: #F28E00;
            display: block;
            text-transform: none; }
            @media (max-width: 1024px) {
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) span,
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul span {
                color: #F28E00;
                font-family: "Open Sans", sans-serif;
                font-weight: 700; } }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li,
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul li {
            text-transform: initial;
            line-height: 30px;
            transition: all 500ms; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li a,
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul li a {
              position: relative;
              font-size: 14px; }
              @media (max-width: 768px) {
                body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li a,
                body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul li a {
                  font-size: 14px; } }
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li a:hover,
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul li a:hover {
                font-weight: bold;
                text-decoration: none; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li.djv-border-top-dashed,
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul li.djv-border-top-dashed {
              margin-top: 5px;
              padding-top: 5px; }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover {
          background-color: rgba(65, 80, 85, 0.9);
          color: white;
          position: relative; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > a {
            color: white; }
          @media (max-width: 1024px) {
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover {
              background-color: transparent; } }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover .marcador {
            display: block; }
            @media (max-width: 1024px) {
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover .marcador {
                display: none; } }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > div:not(.marcador),
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > ul {
            display: block;
            color: black;
            font-family: "Open Sans", sans-serif;
            font-weight: 400; }
            @media (max-width: 1024px) {
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > div:not(.marcador),
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > ul {
                color: white;
                top: 0px; } }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-simple ul {
          min-width: 242px;
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
          padding: 10px; }
          @media (max-width: 1024px) {
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-simple ul {
              width: 100%; } }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns {
          padding-bottom: 10px; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div {
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
            padding: 10px 20px;
            min-width: 300px; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column {
              margin: 0 0px;
              padding: 10px 10px 0 10px;
              overflow: auto; }
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column span {
                font-family: "Open Sans", sans-serif;
                font-weight: 700;
                font-size: 14px;
                color: #F28E00;
                display: block; }
                @media (max-width: 768px) {
                  body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column span {
                    font-size: 14px; } }
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column > ul {
                padding: 0;
                display: block; }
                body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column > ul:first-of-type {
                  display: block;
                  padding: 0;
                  margin-bottom: 10px;
                  /*> li:nth-child(10) {
                                        -moz-page-break-after: always;
                                        -webkit-column-break-after: always;
                                        break-after: always;
                                        margin-top: 20px;
                                    }*/ }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 {
              min-width: 400px;
              column-count: 2;
              align-items: stretch; }
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 > div {
                display: block;
                min-height: 50px;
                border-left: 1px solid #dddddd;
                padding-left: 10px; }
                body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 > div:first-of-type {
                  border-left: 0;
                  padding-left: 0px; }
                @media (max-width: 768px) {
                  body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 > div {
                    border-left: none;
                    padding-left: 0px; } }
              @media (max-width: 768px) {
                body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 {
                  column-count: 1;
                  min-width: auto; } }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-3 {
              min-width: 680px;
              column-count: 3;
              align-items: stretch; }
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-3 > div {
                display: block;
                min-height: 50px; }
              @media (max-width: 768px) {
                body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-3 {
                  column-count: 1;
                  min-width: auto; } }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          left: 31px; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li {
            text-align: center;
            margin-bottom: 10px;
            font-size: 13px;
            text-align: center;
            margin-bottom: 10px;
            padding: 10px;
            border-bottom: 1px dashed #999; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li img {
              background-color: rgba(100, 200, 50, 0.3);
              width: 100px;
              height: 100px;
              display: block;
              margin-bottom: 0px; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li a::before {
              display: none; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li a:hover::before {
              display: none; }

body.recogido .sticky-visible {
  display: block; }

body.recogido .sticky-hidden {
  display: none; }

body.recogido .header-nav-top {
  width: 905px;
  position: relative;
  height: 35px;
  z-index: 1;
  margin: auto; }
  @media (max-width: 1024px) {
    body.recogido .header-nav-top {
      display: none;
      position: fixed;
      top: 0px;
      left: 0;
      z-index: 999;
      float: none;
      max-width: 100vw;
      width: 100%;
      height: calc(100vh);
      padding: 10px;
      overflow-y: scroll;
      background-color: rgba(53, 72, 87, 0.9);
      box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3); } }
  body.recogido .header-nav-top #djv-close-nav-responsive {
    display: none; }
  body.recogido .header-nav-top #main-menu {
    padding-top: 0px;
    margin-top: 0px; }
    body.recogido .header-nav-top #main-menu .sf-menu {
      display: flex;
      position: relative;
      padding-left: 0;
      color: white;
      margin-bottom: 0; }
      body.recogido .header-nav-top #main-menu .sf-menu a {
        color: white;
        text-decoration: none;
        font-weight: 100; }
      body.recogido .header-nav-top #main-menu .sf-menu ul {
        padding: 0; }
      body.recogido .header-nav-top #main-menu .sf-menu li {
        list-style: none; }
      body.recogido .header-nav-top #main-menu .sf-menu > li {
        white-space: nowrap;
        margin: 0 3px;
        text-transform: uppercase;
        cursor: pointer;
        font-size: 13px;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        padding: 6px 5px; }
        @media (max-width: 1024px) {
          body.recogido .header-nav-top #main-menu .sf-menu > li {
            font-size: calc($font-sm - 1px);
            font-family: "Open Sans", sans-serif;
            font-weight: 400;
            margin: 0 .5%; } }
        body.recogido .header-nav-top #main-menu .sf-menu > li .marcador {
          width: 0;
          height: 0;
          border-right: 10px solid transparent;
          border-top: 0px solid transparent;
          border-left: 10px solid transparent;
          border-bottom: 10px solid white;
          position: absolute;
          z-index: 1;
          left: 50%;
          display: none;
          transform: translateX(-50%);
          box-shadow: none !important; }
        body.recogido .header-nav-top #main-menu .sf-menu > li > ul {
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5); }
        body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador),
        body.recogido .header-nav-top #main-menu .sf-menu > li > ul {
          display: none;
          position: absolute;
          background-color: white;
          padding: 5px;
          top: 35px;
          left: 0px; }
          body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador).alineado-derecha,
          body.recogido .header-nav-top #main-menu .sf-menu > li > ul.alineado-derecha {
            left: auto;
            right: 0; }
          @media (max-width: 1024px) {
            body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador),
            body.recogido .header-nav-top #main-menu .sf-menu > li > ul {
              margin-top: 5px;
              top: 0px;
              box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
              border-radius: 3px; } }
          body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) span,
          body.recogido .header-nav-top #main-menu .sf-menu > li > ul span {
            color: #F28E00;
            display: block;
            text-transform: none; }
            @media (max-width: 1024px) {
              body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) span,
              body.recogido .header-nav-top #main-menu .sf-menu > li > ul span {
                color: #F28E00;
                font-family: "Open Sans", sans-serif;
                font-weight: 700; } }
          body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li,
          body.recogido .header-nav-top #main-menu .sf-menu > li > ul li {
            text-transform: initial;
            line-height: 30px;
            transition: all 500ms; }
            body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li a,
            body.recogido .header-nav-top #main-menu .sf-menu > li > ul li a {
              position: relative;
              font-size: 13px;
              color: #333333; }
              @media (max-width: 768px) {
                body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li a,
                body.recogido .header-nav-top #main-menu .sf-menu > li > ul li a {
                  font-size: 13px; } }
              body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li a:hover,
              body.recogido .header-nav-top #main-menu .sf-menu > li > ul li a:hover {
                font-weight: bold;
                text-decoration: none; }
            body.recogido .header-nav-top #main-menu .sf-menu > li > div:not(.marcador) li.djv-border-top-dashed,
            body.recogido .header-nav-top #main-menu .sf-menu > li > ul li.djv-border-top-dashed {
              margin-top: 5px;
              padding-top: 5px; }
        body.recogido .header-nav-top #main-menu .sf-menu > li:hover {
          background-color: rgba(65, 80, 85, 0.9);
          color: white;
          position: relative; }
          body.recogido .header-nav-top #main-menu .sf-menu > li:hover > a {
            color: white; }
          @media (max-width: 1024px) {
            body.recogido .header-nav-top #main-menu .sf-menu > li:hover {
              background-color: transparent; } }
          body.recogido .header-nav-top #main-menu .sf-menu > li:hover .marcador {
            display: block; }
            @media (max-width: 1024px) {
              body.recogido .header-nav-top #main-menu .sf-menu > li:hover .marcador {
                display: none; } }
          body.recogido .header-nav-top #main-menu .sf-menu > li:hover > div:not(.marcador),
          body.recogido .header-nav-top #main-menu .sf-menu > li:hover > ul {
            display: block;
            color: #777777;
            font-family: "Open Sans", sans-serif;
            font-weight: 400; }
            @media (max-width: 1024px) {
              body.recogido .header-nav-top #main-menu .sf-menu > li:hover > div:not(.marcador),
              body.recogido .header-nav-top #main-menu .sf-menu > li:hover > ul {
                color: white;
                top: 0px; } }
        body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-simple ul {
          min-width: 242px;
          box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
          padding: 10px; }
          @media (max-width: 1024px) {
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-simple ul {
              width: 100%; } }
        body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns {
          padding-bottom: 10px; }
          body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div {
            box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
            padding: 10px 20px;
            min-width: 300px; }
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column {
              margin: 0 0px;
              padding: 10px 10px 0 10px;
              overflow: auto; }
              body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column span {
                font-family: "Open Sans", sans-serif;
                font-weight: 700;
                font-size: 14px;
                color: #F28E00;
                display: block; }
                @media (max-width: 768px) {
                  body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column span {
                    font-size: 14px; } }
              body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column > ul {
                padding: 0;
                display: block; }
                body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div .djv-nav-drop-columns-column > ul:first-of-type {
                  display: block;
                  padding: 0;
                  margin-bottom: 10px;
                  /*> li:nth-child(10) {
                                        -moz-page-break-after: always;
                                        -webkit-column-break-after: always;
                                        break-after: always;
                                        margin-top: 20px;
                                    }*/ }
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 {
              min-width: 400px;
              column-count: 2;
              align-items: stretch; }
              body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 > div {
                display: block;
                min-height: 50px; }
              @media (max-width: 768px) {
                body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-2 {
                  column-count: 1;
                  min-width: auto; } }
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-3 {
              min-width: 400px;
              column-count: 3;
              align-items: stretch; }
              body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-3 > div {
                display: block;
                min-height: 50px; }
              @media (max-width: 768px) {
                body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns > div.djv-nav-drop-columns-3 {
                  column-count: 1;
                  min-width: auto; } }
        body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul {
          display: grid;
          grid-template-columns: auto auto auto auto auto;
          left: 31px; }
          body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li {
            text-align: center;
            margin-bottom: 10px;
            font-size: 13px;
            text-align: center;
            margin-bottom: 10px;
            padding: 10px;
            border-bottom: 1px dashed #999; }
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li img {
              background-color: rgba(100, 200, 50, 0.3);
              width: 100px;
              height: 100px;
              display: block;
              margin-bottom: 0px; }
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li a::before {
              display: none; }
            body.recogido .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul > li a:hover::before {
              display: none; }

@media (max-width: 1024px) {
  body:not(.recogido) #contact-icon-responsive {
    display: inline-block; }
  body:not(.recogido) .header-nav-top {
    float: none;
    width: 100%;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(65, 80, 85, 0.98);
    height: calc(100vh);
    overflow-y: scroll;
    display: none;
    box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3);
    z-index: 999; }
    body:not(.recogido) .header-nav-top #djv-close-nav-responsive {
      color: white;
      display: block;
      margin-bottom: 20px;
      cursor: pointer;
      position: relative;
      padding-left: 30px; }
      body:not(.recogido) .header-nav-top #djv-close-nav-responsive::before {
        font-size: 20px;
        position: absolute;
        top: 0px;
        left: 0; }
    body:not(.recogido) .header-nav-top #main-menu {
      margin-top: 10px; }
      body:not(.recogido) .header-nav-top #main-menu .sf-menu {
        display: block;
        position: relative; }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu > li {
          color: white;
          border-bottom: 1px solid rgba(255, 255, 255, 0.9);
          padding-bottom: 4px;
          margin-bottom: 8px;
          position: relative;
          display: block; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-columns .menu-toggle-icon::after,
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-simple .menu-toggle-icon::after,
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums .menu-toggle-icon::after {
            display: inline-block;
            font-family: "comunes";
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\004e';
            position: absolute;
            right: 5px;
            top: 8px; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-simple ul {
            box-shadow: none; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums > ul {
            display: none;
            grid-template-columns: auto;
            grid-gap: 10px;
            left: 0; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums > ul > li {
              font-size: 13px;
              text-align: center;
              margin-bottom: 10px; }
              body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums > ul > li img {
                width: 100px;
                height: 100px;
                display: block;
                margin-bottom: 10px; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums:hover > ul {
            display: none;
            grid-template-columns: auto auto;
            grid-gap: 10px;
            left: 0; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums li {
            text-align: center; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li.djv-nav-drop-thums li img {
              margin-left: auto;
              margin-right: auto; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li .marcador {
            display: none; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li a {
            color: white;
            white-space: normal; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul {
            background-color: rgba(65, 80, 85, 0.3);
            position: relative;
            border-radius: 0; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu > li > ul li {
              white-space: normal; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > ul,
          body:not(.recogido) .header-nav-top #main-menu .sf-menu > li:hover > div:not(.marcador) {
            display: none; }
        body:not(.recogido) .header-nav-top #main-menu .sf-menu .djv-nav-drop-columns > div:not(.marcador) {
          background-color: rgba(65, 80, 85, 0.3);
          position: relative;
          border-radius: 0;
          box-shadow: none;
          padding: 0; }
          body:not(.recogido) .header-nav-top #main-menu .sf-menu .djv-nav-drop-columns > div:not(.marcador) > div {
            float: none;
            margin: 0; }
            body:not(.recogido) .header-nav-top #main-menu .sf-menu .djv-nav-drop-columns > div:not(.marcador) > div span {
              color: #83A7CB;
              border-bottom: 1px solid #83A7CB;
              margin-top: 6px; } }

.user-info {
  display: none;
  position: absolute;
  right: 52px;
  top: 40px;
  background-color: #F28E00;
  padding: 10px;
  width: 300px;
  text-align: center;
  border-radius: 0px 0px 4px 4px;
  z-index: 5; }
  @media (max-width: 768px) {
    .user-info {
      padding-top: 9px;
      width: 100vw;
      right: 0px; } }
  .user-info > * {
    font-size: 0.8rem; }
  .user-info a, .user-info a:visited, .user-info a:hover {
    text-decoration: none;
    color: inherit;
    display: inline-block; }
    .user-info a span, .user-info a:visited span, .user-info a:hover span {
      display: none; }
    .user-info a i, .user-info a:visited i, .user-info a:hover i {
      color: #26445C;
      display: inline-block; }
  .user-info .account,
  .user-info .login,
  .user-info .logout {
    display: inline-block;
    font-size: 14px;
    margin: 0 10px;
    background-color: white;
    padding: 4px 15px;
    border-radius: 2px; }
    @media (max-width: 768px) {
      .user-info .account,
      .user-info .login,
      .user-info .logout {
        margin: 0 10px; } }
  .user-info > span {
    display: inline-block;
    font-size: 14px;
    margin: 0 10px;
    padding: 4px 15px;
    border-radius: 2px;
    color: #26445C; }
    @media (max-width: 768px) {
      .user-info > span {
        display: none; } }
  .user-info .logout::before {
    color: #26445C; }

@media (min-width: 768px) {
  .user-info a, .user-info a:visited, .user-info a:hover {
    display: inline-block; }
    .user-info a span, .user-info a:visited span, .user-info a:hover span {
      display: inline-block; }
    .user-info a i, .user-info a:visited i, .user-info a:hover i {
      display: inline-block; } }

.cart-preview.cart-overview {
  width: 100%;
  position: inherit; }

.cart-preview.cart-overview .body {
  display: block;
  position: inherit;
  width: 100%;
  color: #333333; }

.cart-preview {
  float: right;
  position: relative; }
  .cart-preview a,
  .cart-preview a:hover,
  .cart-preview a:visited {
    text-decoration: none;
    color: inherit; }
  .cart-preview ul {
    margin: 0;
    padding: 0;
    margin-top: 20px;
    margin-bottom: 20px; }
    .cart-preview ul li {
      list-style: none;
      margin-bottom: 0px; }
      .cart-preview ul li > * {
        display: inline-block;
        vertical-align: top; }
  .cart-preview .header {
    display: block;
    font-weight: bold;
    cursor: pointer;
    background-color: #333333;
    text-align: center;
    align-content: center;
    height: 40px;
    padding: 0 15px 0 10px; }
    .cart-preview .header > :first-child {
      float: left; }
    .cart-preview .header > :last-child {
      float: right; }
    .cart-preview .header i {
      font-size: 32px;
      display: block;
      line-height: 13px;
      padding-top: 6px; }
    .cart-preview .header span {
      position: absolute;
      top: 2px;
      right: 19px;
      font-size: 13px;
      color: #F28E00;
      text-align: center;
      display: block;
      width: 20px; }
  .cart-preview .body {
    display: none;
    width: calc(100vw - 1.1rem);
    background-color: white;
    right: 0;
    border: 1px solid #83A7CB;
    border-top: 0;
    padding: 10px;
    color: #333333; }
    .cart-preview .body:hover {
      display: block;
      position: absolute; }
    .cart-preview .body > ul li {
      list-style: none;
      padding: 4px 7px;
      display: grid;
      grid-template-columns: 8% 60% 26% 6%; }
      .cart-preview .body > ul li:hover {
        background-color: #fbdbad; }
      .cart-preview .body > ul li .product-quantity {
        color: #83A7CB;
        border-bottom: 0 !important; }
      .cart-preview .body > ul li .product-quantity::after {
        content: 'x'; }
      .cart-preview .body > ul li .product-name {
        text-align: left;
        padding-left: 5px; }
      .cart-preview .body > ul li .remove-from-cart {
        float: right; }
        .cart-preview .body > ul li .remove-from-cart i::before {
          color: #903c44; }
    .cart-preview .body .cart-subtotals {
      border-top: 1px dashed #cccccc;
      padding-top: 20px;
      margin-top: 20px;
      text-align: right;
      padding-right: 10px; }
      .cart-preview .body .cart-subtotals .value {
        font-family: "Open Sans", sans-serif;
        font-weight: 700; }
      .cart-preview .body .cart-subtotals .products {
        text-align: right; }
    @media (min-width: 576px) {
      .cart-preview .body {
        width: 400px; } }
    .cart-preview .body .ps-cart-remove-icon {
      position: relative; }
      .cart-preview .body .ps-cart-remove-icon::before {
        color: #903c44;
        display: block;
        font-style: normal;
        font-weight: normal;
        line-height: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        padding: 12px 3px 4px 2px;
        text-align: center; }
    .cart-preview .body .blockcart-finalizar {
      background-color: #13a323;
      color: white;
      margin: 20px auto 0px;
      display: block;
      width: 100%;
      padding: 6px;
      text-align: center;
      text-transform: uppercase;
      cursor: pointer;
      border: 2px solid #13a323;
      transition: all 500ms; }
      .cart-preview .body .blockcart-finalizar:hover {
        box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
        background-color: white;
        color: #61af26;
        border: 2px solid #61af26; }
  .cart-preview:hover .body {
    display: block;
    position: absolute;
    z-index: 999; }

/* this is to make the containers take up vertical space */
.cart-preview .cart-total > div::after,
.cart-preview .header::after {
  clear: both;
  content: "\00a0"; }

.cart-preview .cart-total .label::after {
  content: ": "; }

.cart-preview .cart-total > div {
  clear: both;
  border-bottom: 1px solid #ccc; }

.cart-preview .cart-total > div:not(:last-child) {
  margin-bottom: 5px; }

.cart-preview .cart-total {
  font-weight: bold;
  border-top: 1px solid #cccccc;
  padding-top: 20px;
  margin-top: 20px;
  text-align: right;
  padding-right: 10px; }
  .cart-preview .cart-total span {
    font-size: 20px; }

#footer {
  margin-top: 50px; }
  @media (max-width: 1024px) {
    #footer {
      margin-top: 30px; } }
  #footer #punto-diferenciador.full-width {
    margin-bottom: 30px; }
    #footer #punto-diferenciador.full-width h2 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 32px;
      text-align: center;
      margin-bottom: 30px; }
    #footer #punto-diferenciador.full-width .djv-flex {
      max-width: 1170px;
      margin-left: auto;
      margin-right: auto;
      text-align: center;
      display: flex;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      font-size: 13px; }
      @media (max-width: 768px) {
        #footer #punto-diferenciador.full-width .djv-flex {
          display: block;
          padding: 0 20px; } }
      #footer #punto-diferenciador.full-width .djv-flex > * {
        flex: 1;
        margin-left: 10px;
        margin-right: 10px; }
      #footer #punto-diferenciador.full-width .djv-flex i {
        display: block;
        width: 25%;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 10px; }
        #footer #punto-diferenciador.full-width .djv-flex i img {
          width: 100%; }
      #footer #punto-diferenciador.full-width .djv-flex h3 {
        margin-bottom: 20px;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 21px;
        color: black; }
        #footer #punto-diferenciador.full-width .djv-flex h3::before {
          display: block;
          font-size: 70px;
          margin-bottom: 16px; }
      #footer #punto-diferenciador.full-width .djv-flex p {
        margin: 5px 0; }
      #footer #punto-diferenciador.full-width .djv-flex a {
        display: block;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 13px;
        color: #F28E00; }
  #footer .djv-email-subscription {
    background-color: #f1f1f1;
    padding: 16px 0 6px; }
    @media (max-width: 768px) {
      #footer .djv-email-subscription {
        padding-left: 20px;
        padding-right: 20px; } }
    #footer .djv-email-subscription .djv-email-subscription-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      display: grid;
      grid-template-columns: 20% 45% 35%;
      grid-gap: 0px;
      align-items: center; }
      @media (max-width: 1024px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          max-width: 500px;
          min-width: 300px; } }
      @media (max-width: 768px) {
        #footer .djv-email-subscription .djv-email-subscription-container {
          display: block; }
          #footer .djv-email-subscription .djv-email-subscription-container > div {
            margin-bottom: 20px; } }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-logo {
        text-align: center;
        position: relative; }
        @media (max-width: 768px) {
          #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-logo {
            float: left;
            width: 20%; } }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-logo img {
          position: absolute;
          width: 125px;
          left: 92px;
          top: -90px; }
          @media (max-width: 768px) {
            #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-logo img {
              position: relative;
              width: 60px;
              margin-left: auto;
              margin-right: auto;
              display: block;
              top: 20px;
              left: 0; } }
      #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text {
        padding-top: 5px;
        padding-left: 10px;
        position: relative; }
        @media (max-width: 768px) {
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text {
            margin-bottom: 0;
            float: left;
            width: 80%; } }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text h4 {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 22px;
          border-bottom: 1px solid #cccccc;
          padding-bottom: 3px;
          display: inline-block;
          padding-right: 25px; }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p {
          font-family: "Open Sans", sans-serif;
          font-weight: 400;
          font-size: 15px;
          color: #444444; }
        #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification {
          position: absolute;
          right: -350px;
          width: 350px;
          top: -55px;
          background-color: white;
          border: 3px solid; }
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-success {
            color: #5abe20; }
            @media (max-width: 768px) {
              #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-success {
                margin-bottom: 0; } }
          #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error {
            color: #D53128; }
            #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error::before {
              content: "* ";
              font-size: 16px; }
            @media (max-width: 768px) {
              #footer .djv-email-subscription .djv-email-subscription-container .email-subcription-text p.notification.notification-error {
                right: 0;
                margin-bottom: 0; } }
      #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form {
        padding-top: 0px; }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="text"] {
          border: 1px solid #dddddd;
          padding: 11px 16px;
          font-size: 13px;
          width: 70%;
          margin-right: 10px; }
          @media (max-width: 768px) {
            #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="text"] {
              width: 60%;
              display: inline-block; } }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="checkbox"] {
          border: 1px solid #dddddd; }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="submit"] {
          background-color: #F28E00;
          color: white;
          border: 2px solid #F28E00;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer;
          width: 100px;
          padding: 9px 17px;
          font-family: "Open Sans", sans-serif;
          font-weight: 700;
          font-size: 16px;
          color: white; }
          #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="submit"]:hover {
            background-color: white;
            color: #F28E00; }
          @media (max-width: 768px) {
            #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form input[type="submit"] {
              padding: 7px 17px;
              display: inline-block; } }
        #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .control-label {
          font-size: 13px;
          margin-top: 10px;
          color: #444444; }
          #footer .djv-email-subscription .djv-email-subscription-container .djv-email-subscription-form .control-label a {
            font-family: "Open Sans", sans-serif;
            font-weight: 700;
            color: #F28E00; }
      #footer .djv-email-subscription .djv-email-subscription-container .notification-error {
        position: relative;
        right: -453px;
        width: 350px;
        color: #903c44; }
  #footer .block-contact {
    padding: 16px;
    background-color: #777777;
    font-size: 14px;
    color: white; }
    #footer .block-contact .djv-block-contact-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px; }
      @media (max-width: 1024px) {
        #footer .block-contact .djv-block-contact-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .block-contact .djv-block-contact-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .block-contact .djv-block-contact-container {
          max-width: 500px;
          min-width: 300px; } }
      #footer .block-contact .djv-block-contact-container img {
        display: none; }
      #footer .block-contact .djv-block-contact-container div {
        margin: 5px;
        display: inline; }
        #footer .block-contact .djv-block-contact-container div i {
          margin-right: 5px; }
      #footer .block-contact .djv-block-contact-container .djv-block-contact-social {
        float: right;
        margin-top: -8px;
        position: relative;
        display: flex;
        align-items: center; }
        @media (max-width: 1024px) {
          #footer .block-contact .djv-block-contact-container .djv-block-contact-social {
            float: none;
            margin-top: 0;
            margin: 0; } }
        #footer .block-contact .djv-block-contact-container .djv-block-contact-social a {
          margin: 10px 0px 4px 6px; }
        #footer .block-contact .djv-block-contact-container .djv-block-contact-social [class*='djv-icon-']:before {
          font-size: 20px;
          color: white; }
      #footer .block-contact .djv-block-contact-container a {
        color: white; }
  #footer .djv-footer-navigation {
    background-color: #999999;
    color: white;
    padding: 30px 0;
    margin-top: 0;
    margin-bottom: 0; }
    #footer .djv-footer-navigation .djv-footer-navigation-container {
      width: 100%;
      margin-left: auto;
      margin-right: auto;
      max-width: 1200px;
      padding: 16px;
      display: grid;
      grid-template-columns: repeat(4, calc(25% - 10px));
      grid-gap: 10px;
      text-align: left; }
      @media (max-width: 768px) {
        #footer .djv-footer-navigation .djv-footer-navigation-container {
          display: block; } }
      #footer .djv-footer-navigation .djv-footer-navigation-container > div {
        padding-right: 15px;
        padding-left: 5px; }
        @media (max-width: 768px) {
          #footer .djv-footer-navigation .djv-footer-navigation-container > div {
            margin-bottom: 30px;
            border: none; } }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div:last-child {
          border-right: none; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div.djv-footer-tienda a {
          color: white;
          padding-left: 25px; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div.djv-footer-tienda blockquote {
          margin-left: 20px;
          font-size: 13px; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div.djv-footer-tienda .djv-icon-telefono::before {
          margin-right: 10px; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div h4 {
          font-size: 16px;
          padding-bottom: 6px;
          display: block;
          font-family: "Open Sans", sans-serif;
          font-weight: 500; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div p {
          font-family: "Open Sans", sans-serif;
          font-weight: 300;
          font-size: 13px; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div .footer-tittle {
          display: block;
          font-family: "Open Sans", sans-serif;
          font-weight: 700; }
          #footer .djv-footer-navigation .djv-footer-navigation-container > div .footer-tittle.djv-icon-crono a {
            padding-left: 0; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div ul {
          margin: 0;
          padding: 0; }
          @media (max-width: 768px) {
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul {
              margin-left: 0; } }
          #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li {
            font-family: "Open Sans", sans-serif;
            font-weight: 300;
            font-size: 14px;
            background: transparent;
            background-size: 0%;
            transition: background 1s;
            padding: 3px;
            color: #999999; }
            @media (max-width: 1024px) {
              #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li {
                list-style: none; } }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li:hover {
              background: #F5A533;
              cursor: pointer; }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul li a {
              color: white; }
        #footer .djv-footer-navigation .djv-footer-navigation-container > div ul.djv-footer-social-share {
          display: grid;
          grid-template-columns: auto auto auto;
          margin-top: 30px;
          width: 80%; }
          #footer .djv-footer-navigation .djv-footer-navigation-container > div ul.djv-footer-social-share li {
            display: inline-block; }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul.djv-footer-social-share li:before {
              content: '';
              display: none; }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul.djv-footer-social-share li img {
              width: 70%; }
            #footer .djv-footer-navigation .djv-footer-navigation-container > div ul.djv-footer-social-share li:hover {
              background: inherit; }
      #footer .djv-footer-navigation .djv-footer-navigation-container .djv-footer-navigation-metodos-pago > div img {
        width: auto;
        display: block; }
      #footer .djv-footer-navigation .djv-footer-navigation-container .email_subscription {
        font-size: 14px;
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        padding-top: 2px; }
        #footer .djv-footer-navigation .djv-footer-navigation-container .email_subscription input[type="text"] {
          height: 45px;
          line-height: 30px;
          background: #595959;
          padding: 5px 0 5px 12px;
          display: block;
          border: none;
          border-radius: 4px;
          width: 100%;
          margin-bottom: 16px;
          color: white; }
          #footer .djv-footer-navigation .djv-footer-navigation-container .email_subscription input[type="text"]::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: white;
            opacity: .8;
            /* Firefox */ }
        #footer .djv-footer-navigation .djv-footer-navigation-container .email_subscription input[type="submit"] {
          display: block;
          margin: 15px 0;
          padding: 6px 12px;
          border: none;
          -moz-border-radius: 2px;
          -webkit-border-radius: 2px;
          border-radius: 2px;
          background-color: #f1f1f1;
          font-size: 16px;
          font-weight: 400;
          color: white;
          cursor: pointer; }
        #footer .djv-footer-navigation .djv-footer-navigation-container .email_subscription .notification-error {
          color: #f1f1f1;
          font-size: 20px; }
        #footer .djv-footer-navigation .djv-footer-navigation-container .email_subscription .notification-success {
          color: #26445C;
          font-size: 20px; }
  #footer .djv-footer-below, #footer .checkout-footer-simplified {
    padding: 10px 16px;
    background-color: #101c26;
    font-size: 13px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    color: white; }
    #footer .djv-footer-below .djv-footer-below-container, #footer .checkout-footer-simplified .djv-footer-below-container {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px;
      display: block; }
      @media (max-width: 1024px) {
        #footer .djv-footer-below .djv-footer-below-container, #footer .checkout-footer-simplified .djv-footer-below-container {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #footer .djv-footer-below .djv-footer-below-container, #footer .checkout-footer-simplified .djv-footer-below-container {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #footer .djv-footer-below .djv-footer-below-container, #footer .checkout-footer-simplified .djv-footer-below-container {
          max-width: 500px;
          min-width: 300px; } }
      #footer .djv-footer-below .djv-footer-below-container .djv-footer-below-copyright p, #footer .checkout-footer-simplified .djv-footer-below-container .djv-footer-below-copyright p {
        margin-bottom: 0; }
      #footer .djv-footer-below .djv-footer-below-container .djv-footer-below-cms, #footer .checkout-footer-simplified .djv-footer-below-container .djv-footer-below-cms {
        text-align: right; }
    #footer .djv-footer-below a, #footer .checkout-footer-simplified a {
      color: #212f44; }
  #footer .faldon-sin-iva {
    background-color: #333333;
    color: #dddddd;
    font-size: 12px;
    padding: 10px; }
  #footer .desarrollo-djv {
    background-color: black; }
    #footer .desarrollo-djv .djv-container {
      color: white;
      font-size: 12px;
      display: flex;
      padding-top: 10px; }
      @media (max-width: 768px) {
        #footer .desarrollo-djv .djv-container {
          display: block;
          padding: 15px; }
          #footer .desarrollo-djv .djv-container p {
            margin-bottom: 5px; } }
      #footer .desarrollo-djv .djv-container p:first-of-type {
        margin-right: 20px; }

.carousel {
  margin-bottom: 5px; }
  @media (max-width: 768px) {
    .carousel {
      margin-bottom: 0; } }
  .carousel .carousel-item {
    overflow: hidden;
    max-width: 100%; }
    .carousel .carousel-item img {
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      top: 0; }
  .carousel [class*='djv-icon-arrow-']::before {
    background: rgba(0, 0, 0, 0.8);
    font-size: 18px;
    font-weight: normal;
    padding: 14px;
    border-radius: 50%; }
    @media (max-width: 768px) {
      .carousel [class*='djv-icon-arrow-']::before {
        font-size: 16px;
        padding: 10px; } }

.carousel-indicator-container {
  position: relative;
  bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  height: 25px;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }
  .carousel-indicator-container .carousel-indicators {
    position: relative;
    right: 0;
    bottom: 0px;
    left: 0;
    z-index: 15;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding-left: 0;
    margin-right: 15%;
    margin-left: 15%;
    list-style: none;
    width: 70%;
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px; }
    @media (max-width: 1024px) {
      .carousel-indicator-container .carousel-indicators {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      .carousel-indicator-container .carousel-indicators {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      .carousel-indicator-container .carousel-indicators {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      .carousel-indicator-container .carousel-indicators {
        min-width: 100px; } }
    .carousel-indicator-container .carousel-indicators > li {
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
      width: auto;
      height: 24px;
      text-indent: 0;
      background-color: white;
      padding: 0px 15px;
      text-align: center;
      cursor: pointer;
      border-radius: 0;
      margin: 0;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      white-space: nowrap;
      text-overflow: ellipsis; }
      .carousel-indicator-container .carousel-indicators > li::before {
        display: none; }
      .carousel-indicator-container .carousel-indicators > li::after {
        display: none; }
      .carousel-indicator-container .carousel-indicators > li.active {
        width: auto;
        height: auto;
        margin: 0;
        background-color: #f1f1f1;
        border: none; }

@media only screen and (max-width: 768px) {
  .carousel-item img {
    max-height: 175px; }
  .carousel-indicator-container {
    bottom: 20px;
    border: none; }
    .carousel-indicator-container .carousel-indicators > li {
      font-size: 0px;
      border: 2px solid white;
      border-radius: 50%;
      margin: 3px;
      height: 10px;
      width: 10px;
      padding: 0px;
      overflow: hidden;
      background-color: transparent; }
      .carousel-indicator-container .carousel-indicators > li.active {
        height: 10px;
        width: 10px;
        margin: 3px;
        overflow: hidden;
        background-color: white; }
  .carousel-control-prev, .carousel-control-next {
    max-height: 250px; } }

@media (min-width: 768px) {
  .djv_carouselh {
    position: relative; }
    .djv_carouselh > * {
      overflow: hidden;
      position: relative; }
    .djv_carouselh .djv_carouselh-left-arrow,
    .djv_carouselh .djv_carouselh-right-arrow {
      position: absolute;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      font-size: 36px;
      width: 44px;
      height: 100%;
      cursor: pointer;
      background-color: transparent; }
      .djv_carouselh .djv_carouselh-left-arrow::before,
      .djv_carouselh .djv_carouselh-right-arrow::before {
        position: absolute;
        top: 50%;
        transform: translateY(-70%);
        background-color: #eeeefa;
        padding: 18px 3px; }
    .djv_carouselh .djv_carouselh-left-arrow {
      left: 0;
      border-left: 1px solid #ccc; }
      .djv_carouselh .djv_carouselh-left-arrow::before {
        left: 0px;
        font-size: 14px;
        content: '\004b';
        display: inline-block;
        font-family: "comunes";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-right: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc; }
    .djv_carouselh .djv_carouselh-right-arrow {
      right: 0;
      border-right: 1px solid #ccc; }
      .djv_carouselh .djv_carouselh-right-arrow::before {
        right: 0px;
        font-size: 14px;
        content: '\004c';
        display: inline-block;
        font-family: "comunes";
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        border-left: 1px solid #ccc;
        border-top: 1px solid #ccc;
        border-bottom: 1px solid #ccc; }
    .djv_carouselh .djv_carouselh-viewer {
      position: relative; }
      .djv_carouselh .djv_carouselh-viewer > * {
        text-align: left;
        position: absolute;
        transition: left 1s; }
        .djv_carouselh .djv_carouselh-viewer > * > * {
          margin: 0;
          padding: 5px;
          float: left !important;
          clear: none; } }

@media (max-width: 768px) {
  .djv_carouselh.swipe::after {
    display: inline-block;
    font-family: 'comunes';
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: '\006e';
    position: relative;
    left: 50%;
    top: 5px;
    transform: translateX(-50%);
    margin: 10px 0;
    font-size: 42px;
    color: #999999; }
  .djv_carouselh > * {
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%); }
    .djv_carouselh > * > * {
      scroll-snap-align: start;
      width: 100%;
      position: relative;
      display: inline-block;
      margin-right: -4px;
      text-align: center; } }

#home-carousel {
  margin-top: 180px; }

#index h2 {
  text-align: center; }

#index iframe {
  max-width: 100vw; }

#index #djv-two-columns-layer section {
  margin-top: 50px;
  margin-bottom: 50px; }
  @media (max-width: 768px) {
    #index #djv-two-columns-layer section {
      margin-top: 20px;
      margin-bottom: 20px; } }
  #index #djv-two-columns-layer section header {
    text-align: center;
    margin-bottom: 40px; }
    @media (max-width: 768px) {
      #index #djv-two-columns-layer section header {
        margin-bottom: 20px; }
        #index #djv-two-columns-layer section header h1 {
          font-size: 15px;
          color: #777777; } }
    #index #djv-two-columns-layer section header h2 {
      margin-bottom: 0;
      padding-bottom: 0; }
    #index #djv-two-columns-layer section header p {
      margin-bottom: 0;
      padding-bottom: 0; }

#index .banner-sequra img {
  max-width: 100%; }

#index .djv-home-static-blocks p {
  text-align: center;
  margin-bottom: 0px; }

#index .djv-home-static-blocks .home-grid-1 {
  position: relative;
  display: grid;
  grid-template-areas: "primera segunda segunda tercera" "primera cuarta quinta quinta" "sexta septima septima octava";
  grid-gap: 17px 20px;
  margin-top: 10px; }
  @media (max-width: 768px) {
    #index .djv-home-static-blocks .home-grid-1 {
      display: block; }
      #index .djv-home-static-blocks .home-grid-1 > div {
        max-height: 215px;
        height: 215px;
        overflow: hidden;
        max-width: 100%;
        margin: 10px auto; }
        #index .djv-home-static-blocks .home-grid-1 > div img {
          width: 100%; } }
  #index .djv-home-static-blocks .home-grid-1 > div {
    position: relative;
    overflow: hidden; }
    #index .djv-home-static-blocks .home-grid-1 > div a {
      display: block;
      position: relative; }
    #index .djv-home-static-blocks .home-grid-1 > div:hover p.caption {
      height: 100%; }
    #index .djv-home-static-blocks .home-grid-1 > div .caption {
      position: absolute;
      bottom: 0;
      background-color: rgba(35, 35, 35, 0.6);
      color: white;
      width: 100%;
      height: 50px;
      text-align: center;
      padding: 10px;
      margin-bottom: 0;
      font-size: 20px;
      transition: all 500ms; }
      @media (max-width: 768px) {
        #index .djv-home-static-blocks .home-grid-1 > div .caption {
          top: 165px; } }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(1) {
    grid-area: primera; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(1):hover p.caption {
      padding: 70% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(2) {
    grid-area: segunda; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(2):hover p.caption {
      padding: 32% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(3) {
    grid-area: tercera; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(3):hover p.caption {
      padding: 37% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(4) {
    grid-area: cuarta; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(4):hover p.caption {
      padding: 37% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(5) {
    grid-area: quinta; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(5):hover p.caption {
      padding: 32% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(6) {
    grid-area: sexta; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(6):hover p.caption {
      padding: 38% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(7) {
    grid-area: septima; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(7):hover p.caption {
      padding: 32% 10px; }
  #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(8) {
    grid-area: octava; }
    #index .djv-home-static-blocks .home-grid-1 > div:nth-of-type(8):hover p.caption {
      padding: 37% 10px; }

#index .djv-home-static-blocks .home-grid-2 {
  position: relative;
  display: grid;
  grid-template-areas: "primera primera segunda quinta" "tercera cuarta cuarta quinta";
  grid-gap: 17px 20px;
  margin-top: 30px; }
  @media (max-width: 768px) {
    #index .djv-home-static-blocks .home-grid-2 {
      display: block; }
      #index .djv-home-static-blocks .home-grid-2 > div {
        max-height: 250px;
        overflow: hidden;
        max-width: 100%;
        margin: 10px auto; }
        #index .djv-home-static-blocks .home-grid-2 > div img {
          width: 100%; } }
  #index .djv-home-static-blocks .home-grid-2 > div {
    position: relative;
    overflow: hidden; }
    #index .djv-home-static-blocks .home-grid-2 > div a {
      display: block;
      position: relative; }
    #index .djv-home-static-blocks .home-grid-2 > div:hover p.caption {
      height: 100%; }
    #index .djv-home-static-blocks .home-grid-2 > div .caption {
      position: absolute;
      bottom: 0;
      background-color: rgba(35, 35, 35, 0.6);
      color: white;
      width: 100%;
      height: 50px;
      text-align: center;
      padding: 10px;
      margin-bottom: 0;
      font-size: 20px;
      transition: all 500ms; }
      @media (max-width: 768px) {
        #index .djv-home-static-blocks .home-grid-2 > div .caption {
          top: 165px; } }
  #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(1) {
    grid-area: primera; }
    #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(1):hover p.caption {
      padding: 33% 10px; }
  #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(2) {
    grid-area: segunda; }
    #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(2):hover p.caption {
      padding: 38% 10px; }
  #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(3) {
    grid-area: tercera; }
    #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(3):hover p.caption {
      padding: 38% 10px; }
  #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(4) {
    grid-area: cuarta; }
    #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(4):hover p.caption {
      padding: 31% 10px; }
  #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(5) {
    grid-area: quinta; }
    #index .djv-home-static-blocks .home-grid-2 > div:nth-of-type(5):hover p.caption {
      padding: 68% 10px 0; }

#index .full-width {
  background-color: #ECECEC; }
  #index .full-width > img {
    display: block;
    margin: 0 auto;
    max-width: 100vw; }

#index .valoraciones-portada {
  background-color: #f1f1f1;
  padding-top: 30px;
  padding-bottom: 30px;
  margin-top: 50px; }
  #index .valoraciones-portada .valoraciones-portada-container {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: grid;
    grid-template-columns: 50% 50%;
    padding-top: 40px; }
    @media (max-width: 1024px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      #index .valoraciones-portada .valoraciones-portada-container {
        display: block;
        padding-top: 15px; } }
    #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer {
      background-image: url("/themes/base/assets/img/ekomi_home.png");
      background-repeat: no-repeat;
      background-position-y: 85px;
      background-position-x: 110px;
      height: 320px;
      padding: 0 15px;
      text-align: center; }
      @media (max-width: 768px) {
        #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer {
          background-image: none;
          margin-bottom: 20px; } }
      #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer .ekomi_home_title {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 24px; }
      #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer #eKomiWidget_default > a {
        margin-left: 120px; }
        @media (max-width: 768px) {
          #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer #eKomiWidget_default > a {
            margin-left: 0; } }
      #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer .ver-todas {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 13px;
        border: 1px solid #333333;
        color: #333333;
        display: block;
        margin: 25px auto auto;
        text-align: center;
        width: 300px;
        padding: 5px; }
        @media (max-width: 768px) {
          #index .valoraciones-portada .valoraciones-portada-container .eKomiContainer .ver-todas {
            width: 100%;
            padding: 5px 40px; } }
    #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments {
      padding: 0 15px;
      text-align: center; }
      #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_title {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-align: center;
        font-size: 24px; }
      #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container {
        width: 100%;
        display: block;
        overflow: hidden;
        position: relative; }
        @media (max-width: 768px) {
          #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container {
            overflow: visible; } }
        #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow,
        #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow {
          background-color: transparent;
          border: none; }
          #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
          #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
            top: 48%;
            font-size: 16px;
            background-color: #ffffffb3; }
            @media (max-width: 768px) {
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow::before,
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
                color: black; } }
        #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-left-arrow::before {
          left: 5px; }
        #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .djv_carouselh-viewer .djv_carouselh-right-arrow::before {
          right: 5px; }
        #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list {
          padding: 0;
          position: absolute;
          transition: left 1s; }
          @media (max-width: 768px) {
            #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list {
              position: relative; } }
          #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment {
            display: block;
            float: left;
            background-color: white;
            text-align: left;
            min-width: 555px;
            width: 555px;
            padding: 10px; }
            @media (max-width: 768px) {
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment {
                float: none;
                display: inline-block;
                vertical-align: top;
                min-width: auto;
                width: calc(100vw - 30px); } }
            #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .coment-header {
              padding-top: 15px;
              overflow: auto;
              margin-bottom: 15px;
              white-space: normal; }
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .coment-header .comment_image {
                float: left;
                margin-right: 25px;
                margin-left: 25px; }
                @media (max-width: 768px) {
                  #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .coment-header .comment_image {
                    float: none;
                    display: inline;
                    margin-left: 0;
                    margin-right: 5px;
                    margin-bottom: 40px; } }
                #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .coment-header .comment_image img {
                  max-width: 90px; }
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .coment-header .comment_title {
                font-family: "Open Sans", sans-serif;
                font-weight: 500;
                font-size: 13px;
                color: #111111;
                padding-top: 10px;
                white-space: normal; }
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .coment-header .comment_author {
                font-family: "Open Sans", sans-serif;
                font-weight: 300;
                font-size: 12px;
                color: #666666;
                white-space: normal; }
            #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .comment_body {
              background-color: #666666;
              padding: 25px 20px 20px;
              clear: both;
              min-height: 130px;
              position: relative; }
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .comment_body::before {
                content: "";
                width: 0;
                height: 0;
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #666666;
                position: absolute;
                top: -10px;
                left: 50%;
                transform: translateX(-50%); }
              #index .valoraciones-portada .valoraciones-portada-container .ekomi_last_comments .ekomi_home_comments_container .comments-home-list .comment .comment_body .comment-text {
                color: white;
                font-size: 13px;
                font-style: italic;
                padding: 5px 15px;
                white-space: normal; }

#index #grandes-pedidos {
  background-color: #f1f1f1;
  background-size: cover;
  background-position: center;
  min-height: 465px;
  padding: 60px 0 50px; }
  @media (max-width: 768px) {
    #index #grandes-pedidos {
      background-position: left;
      padding: 30px 10px;
      min-height: 335px; } }
  #index #grandes-pedidos .content-text {
    width: 480px;
    text-align: center; }
    @media (max-width: 768px) {
      #index #grandes-pedidos .content-text {
        width: 100%; } }
    #index #grandes-pedidos .content-text h2 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 40px; }
      @media (max-width: 768px) {
        #index #grandes-pedidos .content-text h2 {
          font-size: 36px; } }
    #index #grandes-pedidos .content-text p {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 23px; }
      @media (max-width: 768px) {
        #index #grandes-pedidos .content-text p {
          font-size: 18px; } }
    #index #grandes-pedidos .content-text ul {
      list-style: none;
      text-align: left;
      display: block;
      padding-left: 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 280px; }
      #index #grandes-pedidos .content-text ul li {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 19px;
        margin-bottom: 12px; }
        #index #grandes-pedidos .content-text ul li img {
          width: 30px;
          display: inline-block; }
    #index #grandes-pedidos .content-text .btn-hover-animated-orange {
      background-color: #F28E00;
      color: white;
      border: 2px solid #F28E00;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 10px;
      cursor: pointer;
      display: block;
      width: 80%;
      margin: 30px auto 0;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 19px; }
      #index #grandes-pedidos .content-text .btn-hover-animated-orange:hover {
        background-color: white;
        color: #F28E00; }

.djv-breadcrumb {
  font-size: 13px;
  padding: 15px 0;
  border-bottom: 1px solid #cccccc; }
  @media (max-width: 768px) {
    .djv-breadcrumb {
      padding-left: 0px; } }
  .djv-breadcrumb ol {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    padding-left: 0;
    margin-bottom: 0;
    text-align: center; }
    @media (max-width: 1024px) {
      .djv-breadcrumb ol {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      .djv-breadcrumb ol {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      .djv-breadcrumb ol {
        max-width: 500px;
        min-width: 300px; } }
    .djv-breadcrumb ol li {
      display: inline-block;
      color: #F28E00; }
      .djv-breadcrumb ol li::after {
        content: '\004c';
        display: inline-block;
        font-family: 'comunes';
        font-style: normal;
        font-weight: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        margin: 0 4px;
        font-size: 8px; }
      .djv-breadcrumb ol li:last-of-type a {
        color: #666666; }
      .djv-breadcrumb ol li:last-of-type::after {
        content: "";
        display: none; }

#product .djv-breadcrumb {
  background-color: #ebebeb; }

#product .page-content {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin: 0px;
  position: relative;
  padding: 0px; }
  @media (max-width: 768px) {
    #product .page-content {
      width: 100%;
      padding: 0px; } }
  #product .page-content .product-first-section {
    background-color: #ebebeb;
    display: grid;
    grid-template-areas: "images infoheader" "images descriptionshort" "images variants" "images actions";
    grid-gap: 10px 0px;
    grid-template-columns: 50% 50%;
    padding-left: calc(50% - 590px);
    padding-right: calc(50% - 590px); }
    #product .page-content .product-first-section.not-for-sale {
      grid-template-areas: "images infoheader" "images actions" "images descriptionshort" "images empty";
      grid-template-rows: auto auto auto 200px; }
      #product .page-content .product-first-section.not-for-sale .product-accessories > h3 {
        border-bottom: 1px solid #999999; }
      #product .page-content .product-first-section.not-for-sale .product-accessories .product-accessories-container .products {
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 10px));
        grid-gap: 10px; }
    #product .page-content .product-first-section #sigue_leyendo {
      font-size: 13px;
      color: #F28E00;
      cursor: pointer; }
    @media (max-width: 768px) {
      #product .page-content .product-first-section {
        grid-template-areas: "images " "infoheader" "descriptionshort" "actions";
        grid-template-columns: 100%;
        padding-left: 0;
        padding-right: 0; } }
    #product .page-content .product-first-section .djv-product-images {
      grid-area: images; }
    #product .page-content .product-first-section .djv-product-information-header {
      grid-area: infoheader; }
    #product .page-content .product-first-section .djv-product-detail-variants {
      grid-area: variants; }
    #product .page-content .product-first-section .product-buy-actions {
      grid-area: actions; }
    #product .page-content .product-first-section .djv-description-short {
      grid-area: descriptionshort; }
    #product .page-content .product-first-section .additional-info {
      display: none; }
      #product .page-content .product-first-section .additional-info .social-sharing {
        grid-area: social; }
    #product .page-content .product-first-section .djv-product-images {
      padding: 10px; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section .djv-product-images {
          padding: 0; } }
      #product .page-content .product-first-section .djv-product-images .product-cover img {
        min-width: 360px; }
        @media (max-width: 1024px) {
          #product .page-content .product-first-section .djv-product-images .product-cover img {
            width: 250px;
            margin-left: auto;
            margin-right: auto; } }
    #product .page-content .product-first-section .djv-product-information-header {
      padding: 10px;
      margin-bottom: 0px; }
      #product .page-content .product-first-section .djv-product-information-header div {
        display: block; }
        #product .page-content .product-first-section .djv-product-information-header div.djv-product-information-name h1 {
          font-size: 33px;
          font-family: "Open Sans", sans-serif;
          font-weight: 500; }
          @media (max-width: 768px) {
            #product .page-content .product-first-section .djv-product-information-header div.djv-product-information-name h1 {
              font-size: 20px; } }
        #product .page-content .product-first-section .djv-product-information-header div p {
          font-size: 14px;
          margin-bottom: 0px; }
        #product .page-content .product-first-section .djv-product-information-header div a {
          font-size: 13px;
          display: block; }
      #product .page-content .product-first-section .djv-product-information-header #product-reference {
        margin-bottom: 0px; }
      #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas {
        margin-top: 26px;
        display: block; }
        #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p {
          background-color: white;
          padding: 8px 16px;
          margin-bottom: 6px;
          border-radius: 50px;
          font-weight: bold;
          font-size: 16px; }
          #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p:last-of-type {
            margin-bottom: 0; }
          #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p .verde {
            color: #18A14C; }
          #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p .gris {
            color: #aaaaaa; }
          #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p img {
            float: right;
            margin-top: -30px;
            width: 180px; }
            @media (max-width: 768px) {
              #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p img {
                display: none; } }
          #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p a {
            display: inline;
            padding-left: 16px; }
          #product .page-content .product-first-section .djv-product-information-header .aviso-disponibilidad-mascarillas p small {
            font-weight: bold;
            display: inline-block; }
    @media (max-width: 1024px) {
      #product .page-content .product-first-section .djv-product-detail-variants {
        margin-top: 15px; } }
    #product .page-content .product-first-section .djv-product-detail-variants ul.variants-group {
      list-style: none;
      padding-left: 10px; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section .djv-product-detail-variants ul.variants-group {
          padding-left: 0; } }
      #product .page-content .product-first-section .djv-product-detail-variants ul.variants-group li {
        font-weight: bold;
        font-size: 14px;
        position: relative;
        padding-left: 10px; }
        #product .page-content .product-first-section .djv-product-detail-variants ul.variants-group li.djv-icon-arrow-right::before {
          font-size: 12px;
          top: 6px;
          position: absolute;
          left: 0;
          font-weight: normal; }
        #product .page-content .product-first-section .djv-product-detail-variants ul.variants-group li span.addedPrice {
          margin-left: 5px;
          font-size: 13px;
          font-weight: bold;
          text-align: right; }
        @media (max-width: 768px) {
          #product .page-content .product-first-section .djv-product-detail-variants ul.variants-group li .group-name {
            border-bottom: 1px solid #111111;
            display: block;
            margin-top: 20px; } }
    #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single {
      margin-top: 6px;
      list-style: none;
      padding-left: 5px; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single {
          padding-left: 0;
          margin-left: -10px; }
          #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single li {
            padding: 10px 5px; }
            #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single li:nth-of-type(2n) {
              background-color: #f1f1f1; } }
      #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single li label {
        margin-bottom: 10px;
        font-size: 13px;
        font-weight: normal;
        display: block;
        padding-right: 20px; }
        @media (max-width: 768px) {
          #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single li label {
            margin-bottom: 0;
            padding-right: 0; } }
        #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single li label input[type="radio"] {
          margin-right: 10px; }
          @media (max-width: 768px) {
            #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single li label input[type="radio"] {
              height: 20px;
              float: left;
              width: 20px; } }
      #product .page-content .product-first-section .djv-product-detail-variants ul.variants-single span.addedPrice {
        margin-left: 5px;
        font-size: 13px;
        font-weight: bold;
        text-align: right;
        float: right; }
    #product .page-content .product-first-section .product-buy-actions {
      padding: 10px;
      margin-left: 0;
      margin-right: 0; }
      #product .page-content .product-first-section .product-buy-actions .etiqueta-oferta {
        width: 90px;
        padding: 5px 10px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 0px;
        background-color: #D53128;
        font-size: 14px;
        color: white;
        text-align: center; }
    #product .page-content .product-first-section .djv-description-short {
      padding-left: 10px; }
      @media (max-width: 768px) {
        #product .page-content .product-first-section .djv-description-short img {
          max-width: 92vw; } }
      #product .page-content .product-first-section .djv-description-short p {
        margin-bottom: 10px;
        font-size: 14px; }
      #product .page-content .product-first-section .djv-description-short ul li {
        font-size: 14px; }
        #product .page-content .product-first-section .djv-description-short ul li a {
          font-size: 14px;
          font-weight: 100; }
    #product .page-content .product-first-section .social-sharing {
      display: none; }
  #product .page-content .product-second-section {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: grid;
    grid-gap: 10px;
    grid-template-areas: "extended accesories" "opiniones accesories";
    grid-template-columns: 70% 30%; }
    @media (max-width: 1024px) {
      #product .page-content .product-second-section {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #product .page-content .product-second-section {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #product .page-content .product-second-section {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      #product .page-content .product-second-section {
        display: block;
        width: auto;
        padding: 5px 15px; } }
    #product .page-content .product-second-section .product-information-extended {
      grid-area: extended;
      position: relative; }
      #product .page-content .product-second-section .product-information-extended #leermas {
        display: block; }
      #product .page-content .product-second-section .product-information-extended .product-information-extended-heading {
        border-bottom: 1px "solid" #cccccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        font-size: 20px;
        padding: 16px;
        color: #333333; }
      #product .page-content .product-second-section .product-information-extended .product-information-extended-description {
        font-size: 16px; }
        @media (max-width: 768px) {
          #product .page-content .product-second-section .product-information-extended .product-information-extended-description {
            padding: 0; } }
        #product .page-content .product-second-section .product-information-extended .product-information-extended-description h2 {
          font-size: 22px;
          font-weight: bold;
          text-align: left;
          margin-top: 35px; }
        #product .page-content .product-second-section .product-information-extended .product-information-extended-description img {
          max-width: 100%;
          height: auto; }
        #product .page-content .product-second-section .product-information-extended .product-information-extended-description .parrafo-dos-col {
          column-count: 2;
          margin-top: 40px; }
          @media (max-width: 768px) {
            #product .page-content .product-second-section .product-information-extended .product-information-extended-description .parrafo-dos-col {
              column-count: 1; } }
        @media (max-width: 768px) {
          #product .page-content .product-second-section .product-information-extended .product-information-extended-description iframe {
            max-width: calc(100vw - 32px);
            height: auto; } }
      #product .page-content .product-second-section .product-information-extended .product-attachments .product-attachments-heading {
        border-bottom: 1px "solid" #cccccc;
        padding-bottom: 10px;
        margin-bottom: 10px;
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        text-transform: uppercase;
        font-size: 18px;
        padding: 16px;
        color: #333333; }
      #product .page-content .product-second-section .product-information-extended .product-attachments .attachment {
        width: 25%;
        display: inline-block;
        text-align: center; }
        #product .page-content .product-second-section .product-information-extended .product-attachments .attachment h4 a {
          font-size: 16px;
          color: #212f44; }
        #product .page-content .product-second-section .product-information-extended .product-attachments .attachment p {
          font-size: 16px;
          color: #333333; }
        #product .page-content .product-second-section .product-information-extended .product-attachments .attachment > a {
          background-color: #0083ff;
          color: white;
          border: 2px solid #0083ff;
          border-radius: 50%;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 0;
          cursor: pointer;
          font-size: 13px; }
          #product .page-content .product-second-section .product-information-extended .product-attachments .attachment > a:hover {
            background-color: white;
            color: #0083ff; }
    #product .page-content .product-second-section .djv-product-opiniones {
      grid-area: opiniones; }
    #product .page-content .product-second-section .product-accessories {
      grid-area: accesories; }
      @media (max-width: 768px) {
        #product .page-content .product-second-section .product-accessories {
          width: auto;
          float: none;
          padding: 0 15px; } }
  #product .page-content .djv-product-opiniones {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px; }
    @media (max-width: 1024px) {
      #product .page-content .djv-product-opiniones {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #product .page-content .djv-product-opiniones {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #product .page-content .djv-product-opiniones {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      #product .page-content .djv-product-opiniones {
        padding: 16px; } }
    #product .page-content .djv-product-opiniones .djv-product-opinion {
      color: #333333;
      display: block;
      width: 49%;
      margin-right: 1%;
      float: left; }
      @media (max-width: 768px) {
        #product .page-content .djv-product-opiniones .djv-product-opinion {
          width: 100%; } }
      #product .page-content .djv-product-opiniones .djv-product-opinion .coment-header .comment_image {
        display: none; }
      #product .page-content .djv-product-opiniones .djv-product-opinion .coment-header .comment_title {
        display: none; }
      #product .page-content .djv-product-opiniones .djv-product-opinion .coment-header .comment_author {
        font-size: 12px;
        margin-bottom: 3px; }

#product .page-content.personalizable #add-to-cart-or-refresh {
  display: grid;
  grid-template-areas: "variants " "quantity " "price " "addtocart" "sequra" "textoadicional";
  grid-template-columns: 100%; }
  @media (max-width: 768px) {
    #product .page-content.personalizable #add-to-cart-or-refresh {
      max-width: 95vw; } }
  #product .page-content.personalizable #add-to-cart-or-refresh .product-prices {
    grid-area: price;
    padding: 20px 0 0; }
    #product .page-content.personalizable #add-to-cart-or-refresh .product-prices .product-price .centerprice {
      display: block;
      margin: auto; }
  #product .page-content.personalizable #add-to-cart-or-refresh .product-quantity {
    grid-area: quantity; }
  #product .page-content.personalizable #add-to-cart-or-refresh #sequra-container {
    grid-area: sequra; }
  #product .page-content.personalizable #add-to-cart-or-refresh .product-add-to-cart-button {
    grid-area: addtocart;
    padding: 20px 0;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-around; }
    @media (max-width: 768px) {
      #product .page-content.personalizable #add-to-cart-or-refresh .product-add-to-cart-button {
        padding: 6px 0;
        align-items: self-start; }
        #product .page-content.personalizable #add-to-cart-or-refresh .product-add-to-cart-button > div {
          width: 50%; } }
    #product .page-content.personalizable #add-to-cart-or-refresh .product-add-to-cart-button .sequra-promotion-widget {
      width: 100%;
      padding-bottom: 0; }
  #product .page-content.personalizable #add-to-cart-or-refresh #djv-variants-message {
    display: none; }
  #product .page-content.personalizable #add-to-cart-or-refresh .product-variants {
    grid-area: variants;
    margin-bottom: 0; }
    #product .page-content.personalizable #add-to-cart-or-refresh .product-variants label {
      font-family: "Open Sans", sans-serif;
      font-weight: 700; }
    #product .page-content.personalizable #add-to-cart-or-refresh .product-variants select {
      width: 300px;
      margin-bottom: 20px; }
    #product .page-content.personalizable #add-to-cart-or-refresh .product-variants .product-variants-color ul li .swap-bg span {
      display: none; }

#product #extra-0 {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  width: 100%;
  margin-bottom: 1.5rem; }
  @media (max-width: 1024px) {
    #product #extra-0 {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product #extra-0 {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product #extra-0 {
      max-width: 500px;
      min-width: 300px; } }

#product #tw-target-text-container pre {
  white-space: normal; }

#product #mpm_faqs_form_overlay {
  top: 0; }

#product .gomakoil_faq_page {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  padding: 2rem 1rem;
  background-color: #f6f6f6;
  margin-bottom: 3.5rem;
  border-radius: .5rem; }
  @media (max-width: 1024px) {
    #product .gomakoil_faq_page {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #product .gomakoil_faq_page {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #product .gomakoil_faq_page {
      max-width: 500px;
      min-width: 300px; } }
  #product .gomakoil_faq_page header {
    margin-bottom: 2.5rem; }
    #product .gomakoil_faq_page header h3 {
      font-family: "Fredoka One", cursive;
      font-weight: 400;
      font-size: 24px;
      text-align: left; }
    #product .gomakoil_faq_page header p a {
      color: #F6A532;
      text-decoration: underline; }
  #product .gomakoil_faq_page .m-link-2 {
    display: none !important; }

#product .djv-product-images {
  position: relative; }
  #product .djv-product-images .product-flags {
    list-style: none;
    position: absolute;
    top: 10px;
    text-transform: uppercase;
    color: white;
    left: 0px;
    font-size: 17px;
    font-weight: bold;
    margin-left: 0;
    padding-left: 0;
    z-index: 1; }
    #product .djv-product-images .product-flags li {
      padding: 8px;
      border-bottom-right-radius: 8px; }
    #product .djv-product-images .product-flags .discount {
      background-color: #EB5512; }
    #product .djv-product-images .product-flags .on-sale {
      background-color: #333333; }
  #product .djv-product-images .product-cover {
    overflow: hidden;
    position: relative;
    margin-bottom: 20px;
    cursor: pointer; }
    @media (max-width: 576px) {
      #product .djv-product-images .product-cover {
        min-width: 200px;
        min-height: 0px; } }
    #product .djv-product-images .product-cover img {
      width: auto;
      margin-left: auto;
      margin-right: auto;
      display: block; }
      @media (max-width: 576px) {
        #product .djv-product-images .product-cover img {
          max-width: 100%; } }
  #product .djv-product-images .djv-product-img-action {
    display: none;
    position: relative;
    overflow: auto;
    top: -70px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.6);
    -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
    padding: 10px;
    cursor: pointer; }
    #product .djv-product-images .djv-product-img-action .ver-grande {
      position: relative;
      font-size: 16px;
      text-align: center; }
    @media (max-width: 768px) {
      #product .djv-product-images .djv-product-img-action {
        display: none; } }
  #product .djv-product-images .product-thumbnails.djv_carouselh .djv_carouselh-viewer i.djv_carouselh-right-arrow {
    background-color: #f1f1f1; }
  #product .djv-product-images .product-thumbnails.djv_carouselh .djv_carouselh-viewer i.djv_carouselh-left-arrow {
    background-color: #f1f1f1; }
  #product .djv-product-images .product-thumbnails .thumbnails {
    /*width: 100%;*/
    height: 90px;
    overflow: hidden;
    padding: 0; }
    @media (max-width: 576px) {
      #product .djv-product-images .product-thumbnails .thumbnails {
        overflow: auto; } }
    #product .djv-product-images .product-thumbnails .thumbnails li {
      list-style: none;
      padding: 6px;
      display: inline-block;
      width: 110px;
      min-height: 120px; }
      @media (max-width: 576px) {
        #product .djv-product-images .product-thumbnails .thumbnails li {
          width: auto;
          height: 90px;
          width: 90px;
          min-height: 90px; } }
      #product .djv-product-images .product-thumbnails .thumbnails li.video-item {
        cursor: pointer; }
        #product .djv-product-images .product-thumbnails .thumbnails li.video-item::before {
          content: "";
          background-image: url("/themes/base/assets/img/video-play.png");
          background-repeat: no-repeat;
          background-position: center;
          position: absolute;
          top: 0px;
          left: 0px;
          width: 110px;
          height: 125px; }
        #product .djv-product-images .product-thumbnails .thumbnails li.video-item img {
          width: 98px;
          height: 98px; }
      #product .djv-product-images .product-thumbnails .thumbnails li img {
        width: 100%; }

#product .product-buy-actions {
  border: 1px solid #f1f1f1;
  padding: 16px;
  margin-bottom: 16px;
  margin-left: 10%;
  margin-right: 10%; }
  @media (max-width: 1024px) {
    #product .product-buy-actions {
      margin-left: auto;
      margin-right: auto; } }
  #product .product-buy-actions #add-to-cart-or-refresh {
    display: grid;
    grid-template-areas: "variants variants" "quantity quantity" "price addtocart" "sequra sequra" "textoadicional textoadicional";
    grid-template-columns: repeat(2, 50%); }
    @media (max-width: 768px) {
      #product .product-buy-actions #add-to-cart-or-refresh {
        grid-template-areas: "variants variants" "quantity quantity" "price price" "addtocart addtocart" "textoadicional textoadicional"; } }
    #product .product-buy-actions #add-to-cart-or-refresh .product-prices {
      grid-area: price;
      align-self: center;
      text-align: center; }
      #product .product-buy-actions #add-to-cart-or-refresh .product-prices .product-price {
        margin: 0; }
    #product .product-buy-actions #add-to-cart-or-refresh .product-quantity {
      grid-area: quantity;
      align-self: center;
      margin-bottom: 10px; }
    #product .product-buy-actions #add-to-cart-or-refresh .product-add-to-cart-button {
      grid-area: addtocart; }
    #product .product-buy-actions #add-to-cart-or-refresh .sequra-container {
      grid-area: sequra;
      padding-top: 1.5rem; }
      #product .product-buy-actions #add-to-cart-or-refresh .sequra-container .sequra-promotion-widget {
        transform: scale(75%); }
    #product .product-buy-actions #add-to-cart-or-refresh .product-variants {
      grid-area: variants; }
      #product .product-buy-actions #add-to-cart-or-refresh .product-variants label {
        font-family: "Open Sans", sans-serif;
        font-weight: 700; }
      #product .product-buy-actions #add-to-cart-or-refresh .product-variants select {
        width: 300px;
        margin-bottom: 20px; }
      #product .product-buy-actions #add-to-cart-or-refresh .product-variants .product-variants-color ul li .swap-bg span {
        display: none; }
      @media (max-width: 768px) {
        #product .product-buy-actions #add-to-cart-or-refresh .product-variants .product-variants-select select {
          margin-bottom: 5px; } }
      @media (max-width: 768px) {
        #product .product-buy-actions #add-to-cart-or-refresh .product-variants .product-variants-select a {
          display: block;
          margin-bottom: 10px; } }
    #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional {
      grid-area: textoadicional;
      margin: 10px auto;
      display: block;
      padding-top: 20px;
      border-top: 1px solid #cccccc;
      width: 100%; }
      #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional .container {
        width: 420px; }
        @media (max-width: 768px) {
          #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional .container {
            max-width: 90%; } }
      #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional img {
        float: left;
        width: 75px;
        margin-right: 10px;
        margin-top: -12px; }
      #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional .links-adicionales {
        text-align: center;
        padding-top: 10px;
        margin-top: 45px;
        border-top: 1px solid #cccccc; }
      #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional .out-of-stock p {
        color: #777777;
        font-size: 13px;
        text-align: center; }
        #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional .out-of-stock p strong {
          color: #26510e;
          font-weight: 800; }
        #product .product-buy-actions #add-to-cart-or-refresh .texto-adicional .out-of-stock p a {
          color: #777777;
          font-size: 13px; }
  #product .product-buy-actions .djv-product-additional-info-delivery {
    margin: -10px auto 10px;
    font-size: 13px;
    color: #5abe20;
    line-height: 16px;
    width: 70%;
    text-align: center;
    border-bottom: 1px solid #aaaaaa; }
    #product .product-buy-actions .djv-product-additional-info-delivery p {
      font-family: "Open Sans", sans-serif;
      font-weight: 500; }
      #product .product-buy-actions .djv-product-additional-info-delivery p a {
        color: inherit;
        text-decoration: underline;
        text-align: center; }
      #product .product-buy-actions .djv-product-additional-info-delivery p .djv-icon-truck {
        width: 50px;
        display: block;
        margin: auto;
        font-size: 39.6px;
        margin-bottom: 0px; }
  #product .product-buy-actions .product-add-to-cart-button .gran-tirada {
    padding-top: 16px;
    color: #444444;
    font-size: 15px;
    text-align: center; }
    @media (max-width: 768px) {
      #product .product-buy-actions .product-add-to-cart-button .gran-tirada {
        padding: 6px 0 0; } }
  #product .product-buy-actions .product-add-to-cart-button .add-to-cart {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    border-radius: 3px;
    text-align: left;
    background: #e88e13;
    background: -webkit-linear-gradient(#f6a212, #e88e13);
    background: -o-linear-gradient(#f6a212, #e88e13);
    background: -moz-linear-gradient(#f6a212, #e88e13);
    background: linear-gradient(#f6a212, #e88e13);
    border-color: #d97f00;
    -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
    -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
    box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
    height: 50px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 17px;
    color: white;
    outline: none;
    vertical-align: middle;
    cursor: pointer;
    border: 1px solid transparent;
    white-space: nowrap;
    padding-top: 5px;
    padding-right: 84px;
    width: 220px;
    margin-left: auto;
    margin-right: auto; }
    #product .product-buy-actions .product-add-to-cart-button .add-to-cart:before {
      content: '\0043';
      display: inline-block;
      font-family: 'comunes';
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      font-size: 32px;
      float: left;
      margin-right: 20px;
      position: relative;
      left: 10px; }
  #product .product-buy-actions .djv-advertencia-descatalogado {
    display: block;
    width: 100%;
    padding: 16px;
    color: red;
    text-align: center;
    font-size: 20px; }

@media (max-width: 768px) {
  #product .page-content .product-add-to-cart-button {
    display: block; } }

#product .page-content .personalizalo, #product .page-content .sinpersonalizar {
  display: block;
  margin: auto;
  margin-bottom: 0;
  border-radius: 3px;
  text-align: left;
  -moz-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
  -webkit-box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
  box-shadow: inset 1px 1px 1px rgba(255, 255, 255, 0.4);
  height: 50px;
  width: 205px;
  font-size: 18px;
  font-weight: 400;
  color: white;
  outline: none;
  vertical-align: middle;
  cursor: pointer;
  border: 1px solid transparent;
  white-space: nowrap; }
  @media (max-width: 768px) {
    #product .page-content .personalizalo, #product .page-content .sinpersonalizar {
      width: 130px; } }
  #product .page-content .personalizalo img, #product .page-content .sinpersonalizar img {
    width: 50px;
    top: -20px;
    position: relative;
    margin-right: 10px; }

#product .page-content .personalizalo {
  padding: 0 20px 0 5px;
  background: #e88e13;
  background: -webkit-linear-gradient(#f6a212, #e88e13);
  background: -o-linear-gradient(#f6a212, #e88e13);
  background: -moz-linear-gradient(#f6a212, #e88e13);
  background: linear-gradient(#f6a212, #e88e13);
  border-color: #d97f00; }
  @media (max-width: 768px) {
    #product .page-content .personalizalo {
      padding: 0 5px;
      margin: auto;
      text-align: center;
      font-size: 14px; }
      #product .page-content .personalizalo img {
        display: none; } }

#product .page-content .sinpersonalizar {
  padding: 0 20px;
  background: #e85513;
  background: -webkit-linear-gradient(#ff5608, #e85513);
  background: -o-linear-gradient(#ff5608, #e85513);
  background: -moz-linear-gradient(#ff5608, #e85513);
  background: linear-gradient(#ff5608, #e85513);
  border-color: #ff6719;
  text-align: center; }
  @media (max-width: 768px) {
    #product .page-content .sinpersonalizar {
      padding: 0 5px;
      font-size: 14px; } }

.product-prices {
  display: block;
  text-align: left; }
  @media (max-width: 768px) {
    .product-prices .product-price .prices {
      font-size: 24px; } }
  .product-prices .product-price .prices .price-unit, .product-prices .product-price .prices .regular-price, .product-prices .product-price .prices .price-until, .product-prices .product-price .prices .advertiseWithoutTaxes {
    margin-top: 0px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 12px;
    color: #666666;
    line-height: 24px; }
    @media (max-width: 768px) {
      .product-prices .product-price .prices .price-unit, .product-prices .product-price .prices .regular-price, .product-prices .product-price .prices .price-until, .product-prices .product-price .prices .advertiseWithoutTaxes {
        font-size: 10px;
        line-height: 11px; } }
  .product-prices .product-price .prices .price-until {
    color: black;
    text-align: right;
    padding-right: 6px; }
  .product-prices .product-price .prices .price-to-pay {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    color: #F28E00;
    padding: 0 6px; }
  .product-prices .product-price .prices .regular-price {
    font-size: 16px;
    width: 100%;
    margin-bottom: -10px; }
  .product-prices .product-price .prices .price-unit {
    align-self: start; }
  .product-prices .product-price .prices .advertiseWithoutTaxes {
    align-self: end; }
  .product-prices .product-discount {
    margin-bottom: 5px; }
    .product-prices .product-discount .discount-percentage, .product-prices .product-discount .discount-amount {
      display: inline-block;
      min-height: auto;
      padding: 0 5px;
      background-color: #903c44;
      border: 1px solid #cbcbcb;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 22px;
      color: white; }
    .product-prices .product-discount .regular-price {
      display: inline-block;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 13px;
      text-decoration: line-through;
      color: #444444; }
    .product-prices .product-discount .discount-percentage {
      display: none; }
  .product-prices .has-discount .with-taxes {
    color: #903c44; }
  .product-prices .product-unit-price {
    color: #444444;
    font-size: 20px;
    position: relative;
    width: 20%;
    font-family: "Open Sans", sans-serif;
    font-weight: 700; }
    .product-prices .product-unit-price::after {
      content: "P.V.P. unidad";
      position: absolute;
      left: 50%;
      font-size: 11px;
      top: -21px;
      transform: translateX(-50%); }

.page-content .product-prices {
  display: block;
  text-align: left; }
  .page-content .product-prices > div:not(.product-discount) {
    display: flex;
    justify-content: space-around;
    flex-flow: row wrap;
    line-height: 29px;
    white-space: nowrap;
    margin: 0px;
    grid-gap: 20px; }
    .page-content .product-prices > div:not(.product-discount).price-only-personalizable {
      width: 100%; }
      @media (max-width: 768px) {
        .page-content .product-prices > div:not(.product-discount).price-only-personalizable .product-price {
          display: block; } }
    @media (max-width: 768px) {
      .page-content .product-prices > div:not(.product-discount) > div {
        width: 50%; } }
  .page-content .product-prices .sequra-promotion-widget {
    width: 100%;
    padding-bottom: 0 !important; }
    @media (max-width: 768px) {
      .page-content .product-prices .sequra-promotion-widget {
        width: 100% !important; } }
  .page-content .product-prices .product-price {
    display: flex;
    justify-content: start; }
    .page-content .product-prices .product-price .precio-personalizadas, .page-content .product-prices .product-price .precio-sinpersonalizar {
      display: grid;
      grid-template-areas: "until regular empty" "until pay unit" "until pay advertise";
      grid-template-rows: repeat(3, 18px);
      grid-template-columns: 45px auto 56px;
      align-items: center;
      padding: 0 25px; }
      @media (max-width: 768px) {
        .page-content .product-prices .product-price .precio-personalizadas, .page-content .product-prices .product-price .precio-sinpersonalizar {
          display: block; }
          .page-content .product-prices .product-price .precio-personalizadas > span, .page-content .product-prices .product-price .precio-sinpersonalizar > span {
            display: block;
            text-align: center;
            margin-bottom: 6px; } }
      .page-content .product-prices .product-price .precio-personalizadas .price-until, .page-content .product-prices .product-price .precio-sinpersonalizar .price-until {
        grid-area: until;
        align-self: end; }
      .page-content .product-prices .product-price .precio-personalizadas .price-to-pay, .page-content .product-prices .product-price .precio-sinpersonalizar .price-to-pay {
        grid-area: pay; }
      .page-content .product-prices .product-price .precio-personalizadas .regular-price, .page-content .product-prices .product-price .precio-sinpersonalizar .regular-price {
        grid-area: regular; }
      .page-content .product-prices .product-price .precio-personalizadas .price-unit, .page-content .product-prices .product-price .precio-sinpersonalizar .price-unit {
        grid-area: unit;
        align-self: start; }
      .page-content .product-prices .product-price .precio-personalizadas .advertiseWithoutTaxes, .page-content .product-prices .product-price .precio-sinpersonalizar .advertiseWithoutTaxes {
        grid-area: advertise; }
    .page-content .product-prices .product-price .precio-sinpersonalizar {
      width: 50%;
      display: none; }
      .page-content .product-prices .product-price .precio-sinpersonalizar .price-to-pay {
        color: #EB5512; }

.aclaracion-precios {
  margin-top: -15px; }
  @media (max-width: 768px) {
    .aclaracion-precios {
      margin-top: auto;
      width: 100% !important; } }

.product-quantity {
  border-bottom: 1px solid #cccccc; }
  .product-quantity p {
    font-size: 13px;
    display: block;
    margin-bottom: 0; }
  .product-quantity label {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    display: block;
    font-size: 14px; }
    .product-quantity label span {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 13px;
      color: #777777; }
  .product-quantity .product-add-to-cart-quantity {
    display: block;
    height: 32px;
    overflow: auto;
    margin-bottom: 20px;
    width: 300px; }
    .product-quantity .product-add-to-cart-quantity #quantity_wanted {
      width: 200px;
      text-align: center;
      background-color: white;
      border: 1px solid #cccccc;
      height: 32px;
      display: block;
      float: left;
      padding-top: 3px; }
      .product-quantity .product-add-to-cart-quantity #quantity_wanted[value="0"] {
        color: white; }
    .product-quantity .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-less {
      background-color: white;
      border: 1px solid #cccccc;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      display: block;
      height: 32px;
      margin-top: 0px;
      position: relative;
      float: left;
      border-right: none;
      padding: 0px 10px;
      font-size: 29px;
      line-height: 23px;
      cursor: pointer; }
    .product-quantity .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-plus {
      background-color: white;
      border: 1px solid #cccccc;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      display: block;
      height: 32px;
      margin-top: 0px;
      position: relative;
      float: left;
      border-left: none;
      padding: 2px 10px;
      line-height: 21px;
      cursor: pointer; }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity {
    height: 42px;
    margin: 20px 0px;
    width: 221px; } }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity #quantity_wanted {
    height: 42px;
    width: 132px; } }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-less {
    height: 42px;
    padding: 5px 15px;
    width: 39px; } }

@media (max-width: 768px) {
  #product .product-add-to-cart-quantity .djv-product-add-to-cart-quantity-btn-plus {
    height: 42px;
    padding: 9px 15px;
    width: 39px; } }

.product-variants {
  margin-bottom: 0px; }
  .product-variants label {
    display: block;
    font-size: 14px; }
  .product-variants ul {
    padding-left: 0; }
    .product-variants ul li {
      list-style: none;
      font-weight: bold; }
      .product-variants ul li::before {
        font-size: 13px;
        color: #777777; }
      .product-variants ul li ul {
        padding-left: 20px; }
        .product-variants ul li ul li {
          font-weight: normal; }
          .product-variants ul li ul li input[type="radio"] {
            display: inline-block;
            margin-right: 10px; }
            .product-variants ul li ul li input[type="radio"][checked] + label {
              color: #0083ff; }
          .product-variants ul li ul li label {
            display: inline-block; }
  .product-variants .product-variants-tallas > a {
    margin-left: 15px;
    font-size: 14px;
    text-decoration: underline;
    font-family: "Open Sans", sans-serif;
    font-weight: 500; }
  .product-variants .product-variants-tallas ul {
    display: grid;
    grid-template-columns: auto auto;
    padding-left: 0; }
    .product-variants .product-variants-tallas ul .product-variants-tallas-input-container {
      list-style: none;
      display: block;
      overflow: hidden;
      margin-bottom: 10px;
      cursor: pointer; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container .swap-bg {
        width: 24px;
        height: 24px;
        background-color: white;
        border: 1px solid #aaa;
        display: inline-block;
        background-size: contain;
        cursor: pointer; }
        .product-variants .product-variants-tallas ul .product-variants-tallas-input-container .swap-bg input {
          opacity: 0;
          width: 35px;
          height: 35px;
          cursor: pointer; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container .attribute-name {
        background-color: #aaa;
        padding: 5px 15px;
        font-size: 14px; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.selected .swap-bg::before {
        content: "✔";
        position: absolute;
        margin-left: 5px; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.selected .attribute-name {
        background-color: #83A7CB;
        color: white;
        padding: 5px 15px;
        font-size: 14px; }
      .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.disabled {
        position: relative; }
        .product-variants .product-variants-tallas ul .product-variants-tallas-input-container.disabled span {
          opacity: 0.5; }
  .product-variants .product-variants-color {
    text-align: left; }
    .product-variants .product-variants-color ul {
      padding-left: 0;
      display: flex;
      flex-wrap: wrap;
      gap: 5px; }
      @media (max-width: 576px) {
        .product-variants .product-variants-color ul {
          margin-bottom: 5px; } }
      .product-variants .product-variants-color ul .product-variants-color-input-container {
        list-style: none;
        display: inline-block;
        margin-bottom: 10px;
        width: 30px;
        height: 30px;
        margin-right: 5px;
        cursor: pointer; }
        .product-variants .product-variants-color ul .product-variants-color-input-container.selected .swap-bg {
          border: 2px solid white;
          outline: 2px solid black;
          background-size: contain; }
        .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg {
          width: 30px;
          height: 30px;
          display: inline-block;
          border: 1px solid;
          background-size: contain;
          cursor: pointer; }
          .product-variants .product-variants-color ul .product-variants-color-input-container .swap-bg input {
            opacity: 0;
            width: 28px;
            height: 22px;
            cursor: pointer; }
        .product-variants .product-variants-color ul .product-variants-color-input-container.disabled {
          position: relative; }
          .product-variants .product-variants-color ul .product-variants-color-input-container.disabled span {
            opacity: 0.5; }
          .product-variants .product-variants-color ul .product-variants-color-input-container.disabled::after {
            content: "";
            display: block;
            width: 46px;
            height: 46px;
            position: absolute;
            z-index: 9;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            padding: 0 4px;
            border-radius: 40px;
            background-image: url("https://cdn1.crealo.es/themes/base/assets/img/aspa.svg");
            color: #f1f1f1; }

#product .djv-product-detail-suport {
  margin-top: 20px; }
  #product .djv-product-detail-suport > div {
    display: inline-block;
    width: 48%;
    margin-left: 0.5%;
    margin-right: 0.5%;
    text-align: center;
    padding: 0.5%;
    position: relative; }
    #product .djv-product-detail-suport > div:first-of-type::after {
      border-left: 1px solid #f1f1f1;
      content: " ";
      display: inline-block;
      height: 150px;
      position: absolute;
      top: 0;
      right: -14px; }
    #product .djv-product-detail-suport > div i {
      font-size: 36px; }
    #product .djv-product-detail-suport > div h4 {
      font-size: 16px;
      font-family: "Open Sans", sans-serif;
      font-weight: 500; }
    #product .djv-product-detail-suport > div p {
      font-size: 14px; }

.container-tabla-tallas {
  display: flex;
  flex-wrap: wrap; }
  .container-tabla-tallas .tabla-tallas {
    text-align: center;
    border-collapse: separate;
    min-width: 300px;
    font-size: 15px; }
    @media (max-width: 768px) {
      .container-tabla-tallas .tabla-tallas {
        width: 100%; } }
    .container-tabla-tallas .tabla-tallas th {
      padding: 10px;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-weight: 400; }
      .container-tabla-tallas .tabla-tallas th:nth-of-type(1) {
        background-color: #777777; }
      .container-tabla-tallas .tabla-tallas th:nth-of-type(2) {
        background-color: #F6A532; }
      .container-tabla-tallas .tabla-tallas th:nth-of-type(3) {
        background-color: #1A8EBF; }
    .container-tabla-tallas .tabla-tallas tr td {
      border-bottom: 1px solid #cccccc; }
      .container-tabla-tallas .tabla-tallas tr td:nth-of-type(2) {
        color: #F6A532; }
      .container-tabla-tallas .tabla-tallas tr td:nth-of-type(3) {
        color: #1A8EBF; }
  .container-tabla-tallas .tabla-tallas-img {
    text-align: center;
    min-width: 300px; }
    @media (max-width: 768px) {
      .container-tabla-tallas .tabla-tallas-img {
        width: 100%; } }

.container-tabla-precios {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;
  font-size: 15px;
  margin: 20px auto; }
  @media (max-width: 768px) {
    .container-tabla-precios {
      overflow-x: scroll; } }
  .container-tabla-precios .tabla-precios {
    border-collapse: separate;
    min-width: 250px;
    margin: 0 auto; }
    @media (max-width: 768px) {
      .container-tabla-precios .tabla-precios {
        width: 100%; } }
    .container-tabla-precios .tabla-precios th {
      padding: 10px;
      color: white;
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-weight: 400;
      background-color: #1A8EBF; }
      .container-tabla-precios .tabla-precios th:nth-of-type(2n) {
        background-color: #F6A532; }
      .container-tabla-precios .tabla-precios th:nth-of-type(1) {
        background-color: #777777; }
      .container-tabla-precios .tabla-precios th:nth-of-type(2) {
        background-color: #F6A532; }
      .container-tabla-precios .tabla-precios th:nth-of-type(3) {
        background-color: #1A8EBF; }
    .container-tabla-precios .tabla-precios tr td {
      border-bottom: 1px solid #cccccc;
      color: #1A8EBF;
      padding: 0px; }
      .container-tabla-precios .tabla-precios tr td:nth-of-type(2n) {
        color: #F6A532; }
      .container-tabla-precios .tabla-precios tr td:nth-of-type(1) {
        color: black; }
      .container-tabla-precios .tabla-precios tr td:nth-of-type(2) {
        color: #F6A532; }
      .container-tabla-precios .tabla-precios tr td:nth-of-type(3) {
        color: #1A8EBF; }

.product-miniature {
  position: relative;
  display: grid;
  align-items: stretch;
  grid-template-rows: 255px auto; }
  @media (max-width: 768px) {
    .product-miniature {
      grid-template-rows: 170px auto; } }
  .product-miniature a {
    display: block; }
  .product-miniature .product-miniature-img-block {
    max-height: 260px; }
    @media (max-width: 768px) {
      .product-miniature .product-miniature-img-block {
        max-height: 160px; } }
    .product-miniature .product-miniature-img-block .product-thumbnail {
      grid-area: imgProd;
      display: block; }
      @media (max-width: 1024px) {
        .product-miniature .product-miniature-img-block .product-thumbnail {
          border: none;
          display: block;
          float: none;
          margin-left: auto;
          margin-right: auto; } }
      .product-miniature .product-miniature-img-block .product-thumbnail img {
        display: block;
        position: relative;
        width: 100%;
        max-width: 250px;
        margin-left: auto;
        margin-right: auto; }
  .product-miniature .product-miniature-data-block .name-product {
    grid-area: nameProd;
    display: block;
    text-align: center;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: 16px;
    margin-bottom: 0; }
    @media (max-width: 768px) {
      .product-miniature .product-miniature-data-block .name-product {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 14px;
        line-height: 16px;
        padding: 0 0 5px 0; } }
    .product-miniature .product-miniature-data-block .name-product a {
      color: black; }
  .product-miniature .product-miniature-data-block .stars {
    grid-area: stars;
    display: block;
    color: black;
    text-align: center;
    line-height: 1em; }
    .product-miniature .product-miniature-data-block .stars span {
      display: inline-block;
      color: black;
      font-size: 11px; }
  .product-miniature .product-miniature-data-block .product-description-short {
    display: none;
    grid-area: descProd;
    border-bottom: 1px "dashed" #cccccc;
    padding-bottom: 10px;
    margin-bottom: 10px;
    font-size: 14px;
    overflow: hidden;
    padding-bottom: 10px;
    margin-bottom: 10px; }
    @media (max-width: 1024px) {
      .product-miniature .product-miniature-data-block .product-description-short {
        min-height: auto; } }
    .product-miniature .product-miniature-data-block .product-description-short ul {
      font-size: 14.3px; }
  .product-miniature .product-miniature-data-block .product-price-and-shipping {
    grid-area: priceProd;
    margin: 6px 0;
    position: relative;
    text-align: center; }
    .product-miniature .product-miniature-data-block .product-price-and-shipping .price-discounted {
      font-size: 24px;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-weight: 600;
      color: #D53128; }
    .product-miniature .product-miniature-data-block .product-price-and-shipping .regular-price {
      position: relative;
      text-decoration: line-through;
      color: #777;
      font-size: 13px;
      font-weight: 200; }
    .product-miniature .product-miniature-data-block .product-price-and-shipping .product-price {
      font-family: "Open Sans", sans-serif;
      font-weight: 900;
      font-size: 24px;
      color: #F28E00; }
      .product-miniature .product-miniature-data-block .product-price-and-shipping .product-price small {
        font-size: 0.5em; }
    .product-miniature .product-miniature-data-block .product-price-and-shipping .texto-adicional {
      display: none; }
    .product-miniature .product-miniature-data-block .product-price-and-shipping p {
      margin-bottom: 0; }
  .product-miniature .product-miniature-data-block .product-flags {
    list-style: none;
    position: absolute !important;
    top: 5px !important;
    text-transform: uppercase;
    color: white;
    left: 35px;
    font-size: 14px;
    margin-left: 0;
    padding-left: 0; }
    .product-miniature .product-miniature-data-block .product-flags li {
      padding: 8px;
      border-bottom-right-radius: 8px; }
    .product-miniature .product-miniature-data-block .product-flags .discount {
      background-color: #EB5512; }
    .product-miniature .product-miniature-data-block .product-flags .on-sale {
      background-color: #333333; }
  .product-miniature .product-miniature-data-block .flag-rebajas-verano {
    position: absolute;
    top: 5px;
    right: 0px;
    width: 80px;
    height: 80px;
    background-image: url("/themes/base/assets/img/flag-rebajas.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: top; }
  .product-miniature .product-miniature-data-block .product-list-actions {
    grid-area: actionsProd; }
    .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas, .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart {
      background-color: black;
      color: white;
      border: 2px solid black;
      border-radius: 3px;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 1px 15px;
      cursor: pointer;
      display: block;
      min-width: 140px;
      max-width: 140px;
      margin: auto;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 14px;
      text-transform: lowercase; }
      .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas:hover, .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart:hover {
        background-color: white;
        color: black; }
      @media (max-width: 768px) {
        .product-miniature .product-miniature-data-block .product-list-actions .djv-ver-mas, .product-miniature .product-miniature-data-block .product-list-actions .add-to-cart {
          width: 95%; } }
  .product-miniature .product-miniature-data-block .variant-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    grid-area: variants;
    padding: 5px 0;
    margin-bottom: 4px;
    border-bottom: 1px solid #e7e7e7;
    min-height: 1em; }
    .product-miniature .product-miniature-data-block .variant-links .color {
      display: inline-block;
      margin: 3px 1px;
      font-size: 0;
      width: 16px;
      height: 16px;
      border: 2px solid #dddddd;
      border-radius: 50%;
      background-size: contain; }
  .product-miniature .product-miniature-data-block .product-availability {
    display: none; }

.product-miniature .with-taxes {
  font-size: 13px;
  color: #777777; }
  .product-miniature .with-taxes small {
    font-size: 13px; }
  .product-miniature .with-taxes .regular-price {
    font-size: 13px;
    color: #777777; }
  .product-miniature .with-taxes span {
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: #F28E00;
    text-align: center;
    line-height: 13px;
    padding: 0 0px 0 4px; }

.product-miniature.personalizable .product-miniature-data-block {
  max-width: 250px;
  margin: 0 auto; }
  .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada {
    display: block; }
    .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades p, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada p {
      font-size: 13px;
      color: #777777;
      margin-bottom: 0px; }
      @media (max-width: 768px) {
        .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades p, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada p {
          font-size: 12px; } }
    .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades .with-taxes, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada .with-taxes {
      font-size: 13px;
      color: #777777; }
      .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades .with-taxes small, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada .with-taxes small {
        font-size: 13px; }
      .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades .with-taxes .regular-price, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada .with-taxes .regular-price {
        font-size: 13px;
        color: #777777; }
      .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades .with-taxes span, .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .grantirada .with-taxes span {
        font-family: "Open Sans", sans-serif;
        font-weight: 800;
        font-size: 18px;
        color: #F28E00;
        text-align: center;
        line-height: 13px;
        padding: 0 0px 0 4px; }
  .product-miniature.personalizable .product-miniature-data-block .product-price-and-shipping .unidades .with-taxes span {
    font-size: 15px;
    font-weight: 800;
    color: #777777; }
  .product-miniature.personalizable .product-miniature-data-block .variant-links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    grid-area: variants; }
    .product-miniature.personalizable .product-miniature-data-block .variant-links .color {
      display: inline-block;
      margin: 3px 1px;
      font-size: 0;
      width: 16px;
      height: 16px;
      border: 2px solid #dddddd;
      border-radius: 50%;
      background-size: contain; }

#product .product-accessories {
  padding-top: 20px; }
  @media (max-width: 768px) {
    #product .product-accessories {
      margin-left: 0;
      width: auto;
      float: none;
      padding: 0px; } }
  @media (max-width: 576px) {
    #product .product-accessories .product-accessories-container .products {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      -webkit-scroll-snap-type: mandatory;
      scroll-snap-type: x mandatory;
      -webkit-scroll-snap-points-x: repeat(100%);
      scroll-snap-points-x: repeat(100%); }
      #product .product-accessories .product-accessories-container .products > * {
        scroll-snap-align: start;
        width: 100%;
        position: relative;
        display: inline-block;
        margin-right: -4px;
        text-align: center; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature {
      display: grid;
      grid-template-columns: 100%;
      grid-template-rows: 260px auto;
      margin-bottom: 16px; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-img-block {
      max-height: 260px; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-img-block .product-thumbnail img {
      width: auto;
      max-width: 100%;
      margin-bottom: 15px; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-data-block .name-product {
      margin-left: auto;
      margin-right: auto;
      height: auto;
      white-space: normal;
      text-align: center;
      font-size: 15px;
      font-family: "Open Sans", sans-serif;
      font-weight: 700; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-data-block .stars {
      display: block;
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
      height: auto; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-data-block .product-price-and-shipping {
      display: block;
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
      height: auto; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-data-block .product-price-and-shipping span.without-taxes {
      font-size: 20px; } }
  @media (max-width: 768px) {
    #product .product-accessories .product-miniature .product-miniature-data-block .product-list-actions {
      display: block;
      max-width: 210px;
      margin-left: auto;
      margin-right: auto;
      height: auto; } }

.pagination {
  display: block;
  height: 35px;
  padding-left: 0;
  list-style: none;
  text-align: right;
  margin-top: 15px; }
  .pagination .pagination-summary {
    display: inline-block;
    font-size: 13px; }
    @media (max-width: 768px) {
      .pagination .pagination-summary {
        font-size: 12px; } }
  .pagination ul {
    display: inline-block;
    list-style: none; }
    @media (max-width: 768px) {
      .pagination ul {
        padding-left: 2px; } }
    .pagination ul li {
      display: inline-block; }
      .pagination ul li a {
        color: black;
        border: 1px solid #999999;
        padding: 0 5px;
        font-size: 14px; }
        @media (max-width: 768px) {
          .pagination ul li a {
            font-size: 12px; } }
        .pagination ul li a.disabled {
          color: #999999;
          border: 0; }
        .pagination ul li a.djv-icon-arrow-right, .pagination ul li a.djv-icon-arrow-left {
          border: 0; }

@media (max-width: 768px) {
  #module-djv_navigation-nsearch #bannerSlider.carousel .carousel-item img {
    max-height: 125px; } }

#module-djv_navigation-nsearch #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #content-wrapper #js-product-list {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: grid;
    grid-template-columns: repeat(4, calc(25%));
    grid-gap: 40px 5px; }
    @media (max-width: 1024px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #content-wrapper #js-product-list {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #content-wrapper #js-product-list {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #content-wrapper #js-product-list {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #content-wrapper #js-product-list {
        grid-template-columns: 48% 48%;
        padding: 0 10px; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper {
    position: relative; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper h1 {
      font-family: "Fredoka One", cursive;
      font-weight: 400;
      display: block; }
      @media (max-width: 576px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper h1 {
          font-size: 20px; } }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px; }
      @media (max-width: 1024px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top {
          max-width: 500px;
          min-width: 300px; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button {
        display: none; }
        @media (max-width: 768px) {
          #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button {
            display: block;
            position: relative;
            background-color: #F5A533;
            color: #fff;
            text-align: center;
            padding: 10px;
            border-radius: 5px;
            margin-bottom: 25px;
            cursor: pointer; }
            #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top .djv-navigator-responsive-filter-button i {
              position: absolute;
              right: 8px;
              top: 8px;
              background-color: white;
              padding: 5px;
              border-radius: 50%;
              width: 25px;
              height: 25px;
              color: #F5A533;
              text-align: center; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion {
        margin-left: 0;
        padding-left: 0;
        margin-top: 20px;
        display: none; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li {
          list-style: none; }
          #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li a {
            color: inherit; }
            #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion li a b {
              display: none; }
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-top #djv-filters-your-seleccion .djv-navigation-left-selected-item a::before {
          display: inline-block;
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: 'X';
          color: white;
          background-color: red;
          padding: 4px 6px;
          border-radius: 50px;
          font-size: 13px;
          font-weight: bold;
          margin-right: 5px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list {
      display: grid;
      grid-template-columns: repeat(3, calc(33.333%));
      grid-gap: 30px 5px; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list {
          display: grid;
          grid-template-columns: 48% 48%;
          grid-gap: 30px 10px; } }
      #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list.djv-display-list {
        grid-template-columns: auto; }
    #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-bottom {
      margin-right: auto;
      margin-left: auto;
      max-width: 1170px;
      min-width: 920px; }
      @media (max-width: 1024px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-bottom {
          max-width: 920px;
          min-width: 750px; } }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-bottom {
          max-width: 750px;
          min-width: 480px; } }
      @media (max-width: 480px) {
        #module-djv_navigation-nsearch #djv-two-columns-layer #content-wrapper #js-product-list-bottom {
          max-width: 500px;
          min-width: 300px; } }

.djv-navigation-nsearch-header {
  background-color: #f1f1f1;
  text-align: center;
  position: relative;
  padding: 0;
  z-index: 0; }
  .djv-navigation-nsearch-header #category-description > h1 {
    font-size: 43px;
    font-family: "Fredoka One", cursive;
    font-weight: 400;
    text-transform: uppercase;
    margin-top: 20px; }
    @media (max-width: 768px) {
      .djv-navigation-nsearch-header #category-description > h1 {
        font-size: 20px; } }
  .djv-navigation-nsearch-header #category-description > h3 {
    font-size: 30px;
    font-family: "Fredoka One", cursive;
    font-weight: 400; }
  .djv-navigation-nsearch-header #category-description > p {
    margin: 5px 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 18px; }
    .djv-navigation-nsearch-header #category-description > p a {
      display: inline-block;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 15px;
      color: #F28E00; }
  .djv-navigation-nsearch-header #category-description a {
    color: #F28E00;
    font-weight: 400; }
    .djv-navigation-nsearch-header #category-description a h3 {
      color: black; }

@media (max-width: 1024px) {
  .carousel ~ .djv-navigation-nsearch-header {
    margin-top: 0px; } }

.djv_carouselh.list-originales .products .product-miniature {
  width: 320px;
  min-height: 380px; }

.djv_carouselh .products .product-miniature {
  width: 320px;
  min-height: 430px;
  max-height: 430px;
  /*&.personalizable{
              min-height: 520px;
              max-height: 520px;
            }*/ }
  @media (max-width: 768px) {
    .djv_carouselh .products .product-miniature {
      width: 100%;
      min-height: 360px; }
      .djv_carouselh .products .product-miniature .product-miniature-img-block {
        min-height: 260px; }
      .djv_carouselh .products .product-miniature .name-product {
        padding: 0 10px;
        white-space: normal; }
      .djv_carouselh .products .product-miniature .product-list-actions {
        margin-top: 0; } }

.djv-listas-productos {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .djv-listas-productos {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .djv-listas-productos {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .djv-listas-productos {
      max-width: 500px;
      min-width: 300px; } }
  .djv-listas-productos .djv-listas-productos-parrafo {
    text-align: center;
    margin-bottom: 50px; }

#left-column .djv-navigator-responsive-filter-button {
  display: none; }
  @media (max-width: 1024px) {
    #left-column .djv-navigator-responsive-filter-button {
      display: block;
      position: relative;
      background-color: #F5A533;
      color: #fff;
      text-align: center;
      padding: 10px;
      border-radius: 5px;
      cursor: pointer; }
      #left-column .djv-navigator-responsive-filter-button i {
        position: absolute;
        right: 8px;
        top: 8px;
        background-color: white;
        padding: 5px;
        border-radius: 50%;
        width: 25px;
        height: 25px;
        color: #F5A533;
        text-align: center; } }

#left-column #djv-navigation-left {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px; }
  @media (max-width: 1024px) {
    #left-column #djv-navigation-left {
      display: none;
      position: fixed;
      top: 0;
      background-color: white;
      z-index: 100;
      width: 100vw;
      left: 0;
      padding: 10px;
      overflow-y: auto;
      height: 100vh; } }
  #left-column #djv-navigation-left h2 {
    display: none;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 13.3px;
    padding: 8px 5px;
    border-bottom: 1px solid #cccccc;
    margin-bottom: 10px;
    margin-top: 9px; }
    @media (max-width: 768px) {
      #left-column #djv-navigation-left h2 {
        padding: 10px 5px;
        font-size: 14px;
        text-align: left;
        display: block; } }
  #left-column #djv-navigation-left > div {
    padding-bottom: 5px;
    margin-bottom: 5px; }
    #left-column #djv-navigation-left > div h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 15px;
      margin-bottom: 20px;
      color: #F28E00;
      padding-top: 0;
      margin-left: 10px; }
      #left-column #djv-navigation-left > div h3 span {
        float: right;
        width: 20%;
        font-size: 12px;
        color: #cccccc;
        padding-top: 8px; }
    #left-column #djv-navigation-left > div ul {
      margin-left: 15px;
      padding-left: 0; }
      #left-column #djv-navigation-left > div ul li {
        display: grid;
        grid-template-columns: 5% 80% 15%;
        margin-bottom: 0;
        font-size: 14px;
        background-color: rgba(130, 130, 130, 0);
        transition: background-color 750ms;
        padding: 1px; }
        #left-column #djv-navigation-left > div ul li a {
          padding: 2px;
          color: #333333; }
        #left-column #djv-navigation-left > div ul li span {
          text-align: center;
          padding: 1px;
          font-size: 12px;
          color: #cccccc;
          display: none; }
        #left-column #djv-navigation-left > div ul li:hover {
          background-color: whitesmoke; }
        #left-column #djv-navigation-left > div ul li::before {
          display: inline-block;
          font-family: "comunes";
          font-style: normal;
          font-weight: normal;
          line-height: 1;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: '\004c';
          font-size: 12px;
          padding-top: 6px;
          color: #415055; }
  #left-column #djv-navigation-left .djv_navigation-price {
    display: none; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider {
      margin-bottom: 20px;
      width: 90%;
      margin-left: 5%;
      margin-bottom: 20px; }
      #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-slider .noUi-connect {
        background: #F5A533; }
    #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button {
      background-color: #F28E00;
      color: white;
      border: 2px solid #F28E00;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      margin-top: 10px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      display: block; }
      #left-column #djv-navigation-left .djv_navigation-price .djv_navigation-price-apply-button:hover {
        background-color: white;
        color: #F28E00; }
  #left-column #djv-navigation-left .djv-navigation-left-selected-item a::before {
    display: inline-block;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    content: 'X';
    color: white;
    background-color: red;
    padding: 4px 6px;
    border-radius: 50px;
    font-size: 13px;
    font-weight: bold;
    margin-right: 5px; }
  #left-column #djv-navigation-left .djv-navigation-left-nofilter-selected {
    color: #cccccc; }
  #left-column #djv-navigation-left #djv-icon-close-filters {
    display: none;
    float: right;
    font-size: 14px;
    font-style: normal;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    cursor: pointer;
    background-color: #D53128;
    color: white;
    padding: 5px;
    border-radius: 5px; }
    #left-column #djv-navigation-left #djv-icon-close-filters::before {
      display: inline-block;
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: 'X';
      color: #D53128;
      background-color: white;
      padding: 4px 6px;
      border-radius: 50px;
      font-size: 13px;
      font-weight: bold;
      margin-right: 0px; }
    @media (max-width: 576px) {
      #left-column #djv-navigation-left #djv-icon-close-filters {
        display: inline-block;
        padding: 7px;
        position: absolute;
        right: 11px;
        top: 11px; } }

#left-column .djv-information-delivery {
  padding: 15px 20px;
  border: 1px solid #aaaaaa;
  margin-bottom: 20px;
  line-height: 22px; }
  @media (max-width: 1024px) {
    #left-column .djv-information-delivery {
      display: none; } }
  #left-column .djv-information-delivery i {
    font-size: 38px;
    color: #333333;
    float: left;
    margin-right: 6px;
    margin-top: 2px; }
  #left-column .djv-information-delivery p {
    font-size: 12.6px;
    color: #666666;
    margin-bottom: auto;
    padding-left: 32px; }

#left-column .djv-need-help {
  background-color: #f1f1f1;
  padding: 15px 20px; }
  @media (max-width: 1024px) {
    #left-column .djv-need-help {
      display: none; } }
  #left-column .djv-need-help [class*='djv-icon-']:before {
    margin-right: 6px;
    color: #415055; }
  #left-column .djv-need-help [class*='djv-icon-'] {
    font-weight: bold;
    margin-top: 15px; }
    #left-column .djv-need-help [class*='djv-icon-'] a {
      color: #415055;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      font-weight: 400; }
  #left-column .djv-need-help p {
    font-size: 14px; }

#left-column-filters-sticky {
  position: sticky;
  float: left;
  top: 100px; }
  @media (max-width: 768px) {
    #left-column-filters-sticky {
      position: relative;
      float: none;
      top: 0; } }

#djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header {
  display: grid;
  grid-template-columns: 80% 20%; }
  #djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header span {
    font-size: 11px;
    color: #cccccc;
    padding-top: 8px; }
  #djv-navigation-left .djv_navigation-left-filters .djv_navigation-left-filters-header span.title {
    font-family: "Oswald-Medium";
    font-size: 20px;
    margin-bottom: 20px;
    color: #333333;
    padding-top: 0; }

#djv-navigation-left .djv_navigation-left-filters li {
  display: grid;
  grid-template-columns: 80% 20%;
  margin-bottom: 0;
  border-bottom: 1px solid #f1f1f1;
  font-size: 14px;
  background-color: rgba(130, 130, 130, 0);
  transition: background-color 750ms;
  padding: 1px; }
  #djv-navigation-left .djv_navigation-left-filters li a {
    padding: 2px; }
  #djv-navigation-left .djv_navigation-left-filters li span {
    display: none;
    text-align: center;
    padding: 1px;
    font-size: 11px;
    color: #cccccc; }
  #djv-navigation-left .djv_navigation-left-filters li:hover {
    background-color: whitesmoke; }

#index #djv-two-columns-layer header {
  text-align: center; }
  #index #djv-two-columns-layer header h2 {
    margin-bottom: 30px; }
  #index #djv-two-columns-layer header p {
    margin-bottom: 30px; }

#manufacturer #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #manufacturer #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #manufacturer #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #manufacturer #main {
      max-width: 500px;
      min-width: 300px; } }
  #manufacturer #main h1 {
    font-size: 20px;
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed #666666;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 22px; }
  #manufacturer #main ul {
    display: grid;
    grid-template-columns: repeat(4, calc(25% - 15px));
    margin: 0;
    padding: 0;
    grid-gap: 20px; }
    @media (max-width: 576px) {
      #manufacturer #main ul {
        grid-template-columns: repeat(2, calc(50% - 2px));
        grid-gap: 2px; } }
    #manufacturer #main ul li {
      list-style: none;
      text-align: center;
      list-style: none;
      text-align: center;
      border: 1px dashed #f1f1f1; }
      #manufacturer #main ul li .brand-img {
        margin: 20px auto; }
      #manufacturer #main ul li .brand-infos {
        padding: 10px 0 1px 0px;
        background-color: #f1f1f1; }
        #manufacturer #main ul li .brand-infos h3 {
          font-size: 22px; }
      #manufacturer #main ul li a {
        color: #333333; }
      #manufacturer #main ul li .brand-products a {
        display: block;
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        display: block;
        font-family: "OpenSans-Regular";
        background-color: #f6f6f6;
        padding-bottom: 10px;
        font-size: 13px; }
        #manufacturer #main ul li .brand-products a:last-of-type {
          background-color: #0083ff;
          color: white;
          border: 2px solid #0083ff;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer;
          margin: 20px auto;
          width: 70%; }
          #manufacturer #main ul li .brand-products a:last-of-type:hover {
            background-color: white;
            color: #0083ff; }

#module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column {
  display: block;
  max-width: 100vw; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column img {
      max-width: 100%; }
    #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column .product-miniature img {
      max-width: 160px; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #left-column {
    display: none; }
  #module-djv_navigation-nsearch #djv-two-columns-layer.force-one-column #content-wrapper {
    width: 100%; }

@media (max-width: 768px) {
  #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) img {
    max-width: 100%; }
  #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .product-miniature img {
    max-width: 160px; } }

#module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo {
  width: 133%;
  margin-left: -33%; }
  @media (max-width: 576px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo {
      width: auto;
      margin-left: auto; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .full-width {
    background-color: #ECECEC; }
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .full-width > img {
      display: block;
      margin: 0 auto;
      max-width: 100vw; }
  #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .regular-width {
    padding-left: 25%;
    margin-top: 50px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .regular-width h2 {
      text-align: left;
      font-size: 20px;
      margin-top: 30px; }
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .regular-width a {
      font-weight: 400; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .regular-width {
        padding-left: 0; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .serigrafiadas .grid-3 {
      display: block; }
      #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .serigrafiadas .grid-3 > div {
        display: block; } }
  #module-djv_navigation-nsearch #djv-two-columns-layer:not(.force-one-column) .textoSeo .serigrafiadas .grid-3 img {
    margin: 40px auto; }

#module-djv_navigation-nsearch .djv-navigation-nsearch-header {
  background-color: white; }
  #module-djv_navigation-nsearch .djv-navigation-nsearch-header aside {
    display: none; }

#module-djv_navigation-nsearch #sigue_leyendo {
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  font-size: 15px;
  color: #F28E00;
  cursor: pointer; }

#module-djv_navigation-nsearch .punto-diferenciador, #module-djv_navigation-nsearch .punto-diferenciador-cuatro {
  margin: 80px auto;
  position: relative;
  display: grid;
  grid-template-columns: 33% 33% 33%; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .punto-diferenciador, #module-djv_navigation-nsearch .punto-diferenciador-cuatro {
      display: block; } }
  #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element {
    position: relative;
    cursor: pointer; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element {
        display: block;
        min-height: 90px; } }
    #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element i, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element i {
      display: block;
      width: 90px;
      margin-right: 10px;
      margin-bottom: 10px;
      float: left; }
      #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element i img, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element i img {
        width: 70%; }
    #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element > h3, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element > h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 18px;
      margin-bottom: 0px;
      text-align: left; }
    #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element p, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element p {
      font-family: "Open Sans", sans-serif;
      font-weight: 300;
      font-size: 15px;
      text-align: left; }
    #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element .mensaje-hover, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element .mensaje-hover {
      opacity: 0;
      position: absolute;
      left: 57%;
      top: 30px;
      transform: translateX(-50%);
      width: 270px;
      padding: 15px 20px 10px;
      background-color: #333333;
      border-radius: 4px;
      color: white;
      transition: all 500ms;
      cursor: pointer; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element .mensaje-hover, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element .mensaje-hover {
          margin-left: -10px; } }
      #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element .mensaje-hover.activeMovil, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element .mensaje-hover.activeMovil {
        opacity: 1;
        display: block;
        position: relative;
        top: 0;
        margin-bottom: 20px; }
      #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element .mensaje-hover h4, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element .mensaje-hover h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 15px;
        padding-bottom: 10px;
        border-bottom: 1px solid white;
        text-align: left; }
      #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element .mensaje-hover p, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element .mensaje-hover p {
        font-size: 13px;
        margin-bottom: 0; }
      #module-djv_navigation-nsearch .punto-diferenciador .punto-diferenciador-element .mensaje-hover::before, #module-djv_navigation-nsearch .punto-diferenciador-cuatro .punto-diferenciador-element .mensaje-hover::before {
        content: "▲︎";
        color: #333333;
        position: absolute;
        top: -18px;
        left: 50%;
        transform: translateX(-50%); }

#module-djv_navigation-nsearch .punto-diferenciador-cuatro {
  grid-template-columns: 25% 25% 25% 25%; }

#module-djv_navigation-nsearch .porque-elegir h2 {
  font-family: "Fredoka One", cursive;
  font-weight: 400;
  padding: 20px; }

#module-djv_navigation-nsearch .porque-elegir h3 {
  text-align: center; }

#module-djv_navigation-nsearch .porque-elegir div article {
  text-align: left; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .porque-elegir div article {
      padding: 16px; } }
  #module-djv_navigation-nsearch .porque-elegir div article header h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    color: black;
    text-align: left; }
  #module-djv_navigation-nsearch .porque-elegir div article p {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px; }

@media (max-width: 768px) {
  #module-djv_navigation-nsearch .porque-elegir .grid-2 {
    display: block; } }

#module-djv_navigation-nsearch .galeria {
  background-color: #f1f1f1; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .galeria {
      padding: 20px 10px; } }
  #module-djv_navigation-nsearch .galeria.full-width {
    background-color: #EDEDED; }
  #module-djv_navigation-nsearch .galeria .djv_carouselh {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px; }
    @media (max-width: 1024px) {
      #module-djv_navigation-nsearch .galeria .djv_carouselh {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .galeria .djv_carouselh {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #module-djv_navigation-nsearch .galeria .djv_carouselh {
        max-width: 500px;
        min-width: 300px; } }
  #module-djv_navigation-nsearch .galeria .galeria-content {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    display: flex; }
    @media (max-width: 1024px) {
      #module-djv_navigation-nsearch .galeria .galeria-content {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .galeria .galeria-content {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #module-djv_navigation-nsearch .galeria .galeria-content {
        max-width: 500px;
        min-width: 300px; } }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .galeria .galeria-content {
        display: flex; } }
    #module-djv_navigation-nsearch .galeria .galeria-content .item {
      width: calc(33%);
      text-align: center;
      display: inline-block;
      padding: 0 11px; }
      @media (max-width: 768px) {
        #module-djv_navigation-nsearch .galeria .galeria-content .item {
          width: 100%;
          margin-bottom: 30px; } }
      #module-djv_navigation-nsearch .galeria .galeria-content .item .icono {
        display: block;
        width: 55px;
        margin-left: auto;
        margin-right: auto; }
      #module-djv_navigation-nsearch .galeria .galeria-content .item h3 {
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        color: black;
        margin-top: 30px; }
      #module-djv_navigation-nsearch .galeria .galeria-content .item p {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        color: black;
        white-space: normal; }
      #module-djv_navigation-nsearch .galeria .galeria-content .item .media-content {
        background-color: red; }
        #module-djv_navigation-nsearch .galeria .galeria-content .item .media-content iframe, #module-djv_navigation-nsearch .galeria .galeria-content .item .media-content img {
          max-width: calc(100vw - 20px) !important; }

#module-djv_navigation-nsearch .loquepiensan {
  margin-top: 40px; }
  #module-djv_navigation-nsearch .loquepiensan .container {
    padding: 30px 0;
    display: flex;
    text-align: center; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .loquepiensan .container {
        display: block; } }
    #module-djv_navigation-nsearch .loquepiensan .container .valoracion {
      flex: 1; }
      #module-djv_navigation-nsearch .loquepiensan .container .valoracion h4 {
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        font-size: 15px;
        color: black; }
        #module-djv_navigation-nsearch .loquepiensan .container .valoracion h4 a {
          color: black; }
      #module-djv_navigation-nsearch .loquepiensan .container .valoracion p {
        font-family: "Open Sans", sans-serif;
        font-weight: 300;
        font-size: 15px;
        font-style: italic;
        color: #444444; }

#module-djv_navigation-nsearch .personalizar-manta h2, #module-djv_navigation-nsearch .flex-content h2 {
  margin: 60px auto; }

#module-djv_navigation-nsearch .personalizar-manta > p, #module-djv_navigation-nsearch .flex-content > p {
  display: block;
  margin-top: 40px;
  text-align: center;
  font-size: 18px; }
  #module-djv_navigation-nsearch .personalizar-manta > p a, #module-djv_navigation-nsearch .flex-content > p a {
    font-size: 16px; }

#module-djv_navigation-nsearch .personalizar-manta .content, #module-djv_navigation-nsearch .flex-content .content {
  display: flex;
  gap: 0 1rem; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .personalizar-manta .content, #module-djv_navigation-nsearch .flex-content .content {
      display: block; } }
  #module-djv_navigation-nsearch .personalizar-manta .content > *, #module-djv_navigation-nsearch .flex-content .content > * {
    flex: 1; }
  #module-djv_navigation-nsearch .personalizar-manta .content .step, #module-djv_navigation-nsearch .flex-content .content .step {
    flex: 1;
    display: grid;
    grid-template-columns: 18% 72%;
    grid-gap: 0; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .personalizar-manta .content .step, #module-djv_navigation-nsearch .flex-content .content .step {
        margin-bottom: 80px; } }
    #module-djv_navigation-nsearch .personalizar-manta .content .step .indice, #module-djv_navigation-nsearch .flex-content .content .step .indice {
      font-family: "Fredoka One", cursive;
      font-weight: 400;
      background-color: black;
      color: white;
      font-size: 36px;
      border-radius: 50%;
      height: 110px;
      padding: 25px 70px 30px 22px;
      width: 110px;
      margin-top: 50px; }
    #module-djv_navigation-nsearch .personalizar-manta .content .step .contenido, #module-djv_navigation-nsearch .flex-content .content .step .contenido {
      background-position: left center;
      background-repeat: no-repeat;
      background-color: #f1f1f1;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      padding: 16px; }
      #module-djv_navigation-nsearch .personalizar-manta .content .step .contenido strong, #module-djv_navigation-nsearch .flex-content .content .step .contenido strong {
        font-size: 18px; }
      #module-djv_navigation-nsearch .personalizar-manta .content .step .contenido p, #module-djv_navigation-nsearch .flex-content .content .step .contenido p {
        font-size: 14px; }
      #module-djv_navigation-nsearch .personalizar-manta .content .step .contenido img, #module-djv_navigation-nsearch .flex-content .content .step .contenido img {
        display: block;
        width: 50px;
        margin-left: auto;
        margin-right: auto;
        margin-top: -45px;
        margin-bottom: 20px; }

#module-djv_navigation-nsearch .grandes-pedidos {
  background-color: #f1f1f1;
  background-image: url("/themes/base/assets/img/mantaspersonalizadas.jpg");
  background-repeat: no-repeat;
  background-position: top right;
  text-align: left;
  min-height: 450px; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .grandes-pedidos {
      padding: 40px 16px;
      background-position: bottom right;
      background-size: cover; } }
  @media (max-width: 1170px) {
    #module-djv_navigation-nsearch .grandes-pedidos {
      background-position-x: 240px; } }
  #module-djv_navigation-nsearch .grandes-pedidos .djv-container > * {
    width: 35%; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .grandes-pedidos .djv-container > * {
        width: calc(100% - 30px); } }
  #module-djv_navigation-nsearch .grandes-pedidos .djv-container h2 {
    text-align: left; }
  #module-djv_navigation-nsearch .grandes-pedidos .djv-container strong {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    display: block;
    margin-bottom: 22px;
    font-size: 16px; }
  #module-djv_navigation-nsearch .grandes-pedidos .djv-container ul {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    margin-left: 5px; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .grandes-pedidos .djv-container ul {
        padding-left: 10px; } }
  #module-djv_navigation-nsearch .grandes-pedidos .djv-container a {
    background-color: black;
    color: white;
    border: 2px solid black;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 10px 20px;
    cursor: pointer;
    display: block;
    width: 300px;
    margin-top: 25px; }
    #module-djv_navigation-nsearch .grandes-pedidos .djv-container a:hover {
      background-color: white;
      color: black; }
    @media (max-width: 768px) {
      #module-djv_navigation-nsearch .grandes-pedidos .djv-container a {
        width: calc(100% - 20px); } }

#module-djv_navigation-nsearch .tedamosideas > h2 {
  margin-bottom: 40px; }

#module-djv_navigation-nsearch .tedamosideas .container {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 30px));
  grid-gap: 30px; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .tedamosideas .container {
      display: block; } }
  #module-djv_navigation-nsearch .tedamosideas .container article {
    overflow: hidden; }
    #module-djv_navigation-nsearch .tedamosideas .container article h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 20px;
      margin-top: 20px; }
    #module-djv_navigation-nsearch .tedamosideas .container article p {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px; }
    #module-djv_navigation-nsearch .tedamosideas .container article img {
      margin: 0 auto;
      display: block; }

#module-djv_navigation-nsearch .cuatro_columnas > h2 {
  margin-bottom: 40px; }

#module-djv_navigation-nsearch .cuatro_columnas .container {
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 20px));
  grid-gap: 30px; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .cuatro_columnas .container {
      display: block; } }
  #module-djv_navigation-nsearch .cuatro_columnas .container article {
    overflow: hidden; }
    #module-djv_navigation-nsearch .cuatro_columnas .container article h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 20px;
      margin-top: 20px; }
    #module-djv_navigation-nsearch .cuatro_columnas .container article p {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px; }

#module-djv_navigation-nsearch .tres_columnas > h2 {
  margin-bottom: 40px; }

#module-djv_navigation-nsearch .tres_columnas .container {
  display: grid;
  grid-template-columns: repeat(3, calc(33% - 20px));
  grid-gap: 30px; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .tres_columnas .container {
      display: block; } }
  #module-djv_navigation-nsearch .tres_columnas .container article {
    overflow: hidden; }
    #module-djv_navigation-nsearch .tres_columnas .container article h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 20px;
      margin-top: 20px; }
    #module-djv_navigation-nsearch .tres_columnas .container article p {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 16px; }

#module-djv_navigation-nsearch .clientes {
  background-color: #f1f1f1;
  padding: 34px; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .clientes {
      padding: 10px; } }
  #module-djv_navigation-nsearch .clientes h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    text-transform: inherit;
    color: black;
    font-size: 20px; }
  #module-djv_navigation-nsearch .clientes .djv_carouselh-viewer > div {
    position: relative;
    display: flex;
    transition: left 500ms; }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .clientes .djv-container.swipe::after {
      top: -20px; } }
  @media (max-width: 768px) {
    #module-djv_navigation-nsearch .clientes .djv-container > div {
      width: 100%;
      overflow-x: auto;
      overflow-y: hidden;
      white-space: nowrap;
      -webkit-overflow-scrolling: touch;
      -webkit-scroll-snap-type: mandatory;
      scroll-snap-type: x mandatory;
      -webkit-scroll-snap-points-x: repeat(100%);
      scroll-snap-points-x: repeat(100%); } }

#module-djv_navigation-nsearch .djv-listas-productos {
  margin-top: 50px;
  margin-bottom: 50px; }
  #module-djv_navigation-nsearch .djv-listas-productos h2 {
    font-family: "Fredoka One", cursive;
    font-weight: 400;
    color: black; }
  #module-djv_navigation-nsearch .djv-listas-productos > p {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    color: black;
    margin-bottom: 35px; }

.textoSeo section:not(.full-width), #category-description section:not(.full-width) {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    .textoSeo section:not(.full-width), #category-description section:not(.full-width) {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    .textoSeo section:not(.full-width), #category-description section:not(.full-width) {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    .textoSeo section:not(.full-width), #category-description section:not(.full-width) {
      max-width: 500px;
      min-width: 300px; } }

.textoSeo section header, #category-description section header {
  text-align: center; }

.textoSeo #propuestas-valor-1,
.textoSeo #product-list-1,
.textoSeo #personaliza,
.textoSeo #algunas-ideas,
.textoSeo #product-list-2,
.textoSeo .img-full-width, #category-description #propuestas-valor-1,
#category-description #product-list-1,
#category-description #personaliza,
#category-description #algunas-ideas,
#category-description #product-list-2,
#category-description .img-full-width {
  margin-top: 60px; }
  .textoSeo #propuestas-valor-1 header,
  .textoSeo #product-list-1 header,
  .textoSeo #personaliza header,
  .textoSeo #algunas-ideas header,
  .textoSeo #product-list-2 header,
  .textoSeo .img-full-width header, #category-description #propuestas-valor-1 header,
  #category-description #product-list-1 header,
  #category-description #personaliza header,
  #category-description #algunas-ideas header,
  #category-description #product-list-2 header,
  #category-description .img-full-width header {
    margin-bottom: 25px; }
    .textoSeo #propuestas-valor-1 header h2,
    .textoSeo #product-list-1 header h2,
    .textoSeo #personaliza header h2,
    .textoSeo #algunas-ideas header h2,
    .textoSeo #product-list-2 header h2,
    .textoSeo .img-full-width header h2, #category-description #propuestas-valor-1 header h2,
    #category-description #product-list-1 header h2,
    #category-description #personaliza header h2,
    #category-description #algunas-ideas header h2,
    #category-description #product-list-2 header h2,
    #category-description .img-full-width header h2 {
      font-family: "Fredoka One", cursive;
      font-weight: 700;
      text-transform: uppercase;
      font-size: 36px;
      text-align: center; }
      @media (max-width: 768px) {
        .textoSeo #propuestas-valor-1 header h2,
        .textoSeo #product-list-1 header h2,
        .textoSeo #personaliza header h2,
        .textoSeo #algunas-ideas header h2,
        .textoSeo #product-list-2 header h2,
        .textoSeo .img-full-width header h2, #category-description #propuestas-valor-1 header h2,
        #category-description #product-list-1 header h2,
        #category-description #personaliza header h2,
        #category-description #algunas-ideas header h2,
        #category-description #product-list-2 header h2,
        #category-description .img-full-width header h2 {
          font-size: 24px; } }
    .textoSeo #propuestas-valor-1 header h3,
    .textoSeo #product-list-1 header h3,
    .textoSeo #personaliza header h3,
    .textoSeo #algunas-ideas header h3,
    .textoSeo #product-list-2 header h3,
    .textoSeo .img-full-width header h3, #category-description #propuestas-valor-1 header h3,
    #category-description #product-list-1 header h3,
    #category-description #personaliza header h3,
    #category-description #algunas-ideas header h3,
    #category-description #product-list-2 header h3,
    #category-description .img-full-width header h3 {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 24px; }

.textoSeo #propuestas-valor-1, #category-description #propuestas-valor-1 {
  text-align: left; }

.textoSeo #personaliza .djv-flex:first-of-type, #category-description #personaliza .djv-flex:first-of-type {
  margin: 25px 0px; }
  .textoSeo #personaliza .djv-flex:first-of-type > div p, #category-description #personaliza .djv-flex:first-of-type > div p {
    text-align: center;
    padding: 0 10px; }

.textoSeo #personaliza .djv-flex:last-of-type, #category-description #personaliza .djv-flex:last-of-type {
  margin: 35px 0px 25px; }
  .textoSeo #personaliza .djv-flex:last-of-type > a, #category-description #personaliza .djv-flex:last-of-type > a {
    position: relative;
    display: block;
    background-color: black; }
    @media (max-width: 768px) {
      .textoSeo #personaliza .djv-flex:last-of-type > a, #category-description #personaliza .djv-flex:last-of-type > a {
        margin-bottom: 10px; } }
    .textoSeo #personaliza .djv-flex:last-of-type > a h3, #category-description #personaliza .djv-flex:last-of-type > a h3 {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      padding: 10px;
      margin-bottom: 0;
      text-align: center;
      color: white;
      text-transform: uppercase;
      width: 100%;
      font-size: 20px; }
    .textoSeo #personaliza .djv-flex:last-of-type > a img, #category-description #personaliza .djv-flex:last-of-type > a img {
      max-width: 100%; }

.textoSeo #grandes-pedidos, #category-description #grandes-pedidos {
  background-color: #f1f1f1;
  background-size: cover;
  background-position: center;
  min-height: 465px;
  padding: 60px 0 50px; }
  @media (max-width: 768px) {
    .textoSeo #grandes-pedidos, #category-description #grandes-pedidos {
      background-position: left;
      padding: 30px 50px;
      min-height: 335px; } }
  .textoSeo #grandes-pedidos .content-text, #category-description #grandes-pedidos .content-text {
    width: 480px;
    text-align: center; }
    @media (max-width: 768px) {
      .textoSeo #grandes-pedidos .content-text, #category-description #grandes-pedidos .content-text {
        width: 100%; } }
    .textoSeo #grandes-pedidos .content-text h2, #category-description #grandes-pedidos .content-text h2 {
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 40px; }
      @media (max-width: 768px) {
        .textoSeo #grandes-pedidos .content-text h2, #category-description #grandes-pedidos .content-text h2 {
          font-size: 36px; } }
    .textoSeo #grandes-pedidos .content-text p, #category-description #grandes-pedidos .content-text p {
      font-family: "Open Sans", sans-serif;
      font-weight: 400;
      font-size: 23px; }
      @media (max-width: 768px) {
        .textoSeo #grandes-pedidos .content-text p, #category-description #grandes-pedidos .content-text p {
          font-size: 18px; } }
    .textoSeo #grandes-pedidos .content-text ul, #category-description #grandes-pedidos .content-text ul {
      list-style: none;
      text-align: left;
      display: block;
      padding-left: 0;
      position: relative;
      left: 50%;
      transform: translateX(-50%);
      width: 280px; }
      .textoSeo #grandes-pedidos .content-text ul li, #category-description #grandes-pedidos .content-text ul li {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 19px;
        margin-bottom: 12px; }
        .textoSeo #grandes-pedidos .content-text ul li img, #category-description #grandes-pedidos .content-text ul li img {
          width: 30px;
          display: inline-block; }
    .textoSeo #grandes-pedidos .content-text .btn-hover-animated-orange, #category-description #grandes-pedidos .content-text .btn-hover-animated-orange {
      width: 80%;
      margin-left: auto;
      margin-right: auto;
      margin-top: 30px;
      display: block;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      font-size: 19px; }

.textoSeo #algunas-ideas article header, #category-description #algunas-ideas article header {
  text-align: center;
  color: #666; }
  .textoSeo #algunas-ideas article header h2, #category-description #algunas-ideas article header h2 {
    color: black; }

.textoSeo #algunas-ideas article .idea-block, #category-description #algunas-ideas article .idea-block {
  display: grid;
  grid-template-areas: "text image";
  grid-template-columns: repeat(2, 50%);
  margin: 30px 0; }
  @media (max-width: 768px) {
    .textoSeo #algunas-ideas article .idea-block, #category-description #algunas-ideas article .idea-block {
      grid-template-areas: "image" "text";
      grid-template-columns: 100%;
      margin-bottom: 60px; } }
  .textoSeo #algunas-ideas article .idea-block:nth-of-type(2n), #category-description #algunas-ideas article .idea-block:nth-of-type(2n) {
    grid-template-areas: "image text"; }
    @media (max-width: 768px) {
      .textoSeo #algunas-ideas article .idea-block:nth-of-type(2n), #category-description #algunas-ideas article .idea-block:nth-of-type(2n) {
        grid-template-areas: "image" "text";
        grid-template-columns: 100%; } }
  .textoSeo #algunas-ideas article .idea-block .idea-text, .textoSeo #algunas-ideas article .idea-block .idea-img, #category-description #algunas-ideas article .idea-block .idea-text, #category-description #algunas-ideas article .idea-block .idea-img {
    align-self: center;
    text-align: center;
    color: #666;
    margin: 0 3px; }
    .textoSeo #algunas-ideas article .idea-block .idea-text h3, .textoSeo #algunas-ideas article .idea-block .idea-img h3, #category-description #algunas-ideas article .idea-block .idea-text h3, #category-description #algunas-ideas article .idea-block .idea-img h3 {
      display: block;
      font-size: 22px; }
    .textoSeo #algunas-ideas article .idea-block .idea-text p, .textoSeo #algunas-ideas article .idea-block .idea-img p, #category-description #algunas-ideas article .idea-block .idea-text p, #category-description #algunas-ideas article .idea-block .idea-img p {
      display: block;
      font-size: 16px;
      padding: 0 12px; }
    .textoSeo #algunas-ideas article .idea-block .idea-text .claim-button, .textoSeo #algunas-ideas article .idea-block .idea-img .claim-button, #category-description #algunas-ideas article .idea-block .idea-text .claim-button, #category-description #algunas-ideas article .idea-block .idea-img .claim-button {
      display: flex;
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
      gap: 10px; }
      .textoSeo #algunas-ideas article .idea-block .idea-text .claim-button h4, .textoSeo #algunas-ideas article .idea-block .idea-img .claim-button h4, #category-description #algunas-ideas article .idea-block .idea-text .claim-button h4, #category-description #algunas-ideas article .idea-block .idea-img .claim-button h4 {
        font-size: 16px;
        font-family: "Open Sans", sans-serif;
        font-weight: 900;
        background-image: url("https://cdn2.crealo.es/themes/base/assets/img/flecha.png");
        background-position: 95% bottom;
        background-repeat: no-repeat;
        text-align: right;
        padding-bottom: 35px;
        padding-right: 15px; }
      .textoSeo #algunas-ideas article .idea-block .idea-text .claim-button > *, .textoSeo #algunas-ideas article .idea-block .idea-img .claim-button > *, #category-description #algunas-ideas article .idea-block .idea-text .claim-button > *, #category-description #algunas-ideas article .idea-block .idea-img .claim-button > * {
        flex: 1; }
      .textoSeo #algunas-ideas article .idea-block .idea-text .claim-button > a, .textoSeo #algunas-ideas article .idea-block .idea-img .claim-button > a, #category-description #algunas-ideas article .idea-block .idea-text .claim-button > a, #category-description #algunas-ideas article .idea-block .idea-img .claim-button > a {
        background-color: #415055;
        color: white;
        border: 2px solid #415055;
        border-radius: 0;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        color: white;
        align-self: flex-start; }
        .textoSeo #algunas-ideas article .idea-block .idea-text .claim-button > a:hover, .textoSeo #algunas-ideas article .idea-block .idea-img .claim-button > a:hover, #category-description #algunas-ideas article .idea-block .idea-text .claim-button > a:hover, #category-description #algunas-ideas article .idea-block .idea-img .claim-button > a:hover {
          background-color: white;
          color: #415055; }
  .textoSeo #algunas-ideas article .idea-block .idea-text, #category-description #algunas-ideas article .idea-block .idea-text {
    grid-area: text; }
    @media (max-width: 768px) {
      .textoSeo #algunas-ideas article .idea-block .idea-text, #category-description #algunas-ideas article .idea-block .idea-text {
        width: 100%;
        margin-top: 10px; } }
  .textoSeo #algunas-ideas article .idea-block .idea-img, #category-description #algunas-ideas article .idea-block .idea-img {
    grid-area: image; }
    .textoSeo #algunas-ideas article .idea-block .idea-img img, #category-description #algunas-ideas article .idea-block .idea-img img {
      width: 100%; }

.textoSeo #propuestas-valor-2, #category-description #propuestas-valor-2 {
  margin-top: 70px; }
  .textoSeo #propuestas-valor-2 h3, #category-description #propuestas-valor-2 h3 {
    display: block;
    color: #F28E00;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 2px; }
    .textoSeo #propuestas-valor-2 h3::before, #category-description #propuestas-valor-2 h3::before {
      content: "";
      display: block;
      background-image: url("https://cdn1.crealo.es/themes/base/assets/img/ornament_home.png");
      background-repeat: no-repeat;
      background-position: center;
      height: 30px;
      width: 100%; }
  .textoSeo #propuestas-valor-2 p, #category-description #propuestas-valor-2 p {
    text-align: center; }

.textoSeo .ver-mas, #category-description .ver-mas {
  background-color: #F28E00;
  color: white;
  border: 2px solid #F28E00;
  border-radius: 0;
  transition: all 500ms;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 5px;
  cursor: pointer;
  color: white;
  width: 170px;
  margin: 0px auto;
  font-size: 14px;
  display: block;
  margin-top: 30px; }
  .textoSeo .ver-mas:hover, #category-description .ver-mas:hover {
    background-color: white;
    color: #F28E00; }

.textoSeo .djv-flex, #category-description .djv-flex {
  display: flex; }
  .textoSeo .djv-flex > *, #category-description .djv-flex > * {
    flex: 1;
    margin: 0 7px; }
  .textoSeo .djv-flex > a, #category-description .djv-flex > a {
    background-color: black; }
    .textoSeo .djv-flex > a img, #category-description .djv-flex > a img {
      transition: opacity 500ms;
      opacity: 1; }
      .textoSeo .djv-flex > a img:hover, #category-description .djv-flex > a img:hover {
        opacity: .5; }
  @media (max-width: 768px) {
    .textoSeo .djv-flex, #category-description .djv-flex {
      display: block; } }

.textoSeo .element-icon, #category-description .element-icon {
  display: grid;
  grid-template-columns: 20% 80%; }
  @media (max-width: 768px) {
    .textoSeo .element-icon, #category-description .element-icon {
      display: block;
      text-align: center;
      margin-bottom: 30px; } }
  .textoSeo .element-icon img, #category-description .element-icon img {
    float: left;
    display: inline-block;
    width: 50px;
    margin-right: 16px; }
    @media (max-width: 768px) {
      .textoSeo .element-icon img, #category-description .element-icon img {
        float: none;
        margin-right: 0; } }
  .textoSeo .element-icon div, #category-description .element-icon div {
    display: inline-block; }
    @media (max-width: 768px) {
      .textoSeo .element-icon div, #category-description .element-icon div {
        display: block; } }
    .textoSeo .element-icon div h3, #category-description .element-icon div h3 {
      color: black;
      font-size: 21px;
      font-family: "Open Sans", sans-serif;
      font-weight: 500;
      display: block;
      margin-bottom: 0; }
    .textoSeo .element-icon div p, #category-description .element-icon div p {
      display: block;
      font-size: 14px;
      font-family: "Open Sans", sans-serif;
      font-weight: 300; }

.textoSeo .tag-list, #category-description .tag-list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap; }
  .textoSeo .tag-list a, #category-description .tag-list a {
    background-color: #415055;
    color: white;
    border: 2px solid #415055;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 5px;
    cursor: pointer;
    min-width: 120px;
    margin: 10px; }
    .textoSeo .tag-list a:hover, #category-description .tag-list a:hover {
      background-color: white;
      color: #415055; }

.textoSeo .scrollTo, #category-description .scrollTo {
  cursor: pointer;
  color: #F28E00; }

.textoSeo .franja_destacada, #category-description .franja_destacada {
  background-color: #f1f1f1;
  margin-top: -16px; }
  .textoSeo .franja_destacada span, #category-description .franja_destacada span {
    font-size: 13px; }

section .page-cms {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding: 5px 15px;
  margin: 20px auto; }
  @media (max-width: 1024px) {
    section .page-cms {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    section .page-cms {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    section .page-cms {
      max-width: 500px;
      min-width: 300px; } }
  section .page-cms h1 {
    margin-bottom: 20px;
    padding-bottom: 15px;
    font-family: "Open Sans", sans-serif;
    font-weight: 800;
    font-size: 26px;
    text-align: center;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      section .page-cms h1 {
        font-size: 16px;
        margin-bottom: 5px;
        padding-bottom: 5px; } }
  section .page-cms h2 {
    margin-bottom: 20px;
    text-align: left;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px; }
    section .page-cms h2::before {
      content: "";
      display: block;
      width: 120px;
      height: 5px;
      background-color: #F5A533;
      margin-bottom: 16px; }
    @media (max-width: 768px) {
      section .page-cms h2 {
        font-size: 16px; } }
  section .page-cms h3 {
    margin-bottom: 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px; }
  section .page-cms ul {
    list-style-type: disc;
    box-sizing: border-box; }
    section .page-cms ul.nobullet {
      list-style-type: none; }
    @media (max-width: 768px) {
      section .page-cms ul {
        display: block; }
        section .page-cms ul li {
          display: block;
          margin-bottom: 20px; } }
  section .page-cms ol {
    color: #F5A533;
    font-weight: 700;
    font-size: 18px; }
    section .page-cms ol li span {
      font-size: 16px;
      font-weight: 400;
      color: black; }
      section .page-cms ol li span a {
        color: #F5A533;
        font-weight: 700; }
  section .page-cms .cms-content-two-cols {
    display: grid;
    grid-template-columns: 32% 68%; }
    @media (max-width: 768px) {
      section .page-cms .cms-content-two-cols {
        display: block;
        position: relative; } }
    section .page-cms .cms-content-two-cols .cms-left {
      padding-right: 30px; }
      @media (max-width: 768px) {
        section .page-cms .cms-content-two-cols .cms-left {
          position: sticky;
          top: 90px;
          padding: 5px 0;
          background-color: white;
          z-index: 10; } }
      section .page-cms .cms-content-two-cols .cms-left .cms-left-sticky {
        position: sticky;
        top: 150px; }
        @media (max-width: 768px) {
          section .page-cms .cms-content-two-cols .cms-left .cms-left-sticky {
            position: relative;
            top: 0;
            display: flex;
            margin: 12px 0; } }
      section .page-cms .cms-content-two-cols .cms-left .cms-menu {
        background-color: #5f5f5f;
        padding: 20px;
        border-radius: 16px; }
        @media (max-width: 768px) {
          section .page-cms .cms-content-two-cols .cms-left .cms-menu {
            background-color: #F5A533;
            padding: 8px;
            width: calc(100% - 60px); } }
        section .page-cms .cms-content-two-cols .cms-left .cms-menu .title-menu {
          color: white;
          border-bottom: 1px solid #F5A533;
          padding-bottom: 15px;
          font-size: 18px;
          font-weight: bold; }
          @media (max-width: 768px) {
            section .page-cms .cms-content-two-cols .cms-left .cms-menu .title-menu {
              padding-bottom: 0;
              margin-bottom: 0;
              border: 0;
              font-size: 16px; } }
      section .page-cms .cms-content-two-cols .cms-left .cms-dudas {
        display: flex;
        font-size: 14px;
        padding: 10px;
        margin-top: 16px;
        background-color: #f0f0f0;
        border-radius: 16px; }
        @media (max-width: 768px) {
          section .page-cms .cms-content-two-cols .cms-left .cms-dudas {
            display: block;
            margin-top: 0;
            padding: 0;
            background-color: transparent; } }
        section .page-cms .cms-content-two-cols .cms-left .cms-dudas img {
          width: 90px;
          margin-right: 16px; }
          @media (max-width: 768px) {
            section .page-cms .cms-content-two-cols .cms-left .cms-dudas img {
              width: 40px;
              margin-right: 0;
              margin-left: 16px; } }
        @media (max-width: 768px) {
          section .page-cms .cms-content-two-cols .cms-left .cms-dudas .cms-dudas-text {
            display: none; } }
        section .page-cms .cms-content-two-cols .cms-left .cms-dudas .cms-dudas-text strong {
          font-size: 18px; }
        section .page-cms .cms-content-two-cols .cms-left .cms-dudas .cms-dudas-text p {
          margin-bottom: 8px; }
    section .page-cms .cms-content-two-cols .cms-right {
      padding-right: 20px; }
      @media (max-width: 768px) {
        section .page-cms .cms-content-two-cols .cms-right .expandText {
          margin-bottom: 20px; } }
    section .page-cms .cms-content-two-cols .cms-menu-list {
      padding: 0 5px;
      font-size: 15px; }
      @media (max-width: 768px) {
        section .page-cms .cms-content-two-cols .cms-menu-list {
          position: fixed;
          display: none;
          top: 0px;
          left: 0px;
          z-index: 10;
          width: 100vw;
          height: calc(100vh - 80px);
          padding: 16px;
          background-color: rgba(0, 0, 0, 0.95); } }
      section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-close {
        display: none;
        color: white; }
        @media (max-width: 768px) {
          section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-close {
            position: absolute;
            top: 5px;
            right: 5px;
            display: block;
            font-size: 32px; } }
      section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level {
        margin: 15px 0; }
        section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level .scrollTo {
          position: relative;
          cursor: pointer;
          color: white;
          font-weight: normal; }
          section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level .scrollTo.active {
            color: #F5A533; }
            section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level .scrollTo.active::before {
              content: "";
              position: absolute;
              top: 5px;
              left: -15px;
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #F5A533; }
        section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level:hover {
          color: #F5A533; }
          section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level:hover .scrollTo {
            color: #F5A533;
            text-decoration: none; }
            section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-first-level:hover .scrollTo::before {
              content: "";
              position: absolute;
              top: 5px;
              left: -15px;
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #F5A533; }
      section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level {
        padding-left: 15px;
        margin: 15px 0; }
        @media (max-width: 768px) {
          section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level {
            display: none; } }
        section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level .scrollTo {
          position: relative;
          cursor: pointer;
          color: #a8a8a8;
          font-weight: normal; }
          section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level .scrollTo.active {
            color: #F5A533; }
            section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level .scrollTo.active::before {
              content: "";
              position: absolute;
              top: 5px;
              left: -15px;
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #F5A533; }
        section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level:hover {
          color: #F5A533; }
          section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level:hover .scrollTo {
            color: #F5A533;
            text-decoration: none;
            position: relative; }
            section .page-cms .cms-content-two-cols .cms-menu-list .cms-menu-list-second-level:hover .scrollTo::before {
              content: "";
              position: absolute;
              top: 5px;
              left: -15px;
              display: block;
              width: 10px;
              height: 10px;
              border-radius: 50%;
              background-color: #F5A533; }

#cms #content .cms-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #cms #content .cms-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #cms #content .cms-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #cms #content .cms-content {
      max-width: 500px;
      min-width: 300px; } }
  #cms #content .cms-content img {
    max-width: 100%; }
  #cms #content .cms-content p {
    margin-bottom: auto; }
  #cms #content .cms-content > .header {
    margin-bottom: 25px;
    text-align: center; }
    #cms #content .cms-content > .header h1 {
      font-family: "Open Sans", sans-serif;
      font-weight: 600;
      font-size: 26px; }
    #cms #content .cms-content > .header img {
      margin: 25px auto 25px; }
  #cms #content .cms-content.cms-tienda .grid-2 {
    align-content: start;
    margin-bottom: 25px; }
    @media (max-width: 768px) {
      #cms #content .cms-content.cms-tienda .grid-2 {
        display: block; } }
  #cms #content .cms-content.cms-tienda .grid-3 {
    align-content: start; }
  #cms #content .cms-content.cms-tienda .tienda-contacto, #cms #content .cms-content.cms-tienda .tienda-visitanos {
    text-align: center; }
    #cms #content .cms-content.cms-tienda .tienda-contacto .header, #cms #content .cms-content.cms-tienda .tienda-visitanos .header {
      margin-bottom: 20px; }
      #cms #content .cms-content.cms-tienda .tienda-contacto .header h3, #cms #content .cms-content.cms-tienda .tienda-visitanos .header h3 {
        margin: 10px auto 20px;
        font-size: 24px; }
  #cms #content .cms-content.cms-tienda .tienda-contacto p {
    font-size: 14px; }
  #cms #content .cms-content.cms-tienda .tienda-contacto img {
    width: 54px;
    display: block;
    margin: 5px auto; }

body#cart #notifications {
  display: block;
  margin-left: auto;
  margin-right: auto; }

body#cart #content-wrapper {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    body#cart #content-wrapper {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    body#cart #content-wrapper {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    body#cart #content-wrapper {
      max-width: 500px;
      min-width: 300px; } }
  body#cart #content-wrapper #main {
    display: grid;
    grid-template-columns: 75% 25%;
    gap: 0; }
    @media (max-width: 768px) {
      body#cart #content-wrapper #main {
        display: block; } }
    body#cart #content-wrapper #main .continue-shopping {
      width: 230px;
      margin-left: inherit;
      margin-top: 10px;
      padding-top: 6px;
      padding-left: 20px;
      position: relative; }
      @media (max-width: 768px) {
        body#cart #content-wrapper #main .continue-shopping {
          display: block;
          margin-left: auto;
          margin-right: auto;
          margin-top: 20px; } }
      body#cart #content-wrapper #main .continue-shopping i {
        position: absolute;
        left: 0px;
        top: 8px; }
    @media (max-width: 768px) {
      body#cart #content-wrapper #main > div {
        box-shadow: 2px 2px 8px rgba(0, 0, 0, 0); } }
    body#cart #content-wrapper #main .cart-overview {
      margin-right: 25px; }
      @media (max-width: 768px) {
        body#cart #content-wrapper #main .cart-overview {
          margin-right: 0; } }
      body#cart #content-wrapper #main .cart-overview h1 {
        padding: 16px;
        border-bottom: 1px solid white;
        text-transform: uppercase;
        font-size: 23px; }
      body#cart #content-wrapper #main .cart-overview .banner-cart {
        padding: 1rem;
        background-color: #ffd6d6;
        font-size: 14px;
        color: #490404; }
        body#cart #content-wrapper #main .cart-overview .banner-cart p {
          margin-bottom: 0; }
          body#cart #content-wrapper #main .cart-overview .banner-cart p a {
            font-weight: normal;
            color: #490404;
            text-decoration: underline; }
      body#cart #content-wrapper #main .cart-overview .max-quantity-message {
        display: flex;
        background-color: #F28E00;
        color: white;
        padding: 10px;
        align-items: center; }
        body#cart #content-wrapper #main .cart-overview .max-quantity-message img {
          width: 50px; }
        body#cart #content-wrapper #main .cart-overview .max-quantity-message p {
          padding-left: 15px;
          margin-bottom: 0; }
      body#cart #content-wrapper #main .cart-overview .body {
        padding: 15px 30px; }
        body#cart #content-wrapper #main .cart-overview .body ul {
          padding-left: 0; }
        body#cart #content-wrapper #main .cart-overview .body .cart-header-items {
          list-style: none;
          display: grid;
          grid-template-columns: 65% 35%;
          border-bottom: 1px solid #cccccc;
          color: #666666; }
          body#cart #content-wrapper #main .cart-overview .body .cart-header-items .cart-header-items-total {
            text-align: right;
            margin-right: 85px; }
        body#cart #content-wrapper #main .cart-overview .body .cart-item {
          list-style: none;
          display: grid;
          grid-template-columns: 55% 45%;
          gap: 16px;
          padding-bottom: 16px;
          border-bottom: 1px dashed #dddddd;
          padding-top: 16px;
          font-size: 17px; }
          @media (max-width: 768px) {
            body#cart #content-wrapper #main .cart-overview .body .cart-item {
              display: block;
              margin-bottom: 20px; } }
          body#cart #content-wrapper #main .cart-overview .body .cart-item a {
            color: inherit; }
          body#cart #content-wrapper #main .cart-overview .body .cart-item .secction-label {
            font-family: "Open Sans", sans-serif;
            font-weight: 500;
            font-size: 15px; }
          body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail {
            display: grid;
            grid-template-columns: 30% 70%;
            gap: 16px; }
            @media (max-width: 768px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail {
                display: block; } }
            @media (max-width: 768px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-image {
                float: left;
                width: 40%;
                margin-right: 15px;
                margin-bottom: 15px; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-image img {
              width: 100%; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex > span {
              display: block; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price {
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
              font-size: 20px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price small {
                font-family: "Open Sans", sans-serif;
                font-weight: 400;
                font-size: 12px; }
                @media (max-width: 768px) {
                  body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-price small {
                    display: block; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .regular-price {
              text-decoration: line-through;
              color: #cccccc; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .product-discount .discount-percentage {
              padding: 0 10px;
              background-color: #212f44;
              color: white;
              margin-left: 5px; }
            @media (max-width: 576px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes {
                display: block;
                clear: both; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes {
              margin-left: 20px;
              list-style: none;
              margin-bottom: 10px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes li {
                line-height: 14px;
                font-size: 13px; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .cart-detailed-product-line-detail-tex .cart-resume-selected-attributes .cart-resume-attributes li label {
                  margin-bottom: 0; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures {
              position: relative; }
              @media (max-width: 576px) {
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures {
                  display: block; } }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .miniatura {
                position: relative;
                display: inline-block; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini {
                width: 80px;
                height: 80px;
                cursor: pointer; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini:hover {
                  border: 1px solid #999999; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .ampliada {
                display: none; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .ampliada::before {
                  width: 0;
                  height: 0;
                  border-right: 20px solid #aaa;
                  border-top: 15px solid transparent;
                  border-bottom: 15px solid transparent;
                  content: "";
                  position: absolute;
                  top: 50%;
                  left: -20px;
                  transform: translateY(-50%); }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini:hover + .ampliada {
                display: block;
                position: absolute;
                width: 250px;
                height: 250px;
                top: -105%;
                left: 90px;
                border: 1px solid #aaa;
                z-index: 2;
                box-shadow: 1px 1px 3px #999; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-detail .personalize-miniatures .mini:hover + .ampliada img {
                  width: 100%; }
          body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify {
            display: grid;
            grid-template-areas: "unidades precio delete" "edit precio delete";
            grid-template-rows: 30px 20px;
            text-align: center; }
            @media (max-width: 768px) {
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify {
                margin-top: 5px;
                padding-top: 15px;
                border-top: 1px dashed #cccccc;
                clear: both; } }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .totalUnidades {
              grid-area: unidades;
              font-size: 15px;
              margin-top: 3px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-price {
              grid-area: precio;
              text-align: center;
              font-size: 20px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .cart-edit-personalization {
              grid-area: edit;
              padding-top: 10px;
              color: #F28E00;
              font-size: 13px; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .cart-edit-personalization::before {
                font-size: 14px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .djv-icon-delete {
              grid-area: delete;
              text-align: center;
              font-size: 14px;
              color: red;
              padding-top: 6px; }
            body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity {
              border-bottom: 0; }
              body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity .product-add-to-cart-quantity {
                width: 135px; }
                body#cart #content-wrapper #main .cart-overview .body .cart-item .cart-detailed-product-line-modify .product-quantity .product-add-to-cart-quantity #quantity_wanted {
                  width: 70px; }
    body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal {
      overflow: auto;
      border: 1px solid #dddddd;
      border-top: none;
      font-family: "Open Sans", sans-serif;
      font-weight: 700;
      font-size: 17px; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal > * {
        padding: 8px 16px; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal .cart-title {
        background-color: #f1f1f1;
        color: black;
        text-transform: uppercase;
        font-size: 14px; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal .js-subtotal {
        float: left; }
      body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal span:not(.js-subtotal) {
        text-align: right;
        display: inline-block; }
        body#cart #content-wrapper #main .cart-detailed-right-side #items-subtotal span:not(.js-subtotal):last-of-type {
          float: right; }
    body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo {
      font-size: 12px;
      margin-top: 10px;
      padding: 8px 16px;
      border-bottom: 1px solid #dddddd; }
      body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo .djv-icon-calidad::before {
        margin-right: 8px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo .djv-cart-promo-item {
        display: grid;
        grid-template-columns: 50% 40% 10%;
        color: #83A7CB; }
        body#cart #content-wrapper #main .cart-detailed-right-side .djv-cart-promo .djv-cart-promo-item .djv-icon-close {
          color: #D53128; }
    body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals {
      border: 1px solid #dddddd;
      border-top: none; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals {
        padding: 8px 16px;
        border-bottom: 1px solid #dddddd; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .products {
          display: none; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping {
          display: grid;
          grid-template-columns: auto auto;
          padding-bottom: 5px;
          margin-bottom: 5px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .shipping .value {
            text-align: right; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .discount {
          display: grid;
          grid-template-columns: auto auto;
          padding-bottom: 5px;
          margin-bottom: 5px;
          color: #5abe20; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .discount .value {
            text-align: right; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .tax {
          display: grid;
          grid-template-columns: auto auto;
          padding-bottom: 5px;
          margin-bottom: 5px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-subtotals .tax .value {
            text-align: right; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total {
        display: grid;
        grid-template-columns: auto auto;
        font-size: 24px;
        font-family: "Open Sans", sans-serif;
        font-weight: 600;
        padding: 8px 16px;
        font-weight: 600;
        align-items: baseline; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total .label {
          text-align: left;
          font-size: 18px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total .label small {
            display: block;
            font-size: 12px;
            color: #999999; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-detailed-totals .cart-total .value {
          text-align: right; }
    body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher {
      margin-top: 16px;
      padding: 16px;
      border: 1px solid #dddddd;
      /*#added_vouchers + .vouchers-title + #vouchers-form{
                        height: 100%;
                        opacity: 1;
                    }*/ }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher p {
        font-size: 13px;
        margin-top: 10px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher #vouchers-form {
        height: 0;
        opacity: 0; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .show-vouchers {
        animation-name: showvouchers;
        animation-duration: 1s;
        animation-fill-mode: forwards; }

@keyframes showvouchers {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .added-vouchers-title, body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .vouchers-title {
        font-family: "Open Sans", sans-serif;
        font-weight: 500;
        font-size: 16px;
        color: #F28E00;
        text-decoration: underline; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .vouchers-title {
        margin-bottom: 10px;
        text-align: center;
        cursor: pointer; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher #added_vouchers {
        list-style: none;
        font-size: 14px;
        padding: 5px; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher #added_vouchers li {
          display: table-row;
          column-count: 2; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher #added_vouchers li > * {
            display: table-cell; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul {
        padding-left: 0; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li {
          list-style: none;
          font-size: 13px;
          text-align: center;
          padding: 5px;
          margin: 10px auto;
          background-color: #e0e0e0;
          transition: background-color 500ms; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li:hover {
            background-color: #fafafa; }
            body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li:hover .label .code {
              font-size: 20px; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li .label {
            font-size: 15px; }
            body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li .label .code {
              display: block;
              font-family: "Open Sans", sans-serif;
              font-weight: 700;
              font-size: 17px;
              cursor: pointer;
              transition: font-size 500ms; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li p {
            display: inline-block;
            width: 49%; }
          body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher form ul li a {
            display: inline-block;
            float: right;
            padding: 0 14px;
            font-size: 14px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher input[name="discount_name"] {
        width: 160px;
        text-align: center;
        font-size: 13px;
        border: 1px solid #cccccc;
        padding: 3px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher button {
        background-color: #F28E00;
        color: white;
        border: 2px solid #F28E00;
        border-radius: 0;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        padding: 3px 27px 2px;
        float: right;
        font-size: 14px; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher button:hover {
          background-color: white;
          color: #F28E00; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .djv-icon-question {
        font-size: 12px;
        color: #83A7CB;
        margin-top: 16px;
        display: block; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .djv-icon-question::before {
          margin-right: 5px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .notification {
        padding: 5px 0px;
        display: none; }
        body#cart #content-wrapper #main .cart-detailed-right-side .cart-voucher .notification .js-error-text {
          color: #D53128;
          font-size: 14px;
          margin: 0; }
    body#cart #content-wrapper #main .cart-detailed-right-side .tramitar-pedido-btn {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      margin: 10px auto;
      display: block;
      width: 100%;
      font-size: 20px; }
      body#cart #content-wrapper #main .cart-detailed-right-side .tramitar-pedido-btn:hover {
        background-color: white;
        color: #5abe20; }
    body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance {
      padding: 16px;
      border-bottom: 1px solid #dddddd; }
      body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance ul {
        padding-left: 30px; }
        body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance ul li {
          list-style: none;
          margin-bottom: 16px; }
          body#cart #content-wrapper #main .cart-detailed-right-side #block-reassurance ul li img {
            margin-left: -30px;
            filter: sepia(100%) brightness(310%) hue-rotate(60deg) saturate(300%); }

body#cart.cart-empty .checkout {
  display: none; }

body#cart.cart-empty .cart-overview .body::after {
  content: "No hay articulos seleccionados"; }

body#cart #editPersonalizationModal {
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.8); }
  @media (max-width: 768px) {
    body#cart #editPersonalizationModal {
      position: absolute; } }
  body#cart #editPersonalizationModal #editPersonalizationBox {
    background-color: white;
    border-radius: 6px;
    display: block;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    min-width: 410px;
    z-index: 1000;
    padding: 5px; }
    @media (max-width: 768px) {
      body#cart #editPersonalizationModal #editPersonalizationBox {
        min-width: 99vw;
        min-height: 99vh;
        overflow-y: scroll; } }
    body#cart #editPersonalizationModal #editPersonalizationBox #closeEditPersonalizationFormButton {
      display: block;
      font-size: 25px;
      line-height: 15px;
      padding: 10px;
      background-color: black;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      color: white; }
  body#cart #editPersonalizationModal #editPersonalizationForm {
    height: 80vh; }
    @media (max-width: 768px) {
      body#cart #editPersonalizationModal #editPersonalizationForm {
        overflow-y: scroll; } }

#authentication a {
  color: inherit; }

#authentication #main {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto; }
  @media (max-width: 1024px) {
    #authentication #main {
      padding: 10px; } }
  #authentication #main h1.h3 {
    font-size: 20px; }
  #authentication #main .djv-register-link {
    display: block;
    border-top: 1px dashed #cccccc;
    border-bottom: 1px dashed #cccccc;
    padding: 20px 0 20px;
    margin: 20px 0; }
  #authentication #main .form-footer button {
    border: none;
    background-color: #26445C;
    padding: 6px 30px;
    color: white;
    transition: all 500ms;
    border: 2px solid white;
    cursor: pointer; }
    @media (max-width: 1024px) {
      #authentication #main .form-footer button {
        width: 100%;
        display: block; } }
    #authentication #main .form-footer button:hover {
      box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
      background-color: white;
      color: #26445C;
      border: 2px solid #26445C; }
  #authentication #main .page-authentication #login-form {
    margin-top: 20px; }
    #authentication #main .page-authentication #login-form label {
      display: inline-block;
      margin-right: 20px;
      vertical-align: top; }
      @media (max-width: 1024px) {
        #authentication #main .page-authentication #login-form label {
          display: block;
          margin: 10px 0; } }
      #authentication #main .page-authentication #login-form label span {
        display: block;
        margin-bottom: 10px; }
      #authentication #main .page-authentication #login-form label input {
        display: block;
        width: 100%; }
    #authentication #main .page-authentication #login-form .lost_password {
      margin-top: 20px; }
  #authentication #main .page-authentication #customer-form {
    font-size: 16px; }
    #authentication #main .page-authentication #customer-form .form-fields {
      margin-top: 30px; }
      #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) {
        display: block;
        max-width: 60%;
        margin-bottom: 15px; }
        @media (max-width: 1024px) {
          #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) {
            width: 100%; } }
        #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) span {
          display: block;
          width: 50%;
          margin-bottom: 0px; }
        #authentication #main .page-authentication #customer-form .form-fields label:not(.checkbox-field) input {
          display: block;
          width: 100%; }
      #authentication #main .page-authentication #customer-form .form-fields .lost_password {
        margin-top: 20px; }
      #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field {
        display: block;
        margin-bottom: 15px; }
        #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field span {
          margin-left: 20px;
          display: block;
          margin-top: -24px; }
          #authentication #main .page-authentication #customer-form .form-fields label.checkbox-field span em {
            font-size: 14px; }
      #authentication #main .page-authentication #customer-form .form-fields label.radio-field label {
        display: inline-block;
        width: auto;
        margin-right: 5px;
        margin-bottom: 15px; }
        #authentication #main .page-authentication #customer-form .form-fields label.radio-field label input {
          display: inline-block;
          width: auto;
          margin-right: 5px; }
    #authentication #main .page-authentication #customer-form .form-footer {
      border-top: 1px dashed #cccccc;
      padding-top: 20px;
      margin-top: 20px; }

#password .form-fields label {
  display: block;
  margin-top: 20px; }
  #password .form-fields label > * {
    display: inline-block;
    width: 200px; }

#checkout {
  background-color: #eee;
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }
  #checkout #header {
    position: absolute;
    top: -5px;
    min-height: 36px;
    box-shadow: none; }
    #checkout #header .checkout-header-simplified {
      background-color: black; }
      #checkout #header .checkout-header-simplified div {
        width: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto; }
        #checkout #header .checkout-header-simplified div .logo {
          background-image: url("https://cdn1.crealo.es/themes/base/assets/img/logo_sticky.svg");
          width: 99px;
          height: 30px;
          display: block;
          background-repeat: no-repeat;
          background-size: contain;
          margin: 5px;
          background-position: center; }
          @media (max-width: 576px) {
            #checkout #header .checkout-header-simplified div .logo {
              margin-left: auto;
              margin-right: auto; } }
  #checkout #content {
    width: 100%;
    max-width: 1200px;
    margin: 80px auto 30px auto;
    display: grid;
    grid-template-columns: calc(70% - 16px) calc(30% - 16px);
    grid-column-gap: 16px; }
    @media (max-width: 576px) {
      #checkout #content {
        grid-template-columns: auto;
        padding: 0 10px; } }
    #checkout #content [data-link-action="show-login-form"] {
      background-color: #0083ff;
      color: white;
      border: 2px solid #0083ff;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      padding-left: 16px;
      padding-right: 16px;
      margin: 20px 0;
      display: inline-block; }
      #checkout #content [data-link-action="show-login-form"]:hover {
        background-color: white;
        color: #0083ff; }
    #checkout #content .checkout-process {
      background-color: white;
      border: 1px solid #dadada;
      padding: 22px; }
      @media (max-width: 576px) {
        #checkout #content .checkout-process {
          margin-bottom: 10px; } }
      #checkout #content .checkout-process section h1 {
        font-family: "Fredoka One", cursive;
        font-weight: 400;
        font-size: 20px;
        border-bottom: 1px solid #aaa;
        padding-bottom: 10px;
        margin: 10px 0;
        color: #999; }
      #checkout #content .checkout-process section .content {
        display: none; }
      #checkout #content .checkout-process section.js-current-step h1 {
        color: black;
        font-family: "Fredoka One", cursive;
        font-weight: 700; }
      #checkout #content .checkout-process section.js-current-step .content {
        display: block; }
      #checkout #content .checkout-process section.js-current-step.-complete h1 {
        color: black; }
      #checkout #content .checkout-process section.-complete h1 {
        color: #999; }
        #checkout #content .checkout-process section.-complete h1::after {
          font-size: 14px;
          color: #F28E00;
          content: "editar";
          float: right;
          cursor: pointer;
          padding-top: 5px; }
      #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks {
        display: grid;
        grid-template-columns: repeat(2, 50%); }
        @media (max-width: 1024px) {
          #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks {
            display: block; }
            #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks h4 {
              font-size: 16px;
              font-weight: bold; } }
        #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-login {
          padding: 10px 20px;
          border-right: 1px solid #666666; }
          @media (max-width: 1024px) {
            #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-login {
              border-right: none;
              border-bottom: 1px solid #666666;
              margin-bottom: 20px;
              padding: 10px 10px; } }
          #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-login #login-form label input {
            width: 100%; }
          #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-login #login-form .lost_password {
            float: left;
            padding-top: 16px;
            padding-right: 20px;
            padding-left: 20px; }
            @media (max-width: 1024px) {
              #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-login #login-form .lost_password {
                float: none;
                text-align: center;
                margin-bottom: 0; } }
        #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-register {
          padding: 10px 20px;
          position: relative; }
          @media (max-width: 1024px) {
            #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-register {
              border-bottom: 1px solid #666666;
              margin-bottom: 20px;
              padding: 10px 10px; } }
          #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-register h4 {
            font-size: 20px; }
          #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-register .btn-hover-animated-blue {
            cursor: pointer;
            color: #F28E00;
            text-decoration: underline;
            padding: inherit 20px;
            width: 90%; }
            @media (max-width: 1024px) {
              #checkout #content .checkout-process #checkout-personal-information-step .djv-checkout-blocks .djv-checkout-block-register .btn-hover-animated-blue {
                position: relative;
                top: auto;
                left: auto;
                width: 100%;
                transform: none;
                margin: 20px auto; } }
      #checkout #content .checkout-process #djv-checkout-form-register {
        display: none; }
        #checkout #content .checkout-process #djv-checkout-form-register #customer-form .checkbox-field {
          position: relative; }
          #checkout #content .checkout-process #djv-checkout-form-register #customer-form .checkbox-field input[type="checkbox"] {
            float: left;
            margin-top: 5px;
            margin-right: 5px; }
      #checkout #content .checkout-process #delivery_message {
        width: 100%;
        padding: 16px;
        border: 1px solid #444444; }
      #checkout #content .checkout-process #customer-form {
        margin: 10px 0; }
        #checkout #content .checkout-process #customer-form .form-fields > label {
          display: block; }
          #checkout #content .checkout-process #customer-form .form-fields > label span {
            display: inline-block;
            min-width: 255px;
            max-width: calc(100% - 250px); }
          #checkout #content .checkout-process #customer-form .form-fields > label input[type="radio"] {
            margin: 0 10px; }
          #checkout #content .checkout-process #customer-form .form-fields > label input:not([type="checkbox"]):not([type="radio"]) {
            width: 100%;
            border: none;
            background-color: #eee;
            padding: 5px; }
            @media (max-width: 576px) {
              #checkout #content .checkout-process #customer-form .form-fields > label input:not([type="checkbox"]):not([type="radio"]) {
                width: 100%; } }
        #checkout #content .checkout-process #customer-form .form-fields .checkbox-field {
          border-top: 1px "dashed" #cccccc;
          padding-top: 10px;
          margin-top: 10px;
          border-color: #333333;
          padding-left: 5px;
          padding-right: 5px;
          padding-bottom: 10px; }
          #checkout #content .checkout-process #customer-form .form-fields .checkbox-field > label {
            display: block; }
            @media (max-width: 1024px) {
              #checkout #content .checkout-process #customer-form .form-fields .checkbox-field > label {
                overflow: auto; } }
            #checkout #content .checkout-process #customer-form .form-fields .checkbox-field > label span {
              margin-left: 10px; }
              @media (max-width: 1024px) {
                #checkout #content .checkout-process #customer-form .form-fields .checkbox-field > label span {
                  min-width: 180px;
                  display: block;
                  margin-bottom: 10px;
                  float: right; } }
        #checkout #content .checkout-process #customer-form .form-fields .checkbox-field ~ .checkbox-field {
          margin-top: 0px;
          border-top: 0px dashed #aaa;
          border-bottom: 1px "dashed" #cccccc;
          padding-bottom: 10px;
          margin-bottom: 10px;
          border-color: #333333; }
      #checkout #content .checkout-process #login-form label {
        display: block; }
        #checkout #content .checkout-process #login-form label span {
          display: block;
          margin: 10px 0; }
        #checkout #content .checkout-process #login-form label input {
          border: none;
          background-color: #eee;
          padding: 5px;
          width: 50%; }
          @media (max-width: 576px) {
            #checkout #content .checkout-process #login-form label input {
              width: 100%; } }
      #checkout #content .checkout-process .js-address-form .form-fields {
        /*display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 16px;*/ }
        @media (max-width: 576px) {
          #checkout #content .checkout-process .js-address-form .form-fields {
            display: block; } }
        #checkout #content .checkout-process .js-address-form .form-fields label {
          display: grid;
          grid-template-columns: 30% 70%; }
          #checkout #content .checkout-process .js-address-form .form-fields label span {
            display: inline-block;
            font-size: 14px;
            color: #777777; }
            #checkout #content .checkout-process .js-address-form .form-fields label span small {
              display: block;
              color: #F28E00; }
          #checkout #content .checkout-process .js-address-form .form-fields label input:not([type="checkbox"]), #checkout #content .checkout-process .js-address-form .form-fields label select {
            padding: 5px 10px;
            border: none;
            background-color: #eee;
            width: 100%;
            display: inline-block; }
          #checkout #content .checkout-process .js-address-form .form-fields label input[name="use_same_address"] + span {
            display: inline;
            margin-left: 5px; }
          @media (max-width: 576px) {
            #checkout #content .checkout-process .js-address-form .form-fields label {
              width: 100%; } }
          #checkout #content .checkout-process .js-address-form .form-fields label input[name="esempresa"] {
            width: 25px; }
          #checkout #content .checkout-process .js-address-form .form-fields label input[type="checkbox"] {
            width: 16px;
            text-align: right;
            display: flex;
            justify-self: right; }
      #checkout #content .checkout-process .js-address-form .form-footer {
        border-top: 1px "dashed" #cccccc;
        padding-top: 10px;
        margin-top: 10px;
        margin: 10px 0; }
        #checkout #content .checkout-process .js-address-form .form-footer a {
          background-color: #D53128;
          color: white;
          border: 2px solid #D53128;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer; }
          #checkout #content .checkout-process .js-address-form .form-footer a:hover {
            background-color: white;
            color: #D53128; }
        #checkout #content .checkout-process .js-address-form .form-footer button {
          background-color: #5abe20;
          color: white;
          border: 2px solid #5abe20;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer; }
          #checkout #content .checkout-process .js-address-form .form-footer button:hover {
            background-color: white;
            color: #5abe20; }
      #checkout #content .checkout-process #delivery-addresses, #checkout #content .checkout-process #invoice-addresses {
        display: grid;
        grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
        grid-gap: 16px;
        margin-bottom: 30px; }
        @media (max-width: 576px) {
          #checkout #content .checkout-process #delivery-addresses, #checkout #content .checkout-process #invoice-addresses {
            grid-template-columns: auto; } }
        #checkout #content .checkout-process #delivery-addresses .address-item, #checkout #content .checkout-process #invoice-addresses .address-item {
          border: 1px dashed #dadada;
          padding: 8px;
          position: relative; }
          #checkout #content .checkout-process #delivery-addresses .address-item:last-of-type, #checkout #content .checkout-process #invoice-addresses .address-item:last-of-type {
            border-bottom: 1px dashed #dadada; }
          #checkout #content .checkout-process #delivery-addresses .address-item.selected, #checkout #content .checkout-process #invoice-addresses .address-item.selected {
            border: 4px solid #83A7CB; }
          #checkout #content .checkout-process #delivery-addresses .address-item label, #checkout #content .checkout-process #invoice-addresses .address-item label {
            width: 100%;
            display: block;
            padding-left: 26px;
            margin-bottom: 16px;
            padding-bottom: 40px; }
            #checkout #content .checkout-process #delivery-addresses .address-item label input, #checkout #content .checkout-process #invoice-addresses .address-item label input {
              margin-left: -26px;
              margin-right: 10px; }
          #checkout #content .checkout-process #delivery-addresses .address-item header, #checkout #content .checkout-process #invoice-addresses .address-item header {
            font-size: 20px;
            color: #777777;
            border-bottom: 1px solid #dadada;
            padding-bottom: 6px; }
          #checkout #content .checkout-process #delivery-addresses .address-item footer, #checkout #content .checkout-process #invoice-addresses .address-item footer {
            position: absolute;
            display: block;
            margin-top: 40px;
            bottom: 10px;
            width: 100%;
            display: block; }
            #checkout #content .checkout-process #delivery-addresses .address-item footer a, #checkout #content .checkout-process #invoice-addresses .address-item footer a {
              display: inline-block;
              padding: 3px 16px;
              text-align: center;
              color: white;
              font-size: 14px; }
            #checkout #content .checkout-process #delivery-addresses .address-item footer a[data-link-action="edit-address"], #checkout #content .checkout-process #invoice-addresses .address-item footer a[data-link-action="edit-address"] {
              background-color: #212f44; }
            #checkout #content .checkout-process #delivery-addresses .address-item footer a[data-link-action="delete-address"], #checkout #content .checkout-process #invoice-addresses .address-item footer a[data-link-action="delete-address"] {
              background-color: #212f44; }
      #checkout #content .checkout-process #delivery-addresses + a + h2 {
        margin: 30px 0 0 0; }
      #checkout #content .checkout-process .delivery-options-list .delivery-options {
        display: block; }
        #checkout #content .checkout-process .delivery-options-list .delivery-options .delivery-option {
          display: grid;
          grid-template-columns: 30px calc(100% - 30px);
          align-items: start; }
          #checkout #content .checkout-process .delivery-options-list .delivery-options .delivery-option input[type="radio"] {
            margin-top: 5px; }
          #checkout #content .checkout-process .delivery-options-list .delivery-options .delivery-option img {
            margin-bottom: 3px;
            max-width: 30px;
            margin-left: 10px; }
      #checkout #content .checkout-process .delivery-options-list .mensaje-aviso {
        padding: 16px;
        margin-bottom: 16px;
        background-color: #f1f1f1; }
        #checkout #content .checkout-process .delivery-options-list .mensaje-aviso a {
          color: black;
          text-decoration: underline;
          font-weight: normal; }
        #checkout #content .checkout-process .delivery-options-list .mensaje-aviso .entrega-tiempos-produccion {
          margin-top: 16px;
          font-weight: bold; }
          #checkout #content .checkout-process .delivery-options-list .mensaje-aviso .entrega-tiempos-produccion span {
            color: #F28E00; }
      #checkout #content .checkout-process .payment-options #payment-option-1-additional-information section p {
        background-color: #f1f1f1;
        display: block;
        padding: 30px;
        margin: 16px 0px;
        outline: 1px dashed #cccccc;
        outline-offset: -10px; }
      #checkout #content .checkout-process #bankwire-modal {
        display: none;
        width: 1px;
        height: 1px; }
      #checkout #content .checkout-process .continue {
        background-color: #5abe20;
        color: white;
        border: 2px solid #5abe20;
        border-radius: 0;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        width: 120px;
        margin: 10px; }
        #checkout #content .checkout-process .continue:hover {
          background-color: white;
          color: #5abe20; }
        @media (max-width: 1024px) {
          #checkout #content .checkout-process .continue {
            width: 100%;
            margin: 20px auto; } }
    #checkout #content #js-checkout-summary {
      background-color: white;
      border: 1px solid #dadada; }
      #checkout #content #js-checkout-summary .aeuc_scart {
        padding: 10px 16px;
        margin-bottom: 0; }
      #checkout #content #js-checkout-summary header h1 {
        font-family: "Fredoka One", cursive;
        font-weight: 400;
        background-color: #dddddd;
        color: black;
        text-transform: uppercase;
        font-size: 14px;
        padding: 8px 16px;
        margin-bottom: 0; }
      #checkout #content #js-checkout-summary header p {
        display: flex;
        justify-content: space-between;
        padding: 4px 16px;
        font-family: "Open Sans", sans-serif;
        font-weight: 700;
        font-size: 18px;
        margin-bottom: 0; }
      #checkout #content #js-checkout-summary .products {
        border-top: 1px "solid" #cccccc;
        padding-top: 10px;
        margin-top: 10px; }
      #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-viewdetails {
        font-family: "Open Sans", sans-serif;
        font-weight: 400;
        font-size: 13px;
        color: #F28E00;
        padding: 5px 16px;
        cursor: pointer; }
        #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-viewdetails::before {
          margin-right: 5px;
          font-size: 10px; }
      #checkout #content #js-checkout-summary #cart-summary-product-list .show-details {
        animation-name: showdetails;
        animation-duration: 1s;
        animation-fill-mode: forwards; }

@keyframes showdetails {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }
      #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul {
        padding-left: 0;
        display: none;
        height: 0;
        opacity: 0; }
        #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li {
          list-style: none;
          font-size: 14px;
          display: grid;
          grid-template-columns: 40px auto 70px;
          grid-gap: 6px;
          margin-bottom: 0px;
          border-bottom: 1px solid #ddd; }
          #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li .product-quantity {
            border-bottom: 0px; }
          #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li span {
            padding: 5px 10px; }
            #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li span:first-of-type {
              text-align: center; }
            #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li span:last-of-type {
              text-align: right; }
          #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li.cart-summary-titles {
            background-color: #dddddd;
            color: black;
            text-align: center;
            font-size: 12px; }
            #checkout #content #js-checkout-summary #cart-summary-product-list #cart-summary-ul li.cart-summary-titles span:last-of-type {
              text-align: center; }
      #checkout #content #js-checkout-summary .checkout-continuar-comprando {
        position: relative;
        font-size: 16px;
        text-align: center;
        width: 210px;
        margin-top: 16px;
        margin-left: auto;
        margin-right: auto; }
        #checkout #content #js-checkout-summary .checkout-continuar-comprando i {
          font-size: 24px;
          position: absolute;
          left: 0;
          top: 5px;
          color: #777777;
          display: block; }
        #checkout #content #js-checkout-summary .checkout-continuar-comprando a {
          display: block;
          font-size: 14px; }
      #checkout #content #js-checkout-summary .cart-voucher {
        margin-top: 10px;
        padding: 0px 16px 5px;
        border-bottom: 1px solid #ccc;
        /*#added_vouchers + .vouchers-title + #vouchers-form{
                    height: 100%;
                    opacity: 1;
                }*/ }
        #checkout #content #js-checkout-summary .cart-voucher p {
          font-size: 13px;
          margin-top: 10px; }
        #checkout #content #js-checkout-summary .cart-voucher #vouchers-form {
          height: 0;
          opacity: 0;
          display: none; }
        #checkout #content #js-checkout-summary .cart-voucher .show-vouchers {
          animation-name: showvouchers;
          animation-duration: 1s;
          animation-fill-mode: forwards; }

@keyframes showvouchers {
  from {
    height: 0;
    opacity: 0; }
  to {
    height: 100%;
    opacity: 1; } }
        #checkout #content #js-checkout-summary .cart-voucher .added-vouchers-title, #checkout #content #js-checkout-summary .cart-voucher .vouchers-title {
          font-family: "Open Sans", sans-serif;
          font-weight: 500;
          font-size: 16px;
          color: #F28E00;
          text-decoration: underline; }
        #checkout #content #js-checkout-summary .cart-voucher .vouchers-title {
          margin-bottom: 10px;
          text-align: center;
          cursor: pointer; }
        #checkout #content #js-checkout-summary .cart-voucher #added_vouchers {
          list-style: none;
          font-size: 14px;
          padding: 5px; }
          #checkout #content #js-checkout-summary .cart-voucher #added_vouchers li {
            display: flex;
            justify-content: space-between; }
            #checkout #content #js-checkout-summary .cart-voucher #added_vouchers li > * {
              display: table-cell; }
        #checkout #content #js-checkout-summary .cart-voucher form ul {
          padding-left: 0; }
          #checkout #content #js-checkout-summary .cart-voucher form ul li {
            list-style: none;
            font-size: 13px;
            text-align: center;
            padding: 5px;
            margin: 10px auto;
            background-color: #e0e0e0;
            transition: background-color 500ms; }
            #checkout #content #js-checkout-summary .cart-voucher form ul li:hover {
              background-color: #fafafa; }
              #checkout #content #js-checkout-summary .cart-voucher form ul li:hover .label .code {
                font-size: 20px; }
            #checkout #content #js-checkout-summary .cart-voucher form ul li .label {
              font-size: 15px; }
              #checkout #content #js-checkout-summary .cart-voucher form ul li .label .code {
                display: block;
                font-family: "Open Sans", sans-serif;
                font-weight: 700;
                font-size: 17px;
                cursor: pointer;
                transition: font-size 500ms; }
            #checkout #content #js-checkout-summary .cart-voucher form ul li p {
              display: inline-block;
              width: 49%; }
            #checkout #content #js-checkout-summary .cart-voucher form ul li a {
              display: inline-block;
              float: right;
              padding: 0 14px;
              font-size: 14px; }
        #checkout #content #js-checkout-summary .cart-voucher input[name="discount_name"] {
          width: 160px;
          text-align: center;
          font-size: 13px;
          border: 1px solid #cccccc;
          padding: 3px; }
        #checkout #content #js-checkout-summary .cart-voucher button {
          background-color: #F28E00;
          color: white;
          border: 2px solid #F28E00;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer;
          padding: 3px 27px 2px;
          float: right;
          font-size: 14px; }
          #checkout #content #js-checkout-summary .cart-voucher button:hover {
            background-color: white;
            color: #F28E00; }
        #checkout #content #js-checkout-summary .cart-voucher .djv-icon-question {
          font-size: 12px;
          color: #83A7CB;
          margin-top: 16px;
          display: block; }
          #checkout #content #js-checkout-summary .cart-voucher .djv-icon-question::before {
            margin-right: 5px; }
        #checkout #content #js-checkout-summary .cart-voucher .notification {
          padding: 5px 0px;
          display: none; }
          #checkout #content #js-checkout-summary .cart-voucher .notification .js-error-text {
            color: #D53128;
            font-size: 14px;
            margin: 0; }
      #checkout #content #js-checkout-summary .cart-summary-totals #cart-summary {
        padding: 0px 0px; }
        #checkout #content #js-checkout-summary .cart-summary-totals #cart-summary .products {
          padding: 6px 16px;
          display: flex;
          justify-content: space-between;
          margin-top: 0px; }
        #checkout #content #js-checkout-summary .cart-summary-totals #cart-summary .discount {
          padding: 6px 16px;
          display: flex;
          justify-content: space-between; }
        #checkout #content #js-checkout-summary .cart-summary-totals #cart-summary .shipping {
          padding: 6px 16px;
          display: flex;
          justify-content: space-between; }
        #checkout #content #js-checkout-summary .cart-summary-totals #cart-summary .tax {
          padding: 6px 16px;
          display: flex;
          justify-content: space-between; }
      #checkout #content #js-checkout-summary .cart-summary-totals .cart-summary-totals {
        display: flex;
        justify-content: space-between;
        padding: 6px 16px;
        border-top: 1px solid #cccccc;
        border-bottom: 1px solid #cccccc;
        font-size: 20px;
        font-weight: 800; }
        #checkout #content #js-checkout-summary .cart-summary-totals .cart-summary-totals .label small {
          font-size: 13px;
          color: #777777; }
    #checkout #content #conditions-to-approve {
      margin-top: 20px; }
      #checkout #content #conditions-to-approve ul {
        margin-left: 0;
        padding-left: 0; }
        #checkout #content #conditions-to-approve ul li {
          list-style: none; }
    #checkout #content #payment-confirmation button {
      border: none;
      background-color: #f1f1f1;
      color: #999999;
      padding: 6px;
      margin: 10px; }
      #checkout #content #payment-confirmation button:not([disabled]) {
        background-color: #5abe20;
        color: white;
        border: 2px solid #5abe20;
        border-radius: 0;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        margin: 10px; }
        #checkout #content #payment-confirmation button:not([disabled]):hover {
          background-color: white;
          color: #5abe20; }

.form-errors {
  margin-left: 0;
  padding-left: 0; }
  .form-errors .errorExist {
    background-color: red;
    color: white;
    padding: 16px; }

#order-confirmation #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }
  @media (max-width: 1024px) {
    #order-confirmation #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #order-confirmation #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #order-confirmation #main {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 768px) {
    #order-confirmation #main {
      padding: 10px; } }
  #order-confirmation #main h3 {
    font-size: 20px;
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed #666666;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 400; }
  #order-confirmation #main hr {
    border-top: 3px solid #83A7CB;
    margin: 30px 0; }
  #order-confirmation #main #order-items .djv-order-confirmation-items-table tr {
    border-bottom: 1px solid #83A7CB; }
    @media (max-width: 576px) {
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr {
        border-bottom: 3px solid #83A7CB; } }
    #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td {
      padding: 30px;
      border-right: 1px dotted #cccccc;
      position: relative; }
      @media (max-width: 576px) {
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td {
          display: block;
          padding: 10px;
          border-right: none;
          text-align: center; } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-quantity {
        font-size: 20px; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-quantity {
            display: inline-block;
            width: calc(50% - 10px); } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-price {
        font-size: 20px; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td.djv-order-confirmation-price {
            display: inline-block;
            width: calc(50% - 10px); } }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td:last-of-type {
        border-right: none; }
      #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td img {
        width: 100%; }
        @media (max-width: 576px) {
          #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td img {
            width: auto;
            margin-left: auto;
            margin-right: auto; } }
      @media (max-width: 576px) {
        #order-confirmation #main #order-items .djv-order-confirmation-items-table tr td:nth-of-type(2) {
          border-top: 1px dotted #83A7CB;
          border-bottom: 1px dotted #83A7CB;
          text-align: left; } }
    #order-confirmation #main #order-items .djv-order-confirmation-items-table tr:last-of-type {
      border-bottom: none; }
  #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr {
    border-bottom: 1px dotted #83A7CB; }
    #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr td {
      padding: 16px 30px; }
    #order-confirmation #main #order-items .djv-order-confirmation-resume-table tr:last-of-type {
      border-bottom: none;
      background-color: #F5A533;
      font-size: 16px; }

#my-account .link-list {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  padding-left: 0;
  display: grid;
  grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
  grid-gap: 10px; }
  @media (max-width: 576px) {
    #my-account .link-list {
      display: block; } }
  #my-account .link-list li {
    list-style: none;
    padding: 5px 10px;
    background-color: #26445C;
    transition: all 500ms; }
    @media (max-width: 576px) {
      #my-account .link-list li {
        margin-bottom: 10px; } }
    #my-account .link-list li a {
      color: white; }
    #my-account .link-list li::before {
      /*content: "";
                    width: 30px;
                    margin-right: 5px;
                    padding-right: 5px;
                    border-right: 1px solid $gris2;
                    transition: all 500ms;*/ }
    #my-account .link-list li:hover {
      background-color: #F49F12;
      color: white; }
      #my-account .link-list li:hover::before {
        /*    border-right: 1px solid white;*/ }
    #my-account .link-list li:nth-of-type(4) {
      display: none; }
    #my-account .link-list li:nth-of-type(5) {
      display: none; }
    #my-account .link-list li:last-of-type {
      display: none; }

#history .page-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #history .page-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #history .page-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #history .page-content {
      max-width: 500px;
      min-width: 300px; } }
  #history .page-content h2 {
    font-size: 16px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 20px;
    margin-bottom: 20px; }
  #history .page-content .page-footer {
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px dashed #999; }
    @media (max-width: 1024px) {
      #history .page-content .page-footer {
        max-width: 920px;
        min-width: 750px; } }
    @media (max-width: 768px) {
      #history .page-content .page-footer {
        max-width: 750px;
        min-width: 480px; } }
    @media (max-width: 480px) {
      #history .page-content .page-footer {
        max-width: 500px;
        min-width: 300px; } }
    #history .page-content .page-footer ul {
      margin: 0;
      padding: 0; }
      #history .page-content .page-footer ul li {
        list-style: none;
        display: inline-block; }
        #history .page-content .page-footer ul li a {
          background-color: #0083ff;
          color: white;
          border: 2px solid #0083ff;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer; }
          #history .page-content .page-footer ul li a:hover {
            background-color: white;
            color: #0083ff; }

#history .history-list .history-list-header {
  display: grid;
  grid-template-columns: repeat(3, 12%) 22% repeat(2, 12%) 18%;
  border-bottom: solid 1px black;
  padding-bottom: 10px;
  margin-bottom: 20px; }
  @media (max-width: 576px) {
    #history .history-list .history-list-header {
      display: none; } }
  #history .history-list .history-list-header > div {
    font-family: "Open Sans", sans-serif;
    font-weight: 500;
    font-size: 14px; }

#history .history-list .history-list-body .history-list-body-item {
  display: grid;
  grid-template-columns: repeat(3, 12%) 22% repeat(2, 12%) 18%;
  padding: 20px 0;
  border-bottom: 1px solid #dddddd; }
  @media (max-width: 576px) {
    #history .history-list .history-list-body .history-list-body-item {
      display: block; } }
  #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col {
    align-content: center;
    font-size: 12px; }
    @media (max-width: 768px) {
      #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col {
        margin-bottom: 10px;
        text-align: left; } }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .etiqueta {
      display: none; }
      @media (max-width: 768px) {
        #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .etiqueta {
          display: inline-block;
          font-weight: 600;
          margin-right: 10px; } }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col [data-link-action="view-order-details"] {
      background-color: #0083ff;
      color: white;
      border: 2px solid #0083ff;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      display: inline-block;
      margin-bottom: 8px;
      font-size: 11px; }
      #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col [data-link-action="view-order-details"]:hover {
        background-color: white;
        color: #0083ff; }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-reorder-link {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      display: inline-block;
      font-size: 11px; }
      #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-reorder-link:hover {
        background-color: white;
        color: #5abe20; }
    #history .history-list .history-list-body .history-list-body-item .history-list-body-item-col .order-status-label {
      font-size: 11px;
      white-space: nowrap;
      color: white;
      padding: 5px;
      display: inline-block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      text-align: center; }

@media (max-width: 768px) {
  #order-detail {
    font-size: 12px; } }

#order-detail #main {
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  margin-top: 40px; }
  @media (max-width: 1024px) {
    #order-detail #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #order-detail #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #order-detail #main {
      max-width: 500px;
      min-width: 300px; } }

#order-detail #order-infos {
  background-color: #f1f1f1;
  display: block;
  padding: 30px;
  margin: 16px 0px;
  outline: 1px dashed #cccccc;
  outline-offset: -10px;
  color: #444444; }
  #order-detail #order-infos a {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 5px;
    cursor: pointer;
    display: inline-block;
    margin-bottom: 15px;
    text-decoration: none; }
    #order-detail #order-infos a:hover {
      background-color: white;
      color: #5abe20; }

#order-detail #order-history {
  padding: 16px;
  margin-bottom: 16px; }
  @media (max-width: 768px) {
    #order-detail #order-history {
      padding: 16px 6px; } }
  #order-detail #order-history h1 {
    font-size: 20px;
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed #666666;
    margin-bottom: 20px;
    text-transform: uppercase; }
    @media (max-width: 768px) {
      #order-detail #order-history h1 {
        font-size: 16px; } }
  #order-detail #order-history table thead {
    border-bottom: 1px solid #999; }
  #order-detail #order-history table td, #order-detail #order-history table th {
    padding: 8px; }
    @media (max-width: 768px) {
      #order-detail #order-history table td, #order-detail #order-history table th {
        font-size: 12px; } }
    #order-detail #order-history table td .order-status-label, #order-detail #order-history table th .order-status-label {
      padding: 4px 8px;
      font-size: 14px;
      white-space: nowrap;
      color: white; }
      @media (max-width: 768px) {
        #order-detail #order-history table td .order-status-label, #order-detail #order-history table th .order-status-label {
          font-size: 12px; } }

#order-detail #delivery-address, #order-detail #invoice-address {
  width: calc(50% - 20px);
  margin: 8px;
  display: inline-block;
  border: 1px dashed #999;
  padding: 16px; }
  @media (max-width: 576px) {
    #order-detail #delivery-address, #order-detail #invoice-address {
      width: calc(100% - 20px); }
      #order-detail #delivery-address h1, #order-detail #invoice-address h1 {
        font-size: 16px; } }

#order-detail .box ul {
  margin: 10px 0;
  padding: 0; }
  #order-detail .box ul li {
    list-style: none; }

#order-detail #order-products {
  margin: 40px 0; }
  #order-detail #order-products th {
    white-space: nowrap;
    padding: 8px;
    background-color: #dddddd; }
    @media (max-width: 576px) {
      #order-detail #order-products th {
        font-size: 11px;
        white-space: normal; } }
  #order-detail #order-products td {
    padding: 8px; }
    @media (max-width: 576px) {
      #order-detail #order-products td {
        font-size: 11px; } }
  #order-detail #order-products tfoot, #order-detail #order-products .line-total {
    border-top: 2px solid #999999; }

#order-detail #order-shipping {
  display: block;
  width: 100%; }
  #order-detail #order-shipping tr {
    width: 100%; }
    @media (max-width: 576px) {
      #order-detail #order-shipping tr {
        font-size: 11px; } }
    #order-detail #order-shipping tr td {
      padding: 8px; }
      @media (max-width: 576px) {
        #order-detail #order-shipping tr td {
          font-size: 11px; } }
    #order-detail #order-shipping tr th {
      white-space: nowrap;
      padding: 8px;
      background-color: #dddddd; }
      @media (max-width: 576px) {
        #order-detail #order-shipping tr th {
          white-space: normal; } }

#order-detail .order-message-form {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #777777; }
  @media (max-width: 768px) {
    #order-detail .order-message-form {
      padding: 10px; }
      #order-detail .order-message-form h1 {
        font-size: 16px; } }
  #order-detail .order-message-form .form-fields label {
    display: block;
    margin-bottom: 15px; }
    #order-detail .order-message-form .form-fields label span {
      margin-right: 10px; }
    @media (max-width: 768px) {
      #order-detail .order-message-form .form-fields label textarea, #order-detail .order-message-form .form-fields label select {
        width: 100%; } }
  #order-detail .order-message-form .form-footer button {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 5px;
    cursor: pointer; }
    #order-detail .order-message-form .form-footer button:hover {
      background-color: white;
      color: #5abe20; }

#addresses #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #addresses #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #addresses #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #addresses #main {
      max-width: 500px;
      min-width: 300px; } }
  #addresses #main .page-addresses {
    display: grid;
    grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
    grid-gap: 16px; }
    @media (max-width: 1024px) {
      #addresses #main .page-addresses {
        display: block; } }
    #addresses #main .page-addresses article {
      border: 1px dashed #dadada;
      padding: 16px;
      position: relative; }
      @media (max-width: 1024px) {
        #addresses #main .page-addresses article {
          padding: 8px; } }
      #addresses #main .page-addresses article .action-list {
        padding-left: 0;
        margin-bottom: 0; }
        #addresses #main .page-addresses article .action-list li {
          display: inline-block; }
          @media (max-width: 1024px) {
            #addresses #main .page-addresses article .action-list li {
              width: 49%; } }
          #addresses #main .page-addresses article .action-list li a {
            display: block;
            padding: 3px;
            text-align: center;
            color: white;
            font-size: 14px;
            width: 100%; }
            #addresses #main .page-addresses article .action-list li a[data-link-action="edit-address"] {
              background-color: #0083ff;
              color: white;
              border: 2px solid #0083ff;
              border-radius: 0;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 5px 5px;
              cursor: pointer; }
              #addresses #main .page-addresses article .action-list li a[data-link-action="edit-address"]:hover {
                background-color: white;
                color: #0083ff; }
            #addresses #main .page-addresses article .action-list li a[data-link-action="delete-address"] {
              background-color: #D53128;
              color: white;
              border: 2px solid #D53128;
              border-radius: 0;
              transition: all 500ms;
              text-align: center;
              text-transform: uppercase;
              padding: 5px 5px;
              cursor: pointer; }
              #addresses #main .page-addresses article .action-list li a[data-link-action="delete-address"]:hover {
                background-color: white;
                color: #D53128; }
  #addresses #main .page-footer {
    border-top: 1px dashed #cccccc;
    padding-top: 20px;
    margin-top: 20px; }
    @media (max-width: 1024px) {
      #addresses #main .page-footer {
        border: none;
        padding: 0 10px; } }
    #addresses #main .page-footer ul {
      padding: 0; }
      #addresses #main .page-footer ul li {
        display: inline-block;
        list-style: none; }
        @media (max-width: 1024px) {
          #addresses #main .page-footer ul li {
            display: block;
            margin-bottom: 10px; } }
        #addresses #main .page-footer ul li a {
          background-color: #0083ff;
          color: white;
          border: 2px solid #0083ff;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer;
          padding-left: 16px;
          padding-right: 16px; }
          #addresses #main .page-footer ul li a:hover {
            background-color: white;
            color: #0083ff; }
          @media (max-width: 1024px) {
            #addresses #main .page-footer ul li a {
              width: 100%;
              position: relative;
              display: block; } }

#address #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #address #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #address #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #address #main {
      max-width: 500px;
      min-width: 300px; } }
  @media (max-width: 1024px) {
    #address #main .form-fields {
      display: block; } }
  #address #main .form-fields label {
    display: block; }
    @media (max-width: 1024px) {
      #address #main .form-fields label {
        width: 100%;
        display: block;
        margin-bottom: 20px; } }
    #address #main .form-fields label span {
      display: inline-block;
      width: 25%; }
      @media (max-width: 768px) {
        #address #main .form-fields label span {
          display: block;
          width: 100%; } }
    #address #main .form-fields label input, #address #main .form-fields label select {
      border: none;
      background-color: #eee;
      padding: 5px;
      display: inline-block;
      width: 70%; }
      @media (max-width: 768px) {
        #address #main .form-fields label input, #address #main .form-fields label select {
          display: block;
          width: 100%; } }
    #address #main .form-fields label input[name="esempresa"] {
      width: 25px; }
      @media (max-width: 768px) {
        #address #main .form-fields label input[name="esempresa"] {
          display: block;
          width: 100%; } }
  #address #main .form-fields::after {
    content: "Los campos marcados con un asterisco son obligatorios"; }
  #address #main .form-footer {
    border-top: 1px solid #cccccc;
    padding-top: 20px;
    margin-top: 20px; }
    #address #main .form-footer button {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer; }
      #address #main .form-footer button:hover {
        background-color: white;
        color: #5abe20; }
  #address #main .page-footer {
    border-top: 1px solid #cccccc;
    padding-top: 20px;
    margin-top: 20px; }
    #address #main .page-footer ul {
      margin: 0;
      padding: 0; }
      #address #main .page-footer ul li {
        list-style: none;
        display: inline-block; }
        #address #main .page-footer ul li a {
          background-color: #0083ff;
          color: white;
          border: 2px solid #0083ff;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer; }
          #address #main .page-footer ul li a:hover {
            background-color: white;
            color: #0083ff; }

#identity #main {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-family: "Open Sans", sans-serif;
  font-weight: 400; }
  @media (max-width: 1024px) {
    #identity #main {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #identity #main {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #identity #main {
      max-width: 500px;
      min-width: 300px; } }
  #identity #main h3 {
    font-size: 20px;
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed #666666;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 400; }
  #identity #main .form-fields {
    width: auto;
    display: grid;
    grid-template-areas: 'item1 item1 item1' 'item2 item3 item4' 'item5 item6 empty1' 'item7 empty2 empty2' 'item8 item9 item10' 'item11 item11 item11';
    grid-template-columns: 33% 33% 33%;
    grid-gap: 20px; }
    @media (max-width: 1024px) {
      #identity #main .form-fields {
        display: block; } }
    @media (max-width: 1024px) {
      #identity #main .form-fields label {
        display: block;
        width: 100%;
        margin-bottom: 15px; } }
    #identity #main .form-fields label.required span::before {
      content: "*";
      color: #D53128;
      position: relative;
      padding-right: 5px; }
    #identity #main .form-fields label span {
      display: block; }
    #identity #main .form-fields label input:not([type="radio"]), #identity #main .form-fields label input:not([type="checkbox"]), #identity #main .form-fields label select {
      border: none;
      background-color: #eee;
      padding: 5px;
      width: 100%; }
    #identity #main .form-fields label input[type="radio"], #identity #main .form-fields label input[type="checkbox"] {
      display: inline-block;
      margin: 5px;
      padding: 5px; }
    #identity #main .form-fields::after {
      grid-area: item11;
      content: "Los campos marcados con un asterisco son obligatorios";
      padding-bottom: 20px; }
    #identity #main .form-fields > label:nth-of-type(1) {
      grid-area: item1; }
      #identity #main .form-fields > label:nth-of-type(1) label {
        text-align: center;
        margin-right: 10px; }
        @media (max-width: 1024px) {
          #identity #main .form-fields > label:nth-of-type(1) label {
            display: inline-block;
            width: 25%; } }
        #identity #main .form-fields > label:nth-of-type(1) label input {
          display: inline-block;
          width: auto;
          padding-right: 5px; }
    #identity #main .form-fields > label:nth-of-type(2) {
      grid-area: item2; }
    #identity #main .form-fields > label:nth-of-type(3) {
      grid-area: item3; }
    #identity #main .form-fields > label:nth-of-type(4) {
      grid-area: item4; }
    #identity #main .form-fields > label:nth-of-type(5) {
      grid-area: item5; }
    #identity #main .form-fields > label:nth-of-type(6) {
      grid-area: item6; }
    #identity #main .form-fields > label:nth-of-type(7) {
      grid-area: item7; }
    #identity #main .form-fields > label:nth-of-type(8) {
      grid-area: item8;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: flex-start;
      grid-gap: 5px; }
      @media (max-width: 1024px) {
        #identity #main .form-fields > label:nth-of-type(8) {
          grid-template-columns: 40px auto; } }
      #identity #main .form-fields > label:nth-of-type(8) span {
        display: inline-block; }
    #identity #main .form-fields > label:nth-of-type(9) {
      grid-area: item9;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: flex-start;
      grid-gap: 5px; }
      @media (max-width: 1024px) {
        #identity #main .form-fields > label:nth-of-type(9) {
          grid-template-columns: 40px auto; } }
      #identity #main .form-fields > label:nth-of-type(9) span {
        display: inline-block; }
    #identity #main .form-fields > label:nth-of-type(10) {
      grid-area: item10;
      display: grid;
      grid-template-columns: 20px auto;
      align-items: flex-start;
      grid-gap: 5px; }
      @media (max-width: 1024px) {
        #identity #main .form-fields > label:nth-of-type(10) {
          grid-template-columns: 40px auto; } }
      #identity #main .form-fields > label:nth-of-type(10) span {
        display: inline-block; }
  #identity #main .form-footer [data-link-action="save-customer"] {
    background-color: #5abe20;
    color: white;
    border: 2px solid #5abe20;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 5px;
    cursor: pointer; }
    #identity #main .form-footer [data-link-action="save-customer"]:hover {
      background-color: white;
      color: #5abe20; }
    @media (max-width: 1024px) {
      #identity #main .form-footer [data-link-action="save-customer"] {
        width: 100%;
        display: block;
        margin-top: 15px; } }
  #identity #main .page-footer {
    border-top: 1px solid #cccccc;
    padding-top: 20px;
    margin-top: 20px; }
    #identity #main .page-footer ul {
      margin: 0;
      padding: 0; }
      #identity #main .page-footer ul li {
        background-color: #0083ff;
        color: white;
        border: 2px solid #0083ff;
        border-radius: 0;
        transition: all 500ms;
        text-align: center;
        text-transform: uppercase;
        padding: 5px 5px;
        cursor: pointer;
        display: inline-block;
        margin-right: 10px; }
        #identity #main .page-footer ul li:hover {
          background-color: white;
          color: #0083ff; }
        @media (max-width: 1024px) {
          #identity #main .page-footer ul li {
            width: 100%;
            display: block;
            margin-bottom: 15px; } }
        #identity #main .page-footer ul li a {
          color: inherit; }
          @media (max-width: 1024px) {
            #identity #main .page-footer ul li a {
              width: 100%;
              display: block; } }

#history .page-content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px;
  font-size: 16px;
  text-align: center; }
  @media (max-width: 1024px) {
    #history .page-content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #history .page-content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #history .page-content {
      max-width: 500px;
      min-width: 300px; } }
  #history .page-content h2 {
    font-size: 16px;
    border-bottom: 1px dashed #cccccc;
    padding-bottom: 20px;
    margin-bottom: 20px; }
  #history .page-content table {
    width: 100%; }
  #history .page-content tr {
    border-bottom: 1px solid #999999; }
  #history .page-content th, #history .page-content td {
    padding: 8px;
    font-family: "Open Sans", sans-serif;
    font-weight: 400; }
    #history .page-content th .order-status-label, #history .page-content td .order-status-label {
      padding: 16px;
      white-space: nowrap;
      color: white; }
    #history .page-content th [data-link-action="view-order-details"], #history .page-content td [data-link-action="view-order-details"] {
      background-color: #0083ff;
      color: white;
      border: 2px solid #0083ff;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      display: block;
      margin-bottom: 8px; }
      #history .page-content th [data-link-action="view-order-details"]:hover, #history .page-content td [data-link-action="view-order-details"]:hover {
        background-color: white;
        color: #0083ff; }
    #history .page-content th .order-reorder-link, #history .page-content td .order-reorder-link {
      background-color: #5abe20;
      color: white;
      border: 2px solid #5abe20;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      display: block; }
      #history .page-content th .order-reorder-link:hover, #history .page-content td .order-reorder-link:hover {
        background-color: white;
        color: #5abe20; }
  #history .page-content .page-footer {
    margin-top: 40px;
    padding-top: 20px;
    border-top: 1px dashed #999; }
    #history .page-content .page-footer ul {
      margin: 0;
      padding: 0; }
      #history .page-content .page-footer ul li {
        list-style: none;
        display: inline-block; }
        #history .page-content .page-footer ul li a {
          background-color: #0083ff;
          color: white;
          border: 2px solid #0083ff;
          border-radius: 0;
          transition: all 500ms;
          text-align: center;
          text-transform: uppercase;
          padding: 5px 5px;
          cursor: pointer; }
          #history .page-content .page-footer ul li a:hover {
            background-color: white;
            color: #0083ff; }

label.required span::after {
  content: "*";
  color: red;
  position: absolute;
  padding-top: 0px;
  margin-left: 10px; }

label.required span span {
  padding-left: 12px;
  padding-top: 10px; }

label.required span input[type="checkbox"] {
  text-align: right; }

#contact #content {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #contact #content {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #contact #content {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #contact #content {
      max-width: 500px;
      min-width: 300px; } }
  #contact #content h1 {
    font-size: 20px;
    padding: 10px 0 15px 5px;
    border-bottom: 1px dashed #666666;
    margin-bottom: 20px;
    text-transform: uppercase;
    font-size: 20px; }
  #contact #content .login-form {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 16px; }
    #contact #content .login-form h2 {
      font-size: 20px;
      font-weight: 600; }
    #contact #content .login-form .form-fields {
      display: grid;
      grid-template-columns: 50%; }
      #contact #content .login-form .form-fields label {
        margin-bottom: 20px; }
        #contact #content .login-form .form-fields label.required::before {
          content: "*";
          color: red;
          position: absolute; }
        #contact #content .login-form .form-fields label.required span {
          padding-left: 9px; }
        #contact #content .login-form .form-fields label span {
          display: block;
          margin-bottom: 5px; }
    #contact #content .login-form button {
      background-color: #F28E00;
      color: white;
      border: 2px solid #F28E00;
      border-radius: 0;
      transition: all 500ms;
      text-align: center;
      text-transform: uppercase;
      padding: 5px 5px;
      cursor: pointer;
      padding-left: 16px;
      padding-right: 16px; }
      #contact #content .login-form button:hover {
        background-color: white;
        color: #F28E00; }
    #contact #content .login-form i.material-icons {
      display: none; }
    #contact #content .login-form .form-footer {
      border-top: 1px dashed #cccccc;
      padding-top: 20px;
      margin-top: 20px; }

#pagenotfound #djv-two-columns-layer {
  margin-right: auto;
  margin-left: auto;
  max-width: 1170px;
  min-width: 920px; }
  @media (max-width: 1024px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 920px;
      min-width: 750px; } }
  @media (max-width: 768px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 750px;
      min-width: 480px; } }
  @media (max-width: 480px) {
    #pagenotfound #djv-two-columns-layer {
      max-width: 500px;
      min-width: 300px; } }
  #pagenotfound #djv-two-columns-layer #search_widget input[type="text"] {
    padding: 6px; }
  #pagenotfound #djv-two-columns-layer #search_widget button {
    background-color: #F28E00;
    color: white;
    border: 2px solid #F28E00;
    border-radius: 0;
    transition: all 500ms;
    text-align: center;
    text-transform: uppercase;
    padding: 5px 5px;
    cursor: pointer; }
    #pagenotfound #djv-two-columns-layer #search_widget button:hover {
      background-color: white;
      color: #F28E00; }
    #pagenotfound #djv-two-columns-layer #search_widget button::after {
      content: "Buscar"; }

.page-not-found #search_widget .djv-icon-lupa {
  display: none; }

.page-not-found #search_widget input[type="text"] {
  padding: 5px 3px 6px 3px;
  height: 33px; }

.page-not-found #search_widget button {
  background-color: #F28E00;
  color: white;
  border: 2px solid #F28E00;
  border-radius: 0;
  transition: all 500ms;
  text-align: center;
  text-transform: uppercase;
  padding: 5px 5px;
  cursor: pointer; }
  .page-not-found #search_widget button:hover {
    background-color: white;
    color: #F28E00; }
  .page-not-found #search_widget button::after {
    content: "Buscar"; }

#search h2 {
  font-size: 20px;
  padding: 10px 0 15px 5px;
  border-bottom: 1px dashed #666666;
  margin-bottom: 20px;
  text-transform: uppercase; }

#search .page-not-found > p {
  font-size: 20px;
  padding: 10px 0 15px 5px;
  border-bottom: 1px dashed #666666;
  margin-bottom: 20px;
  text-transform: uppercase; }

#search .page-not-found #search_widget {
  width: 100%;
  display: block;
  margin: 5px auto;
  position: relative; }
  #search .page-not-found #search_widget .ui-helper-hidden-accessible {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px; }
  #search .page-not-found #search_widget input {
    background-color: white;
    width: 100%;
    max-width: 300px;
    display: block;
    position: relative;
    border: 1px solid #999999;
    border-radius: 3px;
    padding: 3px 20px 3px 5px;
    color: #26445C;
    font-family: "Open Sans", sans-serif;
    font-size: 12px; }
    #search .page-not-found #search_widget input::placeholder {
      font-size: 12px;
      color: #666666;
      font-family: "Open Sans", sans-serif;
      text-align: center; }
  #search .page-not-found #search_widget button {
    position: relative;
    top: -25px;
    left: 265px;
    border: none;
    background-color: transparent;
    width: 30px;
    height: 30px;
    cursor: pointer; }
    #search .page-not-found #search_widget button::before {
      display: inline-block;
      font-family: "comunes";
      font-style: normal;
      font-weight: normal;
      line-height: 1;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      content: '\0045';
      font-size: 16px;
      right: 0px;
      top: 0px;
      position: absolute;
      color: #cccccc; }

.ui-widget {
  font-size: 14px; }

ul.link-list > a#identity-link {
  display: inline-block;
  list-style: none;
  padding: 5px 10px;
  background-color: #f6f6f6;
  border: 1px solid #E2E2E2;
  transition: all 500ms;
  color: black; }
  ul.link-list > a#identity-link .link-item i {
    display: none; }

.pendiente {
  border: 1px solid red; }

.djv_debug {
  position: fixed;
  opacity: 0.1;
  top: 80px;
  left: 10px;
  background-color: black;
  border: 4px solid black;
  z-index: 1000;
  padding: 20px;
  width: 110px;
  height: 90px;
  transition: all 1s;
  overflow: hidden; }
  .djv_debug::before {
    content: "DEBUG";
    color: red;
    font-weight: bold;
    display: block; }
  .djv_debug:hover {
    width: 55vw;
    opacity: .9;
    height: calc(100vh - 300px);
    background-color: white;
    overflow: auto; }

.page-content.page-my-account, .page-content.page-address, .page-customer-account .page-content {
  padding: 5px 10px; }

#myFrame {
  border: 4px solid #F28E00;
  box-shadow: 1px 1px 10px #333;
  border-radius: 3px; }

#module-djvpersonalizeproduct-cart #chat-application,
#module-djvpersonalizeproduct-cartv4 #chat-application {
  display: none !important; }

#module-djvpersonalizeproduct-cart .zopim,
#module-djvpersonalizeproduct-cartv4 .zopim {
  display: none !important; }

#module-djvpersonalizeproduct-cart .djv-product-personalize-right-tools,
#module-djvpersonalizeproduct-cartv4 .djv-product-personalize-right-tools {
  height: auto !important; }

#module-djvpersonalizeproduct-cart #footer,
#module-djvpersonalizeproduct-cartv4 #footer {
  display: none; }

#module-faqs-display .m-link-2 {
  display: none !important; }

#mpm_faqs_form_container #mpm_faqs_form_content .input-group-addon .input-group-addon-inner {
  display: block;
  height: 40px; }

#mpm_faqs_form_container #mpm_faqs_form_content .form-control {
  border: 0 !important; }

#mpm_faqs_form_container input[type="text"] {
  padding-left: .75rem !important; }

#mpm_faqs_form_container #mpm_faqs_select_wrapper .selected {
  padding: 9px 15px 11px 12px; }

#mpm_faqs_form_container .input-group-addon {
  border-radius: 5px;
  padding-left: 5px; }
  #mpm_faqs_form_container .input-group-addon i {
    margin-top: 15px; }

#mpm_faqs_form_container .input-group-addon .input-group-addon-inner {
  border-right: 1px solid;
  padding-right: 7px;
  padding-top: 9px;
  padding-left: 10px; }

#notifications {
  margin-top: 45px;
  margin-bottom: 5px;
  font-size: 14px; }
  #notifications ul {
    margin-left: 0;
    padding-left: 0; }
  #notifications li {
    list-style: none; }

.notification {
  padding: 15px; }

.notification-danger {
  background: #fbe9e8;
  border: 2px solid #D53128; }

.notification-warning {
  background: #fcf8e3;
  border: 2px solid #ff7926; }

.notification-success {
  background: #dff0d8;
  border: 2px solid #5abe20; }

.notification-info {
  background: #d9edf7;
  border: 2px solid #0083ff; }

.expandText .content {
  position: relative;
  overflow: hidden;
  transition: all 600ms; }

.expandText .expand-handler, .expandText .contract-handler {
  text-align: center;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #EB5512;
  padding: 10px 0;
  margin-bottom: 0px;
  cursor: pointer; }
  .expandText .expand-handler::before, .expandText .contract-handler::before {
    margin-right: 6px;
    font-size: 14px; }

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  background-color: rgba(0, 0, 0, 0.5);
  display: block !important;
  overflow: hidden;
  outline: 0; }
  @media (max-width: 768px) {
    .modal {
      overflow-y: scroll; } }
  .modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto; }
  .modal .modal-dialog {
    position: relative;
    background-color: white;
    margin: 50px auto;
    min-height: 50vh;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    width: 100%;
    max-width: 700px;
    pointer-events: all;
    padding: 10px; }
    @media (max-width: 768px) {
      .modal .modal-dialog {
        max-width: 100vw !important;
        margin-top: 0; } }
    .modal .modal-dialog iframe {
      width: 100%;
      height: 50vh; }
    .modal .modal-dialog .modal-content {
      border-radius: 0; }
      @media (max-width: 768px) {
        .modal .modal-dialog .modal-content {
          display: block; } }
      .modal .modal-dialog .modal-content .modal-header {
        background-color: #26445C;
        padding: 16px;
        position: relative;
        border-top-left-radius: 0;
        border-top-right-radius: 0; }
        .modal .modal-dialog .modal-content .modal-header .close {
          position: absolute;
          right: 16px;
          top: 16px;
          float: none;
          font-size: 48px;
          font-weight: 700;
          line-height: 0;
          color: #000;
          text-shadow: 0 1px 0 #fff;
          opacity: .5;
          background-color: white;
          height: 100%; }
        .modal .modal-dialog .modal-content .modal-header .modal-title {
          color: white;
          font-size: 16px;
          max-width: 80%; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-header .modal-title {
              font-size: 12px; } }
          .modal .modal-dialog .modal-content .modal-header .modal-title i {
            margin-right: 16px; }
            @media (max-width: 768px) {
              .modal .modal-dialog .modal-content .modal-header .modal-title i {
                display: none; } }
      .modal .modal-dialog .modal-content .modal-body {
        padding: 15px;
        display: grid;
        grid-template-columns: repeat(2, calc(50% - 10px));
        grid-template-areas: "img data" "content content";
        grid-gap: 10px; }
        @media (max-width: 768px) {
          .modal .modal-dialog .modal-content .modal-body {
            display: block;
            padding: 10px; } }
        .modal .modal-dialog .modal-content .modal-body .modal-body-product-img {
          grid-area: img;
          padding: 10px 10px 0px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .modal-body-product-img {
              border-right: none; } }
          .modal .modal-dialog .modal-content .modal-body .modal-body-product-img img {
            display: block;
            margin: auto;
            width: 70%; }
            @media (max-width: 768px) {
              .modal .modal-dialog .modal-content .modal-body .modal-body-product-img img {
                max-width: 100%;
                margin-bottom: 20px; } }
        .modal .modal-dialog .modal-content .modal-body .modal-body-product-data {
          grid-area: data;
          padding: 10px 10px 0px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .modal-body-product-data {
              padding: 10px; }
              .modal .modal-dialog .modal-content .modal-body .modal-body-product-data p {
                margin-bottom: 5px; } }
          .modal .modal-dialog .modal-content .modal-body .modal-body-product-data .product-name {
            font-size: 20px;
            font-family: "Open Sans", sans-serif;
            font-weight: 600; }
          .modal .modal-dialog .modal-content .modal-body .modal-body-product-data .modal-product-price {
            font-size: 20px; }
        .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content {
          grid-area: content;
          display: grid;
          grid-template-columns: 50% 50%;
          padding: 40px; }
          @media (max-width: 768px) {
            .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content {
              padding: 10px;
              display: block;
              overflow-y: scroll; } }
          .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content .modal-body-cart-content-resume p {
            margin-bottom: 5px; }
          .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content .modal-body-cart-content-btn {
            padding: 10px 30px 10px; }
            @media (max-width: 768px) {
              .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content .modal-body-cart-content-btn {
                padding: 10px; } }
            .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content .modal-body-cart-content-btn i {
              margin-right: 10px; }
            .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content .modal-body-cart-content-btn .btn {
              margin-top: 8px;
              margin-bottom: 8px;
              width: 100%;
              text-align: center; }
              @media (max-width: 768px) {
                .modal .modal-dialog .modal-content .modal-body .modal-body-cart-content .modal-body-cart-content-btn .btn {
                  margin-top: 8px !important;
                  margin-bottom: 8px !important; } }
      .modal .modal-dialog .modal-content .modal-footer {
        border-top: 0; }
  .modal#moreInformationFormModal .modal-dialog {
    width: 480px; }
    .modal#moreInformationFormModal .modal-dialog .modal-content {
      display: block;
      border: 0; }
      .modal#moreInformationFormModal .modal-dialog .modal-content .modal-header .modal-title {
        font-size: 17px; }
      .modal#moreInformationFormModal .modal-dialog .modal-content .modal-body {
        display: block;
        padding: 16px; }
        .modal#moreInformationFormModal .modal-dialog .modal-content .modal-body .product {
          display: flex;
          padding: 0;
          margin: 10px 0 20px; }
          .modal#moreInformationFormModal .modal-dialog .modal-content .modal-body .product h3 {
            font-size: 20px; }

#modal-zoom {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: white;
  z-index: 5000; }
  @media (max-width: 768px) {
    #modal-zoom {
      overflow: hidden; } }
  #modal-zoom #modal-zoom-close {
    margin-bottom: 50px;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 10;
    font-size: 32px; }
    @media (max-width: 768px) {
      #modal-zoom #modal-zoom-close {
        top: 16px;
        z-index: 11;
        font-size: 26px;
        color: white; } }
  #modal-zoom .controls {
    position: absolute;
    left: 50%;
    bottom: 120px;
    z-index: 10;
    transform: translateX(-50%);
    background-color: black;
    padding: 18px 10px 10px;
    display: block;
    border-radius: 6px; }
    @media (max-width: 768px) {
      #modal-zoom .controls {
        bottom: auto;
        top: 0;
        right: auto;
        left: 0;
        transform: none;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 3px;
        width: 100%;
        display: flex;
        justify-content: center; } }
    #modal-zoom .controls > i {
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      color: white;
      margin: 5px 10px 1px;
      cursor: pointer; }
  #modal-zoom #advertencia_modal {
    display: none; }
    @media (max-width: 768px) {
      #modal-zoom #advertencia_modal {
        display: flex;
        justify-content: center;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80vw;
        height: 50vh;
        padding: 16px;
        border-radius: 5px;
        transform: translate(-50%, -50%);
        background-color: rgba(255, 255, 255, 0.8);
        z-index: 10;
        box-shadow: 1px 1px 17px rgba(0, 0, 0, 0.2);
        text-align: center; }
        #modal-zoom #advertencia_modal #advertencia-modal-content {
          display: block;
          width: 90%;
          height: 180px;
          padding-top: 15%; }
          #modal-zoom #advertencia_modal #advertencia-modal-content .djv-icon-tap {
            display: block;
            font-size: 72px; } }
  #modal-zoom #image-inside-modal {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh; }
    #modal-zoom #image-inside-modal #consola {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 1000; }
    @media (max-width: 768px) {
      #modal-zoom #image-inside-modal .product-cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh; } }
    #modal-zoom #image-inside-modal .product-cover img {
      position: absolute;
      transform-origin: center center 0;
      width: auto;
      height: 67vh;
      cursor: move; }
      @media (max-width: 768px) {
        #modal-zoom #image-inside-modal .product-cover img {
          width: 100vw;
          height: auto; } }
    #modal-zoom #image-inside-modal #thumbs-modal {
      position: absolute;
      bottom: 0px;
      width: 100%;
      padding: 10px;
      background-color: #ebebeb;
      border: 1px solid #ddd;
      border-radius: 10px;
      list-style: none;
      padding: 5px;
      display: flex;
      justify-content: center;
      margin-bottom: 0; }
      @media (max-width: 768px) {
        #modal-zoom #image-inside-modal #thumbs-modal {
          width: 100%;
          overflow-x: auto;
          overflow-y: hidden;
          white-space: nowrap;
          -webkit-overflow-scrolling: touch;
          -webkit-scroll-snap-type: mandatory;
          scroll-snap-type: x mandatory;
          -webkit-scroll-snap-points-x: repeat(100%);
          scroll-snap-points-x: repeat(100%); } }
      #modal-zoom #image-inside-modal #thumbs-modal li {
        margin: 0 4px;
        width: 85px;
        height: 85px;
        padding: 0; }
        @media (max-width: 768px) {
          #modal-zoom #image-inside-modal #thumbs-modal li {
            scroll-snap-align: start;
            width: 100%;
            position: relative;
            display: inline-block;
            margin-right: -4px;
            text-align: center; } }
        #modal-zoom #image-inside-modal #thumbs-modal li img {
          background-color: white;
          margin-top: 0;
          transition: all 500ms ease-out;
          cursor: pointer;
          box-shadow: 0 0 0 rgba(10, 10, 10, 0);
          width: 85px;
          height: 85px; }
          #modal-zoom #image-inside-modal #thumbs-modal li img:hover {
            margin-top: -10px;
            box-shadow: 6px 3px 10px rgba(10, 10, 10, 0.85); }

.watchmework {
  font-size: 1.75; }

.test {
  font-size: 1.75; }
