#contact {
    #content {
        @include djv-container();
        h1 {
            font-size: $font-lg;
            padding: 10px 0 15px 5px;
            border-bottom: 1px dashed $gris7;
            margin-bottom: 20px;
            text-transform: uppercase;
            font-size: $font-lg;
        }

        .login-form {
            @include fuente($font-first , $font-regular);
            font-size: $font-md;
            h2 {
                font-size: $font-lg;
                font-weight: 600;
            }

            .form-fields {
                display: grid;
                grid-template-columns: 50%;

                label {
                    &.required{
                            &::before{
                                content: "*";
                                color:red;
                                position: absolute;
                            }
                            span{
                                padding-left: 9px;
                            }
                        }
                    margin-bottom: 20px;
                    span {
                        display: block;
                        margin-bottom: 5px;
                    }
                }
            }

            button {
                @include btn-hover-animated($primario2,white,0,5px,5px)  ;
                padding-left: 16px;
                padding-right: 16px;
            }

            i.material-icons {
                display: none;
            }
            .form-footer{
                border-top: 1px dashed $gris3;
                padding-top: 20px;
                margin-top: 20px;
            }
        }
    }
}
