#index{
    #djv-two-columns-layer{
        header{
            text-align: center;
            h2{
                margin-bottom: 30px;
            }
            p{
                margin-bottom: 30px;
            }
        }
    }
}
