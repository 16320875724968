#checkout{
    background-color: #eee;
    @include fuente($font-first , $font-regular);
    #header{
        position: absolute;
        top:-5px;
        min-height: 36px;
        box-shadow: none;
        .checkout-header-simplified{
            background-color: black;
            div{
                width: 100%;
                max-width: 1200px;
                margin-left: auto;
                margin-right: auto;
                .logo {
                    background-image: url("#{$cdnserver1}/themes/base/assets/img/logo_sticky.svg");
                    width: 99px;
                    height: 30px;
                    display: block;
                    background-repeat: no-repeat;
                    background-size: contain;
                    margin: 5px;
                    background-position: center;
                    @media(max-width: $screen-sm){
                        margin-left: auto;
                        margin-right: auto;
                    }
                }
            }

        }
    }

    #content{
        width: 100%;
        max-width: 1200px;
        margin: 80px auto 30px auto;
        display: grid;
        grid-template-columns: calc(70% - 16px) calc(30% - 16px);
        grid-column-gap: 16px;
        @media(max-width: $screen-sm){
            grid-template-columns:auto;
            padding: 0 10px;
        }
        [data-link-action="show-login-form"]{
            @include btn-hover-animated($complementario4,white,0,5px,5px);
            padding-left: 16px;
            padding-right: 16px;
            margin: 20px 0;
            display: inline-block;
        }
        .checkout-process{
            background-color: white;
            border:1px solid #dadada;
            padding: 22px;
            @media(max-width: $screen-sm){
                margin-bottom: 10px;
            }
            section{
                h1{
                    @include fuente($font-second,$font-regular);
                    font-size: $font-lg;
                    border-bottom: 1px solid #aaa;
                    padding-bottom: 10px;
                    margin: 10px 0;
                    color: #999;
                }
                .content{
                    display: none;
                }
                &.js-current-step {
                    h1{
                        color: black;
                        @include fuente($font-second , $font-bold);
                    }
                    .content {
                        display: block;
                    }
                    &.-complete{
                        h1{
                            color: black;
                        }
                    }
                }
                &.-complete{
                    h1{
                        color:#999;
                        &::after{
                            font-size: $font-sm;
                            color:$primario2;
                            content:"editar";
                            float: right;
                            cursor: pointer;
                            padding-top: 5px;
                        }
                    }
                }
            }
            #checkout-personal-information-step{
                .djv-checkout-blocks{
                    display: grid;
                    grid-template-columns: repeat(2,50%);
                    @media(max-width: $screen-lg){
                        display: block;
                        h4{
                            font-size: 16px;
                            font-weight: bold;
                        }
                    }
                    .djv-checkout-block-login{
                        padding: 10px 20px;
                        border-right: 1px solid $gris7;
                        @media(max-width: $screen-lg){
                            border-right: none;
                            border-bottom: 1px solid $gris7;
                            margin-bottom: 20px;
                            padding: 10px 10px;
                        }
                        #login-form{
                            label{
                                input{
                                    width: 100%;
                                }
                            }
                            .lost_password{
                                float: left;
                                padding-top: 16px;
                                padding-right: 20px;
                                padding-left: 20px;
                                @media(max-width: $screen-lg){
                                    float: none;
                                    text-align: center;
                                    margin-bottom: 0;
                                }
                            }
                        }
                    }
                    .djv-checkout-block-register{
                        padding: 10px 20px;
                        position: relative;
                        @media(max-width: $screen-lg){
                            border-bottom: 1px solid $gris7;
                            margin-bottom: 20px;
                            padding: 10px 10px;
                        }
                        h4{
                            font-size: $font-lg;
                        }
                        .btn-hover-animated-blue{
                            cursor: pointer;
                            color: $primario2;
                            text-decoration: underline;
                            padding: inherit 20px;
                            width: 90%;
                            @media(max-width: $screen-lg){
                                position: relative;
                                top:auto;
                                left: auto;
                                width: 100%;
                                transform: none;
                                margin: 20px auto;
                            }
                        }
                    }
                }
            }
            #djv-checkout-form-register{
                display: none;
                #customer-form{
                    .checkbox-field {
                        position: relative;
                        input[type="checkbox"]{
                            float: left;
                            margin-top: 5px;
                            margin-right: 5px;
                        }
                    }
                }
            }
            #checkout-addresses-step,#checkout-delivery-step,#checkout-payment-step{
                //display: none;
            }
            #delivery_message{
                width: 100%;
                padding: 16px;
                border:1px solid $gris8;
            }
            #customer-form{
                margin: 10px 0;
                .form-fields{
                    >label{
                        display: block;
                        span{
                            display: inline-block;
                            min-width: 255px;
                            max-width: calc(100% - 250px);
                        }
                        input[type="radio"]{
                            margin: 0 10px;
                        }
                        input:not([type="checkbox"]):not([type="radio"]){
                            width:100%;
                            border: none;
                            background-color: #eee;
                            padding: 5px;
                            @media(max-width: $screen-sm){
                                width: 100%;
                            }
                        }
                    }
                    .checkbox-field{

                        @include djv-border-top("dashed");
                        border-color: $gris9;
                        padding-left: 5px;
                        padding-right: 5px;
                        padding-bottom: 10px;
                        >label{
                            display: block;
                            @media(max-width: $screen-lg){
                                overflow: auto;
                            }
                            span{
                                margin-left: 10px;
                                @media(max-width: $screen-lg){
                                    min-width: 180px;
                                    display: block;
                                    margin-bottom: 10px;
                                    float: right;
                                }
                            }
                        }

                    }
                    .checkbox-field ~ .checkbox-field {
                        margin-top: 0px;
                        border-top: 0px dashed #aaa;
                        @include djv-border-bottom("dashed");
                        border-color: $gris9;
                    }
                }
            }
            #login-form{
                label{
                    display: block;
                    span{
                        display: block;
                        margin: 10px 0;
                    }
                    input{
                        border: none;
                        background-color: #eee;
                        padding: 5px;
                        width: 50%;
                        @media(max-width: $screen-sm){
                            width: 100%;
                        }
                    }
                }

            }
            .js-address-form{
                .form-fields{
                    /*display: grid;
                    grid-template-columns: auto auto;
                    grid-gap: 16px;*/
                    @media(max-width: $screen-sm){
                        display: block;
                    }
                    label{
                        display: grid;
                        grid-template-columns: 30% 70%;
                        span{
                            display: inline-block;
                            font-size: $font-sm;
                            color: $gris6;
                            small{
                                display: block;
                                color: $primario2;
                            }
                        }
                        input:not([type="checkbox"]), select{
                            padding: 5px 10px;
                            border:none;
                            background-color: #eee;
                            width: 100%;
                            display: inline-block;
                        }
                        input[name="use_same_address"] + span{
                            display:inline;
                            margin-left: 5px;
                        }
                        @media(max-width: $screen-sm){
                            width: 100%;
                        }
                        input[name="esempresa"]{
                            width: 25px;
                        }
                        input[type="checkbox"]{
                            width: 16px;
                            text-align: right;
                            display: flex;
                            justify-self: right;
                        }
                    }
                }
                .form-footer{
                    @include djv-border-top("dashed");
                    margin: 10px 0;
                    a{
                        @include btn-hover-animated($complementario2,white,0,5px,5px);
                    }
                    button{
                        @include btn-hover-animated($complementario1,white,0,5px,5px);
                    }

                }
            }
            #delivery-addresses, #invoice-addresses{
                display: grid;
                grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
                grid-gap: 16px;
                margin-bottom: 30px;
                @media(max-width: $screen-sm){
                    grid-template-columns:auto;
                }
                .address-item{
                    border: 1px dashed #dadada;
                    padding: 8px;
                    position: relative;
                    &:last-of-type{
                        border-bottom: 1px dashed #dadada;
                    }
                    &.selected{
                        border: 4px solid $primario3;
                    }
                    label{
                        width: 100%;
                        display: block;
                        padding-left: 26px;
                        margin-bottom: 16px;
                        padding-bottom: 40px;
                        input{
                            margin-left: -26px;
                            margin-right: 10px;
                        }
                    }
                    header{
                        font-size: $font-lg;
                        color:$gris6;
                        border-bottom: 1px solid #dadada;
                        padding-bottom: 6px;
                    }
                    footer{
                        position: absolute;
                        display: block;
                        margin-top: 40px;
                        bottom: 10px;
                        width: 100%;
                        display: block;
                        a{
                            display: inline-block;
                            padding: 3px 16px;
                            text-align: center;
                            color:white;
                            font-size: $font-sm;
                        }
                        a[data-link-action="edit-address"]{
                            background-color: $primario6;
                        }
                        a[data-link-action="delete-address"]{
                            background-color: $primario6;
                        }
                    }
                }
            }
            #delivery-addresses + a + h2{
                margin: 30px 0 0 0;
            }
            .delivery-options-list{
                .delivery-options{
                    display: block;
                    .delivery-option{
                        display: grid;
                        grid-template-columns: 30px calc(100% - 30px);
                        align-items: start;
                        input[type="radio"]{
                            margin-top: 5px;
                        }
                        img{
                            margin-bottom: 3px;
                            max-width: 30px;
                            margin-left: 10px;
                        }
                    }
                }
                .mensaje-aviso{
                    padding: 16px;
                    margin-bottom: 16px;
                    background-color: $gris1;
                    a{
                        color: black;
                        text-decoration: underline;
                        font-weight: normal;
                    }
                    .entrega-tiempos-produccion{
                        margin-top: 16px;
                        font-weight: bold;
                        span{
                            color: $primario2;
                        }
                    }
                }
            }
            .payment-options{
                #payment-option-1-additional-information{
                    section{
                        p{
                            background-color: $gris1;
                            display: block;
                            padding: 30px;
                            margin: 16px 0px;
                            outline:1px dashed $gris3;
                            outline-offset:-10px;
                        }
                    }
                }
            }
            #bankwire-modal{
                display: none;
                width: 1px;
                height: 1px;
            }
            .continue{
                @include btn-hover-animated($complementario1,white,0,5px,5px);
                width: 120px;
                margin: 10px;
                @media(max-width: $screen-lg){
                    width: 100%;
                    margin: 20px auto;
                }
            }
        }

        #js-checkout-summary{
            background-color: white;
            border:1px solid #dadada;
            .aeuc_scart{
                padding: 10px 16px;
                margin-bottom: 0;
            }
            header{
                h1{
                    @include fuente($font-second,$font-regular);
                    background-color: #dddddd;
                    color: black;
                    text-transform: uppercase;
                    font-size: 14px;
                    padding: 8px 16px;
                    margin-bottom: 0;
                }
                p{
                    display: flex;
                    justify-content: space-between;
                    padding: 4px 16px;
                    @include fuente($font-first , $font-bold);
                    font-size: $font-slg;
                    margin-bottom: 0;
                    span{

                    }
                }
            }
            .products{
                @include djv-border-top("solid");
            }
            #cart-summary-product-list{
                #cart-summary-viewdetails{
                    @include fuente($font-first , $font-regular);
                    font-size: $font-xs;
                    color: $primario2;
                    padding: 5px 16px;
                    cursor: pointer;
                    &::before{
                        margin-right: 5px;
                        font-size: $font-xxxs;
                    }
                }
                .show-details{
                    animation-name: showdetails;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                }
                @keyframes showdetails{
                    from{
                        height: 0;
                        opacity: 0;
                    }
                    to{
                        height: 100%;
                        opacity: 1;
                    }
                }
                #cart-summary-ul{
                    padding-left: 0;
                    display: none;
                    height: 0;
                    opacity: 0;
                    li{
                        list-style: none;
                        font-size: $font-sm;
                        display: grid;
                        grid-template-columns: 40px auto 70px;
                        grid-gap: 6px;
                        margin-bottom: 0px;
                        border-bottom: 1px solid #ddd;
                        .product-quantity{
                            border-bottom: 0px;
                        }
                        span{
                            padding: 5px 10px;
                            &:first-of-type{
                                text-align: center;
                            }
                            &:last-of-type{
                                text-align: right;
                            }
                        }
                        &.cart-summary-titles{
                            background-color: #dddddd;
                            color: black;
                            text-align: center;
                            font-size: $font-xxs;
                            span{
                                &:last-of-type{
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
            .checkout-continuar-comprando{
                position: relative;
                font-size: $font-md;
                text-align: center;
                width: 210px;
                margin-top: 16px;
                margin-left: auto;
                margin-right: auto;
                i{
                    font-size: $font-blg;
                    position: absolute;
                    left: 0;
                    top: 5px;
                    color: $gris6;
                    display: block;
                }
                a{
                    display: block;
                    font-size: $font-sm;
                }
            }
            .cart-voucher {
                margin-top: 10px;
                padding: 0px 16px 5px;
                border-bottom: 1px solid #ccc;
                p{
                    font-size: $font-xs;
                    margin-top: 10px;
                }
                #vouchers-form{
                    height: 0;
                    opacity: 0;
                    display: none;
                }
                /*#added_vouchers + .vouchers-title + #vouchers-form{
                    height: 100%;
                    opacity: 1;
                }*/
                .show-vouchers{
                    animation-name: showvouchers;
                    animation-duration: 1s;
                    animation-fill-mode: forwards;
                }
                @keyframes showvouchers{
                    from{
                        height: 0;
                        opacity: 0;
                    }
                    to{
                        height: 100%;
                        opacity: 1;
                    }
                }
                .added-vouchers-title,.vouchers-title{
                    @include fuente($font-first , $font-medium);
                    font-size: $font-md;
                    color: $primario2;
                    text-decoration: underline;
                }
                .vouchers-title{
                    margin-bottom: 10px;
                    text-align: center;
                    cursor: pointer;
                }

                #added_vouchers{
                    list-style: none;
                    font-size: $font-sm;
                    padding: 5px;
                    li{
                        display: flex;
                        justify-content: space-between;
                        > *{
                            display: table-cell;
                        }
                    }
                }
                form{
                    ul{
                        padding-left: 0;
                        li{
                            list-style: none;
                            font-size: $font-xs;
                            text-align: center;
                            padding: 5px;
                            margin: 10px auto;
                            background-color:#e0e0e0;
                            transition: background-color 500ms;
                            &:hover{
                                background-color: #fafafa;

                                .label{
                                    .code{
                                        font-size: $font-lg;
                                    }
                                }
                            }
                            .label{
                                font-size: $font-smd;
                                .code{
                                    display: block;
                                    @include fuente($font-first , $font-bold);
                                    font-size: $font-bmd;
                                    cursor: pointer;
                                    transition: font-size 500ms;
                                }
                            }
                            p{
                                display: inline-block;
                                width: 49%;
                            }
                            a{
                                display: inline-block;
                                float: right;
                                padding: 0 14px;
                                font-size: 14px;
                            }
                        }
                    }
                }
                input[name="discount_name"] {
                    width: 160px;
                    text-align: center;
                    font-size: $font-xs;
                    border:1px solid $gris3;
                    padding: 3px;
                }

                button {
                    @include btn-hover-animated($primario2,white,0,5px,5px);
                    padding: 3px 27px 2px;
                    float: right;
                    font-size: 14px;
                }
                .djv-icon-question{
                    font-size: $font-xs - 1px;
                    color: $primario3;
                    margin-top: 16px;
                    display: block;
                    &::before{
                        margin-right: 5px;
                    }
                }
                .notification{
                    padding: 5px 0px;
                    display: none;
                    .js-error-text{
                        color: $complementario2;
                        font-size: $font-sm;
                        margin: 0;
                    }
                }
            }
            .cart-summary-totals{
                #cart-summary{
                    padding: 0px 0px;
                    .products{
                        padding: 6px 16px;
                        display: flex;
                        justify-content: space-between;
                        margin-top: 0px;
                    }
                    .discount{
                        padding: 6px 16px;
                        display: flex;
                        justify-content: space-between;
                    }
                    .shipping{
                        padding: 6px 16px;
                        display: flex;
                        justify-content: space-between;
                    }
                    .tax{
                        padding: 6px 16px;
                        display: flex;
                        justify-content: space-between;
                    }
                }
                .cart-summary-totals{
                    display: flex;
                    justify-content: space-between;
                    padding: 6px 16px;
                    border-top: 1px solid #cccccc;
                    border-bottom: 1px solid #cccccc;
                    font-size: $font-lg;
                    font-weight: 800;
                    .label{
                        small{
                            font-size: $font-xs;
                            color: $gris6;
                        }
                    }
                    .value{}
                }
            }


        }

        #conditions-to-approve{
            margin-top: 20px;
            ul{
                margin-left: 0;
                padding-left: 0;
                li{
                    list-style: none;
                }
            }
        }
        #payment-confirmation{
            button{
                border:none;
                background-color: $gris1;
                color:$gris5;
                padding: 6px;
                margin: 10px;
                &:not([disabled]){
                    @include btn-hover-animated($complementario1,white,0,5px,5px);
                    margin:10px;
                }
            }
        }
    }

}
.form-errors{
    margin-left: 0;
    padding-left: 0;
    .errorExist{
        background-color: red;
        color: white;
        padding: 16px;
    }
}
