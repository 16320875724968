@import "project/_vars";
@import "project/_mixins";
@import "project/_common";
@import "project/_carouselh";
@import "project/djv-icon-font/css/comunes";
@import "project/_header-grid";
body { overscroll-behavior: none }
#module-djvpersonalizeproduct-personalizeproduct,
#module-djvpersonalizeproduct-persv4 {
    overflow-x: auto;

    label{
        margin-bottom: 5px;
    }
    p{
        margin-bottom: 5px;
    }
    .form-group{
        margin-bottom: 5px;
    }
    .progress{
        height: 25px;
    }

    > header{
        height: 40px;
        min-height: 40px;
        position: sticky;
        z-index: 10000;
        @media(max-width: $screen-md){
            position:fixed;
        }
        >div{
            background-color: $gris9;
            a{
                &:hover{
                    color:$primario2;
                }
            }
            .logo{
                height: 40px;
                background-image: url("#{$urlimagenes}logo_sticky.svg");
                background-size: 100px;
                background-repeat: no-repeat;
                background-position: right 7px;
                margin-top: 0;
                display: block;
                padding: 8px 0 0 10px;
                width: 290px;
                text-align: left;
                margin: auto;
            }
        }

    }
    @media(max-width: $screen-md){
        overflow-x: auto;
    }
    #djv-two-columns-layer{
        @media(max-width: $screen-md){
            max-width: 1400px;
            min-width: 1170px;
        }
    }
    #blockPersonalizeScreener{
        position: fixed;
        top:0;
        z-index: 998;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.5);
        .back-to-personalize{
            position: absolute;
            top: 60px;
            left: 0;
            border: none;
            background-color: #ff7926;
            color: white;
            padding: 10px 30px 10px 30px;
            border-top-right-radius: 30px;
            border-bottom-right-radius: 30px;
            cursor: pointer;
        }
    }
}

.personalize-content {
    background: $gris1;
    margin-top: -20px;
    padding-top: 20px;
    padding-bottom: 20px;
    @include fuente($font-first , $font-regular);

    #columns {
        // Estilos Segio nueva maquetación 08/20018
        .recordatorio-equipaciones-deportivas{
            background-color: #F5A533;
            padding: 10px;
            display: block;
            color: white;
            position: relative;
            top: -10px;
            p{
                display: block;
                width: calc(100% - 60px);
                margin-left: auto;
                margin-right: auto;
                text-align: center;
                margin-bottom: 0;
                .icon-bell{
                    width: 30px;
                    display: inline-block;
                    margin-right: 10px;
                    transform: rotateZ(-25deg);
                    top: 0px;
                    position: relative;
                }
            }
        }
        .djv-product-personalize-container{
            max-width: 1170px;
            min-width: 920px;
            margin-left: auto;
            margin-right: auto;
            display: grid;
            grid-template-columns: 65% 35%;
            @include fuente($font-first , $font-regular);
            font-size: $font-sm;
            min-width: 1170px;
            .block-tittle{
                font-size: $font-xxs;
                &::before{
                    font-size: $font-xxxs;
                }
            }
            .djv-product-personalize-left{
                overflow: auto;
                @media(max-width: $screen-md){
                    //max-width: 100vw;
                }
                .div-design-area{
                    display: block;
                    min-height: 640px;
                    #image-block{

                    }
                }
                .djv-product-personalize-areas{
                    display: block;
                    min-height: 138px;
                    @media(max-width: $screen-md){
                        margin-left: auto;
                        margin-right: auto;
                        width: 768px;
                    }
                    .select_view ul{
                      display: flex;
                      flex-flow: row wrap;
                      li{
                        width: 20%;
                      }
                    }
                    .personalizationAreaThumbnail{
                        cursor: pointer;
                        img{
                          max-width: 80px;
                        }
                        &.active{
                            border-color: $primario2;
                        }
                    }
                    strong{
                        margin: 15px;
                        text-align: center;
                        display: block;
                    }
                    i{
                        background: $gris1;
                    }
                    ul{
                        text-align: left;
                        display: flex;
                        flex-flow: row wrap;
                        @media(max-width: $screen-md){
                            position: relative;
                        }
                        > * {
                            display: inline-block !important;
                            margin: 0;
                            padding: 5px;
                            float: left !important;
                            clear: none;
                            width: 20%;
                            text-align: center;
                            font-size: $font-xxs;
                            cursor: pointer;
                            a{
                                display: block;
                                text-align: center;
                                border: 1px solid $gris4;
                                padding: 5px;
                            }
                        }
                    }
                }
            }
            .djv-product-personalize-right{
                padding: 2px;
                z-index: 999;
                .djv-product-personalize-right-tools{
                    background-color: white;
                    width: auto;
                    box-shadow: 1px 1px 5px rgba(0,0,0,.5);
                    margin: auto;
                    padding: 15px 20px;
                    overflow-y: auto;
                    overflow-x: hidden;
                    height: 565px;
                    header{
                        padding-bottom: 10px;
                        margin-bottom: 10px;
                        border-bottom: 1px solid $gris6;
                        @include fuente($font-first , $font-regular);
                        font-size:$font-xxs;
                        height: auto;
                        h1{
                            margin-bottom: 5px;
                            @include fuente($font-first , $font-bold);
                            font-size: $font-lg;
                        }
                        .back-to-personalize{
                            @include btn-hover-animated($complementario1,white,0,5px,5px);
                            display: block;
                            margin-top: 10px;
                            font-size: 12px;
                            cursor: pointer;
                        }
                        #version-cliente{
                            //display: none;
                        }
                    }
                    .djv-product-personalize-box{
                        padding-bottom: 30px;
                        .djv-product-personalize-color-attribute-selection{
                            > div{
                                display: flex;
                                gap: 10px;
                                flex-wrap: wrap;
                                text-align: center;
                                > div{
                                    margin-right: 5px;
                                    label{
                                    display: block;
                                    width: 30px;
                                    height: 30px;
                                    overflow: hidden;
                                    background-color: $complementario2;
                                    color: transparent;
                                        input{
                                            opacity: 0;
                                            margin: 5px auto;
                                            display: block;
                                        }
                                    }
                                }
                            }
                        }
                        .design-panel{
                            width: auto;
                            margin: auto;
                            margin-top: 20px;
                            padding: 0px;
                            font-size: 12px;
                            line-height: 14px;
                            > *{
                                align-items: center;
                                img{
                                    width: 30px;
                                }
                            }
                            .design-panel-toggle-image,
                            .design-panel-toggle-text,
                            .design-panel-toggle-layers{
                                width: 90px;
                                padding: 10px;
                                display: inline-block;
                                border: 1px solid $gris4;
                                border-bottom: 0;
                                border-top-left-radius: 6px;
                                border-top-right-radius: 6px;
                                text-align: center;
                                position: relative;
                                top:1px;
                                cursor:pointer;
                                &.selected{
                                    border-bottom: 1px solid white;
                                }
                                a{
                                    display: block;
                                    p{
                                        margin-bottom: 0;
                                    }
                                }
                            }
                            .design-panel-toggle-text-add{

                            }
                            .form-group{
                                margin-bottom: 5px;
                                small{
                                    display: block;
                                    margin-bottom: 5px;
                                }
                            }
                            .btn-create-text{

                            }
                        }
                        .design-panel-panel-text,
                        .design-panel-panel-image,
                        .design-panel-panel-layers{
                            min-height: 180px;
                            padding: 10px;
                        }
                        #dg-popover{
                            border: 1px solid $gris4;
                            width: 100%;
                            .popover-content{
                                .dg-options-content{
                                    .add_item_text{
                                        display: block;
                                        margin-bottom: 10px;
                                        .btn-create-text{
                                            @include btn-hover-animated($primario2,white,0,5px,5px);
                                            display: block;
                                            margin-top: 13px;
                                            @include fuente($font-first , $font-regular);
                                            font-size: $font-xs;
                                            color: white;
                                            text-transform: uppercase;
                                        }
                                    }
                                    .toolbar-action-text{
                                        .form-group{
                                            margin-bottom: 5px;
                                            height: auto;
                                            .text-update{
                                                font-size: $font-xs;
                                                padding: 5px 10px;
                                            }
                                        }
                                    }
                                    .toolbar-action-fonts{
                                        display: block;
                                        > * .form-group{
                                            margin-bottom: 5px;
                                            height: auto;
                                            small{
                                                display: block;
                                                margin-bottom: 5px;
                                                padding-left: 10px;
                                            }
                                        }
                                        .select-font-family{
                                            display:inline-block;
                                            width: 70%;
                                            .dropdown-letters{
                                                width: 100%;
                                                font-size: $font-sm;
                                            }
                                        }
                                        .select-font-color{
                                            display:inline-block;
                                            top: -30px;
                                            position: relative;
                                            .popover-colores{
                                                margin-top: -24px;
                                                .popover-content{
                                                    padding: 9px 9px;
                                                    small{
                                                        position: relative;
                                                        top: 6px;
                                                    }
                                                    .list-colors{
                                                        left: 40%;
                                                        position: relative;
                                                        top: 5px;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        .images-management-container{
                            border: 1px solid $gris4;
                            margin-bottom: 15px;
                            width: 100%;
                            padding: 10px;
                            font-size: $font-sm;
                            .action-upload{
                                @include btn-hover-animated($primario2,white,0,5px,5px);
                                display: block;
                                width: 100%;
                                margin-top: 13px;
                                @include fuente($font-first , $font-regular);
                                font-size: $font-xs;
                                color: white;
                                text-transform: uppercase;
                            }
                            .images-uploaded-container{
                                #images-uploaded{
                                    display: grid;
                                    grid-template-columns: repeat(4,25%);
                                    .view-thumb{
                                        width: 50px;
                                        height: 80px;
                                        overflow: hidden;
                                        position: relative;
                                        margin-bottom: 10px;
                                        @media(max-width: $screen-md){
                                            height: 95px
                                        }
                                        .img-thumbnail{
                                            max-width: 50px;
                                            max-height: 50px;
                                            margin: 0 auto;
                                            display: block;
                                        }
                                        .progress.progress-bar-container{
                                            width: 100%;
                                            bottom: 0px;
                                            margin-top: 5px;
                                            @media(max-width: $screen-md){
                                                height: 40px
                                            }
                                            .progress-bar{
                                                @media(max-width: $screen-md){
                                                    font-size: $font-xxs;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                        .add_to_cart{
                            .hasterminado{
                                display: block;
                                background-color: white;
                                padding: 5px 10px;
                                text-align: center;
                                border: 1px solid #aaa;
                                border-radius: 10px;
                                margin: 14px;
                                font-size: 12px;
                                color: #888;
                            }
                            .btn-add_to_cart{
                                background-color:#0249f3;
                                width: 100%;
                                display: block;
                                border-radius: 0;
                                margin:10px auto;
                                padding: 10px;
                                a{
                                    color:white;
                                    text-transform: uppercase;
                                    @include fuente($font-first, $font-bold);
                                    font-size:15px;
                                }
                            }
                        }
                        .link_help{
                            display: block;
                            padding:6px 3px;
                            background-color: #ededed;
                            border: 1px solid #cacaca;
                            text-align: center;
                            font-size: 12px;
                            img{
                                display: block;
                                margin-left: auto;
                                margin-right: auto;
                                margin-bottom: 5px;
                            }
                            a{
                                display: block;
                                color: #f09205;
                                text-decoration: underline;
                                margin-top: 5px;
                            }
                        }
                        #layersControl{

                            border: 1px solid #aaaaaa;
                            margin-bottom: 16px;
                            .popover-title{
                                @include fuente($font-first , $font-regular);
                                font-size: $font-xs;
                            }
                            #layers{
                                float: none;
                                padding-left: 0;
                                li.layer {
                                    border: 1px solid #E1E1E1;
                                    border-radius: 4px;
                                    line-height: 22px;
                                    margin: 4px;
                                    padding: 2px 4px;
                                    list-style-type: none;
                                    cursor: pointer;
                                    a{
                                        cursor: pointer;
                                    }
                                    img {
                                        height: 25px;
                                        margin-right: 0px;
                                    }
                                    &.active{
                                        background: url("#{$urlimagenes}bg-layer.png") repeat-x scroll left top rgba(0, 0, 0, 0);
                                    }
                                }
                            }
                        }
                    }
                    .djv-product-personalize-size-attribute-qty-selection,.djv-product-personalize-qty-selection{
                        ul{
                            margin-bottom: 5px;
                        }
                        .product-variants{
                            border-bottom: 0;
                            margin-bottom: 0;
                            .block-tittle{
                                margin: 10px 0px;
                            }
                            .product-variants-tallas{
                                ul{
                                    display: block;
                                    li{
                                        .product-variants-tallas-input-container{
                                            margin-bottom: 0px;
                                        }
                                        span{
                                            display: inline-block;
                                            min-width: 50px;
                                            text-align: center;
                                            margin-right: 10px;
                                        }
                                        label{
                                            text-transform: uppercase;
                                            display: inline-block;
                                            input{
                                                background-color: $gris1;
                                                border: 1px solid $gris2;
                                                border-top-left-radius: 4px;
                                                border-bottom-left-radius: 4px;
                                                padding: 5px;
                                                width: 200px;
                                            }
                                        }
                                        button{
                                            display: inline-block;
                                            background-color: $gris8;
                                            border: none;
                                            color: white;
                                            padding: 7px 11px 6px;
                                            font-family: 'comunes';
                                            position: relative;
                                            top: 2px;
                                            cursor: pointer;
                                            &:first-of-type{
                                                @extend .djv-icon-plus;
                                            }
                                            &:last-of-type{
                                                @extend .djv-icon-minus;
                                                border-top-right-radius: 4px;
                                                border-bottom-right-radius: 4px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .djv-product-personalize-other-attribute-selection{
                        .block-tittle{
                            margin: 10px 0px;
                        }
                        select{
                            width: 80%;
                            font-size: $font-xs;
                        }
                    }
                    .djv-product-personalize-production-time{
                        .block-tittle{
                            margin: 10px 0px;
                        }
                        select{
                            width: 80%;
                        }
                    }
                }
                .djv-product-personalize-addtocart-box{
                    background-color: white;
                    width: auto;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
                    margin: auto;
                    padding: 15px 20px;
                    margin-top: 16px;
                    > *{
                        > *{
                            label{
                                text-transform: uppercase;
                                input{
                                    background-color: $gris1;
                                    border: 1px solid $gris2;
                                    border-top-left-radius: 4px;
                                    border-bottom-left-radius: 4px;
                                    padding: 5px;
                                }
                            }
                        }
                    }
                }
                .djv-product-personalize-addtocart{
                    background-color: $gris9;
                    width: auto;
                    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
                    margin: auto;
                    padding: 15px 20px;
                    margin-top: 16px;
                    .add_to_cart{
                        .total-quantity,
                        .price,
                        .subtotal-price,
                        .production-price,
                        .total-price{
                            color: white;
                        }
                        .total-quantity, .production-price{
                            font-size: $font-xxs;
                            color:$gris5;
                        }

                        .total-price{
                            @include fuente($font-first , $font-medium);
                            font-size: $font-lg;
                        }
                        .note{
                            font-size: $font-xxxs;
                            color:$gris5;
                        }
                        #subtotalPrice, #subtotalPriceWT{
                            font-size: $font-lg;
                            @include fuente($font-first , $font-bold);

                        }
                        .djv-product-update-price-button{
                            @include btn-hover-animated($primario2,white,0,5px,5px);
                            display: block;
                            width: 100%;
                            margin-top: 5px;
                            padding: 16px;
                            border-radius: 0px;
                            @include fuente($font-first , $font-medium);
                            font-size: $font-bmd;
                        }
                        .btn-add_to_cart{
                            @include btn-hover-animated($complementario1,white,0,5px,5px);
                            display: block;
                            width: 100%;
                            margin-top: 5px;
                            padding: 16px;
                            border-radius: 0px;
                            @include fuente($font-first , $font-medium);
                            font-size: $font-bmd;
                        }
                    }
                }
            }

        }
        // Fin Estilos Segio nueva maquetación 08/20018
        > div.row {
            margin-bottom: 120px;
        }

        .no_personalize_mobile {
            padding: 15px;
            line-height: 25px;
        }
    }
}
.personalize-product-description{
    max-width: 1170px;
    min-width: 920px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    @media(max-width: $screen-md){
        padding: 10px 20px;
    }
    h2{
        font-size: $font-lg + 2;
        margin-top: 50px;
        text-align: left;
    }
    img{
        max-width: 100%;
        height: auto;
    }
    .container-tabla-precios{
        @media(max-width: $screen-md){
            width: 50%;
            .tabla-precios{
                margin: 0 0;
            }
        }
    }
}
.totalization-summary{
    .subtotal-price-wt{
        color: white;
    }
}
/* Heredado de styles.css */
#design-area{
    margin-top:0;
    position:relative;
    z-index:1;
    .content-inner{
        height: 100%;
    }
}
#uniform-files-upload .action::before {
  content: 'Selec. fichero';
  display: block;
}

#dg-popover .popover-title .popover-close {
    display: none
}

#dg-popover .popover-title {
    background-color: #007aff;
    border: 0 none;
    border-radius: 3px 0 0 3px;
    left: 4px;
    padding: 4px 6px;
    position: relative;
    top: 2px;
    border-bottom: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    font-size: 12px;
    font-weight: 700;
    color: #fff;
    display: none
}
#dg-popover>div.popover-content {
    display: inline-block;
    height: auto;
    margin: 0;
    min-height: auto;
    padding: 0;
    width: 100%
}
.popover-content {
    display: block;
    padding: 9px 14px
}

.dropdown-color {
    border: 1px solid #CCC;
    box-shadow: 1px 1px 2px 0 #666;
    cursor: pointer;
    float: left;
    height: 22px;
    line-height: 22px;
    margin: 3px;
    padding-left: 2px;
    padding-top: 2px;
    text-align: center;
    width: 22px
}

.list-colors .bg-colors {
    display: inline-block;
    height: 27px;
    margin: 2px;
    width: 25px;
    border: 1px solid #CCC;
    cursor: pointer;
    margin-right: 6px
}

#dg-myclipart {
    top: 200px;
}
.ui-movable-handle::before{
    color: #000;
    font-size: 13px;
    line-height: 100%;
    content: "\f047";
    font-family: FontAwesome;
    transform: rotate(90deg);
    position: relative;
    top: -2px;
}

.ui-rotatable-handle::before{
    color: #000;
    font-size: 13px;
    line-height: 100%;
    content: "\f01e";
    font-family: FontAwesome;
    transform: rotate(90deg);
    position: relative;
    top: -2px;
}


.itemControls-container {
    margin:0;
    padding: 0;
    position: absolute;
    z-index: 999999;
    top:0;
    left: 0;
    overflow: visible;
}

.itemControls-area {
    position: absolute;
    overflow: visible;
    border--: 1px solid red;
}

.itemControls-mask {
    border: 1px solid green;
    width: 0px;
    height: 0px;
    visibility: hidden;
    position: absolute;
    /*padding:2px;*/
}

.itemControls-mask .ui-resizable-handle {
    position: absolute;
    bottom: -22px;
    right: -20px;
    background-color: white;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0;
    border: 1px solid gray;
}

.itemControls-mask .ui-removable-handle {
    position: absolute;
    bottom: -22px;
    left: -20px;
    background-color: white;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0;
    border: 1px solid gray;
}

.itemControls-mask .ui-draggable-handle {
    position: absolute;
    top: -22px;
    right: -20px;
    background-color: white;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0;
    border: 1px solid gray;
}

.itemControls-mask .ui-movable-handle {
    position: absolute;
    top: -22px;
    left: -20px;
    background-color: white;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0;
    border: 1px solid gray;
}

.itemControls-mask .ui-rotatable-handle {
    position: absolute;
    top: -22px;
    right: -20px;
    background-color: white;
    height: 20px;
    display: block;
    width: 20px;
    padding: 0;
    border: 1px solid gray;
}

#module-djvpersonalizeproduct-cart,
#module-djvpersonalizeproduct-cartv4
{
    display: block;
    @media(max-width: $screen-md){
        overflow-y: scroll;
    }
    .recogido #djv-two-columns-layer{
        margin-top: 0 !important;
    }
    .personalize-content #columns .djv-product-personalize-container{
        min-width: auto;
        display: block;
        .djv-product-personalize-right{
            .djv-product-personalize-right-tools{
                .djv-product-personalize-size-attribute-qty-selection{
                    .product-variants .product-variants-tallas ul li label input{
                        @media(max-width: $screen-md){
                            width: 90px;
                        }
                    }

                }
                .djv-product-personalize-qty-selection{
                    .product-variants .product-variants-tallas ul li label input{
                        @media(max-width: $screen-md){
                            width: 90px;
                        }
                    }
                }
            }
        }
    }
    #blockPersonalizeScreener{
        display: none;
    }
    #djv-two-columns-layer{
        margin-top: 10px;
    }
    .block-tittle{
        a{
            display: none;
        }
    }
}
.whatchmework{
    font-size: 1.2;
}
