#product{
    .djv-breadcrumb{
        background-color: #ebebeb;
    }
    .page-content{
        @include fuente($font-first , $font-regular);
        margin: 0px;
        position: relative;
        padding: 0px;
        @media(max-width: $screen-md){
            width:100%;
            padding: 0px;
        }
        .product-first-section{
            background-color: #ebebeb;
            display:grid;
            grid-template-areas:
            "images infoheader"
            "images descriptionshort"
            "images variants"
            "images actions";
            grid-gap: 10px 0px;
            grid-template-columns: 50% 50%;
            padding-left: calc(50% - 590px);
            padding-right: calc(50% - 590px);
            &.not-for-sale{
                grid-template-areas:
                "images infoheader"
                "images actions"
                "images descriptionshort"
                "images empty";
                grid-template-rows: auto auto auto 200px;
                .product-accessories{
                    >h3{
                        border-bottom: 1px solid $gris5;
                    }
                    .product-accessories-container{
                        .products{
                            display: grid;
                            grid-template-columns: repeat(2,calc(50% - 10px));
                            grid-gap: 10px;
                        }
                    }
                }
            }
            #sigue_leyendo{
                font-size: $font-xs;
                color: $primario2;
                cursor: pointer;
            }
            @media(max-width: $screen-md){
                grid-template-areas:
                "images "
                "infoheader"
                "descriptionshort"
                "actions";
                grid-template-columns: 100%;
                padding-left: 0;
                padding-right: 0;
            }
            .djv-product-images{
                grid-area: images;
            }
            .djv-product-information-header{
                grid-area: infoheader;
            }
            .djv-product-detail-variants{
                grid-area: variants;
            }
            .product-buy-actions{
                grid-area: actions;
            }
            .djv-description-short{
                grid-area: descriptionshort;
            }
            .additional-info{
                display: none;
                .social-sharing{
                    grid-area:social;
                }
            }
            .djv-product-images{
                padding: 10px;
                @media(max-width: $screen-md){
                    padding: 0;
                }
                .product-cover{
                    img{
                        min-width: 360px;
                        @media(max-width: $screen-lg){
                            width: 250px;
                            margin-left: auto;
                            margin-right: auto;
                        }
                    }
                }
            }
            .djv-product-information-header{
                padding: 10px;
                margin-bottom: 0px;
                div{
                    display: block;
                    &.djv-product-information-name{
                        h1{
                            font-size: $font-xl - 3px;
                            @include fuente($font-first , $font-medium);
                            @media(max-width: $screen-md){
                                font-size: $font-lg;
                            }
                        }
                    }
                    p{
                        font-size: $font-sm;
                        margin-bottom: 0px;
                    }
                    a{
                        font-size: $font-xs;
                        display: block;
                    }
                }
                #product-reference{
                    margin-bottom: 0px;
                }
                .aviso-disponibilidad-mascarillas{
                    margin-top: 26px;
                    display: block;
                    p{
                        background-color: white;
                        padding: 8px 16px;
                        margin-bottom: 6px;
                        border-radius: 50px;
                        font-weight: bold;
                        font-size: 16px;
                        &:last-of-type{
                            margin-bottom: 0;
                        }
                        .verde{
                            color: #18A14C;
                        }
                        .gris{
                            color: $gris4;
                        }
                        img{
                            float: right;
                            margin-top: -30px;
                            width: 180px;
                            @media(max-width: $screen-md){
                                display: none;
                            }
                        }
                        a{
                            display: inline;
                            padding-left: 16px;
                        }
                        small{
                            font-weight: bold;
                            display: inline-block;
                        }
                    }
                }
            }
            .djv-product-detail-variants{
                @media(max-width: $screen-lg){
                    margin-top: 15px;
                }
                ul.variants-group{
                    list-style: none;
                    padding-left: 10px;
                    @media(max-width: $screen-md){
                        padding-left: 0;
                    }
                    li{
                        font-weight: bold;
                        font-size: $font-sm;
                        position: relative;
                        padding-left: 10px;
                        &.djv-icon-arrow-right{
                            &::before{
                                font-size: $font-xxs;
                                top: 6px;
                                position: absolute;
                                left: 0;
                                font-weight: normal;
                            }
                        }
                        span.addedPrice{
                            margin-left: 5px;
                            font-size: $font-xs;
                            font-weight: bold;
                            text-align: right;
                        }
                        .group-name{
                            @media(max-width: $screen-md){
                                border-bottom: 1px solid $gris10;
                                display: block;
                                margin-top: 20px;
                            }
                        }
                    }
                }
                ul.variants-single{
                    margin-top: 6px;
                    list-style: none;
                    padding-left: 5px;
                    @media(max-width: $screen-md){
                        padding-left: 0;
                        margin-left: -10px;
                        li{
                            padding: 10px 5px;
                            &:nth-of-type(2n){
                                background-color:$gris1;
                            }
                        }
                    }
                    li{
                        label{
                            margin-bottom: 10px;
                            font-size: $font-xs;
                            font-weight: normal;
                            display: block;
                            padding-right: 20px;
                            @media(max-width: $screen-md){
                                margin-bottom: 0;
                                padding-right: 0;
                            }
                            input[type="radio"]{
                                margin-right: 10px;
                                @media(max-width: $screen-md){
                                    height: 20px;
                                    float: left;
                                    width: 20px;
                                }
                            }
                        }
                    }
                    span.addedPrice{
                        margin-left: 5px;
                        font-size: $font-xs;
                        font-weight: bold;
                        text-align: right;
                        float: right;
                    }
                }
            }
            .product-buy-actions{
                padding: 10px;
                margin-left: 0;
                margin-right: 0;
                .product-variants{

                }
                .etiqueta-oferta{
                    width: 90px;
                    padding:5px 10px;
                    margin-left: auto;
                    margin-right: auto;
                    margin-bottom: 0px;
                    background-color: $complementario2;
                    font-size: $font-sm;
                    color:white;
                    text-align: center;
                }
            }
            .djv-description-short{
                padding-left: 10px;
                img{
                    @media(max-width: $screen-md){
                        max-width: 92vw;
                    }
                }
                p{
                    margin-bottom: 10px;
                    font-size: $font-sm;
                }
                ul{
                  li{
                    font-size: $font-sm;
                    a{
                      font-size: 14px;
                      font-weight: 100;
                    }
                  }
                }
            }
            .social-sharing{
                display: none;
            }
        }
        .product-second-section{
            @include djv-container();
            display: grid;
            grid-gap: 10px;
            grid-template-areas:
            "extended accesories"
            "opiniones accesories";
            grid-template-columns: 70% 30%;
            @media(max-width: $screen-md) {
                display: block;
                width: auto;
                padding: 5px 15px;
            }
            .product-information-extended{
                grid-area: extended;
                position: relative;
                #leermas{
                    display: block;
                }
                .product-information-extended-heading{
                    @include djv-border-bottom("solid");
                    @include fuente($font-first , $font-semibold);
                    text-transform: uppercase;
                    font-size:$font-lg;
                    padding: 16px;
                    color:$gris9;
                }

                .product-information-extended-description{
                    font-size: $font-md;
                    @media(max-width: $screen-md){
                        padding: 0;
                    }
                    h2{
                        font-size: 22px;
                        font-weight:bold;
                        text-align: left;
                        margin-top: 35px;
                    }
                    img{
                        max-width: 100%;
                        height: auto;
                    }
                    .parrafo-dos-col{
                        column-count:2;
                        margin-top:40px;
                        @media(max-width:$screen-md){
                          column-count:1;
                        }
                      }
                      iframe{
                          @media(max-width:$screen-md){
                              max-width: calc(100vw - 32px);
                              height: auto;
                          }
                      }
                }
                .product-attachments{
                    .product-attachments-heading{
                        @include djv-border-bottom("solid");
                        @include fuente($font-first , $font-medium);
                        text-transform: uppercase;
                        font-size:$font-slg;
                        padding: 16px;
                        color:$gris9;
                    }
                    .attachment{
                        width: 25%;
                        display: inline-block;
                        text-align: center;
                        h4{
                            a{
                                font-size: $font-md;
                                color: $primario6;
                            }
                        }
                        p{
                            font-size: $font-md;
                            color: $gris9;
                        }
                        >a{
                            @include btn-hover-animated($complementario4,white,50%,5px,0);
                            font-size: $font-xs;
                        }
                    }
                }
            }
            .djv-product-opiniones{
                grid-area:opiniones;
            }
            .product-accessories{
                grid-area:accesories;
                @media(max-width: $screen-md){
                    width: auto;
                    float: none;
                    padding: 0 15px;
                }
            }
        }
        .djv-product-opiniones{
            @include djv-container();
            @media(max-width: $screen-md){
                padding: 16px;
            }
            .djv-product-opinion{
                color: $gris9;
                display: block;
                width: 49%;
                margin-right: 1%;
                float: left;
                @media(max-width: $screen-md){
                    width: 100%;
                }
                .coment-header{
                    .comment_image{
                        display: none;
                    }
                    .comment_title{
                        display: none;
                    }
                    .comment_author{
                        font-size: $font-xxs;
                        margin-bottom: 3px;
                    }
                }
            }
        }
    }
    .page-content:not(.personalizable){
    }
    .page-content.personalizable{
        #add-to-cart-or-refresh{
            display: grid;
            grid-template-areas:
            "variants "
            "quantity "
            "price "
            "addtocart"
            "sequra"
            "textoadicional";
            grid-template-columns: 100%;
            @media(max-width: 768px){
              max-width: 95vw;
            }
            .product-prices{
                grid-area:price;
                padding: 20px 0 0;
                .product-price .centerprice {
                  display: block;
                  margin: auto;
                }
            }
            .product-quantity{
                grid-area:quantity;
            }
            #sequra-container{
              grid-area:sequra;
            }
            .product-add-to-cart-button{
                grid-area:addtocart;
                padding: 20px 0;
                display: flex;
                flex-flow: row wrap;
                align-items: center;
                justify-content: space-around;
                @media(max-width: $screen-md){
                    padding: 6px 0;
                    align-items: self-start;
                    >div{
                      width: 50%;
                    }
                }
                .sequra-promotion-widget{
                  width: 100%;
                  padding-bottom: 0;
                }
            }
            #djv-variants-message{
                display: none;
            }
            .product-variants{
                grid-area:variants;
                //background-color: green;
                margin-bottom: 0;
                label{
                    @include fuente($font-first , $font-bold);
                }
                select{
                    width: 300px;
                    margin-bottom: 20px;
                }
                .product-variants-color{
                    ul{
                        li{
                            .swap-bg{
                                span{
                                    display: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #extra-0{
      @include djv-container();
      width: 100%;
      margin-bottom: 1.5rem
    }
    #tw-target-text-container{
      pre{
        white-space: normal;
      }
    }
    #mpm_faqs_form_overlay{
      top: 0;
    }
    .gomakoil_faq_page{
        @include djv-container();
        padding: 2rem 1rem;
        background-color: #f6f6f6;
        margin-bottom: 3.5rem;
        border-radius: .5rem;
        header{
          margin-bottom: 2.5rem;
          h3{
            font-family: "Fredoka One", cursive;
            font-weight: 400;
            font-size: 24px;
            text-align: left;
          }
          p{
            a{
              color: #F6A532;
              text-decoration: underline;
            }
          }
        }
        .m-link-2{
            display: none !important;
        }
    }
}
