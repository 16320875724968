#manufacturer {
	#main {
		@include djv-container();

		h1 {
			font-size: $font-lg;
			padding: 10px 0 15px 5px;
			border-bottom: 1px dashed $gris7;
			margin-bottom: 20px;
			text-transform: uppercase;
			font-size: 22px;
		}

		ul {
			display: grid;
			grid-template-columns: repeat(4, calc(25% - 15px));
			margin: 0;
			padding: 0;
			grid-gap: 20px;
			@media(max-width: $screen-sm){
				grid-template-columns: repeat(2, calc(50% - 2px));
				grid-gap: 2px;
			}
			li {
				list-style: none;
				text-align: center;
				list-style: none;
				text-align: center;
				border: 1px dashed $gris1;
				.brand-img{
					margin: 20px auto;
				}
				.brand-infos{
					padding: 10px 0 1px 0px;
					background-color: $gris1;
					h3{
						font-size: 22px;
					}
				}

				a {
					color: $gris9;
				}

				.brand-products {
					a {
						display: block;
						@include fuente($font-first , $font-regular);
						display: block;
						font-family: "OpenSans-Regular";
						background-color: #f6f6f6;
						padding-bottom: 10px;
						font-size: $font-xs;
						&:last-of-type {
							@include btn-hover-animated($complementario4,white,0,5px,5px);
							margin: 20px auto;
							width: 70%;
						}
					}
				}
			}
		}
	}
}
