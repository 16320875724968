#module-djv_navigation-nsearch {
    #bannerSlider.carousel .carousel-item img {
        @media(max-width: $screen-md) {
            max-height: 125px;
        }
    }

    #djv-two-columns-layer {
        @include djv-container();

        &.force-one-column {
            #content-wrapper {
                #js-product-list {
                    @include djv-container();
                    display: grid;
                    grid-template-columns: repeat(4, calc(25%));
                    grid-gap: 40px 5px;

                    @media(max-width: $screen-md) {
                        grid-template-columns: 48% 48%;
                        padding: 0 10px;
                    }
                }
            }
        }

        #content-wrapper {
            position: relative;

            h1 {
                @include fuente($font-second, $font-regular);
                display: block;

                @media(max-width: $screen-sm) {
                    font-size: $font-lg;
                }
            }

            #js-product-list-top {
                @include djv-container();

                .djv-navigator-responsive-filter-button {
                    display: none;

                    @media(max-width: $screen-md) {
                        display: block;
                        position: relative;
                        background-color: $primario1;
                        color: #fff;
                        text-align: center;
                        padding: 10px;
                        border-radius: 5px;
                        margin-bottom: 25px;
                        cursor: pointer;

                        i {
                            position: absolute;
                            right: 8px;
                            top: 8px;
                            background-color: white;
                            padding: 5px;
                            border-radius: 50%;
                            width: 25px;
                            height: 25px;
                            color: $primario1;
                            text-align: center;
                        }
                    }
                }

                #djv-filters-your-seleccion {
                    margin-left: 0;
                    padding-left: 0;
                    margin-top: 20px;
                    display: none;

                    li {
                        list-style: none;

                        a {
                            color: inherit;

                            b {
                                display: none;
                            }
                        }
                    }

                    .djv-navigation-left-selected-item a::before {
                        display: inline-block;
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content: 'X';
                        color: white;
                        background-color: red;
                        padding: 4px 6px;
                        border-radius: 50px;
                        font-size: $font-xs;
                        font-weight: bold;
                        margin-right: 5px;
                    }
                }
            }

            #js-product-list {
                display: grid;
                grid-template-columns: repeat(3, calc(33.333%));
                grid-gap: 30px 5px;

                @media(max-width: $screen-md) {
                    display: grid;
                    grid-template-columns: 48% 48%;
                    grid-gap: 30px 10px;
                }

                &.djv-display-list {
                    grid-template-columns: auto;
                }
            }

            #js-product-list-bottom {
                @include djv-container();
            }
        }
    }
}

.djv-navigation-nsearch-header {
    background-color: $gris1;
    text-align: center;
    position: relative;
    padding: 0;
    z-index: 0;

    #category-description {
        >h1 {
            font-size: $font-xxl - 2px;
            @include fuente($font-second, $font-regular);
            text-transform: uppercase;
            margin-top: 20px;

            @media(max-width: $screen-md) {
                font-size: $font-lg;
            }
        }

        >h3 {
            font-size: 30px;
            @include fuente($font-second, $font-regular);
        }

        >p {
            margin: 5px 0;
            @include fuente($font-first, $font-light);
            font-size: $font-slg;

            a {
                display: inline-block;
                @include fuente($font-first , $font-medium);
                font-size: $font-smd;
                color: $primario2;
            }
        }

        a {
            color: $primario2;
            font-weight: 400;

            h3 {
                color: black;
            }
        }
    }
}

@media(max-width:$screen-lg) {
    .carousel~.djv-navigation-nsearch-header {
        margin-top: 0px;
    }
}

.djv_carouselh.list-originales {
    .products {
        .product-miniature {
            width: 320px;
            min-height: 380px;
        }
    }
}

.djv_carouselh {
    .products {
        .product-miniature {
            width: 320px;
            min-height: 430px;
            max-height: 430px;

            /*&.personalizable{
              min-height: 520px;
              max-height: 520px;
            }*/
            @media(max-width: $screen-md) {
                width: 100%;
                min-height: 360px;

                .product-miniature-img-block {
                    min-height: 260px;
                }

                .name-product {
                    padding: 0 10px;
                    white-space: normal;
                }

                .product-list-actions {
                    margin-top: 0;
                }
            }
        }
    }
}

.djv-listas-productos {
    @include djv-container();

    .djv-listas-productos-parrafo {
        text-align: center;
        margin-bottom: 50px;
    }
}