#search {
    h2 {
        font-size: $font-lg;
        padding: 10px 0 15px 5px;
        border-bottom: 1px dashed $gris7;
        margin-bottom: 20px;
        text-transform: uppercase;
    }

    .page-not-found {
        > p {
            font-size: $font-lg;
            padding: 10px 0 15px 5px;
            border-bottom: 1px dashed $gris7;
            margin-bottom: 20px;
            text-transform: uppercase;
        }

        #search_widget {
            width: 100%;
            display: block;
            margin: 5px auto;
            position: relative;

            .ui-helper-hidden-accessible {
                border: 0;
                clip: rect(0 0 0 0);
                height: 1px;
                margin: -1px;
                overflow: hidden;
                padding: 0;
                position: absolute;
                width: 1px;
            }

            input {
                background-color: white;
                width: 100%;
                max-width: 300px;
                display: block;
                position: relative;
                border: 1px solid #999999;
                border-radius: 3px;
                padding: 3px 20px 3px 5px;
                color: #26445C;
                font-family: $font-first;
                font-size: 12px;

                &::placeholder {
                    font-size: 12px;
                    color: #666666;
                    font-family: $font-first;
                    text-align: center;
                }
            }
            button{
                position: relative;
                top: -25px;
                left: 265px;
                border: none;
                background-color: transparent;
                width: 30px;
                height: 30px;
                cursor: pointer;

                &::before {
                    display: inline-block;
                    font-family: "comunes";
                    font-style: normal;
                    font-weight: normal;
                    line-height: 1;
                    -webkit-font-smoothing: antialiased;
                    -moz-osx-font-smoothing: grayscale;
                    content:'\0045';
                    font-size: 16px;
                    right: 0px;
                    top: 0px;
                    position: absolute;
                    color: #cccccc;
                }
            }
        }
    }
}

.ui-widget{
    font-size: $font-sm;
}
