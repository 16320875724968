#pagenotfound{
    #djv-two-columns-layer{
        @include djv-container();
        #search_widget{
            input[type="text"]{
                padding: 6px;
            }
            button{
                @include btn-hover-animated($primario2,white,0,5px,5px)  ;
                &::after{
                    content: "Buscar";
                }
            }
        }
    }
}

.page-not-found{
    #search_widget{
        .djv-icon-lupa{
            display: none;
        }
        input[type="text"]{
            padding: 5px 3px 6px 3px;
            height: 33px;
        }
        button{
            @include btn-hover-animated($primario2,white,0,5px,5px);
            &::after{
                content: "Buscar";
            }
        }
    }
}
