#left-column {
    .djv-navigator-responsive-filter-button{
        display: none;

        @media(max-width: $screen-lg){
            display: block;
            position: relative;
            background-color: $primario1;
            color: #fff;
            text-align: center;
            padding: 10px;
            border-radius: 5px;
            cursor: pointer;
            i{
                position: absolute;
                right: 8px;
                top: 8px;
                background-color: white;
                padding: 5px;
                border-radius: 50%;
                width: 25px;
                height: 25px;
                color: $primario1;
                text-align: center;
            }
        }
    }
    #djv-navigation-left {
        @include fuente($font-first , $font-regular);
        font-size: $font-sm;
        @media(max-width: $screen-lg) {
            display: none;
            position: fixed;
            top: 0;
            background-color: white;
            z-index: 100;
            width: 100vw;
            left: 0;
            padding: 10px;
            overflow-y: auto;
            height: 100vh;
        }

        h2 {
            display: none;
            @include fuente($font-first , $font-regular);
            font-size: $font-sm*0.95;
            padding: 8px 5px;
            //border-bottom: 3px solid $primario10;
            border-bottom: 1px solid $gris3;
            margin-bottom: 10px;
            margin-top: 9px;
            @media(max-width: $screen-md){
                padding: 10px 5px;
                font-size: $font-sm;
                text-align: left;
                display: block;
            }
        }

        > div {
            padding-bottom: 5px;
            margin-bottom: 5px;
            //border-bottom: 1px dashed $gris7;
            h3 {
                @include fuente($font-first , $font-medium);
                font-size: $font-smd;
                margin-bottom: 20px;
                color: $primario2;
                padding-top: 0;
                margin-left: 10px;

                span {
                    float: right;
                    width: 20%;
                    font-size: $font-xxs;
                    color: $gris3;
                    padding-top: 8px;
                }
            }

            ul {
                margin-left: 15px;
                padding-left: 0;

                li {
                    display: grid;
                    grid-template-columns: 5% 80% 15%;
                    margin-bottom: 0;
                    //border-bottom: 1px solid $gris1;
                    font-size: $font-sm;
                    background-color: rgba(130,130,130,0);
                    transition: background-color 750ms;
                    padding: 1px;

                    a {
                        padding: 2px;
                        color: $gris9;
                    }

                    span {
                        text-align: center;
                        padding: 1px;
                        font-size: $font-xxs;
                        color: $gris3;
                        display: none;
                    }

                    &:hover {
                        background-color: rgba(245,245,245,1);
                    }
                    &::before{
                        display: inline-block;
                        font-family: "comunes";
                        font-style: normal;
                        font-weight: normal;
                        line-height: 1;
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        content:'\004c';
                        font-size: $font-xxs;
                        padding-top: 6px;
                        color: $primario4;
                    }
                }
            }
        }

        .djv_navigation-price {
            display: none;
            .djv_navigation-price-slider {
                margin-bottom: 20px;
                width: 90%;
                margin-left: 5%;
                margin-bottom: 20px;

                .noUi-connect {
                    background: $primario1;
                }
            }

            .djv_navigation-price-apply-button {
                @include btn-hover-animated($primario2,white,0,5px,5px);
                margin-top: 10px;
                width: 80%;
                margin-left: auto;
                margin-right: auto;
                display: block;
            }
        }

        .djv-navigation-left-selected-item a::before {
            display: inline-block;
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: 'X';
            color: white;
            background-color: red;
            padding: 4px 6px;
            border-radius: 50px;
            font-size: $font-xs;
            font-weight: bold;
            margin-right: 5px;
        }

        .djv-navigation-left-nofilter-selected {
            color: $gris3;
        }

        #djv-icon-close-filters {
            display: none;
            float: right;
            font-size: $font-sm;
            font-style: normal;
            @include fuente($font-first , $font-regular);
            cursor: pointer;
            background-color: $complementario2;
            color:white;
            padding: 5px;
            border-radius: 5px;
            &::before {
                display: inline-block;
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                content: 'X';
                color: $complementario2;
                background-color: white;
                padding: 4px 6px;
                border-radius: 50px;
                font-size: $font-xs;
                font-weight: bold;
                margin-right: 0px;
            }
            @media(max-width: $screen-sm) {
                display: inline-block;
                padding: 7px;
                position: absolute;
                right: 11px;
                top: 11px;
            }
        }
    }

    .djv-information-delivery {
        padding: 15px 20px;
        border: 1px solid $gris4;
        margin-bottom: 20px;
        line-height: 22px;
        @media(max-width: $screen-lg){
            display: none;
        }
        i {
            font-size: $font-xl+2;
            color:$gris9;
            float: left;
            margin-right: 6px;
            margin-top: 2px;
        }
        strong{

        }
        p{
            font-size: $font-sm*0.9;
            color:$gris7;
            margin-bottom: auto;
            padding-left: 32px;
        }
    }

    .djv-need-help {
        background-color: $gris1;
        padding: 15px 20px;
        @media(max-width: $screen-lg){
            display: none;
        }
        [class*='djv-icon-']:before{
            margin-right: 6px;
            color: $primario4;
        }
        [class*='djv-icon-']{
            font-weight: bold;
            margin-top:15px;
            a{
                color: $primario4;
                font-size: $font-sm;
                @include fuente($font-first , $font-regular);
            }
        }
        .djv-icon-telefono{
        }
        .djv-icon-mail{
        }
        .djv-icon-text-baloon{
        }
        p{
            font-size:$font-sm;
        }
    }
}
