#footer{
    margin-top: 50px;
    @media(max-width: $screen-lg){
        margin-top: 30px;
    }
    #punto-diferenciador.full-width{
        margin-bottom: 30px;
        h2{
            @include fuente($font-first , $font-bold);
            text-transform: uppercase;
            font-size: 32px;
            text-align: center;
            margin-bottom: 30px;
        }

        .djv-flex{
            max-width: 1170px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            display: flex;
            @include fuente($font-first , $font-light);
            font-size: $font-sm - 1px;
            @media(max-width: $screen-md){
                display: block;
                padding: 0 20px;
            }
            > * {
                flex: 1;
                margin-left: 10px;
                margin-right: 10px;
            }
            i{
                display: block;
                width: 25%;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 10px;
                img{
                    width: 100%;
                }
            }
            h3{
                margin-bottom: 20px;
                @include fuente($font-first,$font-bold);
                font-size: $font-lg + 1px;
                color:black;
                &::before{
                    display: block;
                    font-size: 70px;
                    margin-bottom: 16px;
                }
            }
            p{
                margin: 5px 0;
            }
            a{
                display: block;
                @include fuente($font-first , $font-bold);
                font-size: $font-sm - 1px;
                color: $primario2;
            }
        }
    }
    .djv-email-subscription{
        background-color: $gris1;
        padding: 16px 0 6px;
        @media(max-width: $screen-md){
            padding-left: 20px;
            padding-right: 20px;
        }
        .djv-email-subscription-container{
            @include djv-container();
            display:grid;
            grid-template-columns: 20% 45% 35%;
            grid-gap: 0px;
            align-items: center;
            @media(max-width: $screen-md){
                display: block;
                >div{
                    margin-bottom: 20px;
                }
            }
            .djv-email-subscription-logo{
                text-align: center;
                position: relative;
                @media(max-width: $screen-md){
                    float: left;
                    width: 20%;
                }
                img{
                    position: absolute;
                    width: 125px;
                    left: 92px;
                    top: -90px;
                    @media(max-width: $screen-md){
                        position: relative;
                        width: 60px;
                        margin-left: auto;
                        margin-right: auto;
                        display: block;
                        top:20px;
                        left: 0;
                    }

                }
            }
            .email-subcription-text{
                padding-top:5px;
                padding-left: 10px;
                position: relative;
                @media(max-width: $screen-md){
                    margin-bottom: 0;
                    float: left;
                    width: 80%;
                }
                h4{
                    @include fuente($font-first , $font-medium);
                    font-size: $font-lg + 2px;
                    border-bottom: 1px solid $gris3;
                    padding-bottom: 3px;
                    display: inline-block;
                    padding-right:25px;
                }
                p{
                    @include fuente($font-first , $font-regular);
                    font-size: $font-smd;
                    color: $gris8;
                }
                p.notification{
                    position: absolute;
                    right: -350px;
                    width: 350px;
                    top: -55px;
                    background-color: white;
                    border: 3px solid;
                    &.notification-success{
                        color:$complementario1;
                        @media(max-width: $screen-md){
                            margin-bottom: 0;
                        }
                    }
                    &.notification-error{
                        color:$complementario2;
                        &::before{
                            content:"* ";
                            font-size: $font-md;
                        }
                        @media(max-width: $screen-md){
                            right: 0;
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .djv-email-subscription-form{
                padding-top: 0px;
                input[type="text"]{
                    border: 1px solid $gris2;
                    padding: 11px 16px;
                    font-size: 13px;
                    width: 70%;
                    margin-right: 10px;
                    @media(max-width: $screen-md){
                        width: 60%;
                        display: inline-block;
                    }
                }
                input[type="checkbox"]{
                    border: 1px solid $gris2;
                }
                input[type="submit"]{
                    @include btn-hover-animated($primario2,white,0,5px,5px);
                    width: 100px;
                    padding: 9px 17px;
                    @include fuente($font-first , $font-bold);
                    font-size: $font-md;
                    color: white;
                    @media(max-width: $screen-md){
                        padding: 7px 17px;
                        display: inline-block;
                    }
                }
                .control-label{
                    font-size: $font-xs;
                    margin-top: 10px;
                    color:$gris8;
                    a{
                        @include fuente($font-first , $font-bold);
                        color: $primario2;
                    }
                }
            }
            .notification-error{
                position: relative;
                right: -453px;
                width: 350px;
                color:$primario10;
            }
        }
    }
    .block-contact{
        padding: 16px;
        background-color: $gris6;
        font-size: $font-sm;
        color:white;
        .djv-block-contact-container{
            @include djv-container();
            img{
                display: none;
            }
            div{
                margin: 5px;
                display: inline;
                i{
                    margin-right: 5px;
                }
            }
            .djv-block-contact-social{
                float: right;
                margin-top: -8px;
                position: relative;
                display: flex;
                align-items: center;
                @media(max-width: $screen-lg){
                    float: none;
                    margin-top: 0;
                    margin: 0;
                }
                a{
                    margin: 10px 0px 4px 6px;
                }
                [class*='djv-icon-']:before{
                    font-size: $font-lg;
                    color:white;
                }
            }
            a{
                color: white;
            }
        }
    }
    .djv-footer-navigation{
        background-color: $gris5;
        color:white;
        padding: 30px 0;
        margin-top: 0;
        margin-bottom: 0;
        .djv-footer-navigation-container{
            width: 100%;
            margin-left: auto;
            margin-right: auto;
            max-width: 1200px;
            padding: 16px;
            display: grid;
            grid-template-columns: repeat(4,calc(25% - 10px));
            grid-gap: 10px;
            text-align: left;
            @media(max-width: $screen-md){
                display: block;
            }

            >div{

                padding-right: 15px;
                padding-left: 5px;
                @media(max-width: $screen-md){
                    margin-bottom: 30px;
                    border: none;
                }
                &:last-child{
                    border-right: none;
                }
                &.djv-footer-tienda{
                    a{
                        color: white;
                        padding-left: 25px;
                    }

                    blockquote{
                        margin-left: 20px;
                        font-size: $font-sm - 1px;
                    }

                    .djv-icon-telefono{
                        &::before{
                            margin-right: 10px;
                        }
                    }
                }
                h4{
                    font-size: $font-md;
                    padding-bottom: 6px;

                    display: block;
                    @include fuente($font-first , $font-medium);
                }
                p{
                    @include fuente($font-first , $font-light);
                    font-size: $font-sm - 1px;
                }
                .footer-tittle{
                    display: block;
                    @include fuente($font-first , $font-bold);
                    &.djv-icon-crono{
                        a{
                            padding-left: 0;
                        }
                    }
                }
                ul{
                    margin: 0;
                    padding: 0;
                    @media(max-width: $screen-md){
                        margin-left: 0;
                    }
                    li{

                        @include fuente($font-first , $font-light);
                        font-size: $font-sm;
                        background: transparent;
                        background-size: 0%;
                        transition: background 1s;
                        padding: 3px;
                        color:$gris5;
                        @media(max-width: $screen-lg){
                            list-style: none;
                        }
                        &:hover{
                            background: $primario1;
                            cursor: pointer;
                        }
                        a{
                            color: white;
                        }
                    }
                }
                ul.djv-footer-social-share{
                    display: grid;
                    grid-template-columns: auto auto auto;
                    margin-top: 30px;
                    width: 80%;
                    li{
                        display: inline-block;
                        &:before{
                            content:'';
                            display: none;
                        }
                        img{
                            width: 70%;
                        }
                        &:hover{
                            background: inherit;
                        }
                    }
                }
            }
            .djv-footer-navigation-metodos-pago{
                >div{
                    img{
                        width: auto;
                        display: block;
                    }
                }
            }
            .email_subscription{
                font-size: $font-sm;
                @include fuente($font-first , $font-light);
                padding-top: 2px;
                input[type="text"]{
                    height: 45px;
                    line-height: 30px;
                    background: #595959;
                    padding: 5px 0 5px 12px;
                    display: block;
                    border:none;
                    border-radius:4px;
                    width: 100%;
                    margin-bottom: 16px;
                    color: white;
                    &::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
                        color: white;
                        opacity: .8; /* Firefox */
                    }
                }
                input[type="submit"]{
                    display: block;
                    margin: 15px 0;
                    padding: 6px 12px;
                    border: none;
                    -moz-border-radius: 2px;
                    -webkit-border-radius: 2px;
                    border-radius: 2px;
                    background-color: $gris1;
                    font-size: $font-md;
                    font-weight: 400;
                    color: white;
                    cursor: pointer;
                }
                .notification-error{
                    color:$gris1;
                    font-size: $font-lg;
                }
                .notification-success{
                    color:$primario5;
                    font-size: $font-lg;
                }
            }
        }
    }
    .djv-footer-below, .checkout-footer-simplified{
        padding: 10px 16px;
        background-color: darken($primario5, 15%);
        font-size: $font-xs;
        @include fuente($font-first , $font-light);
        color:white;
        .djv-footer-below-container{
            @include djv-container();
            display: block;
            .djv-footer-below-copyright{
                p{
                    margin-bottom: 0;
                }
            }
            .djv-footer-below-cms{
                text-align: right;
            }
        }
        a{
            color:$primario6;
        }
    }
    .faldon-sin-iva{
        background-color: $gris9;
        color: $gris2;
        font-size: $font-xxs;
        padding: 10px;
    }
    .desarrollo-djv{
        background-color: black;
        .djv-container{
            color: white;
            font-size: $font-xxs;
            display: flex;
            padding-top: 10px;
            @media(max-width: $screen-md){
                display: block;
                padding: 15px;
                p{
                    margin-bottom: 5px;
                }
            }
            p:first-of-type{
                margin-right: 20px;
            }
        }
    }
}
