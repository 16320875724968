// CDN servers

$cdnserver1: "https://cdn1.crealo.es";
$cdnserver2: "https://cdn2.crealo.es";
$cdnserver3: "https://cdn3.crealo.es";

// IMAGES URL
$urlimagenes: "/themes/base/assets/img/";
$urlicons:    "/themes/base/assets/icons/";

// #### COLORES
$empty-bg:url("#{$urlimagenes}bg-pattern.png");

//Colores primarios
$primario1:  #F5A533;
$primario2:  #F28E00;
$primario3:  #83A7CB;
$primario4:  #415055;
$primario5:  #26445C;
$primario6:  #212f44;
$primario7:  #D4B8AA;
$primario8:  #C6755A;
$primario9:  #EB5512;
$primario10: #903c44;
$primario11: #F49F12;
$primario12: #13a323;

//Escala de grises
$gris1:  #f1f1f1;
$gris2:  #dddddd;
$gris3:  #cccccc;
$gris4:  #aaaaaa;
$gris5:  #999999;
$gris6:  #777777;
$gris7:  #666666;
$gris8:  #444444;
$gris9:  #333333;
$gris10: #111111;

//Colores complementarios
$complementario1:  #5abe20;
$complementario2:  #D53128;
$complementario3:  #ff7926;
$complementario4:  #0083ff;
$complementario5:  #373737;
$complementario6:  white;
$complementario7:  white;
$complementario8:  white;
$complementario9:  white;
$complementario10: white;


$font-first: 'Open Sans', sans-serif;
$font-second: 'Fredoka One', cursive;
$font-light:     300;
$font-regular:   400;
$font-medium:    500;
$font-semibold:  600;
$font-bold:      700;
$font-extrabold: 800;
$font-black:     900;

// Font Sizes
$font-xxxs: 10px;
$font-xxs:  12px;
$font-xs:   13px;
$font-sm:   14px;
$font-smd:  15px;
$font-md:   16px;
$font-bmd:  17px;
$font-slg:  18px;
$font-lg:   20px;
$font-blg:  24px;
$font-xl:   36px;
$font-xxl:  45px;
$font-xxxl: 60px;

$font-responsive-xxs: 0.3vw;
$font-responsive-xs:  0.5vw;
$font-responsive-sm:  0.8vw;
$font-responsive-md:  1vw;
$font-responsive-lg:  1.5vw;
$font-responsive-xl:  3vw;
$font-responsive-xxl: 4vw;

//Screen sizes
// Extra small screen / phone
$screen-xs: 480px;
$screen-phone: $screen-xs;

// Small screen / tablet
$screen-sm: 576px;
$screen-tablet: $screen-sm;

// Medium screen / desktop
$screen-md: 768px;
$screen-desktop: $screen-md;

// Large screen / wide desktop
$screen-lg: 1024px;
$screen-lg-desktop: $screen-lg;

// Very large screen / wide desktop
$screen-xl: 1200px;
$screen-xl-desktop: $screen-xl;

// hd screen / wide desktop
$screen-hd: 1500px;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm - 1);
$screen-sm-max: ($screen-md - 1);
$screen-md-max: ($screen-lg - 1);
$screen-lg-max: ($screen-xl - 1);
$screen-xl-max: ($screen-hd - 1);

// *** herencia de plantilla
// modal
$zindex-modal-backdrop: 1040 !default;
$zindex-modal: 1050 !default;
// Sizes
$spacing-unit: 30px;
$medium-padding: $spacing-unit / 2;
$small-padding: $spacing-unit / 6;
$tiny-padding: 2px;

// Breakpoints
$breakpoint-small : 1000px;
$breakpoint-medium: 1200px;
$breakpoint-large : 1800px;

$watchmework: 8.54;
