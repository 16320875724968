#addresses{
    #main{
        @include djv-container();
        .page-addresses{
            display: grid;
            grid-template-columns: calc(33% - 16px) calc(33% - 16px) calc(33% - 16px);
            grid-gap: 16px;
            @media(max-width: $screen-lg){
                display:block;
            }
            article{
                border: 1px dashed #dadada;
                padding: 16px;
                position: relative;
                @media(max-width: $screen-lg){
                    padding: 8px;
                }
                .action-list{
                    padding-left: 0;
                    margin-bottom: 0;
                    li{
                        display: inline-block;
                        @media(max-width: $screen-lg){
                            width: 49%;
                        }
                        a{
                        display: block;
                        padding: 3px;
                        text-align: center;
                        color: white;
                        font-size: 14px;
                        width: 100%;
                            &[data-link-action="edit-address"]{
                                @include btn-hover-animated($complementario4,white,0,5px,5px);
                            }
                            &[data-link-action="delete-address"]{
                                @include btn-hover-animated($complementario2,white,0,5px,5px);
                            }
                        }
                    }
                }
            }
        }
        .page-footer{
            border-top: 1px dashed $gris3;
            padding-top: 20px;
            margin-top: 20px;
            @media(max-width: $screen-lg){
                border: none;
                padding: 0 10px;
            }
            ul{
                padding: 0;
                li{
                    display: inline-block;
                    list-style: none;
                    @media(max-width: $screen-lg){
                        display: block;
                        margin-bottom: 10px;
                    }
                    a{
                        @include btn-hover-animated($complementario4,white,0,5px,5px);
                        padding-left: 16px;
                        padding-right: 16px;
                        @media(max-width: $screen-lg){
                            width: 100%;
                            position: relative;
                            display: block;
                        }
                    }
                }
            }
        }
    }
}
#address{
    #main{
        @include djv-container();
        .form-fields{
            @media(max-width: $screen-lg){
                display: block;
            }
            label{
                display: block;
                @media(max-width: $screen-lg){
                    width: 100%;
                    display:block;
                    margin-bottom: 20px;
                }
                span{
                    display: inline-block;
                    width: 25%;
                    @media(max-width: $screen-md){
                        display: block;
                        width: 100%;
                    }
                }
                input, select{
                    border: none;
                    background-color: #eee;
                    padding: 5px;
                    display: inline-block;
                    width: 70%;
                    @media(max-width: $screen-md){
                        display: block;
                        width: 100%;
                    }
                }
                input[name="esempresa"]{
                    width: 25px;
                    @media(max-width: $screen-md){
                        display: block;
                        width: 100%;
                    }
                }
            }
            &::after{
                content: "Los campos marcados con un asterisco son obligatorios";
            }
        }
        .form-footer{
            border-top:  1px solid $gris3;
            padding-top: 20px;
            margin-top: 20px;
            button{
                @include btn-hover-animated($complementario1,white,0,5px,5px);
            }
        }
        .page-footer{
            border-top:  1px solid $gris3;
            padding-top: 20px;
            margin-top: 20px;
            ul{
                margin: 0;
                padding: 0;
                li{
                    list-style: none;
                    display: inline-block;
                    a{
                        @include btn-hover-animated($complementario4,white,0,5px,5px);
                    }
                }
            }

        }
    }
}
