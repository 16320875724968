#authentication{
    a{
        color:inherit;
    }
    #main{
        width: 100%;
        max-width: 1200px;
        margin-left: auto;
        margin-right: auto;
        @media(max-width: $screen-lg){
            padding: 10px;
        }
        h1.h3{
            font-size: $font-lg;
        }
        .djv-register-link{
            display: block;
            border-top: 1px dashed $gris3;
            border-bottom: 1px dashed $gris3;
            padding: 20px 0 20px;
            margin: 20px 0;
        }
        .form-footer{
            button{
                border: none;
                background-color: $primario5;
                padding: 6px 30px;
                color:white;
                transition: all 500ms;
                border: 2px solid white;
                cursor: pointer;
                @media(max-width: $screen-lg){
                    width: 100%;
                    display: block;
                }
                &:hover{
                    box-shadow: 2px 2px 9px rgba(0,0,0,.4);
                    background-color: white;
                    color:$primario5;
                    border: 2px solid $primario5;
                }
            }
        }
        .page-authentication{
            #login-form{
                margin-top: 20px;
                label{
                    display: inline-block;
                    margin-right: 20px;
                    vertical-align: top;
                    @media(max-width: $screen-lg){
                        display: block;
                        margin: 10px 0;
                    }
                    span{
                        display: block;
                        margin-bottom: 10px;
                    }
                    input{
                        display:block;
                        width: 100%;
                    }
                }
                .lost_password{
                    margin-top: 20px;
                }

            }
            #customer-form{
                font-size: $font-md;
                .form-fields{
                    margin-top: 30px;
                    label:not(.checkbox-field){
                        display: block;
                        max-width: 60%;
                        margin-bottom: 15px;
                        @media(max-width: $screen-lg){
                            width: 100%;
                        }
                        span{
                            display: block;
                            width: 50%;
                            margin-bottom: 0px;
                        }
                        input{
                            display:block;
                            width: 100%;
                        }
                    }
                    .lost_password{
                        margin-top: 20px;
                    }

                    label.checkbox-field{
                        display: block;
                        margin-bottom: 15px;
                        span{
                            margin-left: 20px;
                            display: block;
                            margin-top: -24px;
                            em{
                                font-size: $font-sm;
                            }
                        }
                    }
                    label.radio-field {
                        label{
                            display:inline-block;
                            width: auto;
                            margin-right: 5px;
                            margin-bottom: 15px;
                            input{
                                display:inline-block;
                                width: auto;
                                margin-right: 5px;
                            }
                        }
                    }
                }
                .form-footer{
                    border-top: 1px dashed $gris3;
                    padding-top: 20px;
                    margin-top: 20px;
                }
            }
        }
    }
}
#password{
    .form-fields{
        label{
            display: block;
            margin-top: 20px;
            >*{
                display: inline-block;
                width: 200px;
            }
        }
    }
}
