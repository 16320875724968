body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
}

#wrapper {
    clear: both;
}

#wrapper,
.header-top,
.header-topbar {
    padding-left: 0;
    padding-right: 0;
}

.header-banner {
    text-align: center;
    background-color: black;
}

#djv-two-columns-layer{
    min-height: 480px;
    margin-top: 20px;
    @media(max-width: $screen-lg){
        margin-top: 0px;
        .page-content{
            //padding: 0 15px;
        }
    }
}
.recogido{
    #djv-two-columns-layer{
        margin-top: 240px;
    }
}
#index{
    .carousel{
        @media(max-width: $screen-lg){
            margin-top: 90px;
            margin-bottom: 0px;
        }
        @media(max-width: $screen-md){
            margin-top: 40px;
        }
    }
    #djv-two-columns-layer{
        margin-top: 40px;
        @media(max-width: $screen-md){
            margin-top: 10px;
        }
    }
    &.recogido{
        .carousel{
            margin-top: 140px;
            margin-bottom: 0px;
            @media(max-width: $screen-lg){
            }
        }
    }
}

#module-djv_navigation-nsearch{
    .carousel{
        margin-bottom: 0;
        @media(max-width: $screen-md){
            margin-top: 40px;
        }
    }
    #djv-two-columns-layer:not(.force-one-column){
        display: grid;
        grid-template-columns: 25% 75%;
        margin-top: 20px;
        @media(max-width: $screen-md){
            margin-top: 0;
        }
        >div{
            padding: 0px;
            @media(max-width: $screen-lg){
                padding: 8px;
            }
        }
        @media(max-width: $screen-lg){
            display:block;
        }
    }
    .djv-navigation-nsearch-header{
        @media(max-width: $screen-lg){
            margin-top: 40px;
            padding: 10px;
        }
    }
    &.recogido{
        .djv-navigation-nsearch-header{
            margin-top: 140px;
        }
        .carousel{
            margin-top: 210px;
            margin-bottom: 0;
        }
        .carousel ~ .djv-navigation-nsearch-header{
            margin-top: 0px;
        }
        #djv-two-columns-layer{
            margin-top: 30px;
        }
    }
}
#product{
    #djv-two-columns-layer{
        margin-top:0px;
    }
    &.recogido{
        .djv-breadcrumb{
            margin-top: 200px;
        }
    }
}
#authentication{
    #djv-two-columns-layer{
        margin-top: 40px;
    }
    &.recogido{
        #djv-two-columns-layer{
            margin-top: 160px;
        }
    }
}
#module-faqs-display{
    #center_column_fag{
        @include djv-container();
    }
}
