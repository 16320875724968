.expandText{
    .content{
        position: relative;
        overflow: hidden;
        transition: all 600ms;
    }
    .expand-handler, .contract-handler{
        text-align: center;
        @include fuente($font-first, $font-regular);
        font-size: $font-sm;
        color: $primario9;
        padding: 10px 0;
        margin-bottom: 0px;
        cursor: pointer;
        &::before{
            margin-right: 6px;
            font-size: $font-sm;
        }
    }
}
