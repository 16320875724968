body:not(.recogido){
  .sticky-visible{
    display: none;
  }
    .header-nav-top {
        width: 100%;
        position: relative;
        height: 35px;
        z-index: 1;
        margin: auto auto;
        @media(max-width: $screen-lg){
            display: none;
            position: fixed;
            top: 0px;
            left: 0;
            z-index: 999;
            float: none;
            max-width: 100vw;
            width: 100%;
            height: calc(100vh);
            padding: 10px;
            overflow-y: scroll;
            background-color: rgba(53, 72, 87, 0.9);
            box-shadow: 1px 7px 5px 2px rgba(0, 0, 0, 0.3);

        }
    #djv-close-nav-responsive{
        display: none;
    }
    #main-menu {
        padding-top: 0px;
        margin-top: 10px;
        .sf-menu {
            display: flex;
            position: relative;
            padding-left: 0;
            color:black;
            margin-bottom: 0;

            a {
                color: inherit;
                text-decoration: none;
                font-weight: 400;
            }

            ul {
                padding: 0;
                &.indentado{
                  color:#666666;
                  margin-top: -10px;
                  padding-left: 5px;
                  a{
                    &:hover{
                      text-decoration: underline !important;
                    }
                  }
                }

            }

            li {
                list-style: none;
            }

            > li {
                white-space: nowrap;
                margin: 0 .2rem;
                text-transform: uppercase;
                cursor: pointer;
                font-size: $font-sm;
                @include fuente($font-first , $font-regular);
                padding:6px 6px;
                @media(max-width: $screen-lg){
                    font-size: $font-sm;
                    @include fuente($font-first , $font-regular);
                    margin: 0 .5%;
                }
                .marcador {
                    width: 0;
                    height: 0;
                    border-right: 10px solid transparent;
                    border-top: 0px solid transparent;
                    border-left: 10px solid transparent;
                    border-bottom: 10px solid white;
                    position: absolute;
                    z-index: 1;
                    left: 50%;
                    display: none;
                    transform: translateX(-50%);
                    box-shadow: none!important;
                }

                > ul {
                    box-shadow: 1px 1px 6px rgba(0,0,0,.5);
                }

                > div:not(.marcador),
                > ul {
                    display: none;
                    position: absolute;
                    background-color: white;
                    padding: 5px;
                    top: 37px;
                    left: 0px;
                    &.alineado-derecha{
                        left: auto;
                        right: 0;
                    }
                    @media(max-width: $screen-lg){
                        margin-top: 5px;
                        top:0px;
                        box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.5);
                        border-radius: 3px;
                    }
                    span {
                        color:$primario2;
                        display: block;
                        text-transform: none;
                        @media(max-width: $screen-lg){
                            color:$primario2;
                            @include fuente($font-first , $font-bold);
                        }
                    }

                    li {
                        text-transform: initial;
                        line-height: 30px;
                        transition: all 500ms;
                        a {
                            position: relative;
                            font-size: $font-sm;
                            @media(max-width: $screen-md){
                                font-size: $font-sm;
                            }
                            &:hover {
                                font-weight: bold;
                                text-decoration: none;
                            }
                        }
                        &.djv-border-top-dashed{
                            margin-top: 5px;
                            padding-top: 5px;
                        }
                    }
                }

                &:hover {
                    background-color: rgba($primario4, 0.9);
                    color:white;
                    position: relative;
                    >a{
                        color:white;
                    }
                    @media(max-width: $screen-lg){
                        background-color: transparent;
                    }
                    .marcador {
                        display: block;
                        @media(max-width: $screen-lg){
                            display:none;
                        }
                    }

                    > div:not(.marcador),
                    > ul {
                        display: block;
                        color:black;
                        @include fuente($font-first , $font-regular);
                        @media(max-width: $screen-lg){
                            color:white;
                            top:0px;
                        }
                    }
                }
                &.djv-nav-drop-simple{
                    ul{
                        min-width: 242px;
                        box-shadow: 1px 1px 6px rgba(0,0,0,.5);
                        padding: 10px;
                        @media(max-width: $screen-lg){
                            width: 100%;
                        }
                    }
                }
                &.djv-nav-drop-columns {
                    padding-bottom: 10px;
                    > div{
                        box-shadow: 1px 1px 6px rgba(0,0,0,.5);
                        padding: 10px 20px;
                        min-width: 300px;
                        //column-count: 2;
                        .djv-nav-drop-columns-column {
                            margin: 0 0px;
                            padding: 10px 10px 0 10px;
                            overflow: auto;
                            span {
                                @include fuente($font-first , $font-bold);
                                font-size: $font-sm;
                                color:$primario2;
                                display: block;
                                @media(max-width: $screen-md){
                                    font-size: $font-sm;
                                }
                            }
                            > ul {
                                padding: 0;
                                display: block;
                                &:first-of-type {
                                    display: block;
                                    padding: 0;
                                    margin-bottom: 10px;
                                    /*> li:nth-child(10) {
                                        -moz-page-break-after: always;
                                        -webkit-column-break-after: always;
                                        break-after: always;
                                        margin-top: 20px;
                                    }*/
                                }
                            }
                        }
                        &.djv-nav-drop-columns-2 {
                            min-width: 400px;
                            column-count: 2;
                            align-items: stretch;
                            >div{
                                display: block;
                                min-height: 50px;
                                border-left: 1px solid $gris2;
                                padding-left:10px;
                                &:first-of-type{
                                    border-left: 0;
                                    padding-left:0px;
                                }
                                @media(max-width: $screen-md){
                                  border-left: none;
                                  padding-left:0px;
                                }

                            }
                            @media(max-width: $screen-md){
                                column-count: 1;
                                min-width: auto;
                            }
                        }
                        &.djv-nav-drop-columns-3 {
                            min-width: 680px;
                            column-count: 3;
                            align-items: stretch;
                            >div{
                                display: block;
                                min-height: 50px;
                            }
                            @media(max-width: $screen-md){
                                column-count: 1;
                                min-width: auto;
                            }
                        }
                    }
                }

                &.djv-nav-drop-thums {
                    &:hover {
                        > ul {
                            display: grid;
                            grid-template-columns: auto auto auto auto auto;
                            left: 31px;

                            > li {
                                text-align: center;
                                margin-bottom: 10px;
                                font-size: $font-xs;
                                text-align: center;
                                margin-bottom: 10px;
                                padding: 10px;
                                border-bottom: 1px dashed #999;

                                img {
                                    background-color: rgba(100,200,50,.3);
                                    width: 100px;
                                    height: 100px;
                                    display: block;
                                    margin-bottom: 0px;
                                }
                                a {
                                    &::before {
                                        display: none;
                                    }
                                    &:hover {
                                        &::before {
                                            display: none;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
}
