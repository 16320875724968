.container-tabla-tallas{
    display: flex;
    flex-wrap: wrap;
    .tabla-tallas{
        text-align: center;
        border-collapse: separate;
        min-width: 300px;
        font-size: $font-smd;
        @media(max-width: $screen-md){
            width: 100%;
        }
        th{
            padding: 10px;
            color:white;
            @include fuente($font-first , $font-regular);
            font-weight: 400;
            &:nth-of-type(1){
                background-color: $gris6;
            }
            &:nth-of-type(2){
                background-color: #F6A532;
            }
            &:nth-of-type(3){
                background-color: #1A8EBF;
            }
        }
        tr{
            td{
                border-bottom: 1px solid $gris3;
                &:nth-of-type(2){
                    color: #F6A532;
                }
                &:nth-of-type(3){
                    color: #1A8EBF;
                }
            }
        }
    }
    .tabla-tallas-img{
        text-align: center;
        min-width: 300px;
        @media(max-width: $screen-md){
            width: 100%;
        }
    }
}
.container-tabla-precios{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    text-align: center;
    font-size: $font-smd;
    margin: 20px auto;
    @media(max-width: $screen-md){
        overflow-x: scroll;
    }
    .tabla-precios{
        border-collapse: separate;
        min-width: 250px;
        margin: 0 auto;
        @media(max-width: $screen-md){
            width: 100%;
        }
        th{
            padding: 10px;
            color:white;
            @include fuente($font-first , $font-regular);
            font-weight: 400;
            background-color: #1A8EBF;
            &:nth-of-type(2n){
                background-color: #F6A532;
            }
            &:nth-of-type(1){
                background-color: $gris6;
            }
            &:nth-of-type(2){
                background-color: #F6A532;
            }
            &:nth-of-type(3){
                background-color: #1A8EBF;
            }
        }
        tr{
            td{
                border-bottom: 1px solid $gris3;
                color: #1A8EBF;
                padding: 0px;
                &:nth-of-type(2n){
                    color: #F6A532;
                }
                &:nth-of-type(1){
                    color: black;
                }
                &:nth-of-type(2){
                    color: #F6A532;
                }
                &:nth-of-type(3){
                    color: #1A8EBF;
                }
            }
        }
    }
}
