#home-carousel{
    margin-top: 180px;
}

#index{
    h2{
        text-align: center;
    }
    iframe{
        max-width: 100vw;
    }
    #djv-two-columns-layer{
        section{
            margin-top: 50px;
            margin-bottom: 50px;
            @media(max-width: $screen-md){
                margin-top: 20px;
                margin-bottom: 20px;
            }
            header{
                text-align: center;
                margin-bottom: 40px;
                @media(max-width: $screen-md){
                    margin-bottom: 20px;
                    h1{
                        font-size: $font-smd;
                        color: $gris6;
                    }
                }
                h2{
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
                p{
                    margin-bottom: 0;
                    padding-bottom: 0;
                }
            }
        }
    }
    .banner-sequra{
      img{
        max-width: 100%;
      }
    }
    .djv-home-static-blocks{
        p{
            text-align: center;
            margin-bottom: 0px;
        }
        .home-grid-1{
            position: relative;
            display: grid;
            grid-template-areas:
            "primera segunda segunda tercera"
            "primera cuarta quinta quinta"
            "sexta septima septima octava";
            grid-gap: 17px 20px;
            margin-top: 10px;
            @media(max-width: $screen-md){
                display: block;
                >div{
                    max-height: 215px;
                    height: 215px;
                    overflow: hidden;
                    max-width: 100%;
                    margin: 10px auto;
                    img{
                        width: 100%;
                    }
                }
            }
            >div{
                position: relative;
                overflow: hidden;
                a{
                    display: block;
                    position: relative;
                }
                &:hover{
                    p.caption{
                        height: 100%;
                    }
                }
                .caption{
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(35, 35, 35, 0.6);
                    color:white;
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    padding: 10px;
                    margin-bottom: 0;
                    font-size: $font-lg;
                    transition: all 500ms;
                    @media(max-width: $screen-md){
                        top: 165px;
                    }
                }
            }
            >div:nth-of-type(1){
                grid-area:primera;
                &:hover{
                    p.caption{
                        padding: 70% 10px;
                    }
                }
            }
            >div:nth-of-type(2){
                grid-area:segunda;
                &:hover{
                    p.caption{
                        padding: 32% 10px;
                    }
                }
            }
            >div:nth-of-type(3){
                grid-area:tercera;
                &:hover{
                    p.caption{
                        padding: 37% 10px;
                    }
                }
            }
            >div:nth-of-type(4){
                grid-area:cuarta;
                &:hover{
                    p.caption{
                        padding: 37% 10px;
                    }
                }
            }
            >div:nth-of-type(5){
                grid-area:quinta;
                &:hover{
                    p.caption{
                        padding: 32% 10px;
                    }
                }
            }
            >div:nth-of-type(6){
                grid-area:sexta;
                &:hover{
                    p.caption{
                        padding: 38% 10px;
                    }
                }
            }
            >div:nth-of-type(7){
                grid-area:septima;
                &:hover{
                    p.caption{
                        padding: 32% 10px;
                    }
                }
            }
            >div:nth-of-type(8){
                grid-area:octava;
                &:hover{
                    p.caption{
                        padding: 37% 10px;
                    }
                }
            }
        }
        .home-grid-2{
            position: relative;
            display: grid;
            grid-template-areas:
            "primera primera segunda quinta"
            "tercera cuarta cuarta quinta";
            grid-gap: 17px 20px;
            margin-top: 30px;
            @media(max-width: $screen-md){
                display: block;
                >div{
                    max-height: 250px;
                    overflow: hidden;
                    max-width: 100%;
                    margin: 10px auto;
                    img{
                        width: 100%;
                    }
                }
            }
            >div{
                position: relative;
                overflow: hidden;
                a{
                    display: block;
                    position: relative;
                }
                &:hover{
                    p.caption{
                        height: 100%;
                    }
                }
                .caption{
                    position: absolute;
                    bottom: 0;
                    background-color: rgba(35, 35, 35, 0.6);
                    color:white;
                    width: 100%;
                    height: 50px;
                    text-align: center;
                    padding: 10px;
                    margin-bottom: 0;
                    font-size: $font-lg;
                    transition: all 500ms;
                    @media(max-width: $screen-md){
                        top: 165px;
                    }
                }
            }
            >div:nth-of-type(1){
                grid-area:primera;
                &:hover{
                    p.caption{
                        padding: 33% 10px;
                    }
                }
            }
            >div:nth-of-type(2){
                grid-area:segunda;
                &:hover{
                    p.caption{
                        padding: 38% 10px;
                    }
                }
            }
            >div:nth-of-type(3){
                grid-area:tercera;
                &:hover{
                    p.caption{
                        padding: 38% 10px;
                    }
                }
            }
            >div:nth-of-type(4){
                grid-area:cuarta;
                &:hover{
                    p.caption{
                        padding: 31% 10px;
                    }
                }
            }
            >div:nth-of-type(5){
                grid-area:quinta;
                &:hover{
                    p.caption{
                        padding: 68% 10px 0;
                    }
                }
            }
        }
    }
    .full-width{
        background-color: #ECECEC;
        >img{
            display: block;
            margin: 0 auto;
            max-width: 100vw;
        }
    }
    .valoraciones-portada{
        background-color: $gris1;
        padding-top: 30px;
        padding-bottom: 30px;
        margin-top: 50px;
        .valoraciones-portada-container{
            @include djv-container();
            display: grid;
            grid-template-columns: 50% 50%;
            padding-top: 40px;
            @media(max-width: $screen-md){
                display: block;
                padding-top: 15px;
            }
            .eKomiContainer{
                background-image: url("#{$urlimagenes}ekomi_home.png");
                background-repeat: no-repeat;
                background-position-y: 85px;
                background-position-x: 110px;
                height: 320px;
                padding: 0 15px;
                text-align: center;
                @media(max-width: $screen-md){
                    background-image: none;
                    margin-bottom: 20px;
                }
                .ekomi_home_title{
                    @include fuente($font-first , $font-medium);
                    font-size:$font-blg;
                }
                .ekomi_home_text{

                }
                #eKomiWidget_default{
                    > a {
                        margin-left: 120px;
                        @media(max-width: $screen-md){
                            margin-left: 0;
                        }
                    }
                }
                .ver-todas{
                    @include fuente($font-first , $font-regular);
                    font-size: $font-xs;
                    border: 1px solid $gris9;
                    color:$gris9;
                    display: block;
                    margin: 25px auto auto;
                    text-align: center;
                    width: 300px;
                    padding: 5px;
                    @media(max-width: $screen-md){
                        width: 100%;
                        padding: 5px 40px;;
                    }
                }
            }
            .ekomi_last_comments{
                padding: 0 15px;
                text-align: center;
                .ekomi_home_title{
                    @include fuente($font-first , $font-medium);
                    text-align: center;
                    font-size:$font-blg;
                }
                .ekomi_home_comments_container {
                    width: 100%;
                    display: block;
                    overflow: hidden;
                    position: relative;
                    @media(max-width: $screen-md){
                        overflow: visible;
                    }
                    .djv_carouselh-viewer .djv_carouselh-left-arrow,
                    .djv_carouselh-viewer .djv_carouselh-right-arrow{
                        background-color: transparent;
                        border: none;
                        &::before{
                            top:48%;
                            font-size: $font-md;
                            background-color: #ffffffb3;
                            @media(max-width: $screen-md){
                                color:black;
                            }
                        }
                    }
                    .djv_carouselh-viewer .djv_carouselh-left-arrow{
                        &::before{
                            left: 5px;
                        }
                    }
                    .djv_carouselh-viewer .djv_carouselh-right-arrow{
                        &::before{
                            right:5px;
                        }
                    }
                    .comments-home-list{
                        padding: 0;
                        position: absolute;
                        transition: left 1s;
                        @media(max-width: $screen-md){
                            position: relative;
                        }
                        .comment{
                            display: block;
                            float: left;
                            background-color: white;
                            text-align: left;
                            min-width: 555px;
                            width: 555px;
                            padding: 10px;
                            @media(max-width: $screen-md){
                                float: none;
                                display: inline-block;
                                vertical-align: top;
                                min-width: auto;
                                width: calc(100vw - 30px);
                            }
                            .coment-header{
                                padding-top: 15px;
                                overflow: auto;
                                margin-bottom: 15px;
                                white-space: normal;
                                .comment_image{
                                    float: left;
                                    margin-right: 25px;
                                    margin-left: 25px;
                                    @media(max-width: $screen-md){
                                        float: none;
                                        display: inline;
                                        margin-left: 0;
                                        margin-right: 5px;
                                        margin-bottom: 40px;
                                    }
                                    img{
                                        max-width: 90px;
                                    }
                                }
                                .comment_title{
                                    @include fuente($font-first , $font-medium);
                                    font-size: $font-xs;
                                    color:$gris10;
                                    padding-top: 10px;
                                    white-space: normal;
                                }
                                .comment_author{
                                    @include fuente($font-first , $font-light);
                                    font-size: $font-xs - 1px;
                                    color: $gris7;
                                    white-space: normal;
                                }
                            }
                            .comment_body{
                                background-color: $gris7;
                                padding: 25px 20px 20px;
                                clear: both;
                                min-height: 130px;
                                position: relative;
                                &::before{
                                    content: "";
                                    width: 0;
                                    height: 0;
                                    border-left: 10px solid transparent;
                                    border-right: 10px solid transparent;
                                    border-bottom: 10px solid $gris7;
                                    position: absolute;
                                    top: -10px;
                                    left: 50%;
                                    transform: translateX(-50%);
                                }
                                .comment-text{
                                    color: white;
                                    font-size: $font-xs;
                                    font-style: italic;
                                    padding: 5px 15px;
                                    white-space: normal;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    #grandes-pedidos{
        background-color: $gris1;
        background-size: cover;
        background-position: center;
        min-height: 465px;
        padding: 60px 0 50px;
        @media(max-width: $screen-md){
            background-position: left;
            padding: 30px 10px;
            min-height: 335px;
        }
        .content-text{
            width: 480px;
            text-align: center;
            @media(max-width: $screen-md){
                width: 100%;
            }
            h2{
                @include fuente($font-first , $font-bold);
                font-size: 40px;
                @media(max-width: $screen-md){
                    font-size: 36px;
                }
            }
            p{
                @include fuente($font-first , $font-regular);
                font-size: 23px;
                @media(max-width: $screen-md){
                    font-size: 18px;
                }
            }
            ul{
                list-style: none;
                text-align: left;
                display: block;
                padding-left: 0;
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                width: 280px;
                li{
                    @include fuente($font-first , $font-regular);
                    font-size: 19px;
                    margin-bottom: 12px;
                    img{
                        width: 30px;
                        display: inline-block;
                    }
                }
            }
            .btn-hover-animated-orange{
                @include btn-hover-animated($primario2,white,0,10px,10px)
                display: block;
                width: 80%;
                margin: 30px auto 0;
                @include fuente($font-first , $font-medium);
                font-size: 19px;
            }
        }
    }
}
