#product{
    .djv-product-images{
        position: relative;
        .product-flags{
            list-style: none;
            position: absolute;
            top: 10px;
            text-transform: uppercase;
            color: white;
            left: 0px;
            font-size: $font-bmd;
            font-weight: bold;
            margin-left: 0;
            padding-left: 0;
            z-index: 1;
            li{
                padding: 8px;
                border-bottom-right-radius: 8px;
            }
            .discount{
                background-color: $primario9;
            }
            .on-sale{
                background-color: $gris9;
            }
        }
        .product-cover{
            overflow: hidden;
            position: relative;
            margin-bottom: 20px;
            cursor: pointer;
            @media(max-width: $screen-sm){
                min-width: 200px;
                min-height: 0px;
            }
            img{
                width: auto;
                margin-left: auto;
                margin-right: auto;
                display: block;
                @media(max-width: $screen-sm){
                    max-width: 100%;
                }
            }
        }
        .djv-product-img-action{
            display: none;
            position: relative;
            overflow: auto;
            top: -70px;
            left: 50%;
            transform: translateX(-50%);
            background: rgba(255, 255, 255, 0.6);
            -moz-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
            -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
            box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
            padding: 10px;
            cursor: pointer;

            .ver-grande{
                position:relative;
                font-size: $font-md;
                text-align: center;
            }

            @media(max-width: $screen-md){
                display: none;
            }
        }
        .product-thumbnails{
            @media(max-width: $screen-sm){

            }
            &.djv_carouselh{
                .djv_carouselh-viewer{
                    i{
                        &.djv_carouselh-right-arrow{
                            background-color: $gris1;
                        }
                        &.djv_carouselh-left-arrow{
                            background-color: $gris1;
                        }
                    }
                }
            }
            .thumbnails{
                /*width: 100%;*/
                height: 90px;
                overflow: hidden;
                padding: 0;
                @media(max-width: $screen-sm){
                    overflow: auto;
                }
                li{
                    list-style: none;
                    padding: 6px;

                    display: inline-block;
                    width: 110px;
                    min-height: 120px;
                    @media(max-width: $screen-sm){
                        width: auto;
                        height: 90px;
                        width: 90px;
                        min-height: 90px;
                    }
                    &.video-item{
                        cursor: pointer;
                            &::before{
                            content:"";
                            background-image: url("#{$urlimagenes}video-play.png");
                            background-repeat: no-repeat;
                            background-position: center;
                            position: absolute;
                            top: 0px;
                            left: 0px;
                            width: 110px;
                            height: 125px;
                        }
                        img{
                            width: 98px;
                            height: 98px;
                        }
                    }
                    img{
                        width: 100%;
                        //border: 1px solid $gris4;
                    }
                }
            }
        }

    }
}
