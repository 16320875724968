#identity{
    #main{
        @include djv-container();
        @include fuente($font-first , $font-regular);
        h3{
            font-size: $font-lg;
            padding: 10px 0 15px 5px;
            border-bottom: 1px dashed $gris7;
            margin-bottom: 20px;
            text-transform: uppercase;
            @include fuente($font-first , $font-regular);
        }
        .form-fields{
            width: auto;
            display: grid;
            grid-template-areas:
            'item1 item1 item1'
            'item2 item3 item4'
            'item5 item6 empty1'
            'item7 empty2 empty2'
            'item8 item9 item10'
            'item11 item11 item11';
            grid-template-columns: 33% 33% 33%;
            grid-gap: 20px;
            @media(max-width: $screen-lg){
                display: block;
            }
            label{
                @media(max-width: $screen-lg){
                    display: block;
                    width: 100%;
                    margin-bottom: 15px;
                }
                &.required{
                    span{
                        &::before{
                            content: "*";
                            color:$complementario2;
                            position: relative;
                            padding-right: 5px
                        }
                    }
                }
                span{
                    display: block;
                }
                input:not([type=radio]),input:not([type=checkbox]), select{
                    border: none;
                    background-color: #eee;
                    padding: 5px;
                    width: 100%;
                }
                input[type="radio"],input[type="checkbox"]{
                    display: inline-block;
                    margin: 5px;
                    padding: 5px;
                }
            }
            &::after{
                grid-area: item11;
                content: "Los campos marcados con un asterisco son obligatorios";
                padding-bottom: 20px;
            }
            >label:nth-of-type(1){
                grid-area: item1;
                label{
                    text-align: center;
                    margin-right: 10px;
                    @media(max-width: $screen-lg){
                        display: inline-block;
                        width: 25%;
                    }
                    input{
                        display: inline-block;
                        width: auto;
                        padding-right: 5px;
                    }
                }
            }
            >label:nth-of-type(2){
                grid-area: item2;
            }
            >label:nth-of-type(3){
                grid-area: item3;
            }
            >label:nth-of-type(4){
                grid-area: item4;
            }
            >label:nth-of-type(5){
                grid-area: item5;
            }
            >label:nth-of-type(6){
                grid-area: item6;
            }
            >label:nth-of-type(7){
                grid-area: item7;
            }
            >label:nth-of-type(8){
                grid-area: item8;
                display: grid;
                grid-template-columns: 20px auto;
                align-items: flex-start;
                grid-gap: 5px;
                @media(max-width: $screen-lg){
                    grid-template-columns: 40px auto;
                }
                span{
                    display: inline-block;
                }
            }
            >label:nth-of-type(9){
                grid-area: item9;
                display: grid;
                grid-template-columns: 20px auto;
                align-items: flex-start;
                grid-gap: 5px;
                @media(max-width: $screen-lg){
                    grid-template-columns: 40px auto;
                }
                span{
                    display: inline-block;
                }
            }
            >label:nth-of-type(10){
                grid-area: item10;
                display: grid;
                grid-template-columns:20px auto;
                align-items: flex-start;
                grid-gap: 5px;
                @media(max-width: $screen-lg){
                    grid-template-columns: 40px auto;
                }
                span{
                    display: inline-block;
                }
            }
        }
        .form-footer{
            [data-link-action="save-customer"]{
                @include btn-hover-animated($complementario1,white,0,5px,5px)  ;
                @media(max-width: $screen-lg){
                    width: 100%;
                    display: block;
                    margin-top: 15px;
                }
            }
        }
        .page-footer{
            border-top:  1px solid $gris3;
            padding-top: 20px;
            margin-top: 20px;
            ul{
                margin: 0;
                padding: 0;
                li{
                    @include btn-hover-animated($complementario4,white,0,5px,5px);
                    display: inline-block;
                    margin-right: 10px;
                    @media(max-width: $screen-lg){
                        width: 100%;
                        display: block;
                        margin-bottom: 15px;
                    }
                    a{
                        color: inherit;
                        @media(max-width: $screen-lg){
                            width: 100%;
                            display: block;
                        }
                    }
                }
            }
        }
    }

}
