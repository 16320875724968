.cart-preview.cart-overview {
    width: 100%;
    position: inherit;
}

.cart-preview.cart-overview .body {
    display: block;
    position: inherit;
    width: 100%;
    color: $gris9;
}

.cart-preview {
    float: right;
    position: relative;

    a,
    a:hover,
    a:visited {
        text-decoration: none;
        color: inherit;
    }

    ul {
        margin: 0;
        padding: 0;
        margin-top: 20px;
        margin-bottom: 20px;

        li {
            list-style: none;
            margin-bottom: 0px;

            > * {
                display: inline-block;
                vertical-align: top;
            }
        }
    }

    .header {
        display: block;
        font-weight: bold;
        cursor: pointer;
        background-color: $gris9;
        text-align: center;
        align-content: center;
        height: 40px;
        padding: 0 15px 0 10px;
        > :first-child {
            float: left;
        }
        > :last-child {
            float: right;
        }

        i {
            font-size: 32px;
            display: block;
            line-height: 13px;
            padding-top: 6px;
        }

        span {
            position: absolute;
            top: 2px;
            right: 19px;
            font-size: $font-xs;
            color: $primario2;
            text-align: center;
            display: block;
            width: 20px;
        }
    }

    .body {
        display: none;
        width: calc(100vw - 1.1rem);
        background-color: white;
        right: 0;
        border: 1px solid $primario3;
        border-top: 0;
        padding: 10px;
        color: $gris9;

        &:hover {
            display: block;
            position: absolute;
        }

        > ul {
            li {
                list-style: none;
                padding: 4px 7px;
                display: grid;
                grid-template-columns: 8% 60% 26% 6%;
                &:hover {
                    background-color:lighten($primario1, 25%) ;
                }
                .product-quantity {
                    color: $primario3;
                    border-bottom: 0 !important;
                }

                .product-quantity::after {
                    content: 'x';
                }

                .product-name {
                    text-align: left;
                    padding-left: 5px;
                }

                .remove-from-cart {
                    i::before {
                        color: $primario10;
                    }
                    float: right;
                }

                .product-price {
                }
            }
        }

        .cart-subtotals {
            border-top: 1px dashed $gris3;
            padding-top: 20px;
            margin-top: 20px;
            text-align: right;
            padding-right: 10px;
            .value{
                @include fuente($font-first , $font-bold);
            }

            .products {
                text-align: right;

            }

            .shipping {

            }
        }
        @media(min-width: $screen-sm) {
            width: 400px;
        }

        .ps-cart-remove-icon {
            position: relative;
            &::before {
                color: $primario10;
                display: block;
                font-style: normal;
                font-weight: normal;
                line-height: 0;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                padding: 12px 3px 4px 2px;
                text-align: center;
            }
        }
        .blockcart-finalizar{
            background-color: #13a323;
            color: white;
            margin: 20px auto 0px;
            display: block;
            width: 100%;
            padding: 6px;
            text-align: center;
            text-transform: uppercase;
            cursor: pointer;
            border: 2px solid #13a323;
            transition: all 500ms;
            &:hover {
                box-shadow: 2px 2px 9px rgba(0, 0, 0, 0.4);
                background-color: white;
                color: #61af26;
                border: 2px solid #61af26;
            }
        }
    }

    &:hover .body {
        display: block;
        position: absolute;
        z-index: 999;
    }
}



/* this is to make the containers take up vertical space */
.cart-preview .cart-total > div::after,
.cart-preview .header::after {
    clear: both;
    content: "\00a0";
}

.cart-preview .cart-total .label::after {
    content: ": ";
}

.cart-preview .cart-total > div {
    clear: both;
    border-bottom: 1px solid #ccc;
}

.cart-preview .cart-total > div:not(:last-child) {
    margin-bottom: 5px;
}

.cart-preview .cart-total {
    font-weight: bold;
    border-top:  1px solid $gris3;
    padding-top: 20px;
    margin-top: 20px;
    text-align: right;
    padding-right: 10px;
    span {
        font-size: $font-lg;
    }
}
