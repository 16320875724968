.pagination{
    display: block;
    height: 35px;
    padding-left: 0;
    list-style: none;
    text-align: right;
    margin-top: 15px;
    .pagination-summary{
        display: inline-block;
        font-size: $font-xs;
        @media(max-width: $screen-md){
            font-size: $font-xxs;
        }
    }
    ul{
        display: inline-block;
        list-style: none;
        @media(max-width: $screen-md){
            padding-left: 2px;
        }
        li{
            display: inline-block;
            a{
                color: black;
                border: 1px solid $gris5;
                padding: 0 5px;
                font-size: $font-sm;
                @media(max-width: $screen-md){
                    font-size: $font-xxs;
                }
                &.disabled{
                    color: $gris5;
                    border: 0;
                }
                &.djv-icon-arrow-right,&.djv-icon-arrow-left{
                    border: 0;
                }
            }
        }
    }
}
