@media(min-width: $screen-md) {
    .djv_carouselh {
        position: relative;

        >* {
            overflow: hidden;
            position: relative;
        }

        .djv_carouselh-left-arrow,
        .djv_carouselh-right-arrow {
            position: absolute;
            z-index: 1;
            top: 50%;
            transform: translateY(-50%);
            font-size: 36px;
            width: 44px;
            height: 100%;
            cursor: pointer;
            background-color: transparent;

            &::before {
                position: absolute;
                top: 50%;
                transform: translateY(-70%);
                background-color: #eeeefa;
                padding: 18px 3px;
            }
        }

        .djv_carouselh-left-arrow {
            left: 0;
            border-left: 1px solid #ccc;

            &::before {
                left: 0px;
                font-size: $font-sm;
                content: '\004b';
                display: inline-block;
                font-family: "comunes";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                border-right: 1px solid #ccc;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
            }
        }

        .djv_carouselh-right-arrow {
            right: 0;
            border-right: 1px solid #ccc;

            &::before {
                right: 0px;
                font-size: $font-sm;
                content: '\004c';
                display: inline-block;
                font-family: "comunes";
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                border-left: 1px solid #ccc;
                border-top: 1px solid #ccc;
                border-bottom: 1px solid #ccc;
            }
        }

        .djv_carouselh-viewer {
            position: relative;

            >* {
                text-align: left;
                position: absolute;
                transition: left 1s;

                >* {
                    margin: 0;
                    padding: 5px;
                    float: left !important;
                    clear: none;
                }
            }
        }
    }
}

@media(max-width: $screen-md) {
    .djv_carouselh {
        &.swipe::after {
            display: inline-block;
            font-family: 'comunes';
            font-style: normal;
            font-weight: normal;
            line-height: 1;
            -webkit-font-smoothing: antialiased;
            -moz-osx-font-smoothing: grayscale;
            content: '\006e';
            position: relative;
            left: 50%;
            top: 5px;
            transform: translateX(-50%);
            margin: 10px 0;
            font-size: 42px;
            color: $gris5;
        }

        >* {
            width: 100%;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            -webkit-overflow-scrolling: touch;
            -webkit-scroll-snap-type: mandatory;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-points-x: repeat(100%);
            scroll-snap-points-x: repeat(100%);

            >* {
                scroll-snap-align: start;
                width: 100%;
                position: relative;
                display: inline-block;
                margin-right: -4px;
                text-align: center;
            }
        }
    }
}