#my-account {
    .link-list {
        @include fuente($font-first , $font-regular);
        padding-left: 0;
        display: grid;
        grid-template-columns: calc(33% - 10px) calc(33% - 10px) calc(33% - 10px);
        grid-gap: 10px;
        @media(max-width: $screen-sm) {
            display: block;
        }

        li {
            list-style: none;
            padding: 5px 10px;
            background-color: $primario5;
            transition: all 500ms;
            @media(max-width: $screen-sm) {
                margin-bottom: 10px;
            }

            a {
                color: white;
            }

            &::before {
                /*content: "";
                    width: 30px;
                    margin-right: 5px;
                    padding-right: 5px;
                    border-right: 1px solid $gris2;
                    transition: all 500ms;*/
            }

            &:hover {
                background-color: $primario11;
                color: white;
                &::before {
                    /*    border-right: 1px solid white;*/
                }
            }
            &:nth-of-type(4){
                display: none;
            }
            &:nth-of-type(5){
                display: none;
            }
            &:last-of-type{
                display: none;
            }
        }
    }
}
#history{
    .page-content{
        @include djv-container();
        h2{
            font-size: $font-md;
            border-bottom: 1px dashed $gris3;
            padding-bottom:20px;
            margin-bottom:20px;
        }

        .page-footer{
            @include djv-container();
            margin-top: 40px;
            padding-top: 20px;
            border-top: 1px dashed #999;
            ul{
                margin: 0;
                padding: 0;
                li{

                    list-style: none;
                    display: inline-block;
                    a{
                        @include btn-hover-animated($complementario4,white,0,5px,5px);
                    }
                }
            }
        }
    }
    .history-list{
        .history-list-header{
            display: grid;
            grid-template-columns: repeat(3,12%) 22% repeat(2,12%) 18%;
            border-bottom: solid 1px black;
            padding-bottom: 10px;
            margin-bottom: 20px;
            @media(max-width: $screen-sm){
                display: none;
            }
            >div{
                @include fuente($font-first , $font-medium);
                font-size: $font-sm;
            }
        }
        .history-list-body{
            .history-list-body-item{
                display: grid;
                grid-template-columns: repeat(3,12%) 22% repeat(2,12%) 18%;
                padding: 20px 0;
                border-bottom: 1px solid $gris2;
                @media(max-width: $screen-sm){
                    display:block;
                }
                .history-list-body-item-col{
                    align-content: center;
                    font-size: $font-xs - 1px;
                    @media(max-width: $screen-md){
                        margin-bottom: 10px;
                        text-align: left;
                    }
                    .etiqueta{
                        display:none;
                        @media(max-width: $screen-md){
                            display: inline-block;
                            font-weight: 600;
                            margin-right: 10px;
                        }
                    }
                    [data-link-action="view-order-details"]{
                        @include btn-hover-animated($complementario4,white,0,5px,5px);
                        display: inline-block;
                        margin-bottom: 8px;
                        font-size: $font-xs - 2px;
                    }
                    .order-reorder-link{
                        @include btn-hover-animated($complementario1,white,0,5px,5px);
                        display: inline-block;
                        font-size: $font-xs - 2px;
                    }
                    .order-status-label {
                        font-size: $font-xs - 2px;
                        white-space: nowrap;
                        color: white;
                        padding: 5px;
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        border-radius: 50%;
                        text-align: center;
                    }
                }
            }
        }
    }
}
#order-detail{
    @media(max-width: $screen-md){
        font-size: $font-xxs;
    }
    #main{
        @include fuente($font-first , $font-regular);
        @include djv-container();
        margin-top: 40px;
    }
    #order-infos{
        background-color: $gris1;
        display: block;
        padding: 30px;
        margin: 16px 0px;
        outline:1px dashed $gris3;
        outline-offset:-10px;
        color: $gris8;
        a{
            @include btn-hover-animated($complementario1,white,0,5px,5px);
            display: inline-block;
            margin-bottom: 15px;
            text-decoration: none;
        }
    }
    #order-history{
        padding: 16px;
        margin-bottom: 16px;
        @media(max-width: $screen-md){
            padding: 16px 6px;
        }
        h1{
            font-size: $font-lg;
            padding: 10px 0 15px 5px;
            border-bottom: 1px dashed $gris7;
            margin-bottom: 20px;
            text-transform: uppercase;
            @media(max-width: $screen-md){
                font-size: $font-md;
            }
        }
        table{
            thead{
                border-bottom: 1px solid #999;
            }
            td,th{
                padding: 8px;
                @media(max-width: $screen-md){
                    font-size: $font-xxs;
                }
                .order-status-label {
                    padding:4px 8px;
                    font-size: 14px;
                    white-space: nowrap;
                    color: white;
                    @media(max-width: $screen-md){
                        font-size: $font-xxs;
                    }
                }
            }
        }

    }
    #delivery-address,#invoice-address{
        width: calc(50% - 20px);
        margin: 8px;
        display: inline-block;
        border: 1px dashed #999;
        padding: 16px;
        @media(max-width: $screen-sm){
            width: calc(100% - 20px);
            h1{
                font-size: $font-md;
            }
        }
    }
    .box{
        ul{
            margin:10px 0;
            padding: 0;
            li{
                list-style: none;
            }
        }
    }
    #order-products{
        margin: 40px 0;
        th{
            white-space: nowrap;
            padding: 8px;
            background-color:$gris2;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
                white-space: normal;
            }
        }
        td{
            padding: 8px;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
        }
        tfoot, .line-total{
            border-top: 2px solid $gris5;
        }
    }
    #order-shipping{
        display: block;
        width: 100%;
        tr{
            width: 100%;
            @media(max-width: $screen-sm){
                font-size: $font-xs - 2px;
            }
            td{
                padding: 8px;
                @media(max-width: $screen-sm){
                    font-size: $font-xs - 2px;
                }
            }
            th{
                white-space: nowrap;
                padding: 8px;
                background-color:$gris2;
                @media(max-width: $screen-sm){
                    white-space: normal;
                }
            }
        }
    }
    .order-message-form{
        margin-top: 20px;
        padding-top: 20px;
        border-top: 1px solid $gris6;
        @media(max-width: $screen-md){
            padding: 10px;
            h1{
                font-size: $font-md;
            }
        }
        .form-fields{
            label{
                display: block;
                margin-bottom: 15px;
                span{
                    margin-right: 10px;
                }
                @media(max-width: $screen-md){
                    textarea, select{
                        width: 100%;
                    }
                }
            }
        }
        .form-footer{
            button{
                @include btn-hover-animated($complementario1,white,0,5px,5px);
            }
        }
    }
}
