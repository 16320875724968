// Only when this is empty can I sleep well
ul.link-list{
    >a#identity-link{
        display: inline-block;
        list-style: none;
        padding: 5px 10px;
        background-color: #f6f6f6;
        border: 1px solid #E2E2E2;
        transition: all 500ms;
        color:black;
        .link-item{
            i{
                display: none;
            }
        }
    }
}
.pendiente{
    border:1px solid red;
}
.djv_debug{
    position: fixed;
    opacity: 0.1;
    top: 80px;
    left: 10px;
    background-color: black;
    border: 4px solid black;
    z-index: 1000;
    padding: 20px;
    width: 110px;
    height: 90px;
    transition: all 1s;
    overflow: hidden;
    &::before{
        content: "DEBUG";
        color: red;
        font-weight: bold;
        display: block;
    }
    &:hover{
        width: 55vw;
        opacity: .9;
        height: calc(100vh - 300px;);
        background-color: white;
        overflow: auto;
    }
}
.page-content.page-my-account, .page-content.page-address, .page-customer-account .page-content{
    padding: 5px 10px;
}
#myFrame{
    border: 4px solid $primario2;
    box-shadow: 1px 1px 10px #333;
    border-radius: 3px;
}

#module-djvpersonalizeproduct-cart,
#module-djvpersonalizeproduct-cartv4 {
    #chat-application{
        display: none !important;
    }
    .zopim{
        display: none !important;
    }
    .djv-product-personalize-right-tools{
        height: auto !important;
    }
    #footer{
        display: none;
    }
}

#module-faqs-display{
    .m-link-2{
        display: none !important;
    }
}
#name_customer_form_group{
    .input-group{
        input[type="text"]{

        }
    }
}
#mpm_faqs_form_container{
    #mpm_faqs_form_content {
        .input-group-addon{
            .input-group-addon-inner{
                display: block;
                height: 40px;
            }
        }
        .form-control{
            border: 0 !important;
        }
    }
    input[type="text"]{
        padding-left: .75rem !important;
    }
    #mpm_faqs_select_wrapper{
        .selected{
            padding: 9px 15px 11px 12px;
        }
    }
    .input-group-addon{
        border-radius: 5px;
        padding-left: 5px;
        i{
            margin-top: 15px;
        }
    }
    .input-group-addon .input-group-addon-inner {
        border-right: 1px solid;
        padding-right: 7px;
        padding-top: 9px;
        padding-left: 10px;
    }

}
