*{
  box-sizing: border-box;
}
body{
  @include fuente($font-first , $font-regular);
  color:black;
}
h2{
    @include fuente($font-second , $font-regular);
    text-transform: uppercase;
    font-size: $font-xl;
    text-align: center;
    color: black;
    @media(max-width: $screen-md){
        font-size: $font-blg;
    }
}
h3{
    @include fuente($font-second , $font-regular);
    font-size: $font-blg;
}
a{
    color:$primario2;
    font-weight: $font-bold;
    &:hover{
        color:$primario2;
        text-decoration: underline;
    }
}
p{
    @include fuente($font-first , $font-regular);
}
.full-width{
    @include full-width();
}
.djv-container{
    margin-right: auto;
    margin-left: auto;
    max-width: 1170px;
    min-width: 920px;
    @media(max-width:$screen-lg){
        max-width: 920px;
        min-width: 750px;
    }
    @media(max-width:$screen-md){
        max-width: 750px;
        min-width: 480px;
    }
    @media(max-width:$screen-xs){
        max-width: 500px;
        min-width: 300px;
    }
}
.grid-2,.grid-3,.grid-4,.grid-5{
    display: grid;
    grid-gap: 10px;
}
.grid-2{
    grid-template-columns: repeat(2, calc(50% - 10px));
}
.grid-3{
    grid-template-columns: repeat(3,calc(33.33% - 10px));
}
.grid-4{
    grid-template-columns: repeat(4,calc(25% - 10px));
}
.grid-5{
    grid-template-columns: repeat(5,calc(20% - 10px));
}
.xs-visible{
    @media(max-width: $screen-md){
        display: block!important;
    }
}
    .blackfridayflag {
        position: absolute;
        top: 0px;
        right: 0px;
        z-index: 1;
        img{
            width: 75px;
        }
    }
.ps-alert-success{
    li{
        list-style: none;
        border-bottom: 1px dashed darken($complementario1, 15%);
        i{
            float: left;
            margin-right: 20px;
            color:$complementario1;
            font-size: $font-xxl;
            padding-bottom: 20px;
        }
    }
}
.stars{
    i{
      font-size: 11px;
      color: black;
    }
    span{
        font-size: $font-xxxs;
        display: inline-block;
        position: relative;
        width: 20px;
        height: 20px;
        a{
            position: absolute;
            top: 6px;
            font-size: 11px;
            color: black;
        }
    }
}
@media(max-width: $screen-md){
    body{
        overflow-x: hidden;
    }
    .xs-hidden{
        display: none;
    }
}
#lgcookieslaw_banner{
    #lgcookieslaw_accept{
        background-color: #dadada;
        padding: 5px;
        display: block;
        border: 0;
        border-radius: 6px;
    }
}
.microdatos{
    display: none;
}
.hidden{
  display: none;
}
