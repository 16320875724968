#left-column-filters-sticky{
    position: sticky;
    float: left;
    top: 100px;
    @media(max-width: $screen-md){
        position: relative;
        float: none;
        top:0;
    }
}
#djv-navigation-left {
    .djv_navigation-left-filters {
        .djv_navigation-left-filters-header{
            display: grid;
            grid-template-columns: 80% 20%;
            span{
                font-size: 11px;
                color: $gris3;
                padding-top: 8px;
            }
            span.title{
                font-family: "Oswald-Medium";
                font-size: $font-lg;
                margin-bottom: 20px;
                color:$gris9;
                padding-top: 0;
            }

        }
        li{
            display: grid;
            grid-template-columns: 80% 20%;
            margin-bottom: 0;
            border-bottom:1px solid $gris1;
            font-size: $font-sm;
            background-color: rgba(130,130,130,0);
            transition: background-color 750ms;
            padding: 1px;
            a{
                padding: 2px;
            }
            span{
                display: none;
                text-align: center;
                padding: 1px;
                font-size: 11px;
                color: $gris3;
            }
            &:hover{
                background-color: rgba(245,245,245,1);
            }
        }
    }
}
