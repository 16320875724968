.product-quantity{
    border-bottom: 1px solid $gris3;
    p{
        font-size: $font-xs;
        display: block;
        margin-bottom: 0;
    }
    label{
        @include fuente($font-first , $font-bold);
        display: block;
        font-size: $font-sm;
        span{
            @include fuente($font-first , $font-regular);
            font-size: $font-xs;
            color: $gris6;
        }
    }
    .product-add-to-cart-quantity{
        display: block;
        height: 32px;
        overflow: auto;
        margin-bottom: 20px;
        width: 300px;
        #quantity_wanted{
            width: 200px;
            text-align: center;
            background-color: white;
            border: 1px solid $gris3;
            height: 32px;
            display: block;
            float: left;
            padding-top: 3px;
            &[value="0"]{
                color: white;
            }
        }
        .djv-product-add-to-cart-quantity-btn-less{
            background-color: white;
            border: 1px solid $gris3;
            border-top-left-radius: 4px;
            border-bottom-left-radius: 4px;
            display: block;
            height: 32px;
            margin-top: 0px;
            position: relative;
            float: left;
            border-right: none;
            padding: 0px 10px;
            font-size: 29px;
            line-height: 23px;
            cursor: pointer;
        }
        .djv-product-add-to-cart-quantity-btn-plus{
            background-color: white;
            border: 1px solid $gris3;
            border-top-right-radius: 4px;
            border-bottom-right-radius: 4px;
            display: block;
            height: 32px;
            margin-top: 0px;
            position: relative;
            float: left;
            border-left: none;
            padding: 2px 10px;
            line-height: 21px;
            cursor: pointer;
        }
    }
}
#product{
    .product-add-to-cart-quantity{
        @media(max-width: $screen-md){
            height: 42px;
            margin: 20px 0px;
            width: 221px;
        }
        #quantity_wanted{
            @media(max-width: $screen-md){
                height: 42px;
                width: 132px;
            }
        }
        .djv-product-add-to-cart-quantity-btn-less{
            @media(max-width: $screen-md){
                height: 42px;
                padding: 5px 15px;
                width: 39px;
            }
        }
        .djv-product-add-to-cart-quantity-btn-plus{
            @media(max-width: $screen-md){
                height: 42px;
                padding: 9px 15px;
                width: 39px;
            }
        }
    }
}
