section .page-cms{
    @include djv-container();
    @include fuente($font-first , $font-regular);
    padding: 5px 15px;
    margin: 20px auto;
    h1{
        margin-bottom: 20px;
        padding-bottom: 15px;
        @include fuente($font-first , $font-extrabold);
        font-size: $font-blg + 2;
        text-align: center;
        text-transform: uppercase;
        @media(max-width: $screen-md){
            font-size: $font-md;
            margin-bottom: 5px;
            padding-bottom: 5px;
        }
    }
    h2{
        margin-bottom: 20px;
        text-align: left;
        text-transform: uppercase;
        @include fuente($font-first , $font-bold);
        font-size: $font-blg;
        &::before{
            content:"";
            display: block;
            width: 120px;
            height: 5px;
            background-color: $primario1;
            margin-bottom: 16px;
        }
        @media(max-width: $screen-md){
            font-size: $font-md;
        }
    }
    h3{
        margin-bottom: 20px;
        @include fuente($font-first , $font-bold);
        font-size: $font-lg;
    }
    ul{
        list-style-type: disc;
        box-sizing: border-box;
        &.nobullet{
            list-style-type:none;
        }
        @media(max-width: $screen-md){
            display: block;
            li{
                display: block;
                margin-bottom: 20px;
            }
        }
    }
    ol{
        color: $primario1;
        font-weight: $font-bold;
        font-size: $font-slg;
        li{
            span{
                font-size: $font-md;
                font-weight: $font-regular;
                color: black;
                a{
                    color: $primario1;
                    font-weight: $font-bold;
                }
            }
        }
    }
    .cms-content-two-cols{
        display: grid;
        grid-template-columns: 32% 68%;
        @media(max-width: 768px){
            display: block;
            position: relative;
        }
        .cms-left{
            padding-right: 30px;
            @media(max-width: 768px){
                position: sticky;
                top: 90px;
                padding: 5px 0;
                background-color: white;
                z-index: 10;
            }
            .cms-left-sticky{
                position: sticky;
                top: 150px;
                @media(max-width: 768px){
                    position: relative;
                    top:0;
                    display: flex;
                    margin: 12px 0;
                }
            }
            .cms-menu{
                background-color: #5f5f5f;
                padding: 20px;
                border-radius: 16px;
                @media(max-width: 768px){
                    background-color: $primario1;
                    padding: 8px;
                    width: calc(100% - 60px);
                }
                .title-menu{
                    color: white;
                    border-bottom: 1px solid $primario1;
                    padding-bottom: 15px;
                    font-size: 18px;
                    font-weight: bold;
                    @media(max-width: 768px){
                        padding-bottom: 0;
                        margin-bottom: 0;
                        border: 0;
                        font-size: 16px;
                    }
                }
            }
            .cms-dudas{
                display: flex;
                font-size: 14px;
                padding: 10px;
                margin-top: 16px;
                background-color: #f0f0f0;
                border-radius: 16px;
                @media(max-width: 768px){
                    display: block;
                    margin-top: 0;
                    padding: 0;
                    background-color: transparent;
                }

                img{
                    width: 90px;
                    margin-right: 16px;
                    @media(max-width: 768px){
                        width: 40px;
                        margin-right: 0;
                        margin-left: 16px;
                    }
                }
                .cms-dudas-text{
                    @media(max-width: 768px){
                        display: none;
                    }
                    strong{
                        font-size: 18px;
                    }
                    p{
                        margin-bottom: 8px;
                    }
                }
            }
        }
        .cms-right{
            padding-right: 20px;
            @media(max-width: $screen-md){
                .expandText{
                    margin-bottom: 20px;
                }
            }
        }
        .cms-menu-list{
            padding: 0 5px;
            font-size: 15px;
            @media(max-width: 768px){
                position: fixed;
                display: none;
                top: 0px;
                left: 0px;
                z-index: 10;
                width: 100vw;
                height: calc(100vh - 80px);
                padding: 16px;
                background-color: rgba(0, 0, 0, 0.95);
            }
            .cms-menu-close{
              display: none;
              color: white;
              @media(max-width: 768px){
                position: absolute;
                top: 5px;
                right: 5px;
                display: block;
                font-size: 32px;
              }
            }
            .cms-menu-list-first-level{
                margin: 15px 0;
                .scrollTo{
                    position: relative;
                    cursor: pointer;
                    color: white;
                    font-weight: normal;
                    &.active{
                        color: $primario1;
                        &::before{
                            content: "";
                            position: absolute;
                            top: 5px;
                            left: -15px;
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color:  $primario1;
                        }
                    }
                }
                &:hover{
                    color: $primario1;
                    .scrollTo{
                        color: $primario1;
                        text-decoration: none;
                        &::before{
                            content: "";
                            position: absolute;
                            top: 5px;
                            left: -15px;
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color:  $primario1;
                        }
                    }
                }
            }
            .cms-menu-list-second-level{
                padding-left: 15px;
                margin: 15px 0;
                @media(max-width: 768px){
                  display: none;
                }
                .scrollTo{
                    position: relative;
                    cursor: pointer;
                    color: #a8a8a8;
                    font-weight: normal;
                    &.active{
                        color: $primario1;
                        &::before{
                            content: "";
                            position: absolute;
                            top: 5px;
                            left: -15px;
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color:  $primario1;
                        }
                    }
                }
                &:hover{
                    color: $primario1;
                    .scrollTo{
                        color: $primario1;
                        text-decoration: none;
                        position: relative;
                        &::before{
                            content: "";
                            position: absolute;
                            top: 5px;
                            left: -15px;
                            display: block;
                            width: 10px;
                            height: 10px;
                            border-radius: 50%;
                            background-color:  $primario1;
                        }
                    }
                }
            }
        }
    }
}
#cms{
    #content{
        .cms-content{
            @include djv-container();
            img{
                max-width: 100%;
            }
            p{
                margin-bottom: auto;
            }
            > .header{
                margin-bottom: 25px;
                text-align: center;
                h1{
                    @include fuente($font-first , $font-semibold);
                    font-size: $font-lg + 6px;
                }
                p{

                }
                img{
                    margin:25px auto 25px;
                }
            }

            &.cms-tienda{
                .grid-2{
                    align-content: start;
                    margin-bottom: 25px;
                    @media(max-width: $screen-md){
                        display: block;
                    }
                }
                .grid-3{
                    align-content: start;
                }
                .tienda-contacto,.tienda-visitanos{
                    text-align: center;
                    .header{

                        margin-bottom: 20px;
                        h3{
                            margin: 10px auto 20px;
                            font-size: $font-blg;
                        }
                    }
                }
                .tienda-contacto{
                    p{
                        font-size: $font-sm;
                    }
                    img{
                        width: 54px;
                        display: block;
                        margin: 5px auto;
                    }
                }
            }
        }
    }
}
