.product-prices{
    display: block;
    text-align: left;
    .product-price{
        .prices{

            @media(max-width: $screen-md){
                font-size: $font-blg;
            }
            .price-unit,.regular-price,.price-until,.advertiseWithoutTaxes{
                margin-top: 0px;
                @include fuente($font-first , $font-regular);
                font-size: $font-xxs;
                color:$gris7;
                line-height: 24px;
                @media(max-width: $screen-md){
                    font-size: $font-xxxs;
                    line-height: 11px;
                }
            }
            .price-until{
                color: black;
                text-align: right;
                padding-right: 6px;
            }
            .price-to-pay{
                @include fuente($font-first , $font-bold);
                font-size: $font-xl;
                color:$primario2;
                padding: 0 6px;
            }
            .regular-price{
                font-size: 16px;
                width: 100%;
                margin-bottom: -10px
            }
            .price-unit{
                align-self: start;
            }
            .advertiseWithoutTaxes{
                align-self: end;
            }
        }
    }

    .product-discount{
        margin-bottom: 5px;
        //min-height: 50px;
        .discount-percentage,.discount-amount{
            display: inline-block;
            min-height: auto;
            padding: 0 5px;
            background-color:$primario10;
            border: 1px solid darken($gris1,15%);
            @include fuente($font-first , $font-medium);
            font-size: 22px;
            color:white;
        }
        .regular-price{
            display: inline-block;
            @include fuente($font-first , $font-medium);
            font-size: $font-xs;
            text-decoration: line-through;
            color:$gris8;
        }
        .discount-percentage{display: none;}
    }

    .has-discount{
        .with-taxes{
            color:$primario10;
        }
    }
    .product-unit-price{
        color:$gris8;
        font-size: 20px;
        position: relative;
        width: 20%;
        @include fuente($font-first , $font-bold);
        &::after{
            content:"P.V.P. unidad";
            position: absolute;
            left: 50%;
            font-size: 11px;
            top: -21px;
            transform: translateX(-50%);
        }
    }
}
.page-content{
    .product-prices{
        display: block;
        text-align: left;
        >div:not(.product-discount){
            display: flex;
            justify-content: space-around;
            flex-flow: row wrap;
            line-height: 29px;
            white-space: nowrap;
            margin: 0px;
            grid-gap:20px;
            &.price-only-personalizable{
              width: 100%;
              @media(max-width: $screen-md){
                .product-price{
                  display: block;
                }
              }
            }

            @media(max-width: $screen-md){
              >div{
                width: 50%;
              }
            }
        }
        .sequra-promotion-widget{
          width: 100%;
          padding-bottom: 0!important;
          @media(max-width: $screen-md){
            width: 100%!important;
          }
        }
        .product-price{
            display: flex;
            justify-content: start;
            .precio-personalizadas,.precio-sinpersonalizar{
                display: grid;
                grid-template-areas:
                    "until regular empty"
                    "until pay unit"
                    "until pay advertise";
                grid-template-rows: repeat(3,18px);
                grid-template-columns: 45px auto 56px;
                align-items: center;
                padding: 0 25px;
                @media(max-width: $screen-md){
                  display: block;
                  >span{
                    display: block;
                    text-align: center;
                    margin-bottom: 6px;
                  }
                }
                .price-until{
                    grid-area: until;
                    align-self: end;
                }
                .price-to-pay{
                    grid-area: pay;
                }
                .regular-price{
                    grid-area: regular;
                }
                .price-unit{
                    grid-area: unit;
                    align-self: start;
                }
                .advertiseWithoutTaxes{
                    grid-area: advertise;
                }
            }
            .precio-sinpersonalizar{
              width: 50%;
              display: none;
                .price-to-pay{
                    color: $primario9;
                }
            }
        }
    }
}
.aclaracion-precios{
    margin-top: -15px;;
    @media(max-width:768px){
        margin-top: auto;
        width: 100% !important;
    }
}