.djv-breadcrumb{
    font-size: $font-xs;
    padding: 15px 0;
    border-bottom: 1px solid $gris3;
    @media(max-width: $screen-md){
        padding-left: 0px;
    }
    ol{
        @include djv-container();
        padding-left: 0;
        margin-bottom: 0;
        text-align: center;
        li{
            display: inline-block;
            color:$primario2;
            &::after {
                content:'\004c';
                display: inline-block;
                font-family: 'comunes';
                font-style: normal;
                font-weight: normal;
                line-height: 1;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
                margin: 0 4px;
                font-size: 8px;
            }
            &:last-of-type{
                a{
                    color:$gris7;
                }
                &::after{
                    content: "";
                    display: none;
                }
            }
        }
    }
}
