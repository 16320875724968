#header{
    min-height: 180px;
    position: relative;
    z-index: 99;
    width: 100%;
    top: 0;
    border-bottom: 1px solid $gris3;
    max-width: 100vw;
    //box-shadow: 1px 1px 5px 2px rgba(0, 0, 0, 0.2);
    @media(max-width: $screen-lg){
        min-height: 40PX;
        height: 40px;
        position: fixed;
    }
    #header-topbanner{
        position: relative;
        width: 100%;
        display: none;
        overflow: hidden;
        background-color: white;
        .djv-container{
            @media(max-width: $screen-md){
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                max-height: 105px;
            }
            position: relative;
            #close-banner-top{
                display: none;
                position: absolute;
                top:5px;
                right: 10px;
                background-color: white;
                border-radius: 50%;
                height: 18px;
                @media(max-width: $screen-md){
                    right: 2px;
                }
            }
            a{
                position: relative;
                display: block;
                overflow: hidden;
                width: 100%;
            }
            img{
                position: relative;
                left: 50%;
                transform: translateX(-50%);
                @media(max-width: $screen-md){
                    max-width: 100%;
                    max-height: 105px;
                }
            }

        }
        @media(max-width: $screen-lg){
            display: none;
        }
    }
    i.djv-icon-lupa{
        display: none;
        position: relative;
        color: white;
        cursor: pointer;
        z-index: 9;
        top: 9px;
        font-size: 19px;
        position: absolute;
        right: 115px;
        @media(max-width: $screen-lg){
            display: block;
            right: auto;
            left: calc(-100vw + 169px);
            position: absolute;
        }
    }
    #menu-icon-responsive {
        display: inline-block;
        position: relative;
        top: 2px;
        font-size: $font-lg;
        left: 14px;
        color: white;
        cursor: pointer;
        z-index: 9;
        &::before{
            @media(max-width: $screen-lg){
                display: inline-block;
            }
        }
        span{
            @media(max-width: $screen-lg){
                display: none;
            }
        }

    }
    .headerGrid{
        background-image: url("#{$urlimagenes}topbarbg.gif");
        background-repeat: repeat-x;
        background-size: 1px 40px;
        background-color: white;
        height: 180px;
        @media(max-width: $screen-lg){
            height: 40px;
        }
        .djv-container{
            position: relative;
            display: grid;
            grid-template-columns: 31% 44% 25%;
            grid-template-areas:
            "topbarleft topbarcenter topbarright"
            "headersectionleft headersectioncenter headersectionright"
            "mainMenu mainMenu mainMenu";
            align-items: center;
            @media(max-width: $screen-lg){
                grid-template-columns: 100px auto 100px;
                grid-template-areas:"navicons headersectioncenter topbarright";
            }
            @media(max-width: $screen-lg){
                margin-left: 0px;
                margin-right: 0px;
                width: 100%;
            }
            /* Bloque de grid */
            .nav-icons{
                grid-area: navicons;
                display: none;
                @media(max-width: $screen-lg){
                    display: block;
                }
            }
            .header-topbar-before{
                grid-area: topbarleft;
                display: inline-block;
                font-size: 0.8rem;
                color: white;
                @media(max-width: $screen-lg){
                    display: none;
                }
                a{
                    color:inherit;
                }
                .link-color{
                    color: $gris3;
                    &:hover{
                        color: $gris9;
                    }
                }
            }
            .header-topbar-center{
                grid-area: topbarcenter;
                display: inline-block;
                font-size: 0.8rem;
                text-align: center;
                @include fuente($font-first , $font-bold);
                color: white;
                span{
                    color:white;
                    a{
                        color:white;
                    }
                }
                i{
                    position: relative;
                    margin-right: 7px;
                    margin-left: 7px;
                    &::before{
                        font-size: $font-md;
                        font-weight: bold;

                    }
                }
                @media(max-width: $screen-lg){
                    display: none;
                }
            }
            .header-topbar-after{
                grid-area: topbarright;
                display: inline-block;
                font-size: 0.8rem;
                position: relative;
                .djv-icon-usuario{
                    color: white;
                }
            }
            .header-section-left{
                grid-area: headersectionleft;
                @media(max-width: $screen-lg){
                    display: none;
                }
                h1,h2{
                    font-size: $font-md;
                    @include fuente($font-first , $font-black);
                    @include djv-border-bottom("solid");
                    padding-bottom: 5px;
                    margin-bottom: 5px;
                }
                p{
                    font-size: $font-xs+1;
                    color:$gris5;
                    margin-bottom: 0;
                }
            }
            .header-section-center{
                grid-area: headersectioncenter;
            }
            .header-section-right{
                grid-area: headersectionright;
                position: relative;
                @media(max-width: $screen-lg){
                    display: none;
                    position: absolute;
                    top: 40px;
                    width: 100%;
                    padding: 0px 4px;
                    background-color: $primario2;
                }
            }
            .header-nav-top{
                grid-area: mainMenu;
                @media(max-width: $screen-lg){
                    display: none;
                }
            }
            /* --- Fin Bloque de elementos adicionales*/
            /* Bloque de elementos adicionales*/
            .djv-icon-usuario {
                font-size:21px;
                position: absolute;
                top: 8px;
                right: 71px;
                padding: 0 6px;
                cursor: pointer;
                color: #26314c;
            }
            .djv-icon-coment, .djv-icon-crono{
                font-size: $font-sm*1.1;
                margin-right: 4px;
                position: relative;
                display: inline-block;
                width: 20px;
                height: 20px;
            }

            .logo{
                background-image: url("#{$urlimagenes}logo.svg");
                background-position: left top;
                background-repeat: no-repeat;
                background-size: 250px;
                width: 300px;
                height: 100px;
                display: block;
                margin: -6px auto 0 auto;
                padding: 0px;
                position: relative;
                @media(max-width: $screen-lg){
                    width: 100px;
                    height: 40px;
                    background-image: url("#{$urlimagenes}logo_sticky.svg");
                    background-size: 100px;
                    background-position: center 10px;
                    margin-top: 0;
                }
                @media(max-width: $screen-sm){
                    width: 100px;
                    height: 40px;

                }
                .titleLogo{
                    @include fuente($font-first , $font-black);
                    font-size: 54px;
                    color: black;
                    top: 35px;
                    position: relative;
                    margin-left: 75px;
                }
                .subTitleLogo{
                    display: block;
                    margin-top: 24px;
                    margin-bottom: 10px;
                    padding: 5px;
                    position: absolute;
                    bottom: -10px;
                    font-size: $font-sm;
                    color: $gris6;
                    line-height: $font-xs;
                    @media(max-width: $screen-lg){
                        display:none;
                    }
                }
            }
            #search_widget {
                width: 100%;
                display: block;
                margin: 5px auto;
                position: relative;
                input{
                    background-color: white;
                    width: 100%;
                    display: block;
                    position: relative;
                    border: 1px solid #999999;
                    border-bottom-left-radius: 0px;
                    border-bottom-right-radius: 0px;
                    border-top-left-radius: 0px;
                    border-top-right-radius: 0px;
                    padding: 11px 20px 11px 5px;
                    color: #26445C;
                    font-family: $font-first;
                    font-size: $font-xs;
                    @media(max-width: $screen-lg){
                        border:none;
                    }
                    &::placeholder {
                        font-size: 12px;
                        color: #666666;
                        font-family: $font-first;
                        text-align: center;
                    }
                }
                button {
                    position: absolute;
                    top: 8px;
                    right: 0px;
                    border: none;
                    background-color: transparent;
                    width: 30px;
                    height: 30px;
                    cursor: pointer;
                    &::before {
                        display: inline-block;
                        font-size: $font-blg;
                        right: 0px;
                        top: 0px;
                        position: absolute;
                        color: $gris6;
                        margin-right: 10px;
                        margin-top: 2px;
                    }
                }
            }
            #blockcart-wrapper {
                position: relative;
                z-index: 999;
                display: inline-block;
                float: right;
            }
            .blockcart {
                .header {
                    color: white;
                    font-size: 0.8rem;
                }
            }
            .header-nav-corporative{
                margin: 0;
                padding: 0;
                text-align: center;
                @media(max-width: $screen-lg){
                    display: none;
                }
                li{
                    list-style: none;
                    display: inline-block;
                    font-size: 13px;
                    color:$primario1;
                    text-align: center;
                    a{
                        color:inherit;
                        &.nav-link{
                            padding: 5px;
                        }
                        i{
                            display: block;
                            width: 25px;
                            height: 25px;
                            margin: 0 auto;
                            font-size: $font-lg;
                        }
                    }
                    &.nav-item-separator{
                        color:$gris9;
                    }
                }
            }
            /* -- Fin Bloque de elementos adicionales*/
        }
    }
    &.recogido{
        height: 38px;
        min-height: auto;
        position: fixed;
        top:0;
        .djv-icon-lupa{
            display: inline-block;
            color: white;
        }
        .headerGrid{
            height: 39px;
            .djv-container{
                grid-template-columns: 120px auto 140px;
                grid-template-areas:"headersectioncenter mainMenu topbarright";
                position: relative;
                form{
                    .djv-icon-lupa{
                        display: none;
                    }
                }
            }
            .nav-icons{
                grid-area: navicons;
                display: none;
            }
            .header-topbar-before{
                display: none;
            }
            .header-topbar-center{
                display: none;
            }
            .header-topbar-after{
                grid-area: topbarright;
                margin-top: -6px;
            }
            .header-section-left{
                display: none;
            }
            .header-section-center{
                grid-area: headersectioncenter;
                position: relative;
                top:-2px;
            }
            .header-section-right{
                display: none;
                position: absolute;
                right: 67px;
                width: 200px;
                top: 32px;
                #search_widget {
                    width: 100%;
                    display: block;
                    margin: 5px auto;
                    position: relative;
                    input{
                        border-bottom-left-radius: 3px;
                        border-bottom-right-radius: 3px;
                        border-top-left-radius: 0px;
                        border-top-right-radius: 0px;
                    }

                }
            }
            .header-nav-top{
                grid-area: mainMenu;
                //display: none;
                //position:fixed;
                //left:0;
                //top:0;
                //height:100vh;
                z-index:10;
            }
            .header-nav-corporative{
                display:none;
            }
            .logo{
                display: block;
                background-image: url("#{$urlimagenes}logo_sticky.svg");
                background-repeat: no-repeat;
                background-size: contain;
                width: 100px;
                height: 36px;
                margin: 7px auto 0;
                @media(max-width: $screen-lg){
                    margin: 0;
                }
                .subTitleLogo{
                    display: none;
                }
            }
        }
    }
}
